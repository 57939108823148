<template>
    <div>
        <topName
      ref="topNav"
      :showDateCredit="false"
      :showEventTicket="false"
      :showMobileTop="false"
    />
    <div class="page_container">
        <div
        class="mx-auto col-lg-4 col-md-5 col-sm-12 px-2 page-div py-5 text-center"
        style="max-width: 360px !important"
      >

        <b-img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/2022/paused_icon.png" width="100" center class="mb-5"></b-img>


        <h4 class="mb-3">{{$t('account_is_paused')}}</h4>
        <p class="mb-4">{{$t('unpause_for_activate_matches')}}</p>
        
        <b-button variant="ls-red2" @click="unpauseAccount" class="text-uppercase fw-600 px-5" pill>{{$t('unpause_account')}}</b-button>
      </div>
    </div>
    </div>
</template>
<script>
import topName from "../common/topNav";
export default {
    name: "account-paused",
    components: {
    topName,
  },
    data(){
        return {}
    },
    mounted(){

    },
    methods: {
        unpauseAccount(){
            let self = this;
            self.$layer.loading();
            let fdata = new FormData();
            fdata.append("operate", "active");
            self.$api.postClientOperate(fdata, self.$store.state).then((rs) => {
                self.$layer.closeAll("loading");
                // location.reload()
                self.$router.push({ name: "profile-me" });
            });
        }
    }
}
</script>