<template>
  <div v-if="verified==1">
    <a class="vifiedIcon" @click.stop="diaplayDesc">
      <b-img
        width="25"
        src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/photo_verified_gray.png"
        style="border: none !important;"
      />
    </a>
    <div v-if="descShow" class="text-center verifiedDesc">
      <p>
        <strong>{{$t('verified_photo')}}</strong>
      </p>
      <p>{{$t('verified_photo_desc')}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "verifiedTag",
  props: {
    verified: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      descShow: false
    };
  },
  methods: {
    diaplayDesc() {
      if (this.descShow) {
        this.descShow = false;
      } else {
        this.descShow = true;
      }
    }
  }
};
</script>