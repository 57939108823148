<template>
  <div id="app">
    <!-- <div
      id="lsloaddiv"
      style="background: rgba(0,0,0,.7);
    position: fixed;
    z-index:99999999;
    width: 100%;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;"
    >
      <div
        style="width: 200px;
    text-align: center;
    background: #fff;
    color: #999;
    border-radius: 4px;"
      >
        <img src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/loading.gif" style="border:none !important;" />
      </div>
    </div>-->
    <transition name="fade" mode="out-in">
      <div id="viewContent">
        <router-view />
      </div>
    </transition>
  </div>
</template>

<script>
// import { tencentIM } from "./utils/mixin";
export default {
  name: "App",
  //   mixins: [tencentIM],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    startLottie(elId, filePath) {
      if (bodymovin) {
        bodymovin.loadAnimation({
          // container: this.$refs.lavContainer,
          container: document.getElementById(elId),
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: `https://cdn-cf2.lovestruck.com/upload/gen/${filePath}`,

        });
      }

    },
    apptest() {
      console.log("app test......");
    },
    closeLoading() {
      //   $("#viewContent").show();
      this.isLoading = false;
      // $("#lsloaddiv").fadeOut();
    },
    showLoading() {
      this.isLoading = true;
      //   $("#viewContent").hide();
      // $("#lsloaddiv").show();
    },
    loadBranchBar() {
      console.log("loadBranchBar", typeof branch);
      if (typeof branch != "undefined") {
        //branch.init('key_live_pfyCbRdcV10KNKyVu9LSxcfavCaNZQOV');
        branch.init(
          "key_live_pfyCbRdcV10KNKyVu9LSxcfavCaNZQOV",
          function (err, data) {
            console.log("branch", err, data);
            // branch.banner();
          }
        );
      }
    },
  },
  mounted() {
    console.log("app dfdsf mounted");
    // this.initTIM();
    let waitLovestruckLoaded = () => {
      return new Promise((resolve, reject) => {
        let ts = setInterval(() => {
          if (typeof Lovestruck === "object") {
            resolve();
            ts && clearInterval(ts);
          }
        }, 500);
      });
    };

    let registerWebPush = () => {
      let clientAuthToken = localStorage.token;
      let sns = Lovestruck.SNS({
        messagingSenderId: "182656465799", // Lovestruck Firebase Sender ID
        swUrl: "/app/lib/firebase-messaging-sw.js", // null means default "/firebase-messaging-sw.js"
      });

      // Set handler when notification received
      sns.onMessageInForeground((payload) => {
        // ref: https://developer.mozilla.org/en-US/docs/Web/API/notification
        console.info(`foreground notification payload:`, payload);
        new Notification(_.get(payload, "notification.title"));
      });

      if (clientAuthToken) {
        sns
          .onload()
          .then(({ firebase }) => {
            console.info(
              `lovestruck SNS ready, firebase object loaded`,
              firebase
            );

            // prompt user allow permission
            return sns
              .askForPermissionToReceiveNotifications()
              .catch((err) => { });
          })
          .then((firebaseToken) => {
            console.info(
              `user accepted, register user token: ${firebaseToken}`
            );
            sns.sendToken(firebaseToken, clientAuthToken);
          })
          .catch((err) => {
            console.error(`something wrong`, err);
          });
      }
    };

    waitLovestruckLoaded().then(registerWebPush);
  },
};
</script>

<style>
#app {
  width: 100%;
  height: fit-content;
}

#viewContent {
  width: 100%;
  height: inherit;
}

.btn:focus,
.btn:active:focus,
.btn:active:focus,
.btn:focus,
.btn:active.focus,
.btn:active.focus {
  outline: none;
  box-shadow: none !important;
}
</style>
