import { render, staticRenderFns } from "./singlePage.vue?vue&type=template&id=ae7f0caa&"
import script from "./singlePage.vue?vue&type=script&lang=js&"
export * from "./singlePage.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "19b3b2cc"
  
)

export default component.exports