import { render, staticRenderFns } from "./registerForm.vue?vue&type=template&id=49edb994&scoped=true&"
import script from "./registerForm.vue?vue&type=script&lang=js&"
export * from "./registerForm.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "49edb994",
  "13fd8dd8"
  
)

export default component.exports