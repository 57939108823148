<template>
  <div
    v-if="show"
    id="sms_country_box"
    class="sms_country_box"
    v-bind:style="{ top: `${topx}px` }"
  >
    <!-- <div class="m_top bg_fff">
      <a href="javascript:;" rel="nofollow" @click="closeList">
        <i class="fa fa-caret-left text-dark"></i>
      </a>
    </div> -->
    <div class="content" style="padding: 10px">
      <!-- <p class="list-title">{{$t('top_country')}}</p> -->
      <ul v-if="countryCodeList.length > 0" class="countty-list">
        <li
          v-for="(p, index) in countryCodeList"
          v-if="p.dial_code"
          :key="`1-${index}`"
          @click="onSelectCountry(p)"
        >
          <a href="javascript:;" class="country_item" :data-code="p.dial_code">
            <img
              class="site_flag_img border-0"
              :src="p.flag_url"
              :alt="p.name"
            />
            <!-- <span>{{ p.emoji }}</span> -->
            <span>+{{ p.dial_code }} {{ p.name }}</span>
          </a>
        </li>
      </ul>
      <!-- <p class="list-title">{{$t('all_country')}}</p>
      <ul class="countty-list">
        <li
          v-for="(se, index) in secondary_country"
          v-if="se.dial_code"
          :key="index"
          @click="onSelectCountry(se)"
        >
          <a href="javascript:;" class="country_item" :data-code="se.dial_code">
            <img class="site_flag_img border-0" :src="se.flag_url" :alt="se.name" />
            <span>+{{ se.dial_code }} {{ se.name }}</span>
          </a>
        </li>
      </ul>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "countrySmsCodeList",
  props: {
    topx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      displayVal: "none",
      countryCodeList: [],
      primary_country: [],
      secondary_country: [],
      show: false,
    };
  },
  methods: {
    onSelectCountry(country) {
      //console.log(country);
      //   this.displayVal = "none";
      this.show = false;
      this.$emit("selectCountry", country);
    },
    showList() {
      //   this.displayVal = "block";
      let self = this
      if(self.show){
        self.closeList()
      }else{
        self.show = true;
      }
      
    },
    closeList() {
      //   this.displayVal = "none";
      this.show = false;
      this.$emit("closeList");
    },
  },
  mounted() {
    let self = this;
    //getIpLocal
    // console.log("getCountryCode2");
    self.$api.getCountryCode2(self.$store.state).then((rs) => {
      self.countryCodeList = rs;
      //   self.primary_country = rs.primary;
      //   self.secondary_country = rs.secondary;
    });
  },
};
</script>

<style></style>
