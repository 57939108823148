<template>
  <div class="msgInputDiv">
    <div class="msgPlaceholder">{{ $t("type_message") }}</div>
    <div
      class="msgInput"
      contenteditable="true"
      v-html="messageContent"
      @input="changeText"
      :style="inputStyle"
    ></div>
  </div>
</template>
<script>
export default {
  name: "msg-input",
  props: {
    minHeight: {
      type: Number,
      default: 38,
    },
    maxHeight: {
      type: Number,
      default: 160,
    },
    // text: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      messageContent: "",
      inputStyle: {
        "min-height": `${this.minHeight}px`,
        "max-height": `${this.maxHeight}px`,
      },
    };
  },
  mounted() {
    let self = this;
    setTimeout(function () {
      self.bindEvent();
    }, 100);
  },
  methods: {
    bindEvent() {
      let self = this;
      $(".msgPlaceholder").on("click", function () {
        this.hide();
      });

      $(".msgInput").on("focus", function () {
        $(".msgPlaceholder").hide();
      });

      $(".msgInput").on("blur", function () {
        let text = self.$el.innerText;
        if (text.length == 0) {
          $(".msgPlaceholder").show();
        }
      });
    },
    changeText() {
      //   console.log("change text", this.messageContent);
      this.$emit("change", this.$el.innerText);
    },
    clearText() {
      //   console.log("clear text", this.messageContent);
      this.messageContent = "";
      //   this.$el.innerText = "";
      $(".msgInput").html("");
      $(".msgPlaceholder").show();
    },
  },
};
</script>
<style>
.msgInputDiv {
  position: relative;
  width: 100%;
}
.msgInput {
  position: relative;
  width: 100%;
  /* max-width: calc(100% - 40px); */
  /* min-height: 20px;
  max-height: 160px; */
  _height: 120px;
  margin-left: auto;
  margin-right: auto;
  padding: 3px;
  outline: 0;
  border: 1px solid #a0b3d6;
  font-size: 16px;
  padding: 8px 15px;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  border-color: #ced4da;
  border-radius: 19px;
  line-height: 20px;
}
.msgPlaceholder {
  position: absolute;
  left: 15px;
  top: 6px;
  color: #b7b7b7;
}
</style>