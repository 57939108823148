import { render, staticRenderFns } from "./profileCardCom.vue?vue&type=template&id=200b2244&scoped=true&"
import script from "./profileCardCom.vue?vue&type=script&lang=js&"
export * from "./profileCardCom.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./profileCardCom.vue?vue&type=style&index=0&id=200b2244&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "200b2244",
  "01d85896"
  
)

export default component.exports