<template>
  <div class="bg-faf min-h100 text-1d">
    <!-- <div class="" style="z-index: 1000; width: 100%; text-align: right">
      <a href="javascript:;" @click="closePage" class="p-2">
        <i class="lsicon lsicon-30 close_grey2"></i>
      </a>
    </div> -->
    <div class="page6-2 px-3">
      <h5 class="py-5 text-center fw-600">
        {{ $t("payment_pending_review") }}
      </h5>

      <div class="card bg-white mb-5 mx-auto p-3 text-center rounded border-0">
        <div class="mb-3">
          <i
            class="fa-regular fa-hands-holding-dollar"
            style="font-size: 50px"
          ></i>
        </div>
        <p class="fw-600 mb-3">{{ $t("payment_pending_review_desc1") }}</p>

        <p class="mb-3">{{ $t("payment_pending_review_desc2") }}</p>
        <p>{{ $t("payment_pending_review_desc3") }}</p>
      </div>

      <div class="text-center">
        <b-button
          @click="closePage"
          variant="ee1"
          class="mt-4 px-5 text-uppercase l-space2 w-100"
          pill
          style="height: 53px"
        >
          {{ $t("close_txt") }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pending-review",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    closePage() {
      this.$router.push({ name: "profile-me" });
    },
  },
};
</script>