<template>
  <div>
    <topName
      ref="topNav"
      :showDateCredit="false"
      :showEventTicket="false"
      :showMobileTop="false"
    />
    <div class="page_container">
      <div class="page6-2 px-2 py-5 text-center">
        <b-img
          src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/2022/call_image.jpg"
          width="220"
          center
          class="mb-5"
        ></b-img>

        <h4 class="mb-3">{{ $t("verify_pending_title") }}</h4>
        <p>{{ $t("usually_48_hours") }}</p>
        <div style="height: 20vh"></div>
        <p class="px-3" v-html="verifyPendingDesc"></p>
      </div>
    </div>
  </div>
</template>
<script>
import topName from "../common/topNav";
import helper from "../../utils/helper";
export default {
  name: "verify-pending",
  components: {
    topName,
  },
  data() {
    return {
      userInfo: this.$storage.get("userInfo"),
      verifyPendingDesc: "",
    };
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();

    self.verifyPendingDesc = self
      .$t("verify_pending_desc")
      .replace(
        "<a>",
        `<a href="javascript:;" class="text-ls-red2 chatMatchamer">`
      );

    setTimeout(function () {
      self.initClick();
    }, 300);
  },
  methods: {
    initClick() {
      let self = this;
      $(".chatMatchamer").on("click", function () {
        self.goMatchmakerChat();
      });
    },
    goMatchmakerChat() {
      let self = this;

      self.$router.push({
        name: "client-chat",
        query: {
          clientId: helper.lshash.encode(
            _.get(self.userInfo, "matchmaker_chat_id", 0)
          ),
        },
      });
    },
  },
};
</script>