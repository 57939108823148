import { render, staticRenderFns } from "./planCarousel.vue?vue&type=template&id=05065414&scoped=true&"
import script from "./planCarousel.vue?vue&type=script&lang=js&"
export * from "./planCarousel.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./planCarousel.vue?vue&type=style&index=0&id=05065414&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "05065414",
  "20d5f70e"
  
)

export default component.exports