<template>
  <div>
    <div id="memberIframeBox">
      <iframe :src="iframeUrl" width="550" height="680"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "lsIframe",
  props: {
    iframeUrl: {
      type: String,
      default: ""
    }
  },
  methods: {
    show() {
      let self = this;

      self.$layer.loading();
      $("#memberIframeBox").fadeIn();

      setTimeout(function() {
        self.$layer.closeAll("loading");
      }, 2000);
    },
    hide() {
      $("#memberIframeBox").fadeOut();
    }
  }
};
</script>
<style scoped>
#memberIframeBox {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  text-align: center;
  display: none;
}
#memberIframeBox iframe {
  width: 550px;
  height: 680px;
  border: none;
  margin-top: 10px;
}
</style>