import { render, staticRenderFns } from "./likeChatSentPopup.vue?vue&type=template&id=9854b9dc&scoped=true&"
import script from "./likeChatSentPopup.vue?vue&type=script&lang=js&"
export * from "./likeChatSentPopup.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./likeChatSentPopup.vue?vue&type=style&index=0&id=9854b9dc&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "9854b9dc",
  "4710da68"
  
)

export default component.exports