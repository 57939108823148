//import LRU from 'lru-cache'
export function createAPI() {
    let api = {}
    //   console.log('client create api')
    api.onServer = false

    // fetched item cache
    // api.cachedItems = new LRU({
    //   max: 1000,
    //   maxAge: 1000 * 60 * 180,
    //     // length: function (n, key) { return n * 2 + key.length },
    //     // dispose: function (key, n) { n.close() }
    //   // maxAge: 1000 * 60 * 15 // 15 min cache
    // })

    return api
}
