<template>
  <div>
    <button @click="showCopper">Show Copper</button>
    <input type="file" name="photo" @change="selectFile" />
    <div>
      <img :src="dataUrl" alt="" width="200" />
    </div>

    <imageCopper @change="change" ref="imageCopper" />
  </div>
</template>

<script>
import imageCopper from "../common/imageCopper.vue";
// import regForm from "../common/registerForm.vue";
export default {
  components: {
    imageCopper,
  },
  data() {
    return {
      dataUrl: "",
      //   img: "http://cdn2.88ljsm.com/suyuan/images/product/202203/400acfdece1088f9cccd7e23f7e11658.jpg",
      img: "http://cdn2.88ljsm.com/suyuan/images/index_blocks/kt-02.jpeg",
    };
  },
  methods: {
    selectFile() {
      let e = window.event;

      //console.log(index);
      const file = e.target.files[0];

      // this.compressor(file, index);
      this.$refs.imageCopper.showModal(file, 2 / 3);
    },
    showCopper() {
      this.$refs.imageCopper.showModal(this.img, 2 / 3);
    },
    change(data) {
      console.log(data);
      //   console.log(data.canvas.toDataURL("image/jpeg"));
      this.dataUrl = data.imageData;
    },
  },
};
</script>