import { render, staticRenderFns } from "./yotiResult.vue?vue&type=template&id=4d9e1672&"
import script from "./yotiResult.vue?vue&type=script&lang=js&"
export * from "./yotiResult.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "7115668e"
  
)

export default component.exports