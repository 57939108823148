import { render, staticRenderFns } from "./local.vue?vue&type=template&id=e2912658&scoped=true&"
import script from "./local.vue?vue&type=script&lang=js&"
export * from "./local.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "e2912658",
  "4c791fd5"
  
)

export default component.exports