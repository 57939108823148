export default {
    set(key, value, exp = 0) {
        if (typeof localStorage == 'undefined') {
            return false;
        }
        let now = (new Date()).getTime();
        let exp_key = `${key}_expiry`;

        exp = exp ? now + (exp * 1000) : 0;

        localStorage.setItem(exp_key, exp);
        if (typeof value == 'object') {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            localStorage.setItem(key, value)
        }
    },
    get(key) {
        // console.log('-----storage get:', key)
        if (typeof localStorage == 'undefined') {
            return false;
        }
        let now = (new Date()).getTime();
        let exp_key = `${key}_expiry`;
        let expiry = localStorage.getItem(exp_key)
        //console.log(expiry,now)
        if (expiry > 0 && expiry < now) {
            this.remove(key);
            return false;
        }
        let cache = localStorage.getItem(key)
        try {
            return cache ? JSON.parse(cache) : false;
        } catch (e) {
            //console.log("is not object");
            return cache
        }

    },
    remove(key) {
        if (typeof localStorage == 'undefined') {
            return false;
        }
        localStorage.removeItem(key);
        localStorage.removeItem(`${key}_expiry`);
    },
    clear() {
        if (typeof localStorage == 'undefined') {
            return false;
        }

        localStorage.clear();
    },
    setAuthTag() {
        this.set('hasAuth', 1, 60);
    },
    getAuthTag() {
        return this.get('hasAuth')
    },
    clearLogin() {
        // this.clear()
        // console.log('........clearLogin.....')
        if (typeof localStorage == 'undefined') {
            return false;
        }

        let clientId = _.get(this.get('user'), 'client_id', 0);
        this.remove('user')
        this.remove('userInfo')
        this.remove('token')
        this.remove('tmp-user')
        this.remove('chat-ref')
        this.remove('cur-lang')
        this.remove('userInfoData')
        this.remove('crm-select-plan')
        this.remove(`match_clients_${clientId}`)
        this.remove('hasAuth');
        this.remove('signIn');
        this.remove('user-goal');
        this.remove(`TIM_1400398088_c_${clientId}_conversationMap`);
        this.remove(`TIM_1400398088_c_${clientId}_profile`);
        this.remove('branch_session_first');
        return true;
    },
    setPaymentSelectedPlan(data) {
        return this.set('crm-select-plan', data, 3600)
    },
    getPaymentSelectedPlan() {
        return this.get('crm-select-plan')
    },
    clearPaymentSelectedPlan() {
        this.remove('crm-select-plan')
    },
    setLogiJump(jump) {
        return this.set('login_jump', jump, 3600)
    },
    getLogiJump() {
        return this.get('login_jump')
    },
    clearLogiJump() {
        return this.remove('login_jump')
    },
    clearUserInfo() {
        this.remove('userInfo')
    },
    updateUserInfo(client) {
        let userInfo = this.get('userInfo')
        // console.log('updateUserInfo', client)
        this.set('userInfo', JSON.stringify(client))
        // if (typeof userInfo == 'object') {
        //     userInfo.client_status_id = client.client_status_id
        //     userInfo.language_id = client.language_id
        //     userInfo.is_verified = client.is_verified
        //     userInfo.membership_level_id = client.membership_level_id
        //     userInfo.membership_level_matchmaking_id = client.membership_level_matchmaking_id
        //     userInfo.matchmaker_chat_id = client.matchmaker_chat_id
        //     userInfo.chat_matchamker_info = client.chat_matchamker_info


        // if (typeof userInfo == 'object') {
        // userInfo.client_status_id = client.client_status_id
        // userInfo.language_id = client.language_id
        // userInfo.is_verified = client.is_verified
        // userInfo.membership_level_id = client.membership_level_id
        // userInfo.membership_has_expired = client.membership_has_expired
        // userInfo.membership_discount = client.membership_discount
        // userInfo.matchmaker_chat_id = client.matchmaker_chat_id
        // userInfo.chat_matchamker_info = client.chat_matchamker_info

        //     this.set('userInfo', JSON.stringify(userInfo))
        // } else {
        //     this.set('userInfo', JSON.stringify(client))
        // }



    }
};