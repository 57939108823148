import {
    Url,
    PhoneCodes,
    CallMethods,
    CallPurpose,
    StoreIds,
    DefaultValue
} from "../core/const";
import io from "socket.io-client";
import TIM from "tim-js-sdk";
import TIMUploadPlugin from "tim-upload-plugin";

export const mixin = {
    methods: {
        getClientId: function (from_client_id, to_client_id) {
            return (from_client_id) ? from_client_id : to_client_id
        },
        getName: function (first_name, last_name) {
            if (first_name && last_name) {
                return `${first_name} ${last_name}`;
            }
            else if (first_name && !last_name) {
                return first_name;
            }
            else if (last_name && !first_name) {
                return last_name
            }
            else {
                return 'Unknown'
            }
        },
        getAgeGender: function (age, gender) {
            if (age && gender) {
                return `(${age}${gender})`;
            }
            else if (age && !gender) {
                return `(${age})`;
            }
            else if (gender && !age) {
                return `(${gender})`;
            }
            else {
                return '';
            }
        },
        fullTitle: function (first_name, last_name, age, gender) {
            var result = this.getName(first_name, last_name);
            return `${result}  ${this.getAgeGender(age, gender)}`;
        },
        fullTitleConversationHeader: function (first_name, last_name, age, gender) {
            var first = first_name ? first_name : '';
            var last = last_name ? last_name : '';
            var result = 'Unknown'
            if ((first + last).length >= 1) {
                result = first + ' ' + last;
            }
            return `${result}  ${this.getAgeGender(age, gender)}`;
        },
        clientDetail: function (user) {
            return {
                client_id: user.client.client_id,
                client_first_name: user.client.first_name,
                client_last_name: user.client.last_name,
                client_photo_url: user.client.photo_url,
                from_client_id: user.from_client_id,
                from_employee_id: user.from_employee_id,
                to_client_id: user.to_client_id,
                to_employee_id: user.to_employee_id,
                client_age: user.client.age,
                client_gender: user.client.gender,
                tencent_im_groupID: user.client.tencent_im_groupID,
                tencent_im_userID: user.client.tencent_im_userID,
                phone: `+${user.client.country_phone_code}${user.client.phone_number}`,
                city_id: user.client.city_id,
                status: (user.client.status.description) ? user.client.status.description : '',
                // sent_time: user.sent_time,
                last_active_time: _.get(
                    user,
                    "client.last_active_timestamp",
                    0
                ),
                membership_level_id: user.client.membership_level.membership_level_id,
                membership_level: user.client.membership_level.description,
                total_paid_amount: user.client.total_paid_amount ? user.client.total_paid_amount : 0,
                date_credits_str: `${_.get(user, 'client.date_credits') ? _.get(user, 'client.date_credits') : 0} Date Credits`,
                payment_count: `${_.get(user, 'client.paidCount', 0)} payments`,
                chat_type: _.get(user, 'chat_type')
            };
        },
        openLink(clientID) {
            let url = `${Url.DATETIX}${clientID}`;
            window.open(url, "_blank");
        }
    }
};

export const voipMixin = {
    data() {
        return {
            user: JSON.parse(localStorage.getItem("user")),
            modalConfig: {
                visible: false,
                user: null,
                error: "",
                isCallingToClient: false
            },
            cID: null,
            voip: Lovestruck.VOIP({
                authorization: JSON.parse(localStorage.getItem("user")).token
            })
        }
    },
    methods: {
        //start call
        startCall(phone) {
            const app = this;
            let phone_number = '';
            if (phone) {
                phone_number = phone.replace('(', '').replace(')', '');
            }
            this.voip.call(phone_number).catch(err => {
                app.modalConfig.error = `${err.message} (Network connection issue!)`;
                console.log(
                    "%c VOIP Error",
                    "background: red; color: #FFF",
                    err.message
                );
            });
        },
        //end call
        endCall() {
            this.modalConfig.error = "";
            console.log("%c Call Ended", "background: blue; color: #FFF");
            this.voip.hangup().catch(err => {
                console.log(
                    "%c VOIP Error",
                    "background: red; color: #FFF",
                    err.message
                );
            });
        },
        // Store Call log
        postCallLog(data) {
            const app = this;
            let formData = new FormData();
            // let  authorization = JSON.parse(localStorage.getItem("user")).token;
            // let recordingURL = data.recordingUrl.replace('{TOKEN}', authorization)
            formData.append("client_id", app.cID);
            formData.append("call_time", data.createdAt);
            formData.append("call_purpose_id", CallPurpose.CustomerService);
            formData.append("call_notes", "Called from customer service");
            formData.append("call_method", CallMethods.VOIP);
            formData.append("recording_file", data.recordingUrl);
            formData.append("durationSinceConnected", data.durationSinceConnected);
            this.$http
                .post(`/api/v2/employee/callLogging`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(function (res) {
                    console.log(res, "SUCCESS!!");
                })
                .catch(function (e) {
                    console.log("FAILURE!!", e);
                });
        },
        clickErrorhandler(err) {
            console.log("%c VOIP Error", "background: red; color: #FFF", err.message);
            this.modalConfig.error = err.message;
            return err;
            // this.modalConfig.visible = false;
        }
    },
    mounted() {
        const app = this;

        //VOIP INIT(Connect)
        if (app.user.isEmployee) {
            this.voip.ready().then(() => {
                console.log("%c Initializing VOIP", "background: #222; color: #bada55");
            });
        }
        // on call request sent
        this.voip.on("requested", data => {
            console.warn("$user >>>> requested", data);
        });

        // on call accepted
        this.voip.on("conversation_started", data => {
            console.warn("$user >>>> conversation start", data);
        });

        // on error
        this.voip.on("error", err => {
            console.log("voip calling", err.message);
            console.error("voip calling", err);
            this.modalConfig.error = err.message;
        });

        // on call finished
        this.voip.on("end", data => {
            console.log("finished");
            console.warn("$user >>>> call ended", data);
            this.modalConfig.error = "";
            this.modalConfig.visible = false;
            app.postCallLog(data);
        });
    }
}

export const tencentIM = {
    data() {
        return {
            TIMO: null,
            tim: null,
            tencentImUserID: null,
            tencentImSig: null,
            repeatLogin: true
        }
    },
    methods: {
        initTIM() {
            let self = this;
            self.TIMO = TIM;
            let options = {
                SDKAppID: 1400398088, // 接入时需要将 0 替换为您的云通信应用的 SDKAppID，类型为 Number
            };
            // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
            self.tim = TIM.create(options); // SDK 实例通常用 tim 表示

            // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
            // self.tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
            self.tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用

            // 注册腾讯云即时通信 IM 上传插件
            self.tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });

            self.TIMLogin();
            self.addTIMEvnets()
        },
        addTIMEvnets() {
            let self = this;
            console.log("tim event");
            self.tim.on(TIM.EVENT.MESSAGE_RECEIVED, self.onTIMMessageReceived);
            //   self.$parent.TIMSdkReady(self.onTIMSdkReady);
            self.tim.on(TIM.EVENT.SDK_READY, self.onTIMSdkReady);
            self.tim.on(TIM.EVENT.SDK_NOT_READY, self.onSdkNotReady);
        },
        TIMLogin() {
            let self = this;
            // console.log(_.get(self.$storage.get('userInfo'), "tencent_im_userID"), _.get(self.$storage.get('userInfo'), "tencent_im_sig"))
            let promise = self.tim.login({
                userID: self.tencentImUserID,
                userSig: self.tencentImSig,
            });
            promise
                .then(function (imResponse) {
                    // console.log("登录成功", imResponse.data); // 登录成功
                    if (imResponse.data.repeatLogin === true) {
                        if (self.repeatLogin) { self.TIMLoginOut(); }
                        // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
                        console.log(imResponse.data.errorInfo);
                    }

                    //   self.loopTIMConversationList(5000);
                })
                .catch(function (imError) {
                    console.warn("login error:", imError); // 登录失败的相关信息
                });
        },
        TIMLoginOut() {
            let self = this;
            console.log("login out");
            let promise = self.tim.logout();
            promise
                .then(function (imResponse) {
                    // clearInterval(self.loopTimer);
                    console.log(imResponse.data); // 登出成功
                })
                .catch(function (imError) {
                    console.warn("logout error:", imError);
                });
        },
        // TIMMessageReceived(func) {
        //     console.log('TIMMessageReceived..', func)
        //     this.tim.on(
        //         TIM.EVENT.MESSAGE_RECEIVED,
        //         func
        //     );
        // },
        // TIMSdkReady(func) {
        //     console.log('TIMSdkReady..')
        //     this.tim.on(
        //         TIM.EVENT.SDK_READY,
        //         func
        //     );
        // }
    }
}

export const webSocket = {
    data() {
        return {
            socket: null
        }
    },
    methods: {
        initSocket() {
            let self = this;
            console.log('...initSocket')
            if (!self.socket) {
                self.socket = io("https://im.lovestruck.com", {
                    path: "/chat.io",
                    query: {
                        token: _.get(self.$storage.get("user"), "token"),
                    },
                });
            }

            self.listenServerNotify()
        },
        listenServerNotify() {
            let self = this;

            self.socket.on("serverNotify", (data) => {
                try {
                    let jsonData = JSON.parse(data);

                    console.log("serverNotify=", jsonData);
                    let type = _.get(jsonData, 'type')
                    // console.log('type=', type)
                    switch (type) {
                        case 'match_first_msg':
                            self.doMatchFirstMsg(jsonData);
                            break;
                        case 'verify_res_msg':
                            self.doVerifyResMsg(jsonData);
                            break;
                        case 'yoti_res_msg':
                            self.doYotiResMsg(jsonData);
                            break;
                    }
                } catch (err) {
                    console.log('serverNotify', err)
                }



            });
        },
        doYotiResMsg(jsonData) { },
        doVerifyResMsg(jsonData) {
            let self = this;
            console.log('doVerifyResMsg')
            let toClientId = _.get(jsonData, 'to_client_id')
            if (self.client && self.$route.name == 'profile-unverified' && toClientId == self.client.client_id) {
                if (_.get(jsonData, 'data.is_verified') == 1) {
                    self.$router.push({ name: "matchmaking-new-matches" });
                }

                return false;
            }

        },
        doMatchFirstMsg(jsonData) {
            let self = this;
            // console.log('doMatchFirstMsg')
            let toClientId = _.get(jsonData, 'to_client_id')
            if (self.client && self.$route.name == 'matchmaking-new-matches' && toClientId == self.client.client_id) {

                let index = _.findIndex(self.matches, {
                    target_client: { client_id: _.get(jsonData, "data.match_client_id") },
                });
                // console.log("index=", index);
                if (index > -1) {
                    self.matches[index]["label_button"]["label_array"] = _.get(
                        jsonData,
                        "data.label_array"
                    ); //
                    self.matches[index]["label_button"]["buttons"] = _.get(
                        jsonData,
                        "data.buttons"
                    );

                    self.matches[index]["target_client"]["msg_sent_count"] = 1;
                }
                // let intro = _.get(self.matches, index);
                // if (self.currentMatch && _.get(self.currentMatch, "target_client.client_id") == _.get(jsonData, "target_client_id")) {
                //     self.currentMatch["label_button"]["label_array"] = _.get(
                //         jsonData,
                //         "label_array"
                //     ); //
                //     self.$refs.newMatchCardCom.showView(self.currentMatch);
                // }
            }
        }
    }
}

export const accountStatus = {
    methods: {
        checkAccountStatus(client) {
            if (client.is_delete_account == 1) {
                this.deletedAccountAlert()
            }
        },
        deletedAccountAlert() {
            let self = this;
            const h = self.$createElement;
            let message = h("div", { class: ['text-center'] }, [
                h("p", {}, ['Your account has been deleted. Do you want to un-delete your account?'])
            ])

            self.$bvModal
                .msgBoxConfirm(message, {
                    title: false,
                    headerClass: "text-secondary",
                    bodyClass: "text-secondary",
                    size: "md",
                    buttonSize: "md",
                    okVariant: 'ls-red2',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    footerClass: "p-2",
                    hideHeaderClose: true,
                    centered: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true
                })
                .then((value) => {
                    // console.log('msgBoxConfirm', value)
                    self.$layer.loading();
                    if (value) {
                        let fdata = new FormData();
                        fdata.append("operate", "active");


                        self.$api.postClientOperate(fdata, self.$store.state).then((rs) => {
                            // if (actName == "confirm-delete") {
                            //   self.loginOut();
                            // } else {
                            //   self.loadData();
                            // }
                            location.reload()
                        });
                    } else {
                        self.$storage.clearLogin();
                        let url = `https://${location.hostname}/app/`;

                        location.href = url;
                    }

                })
        }
    }
}