<template>
  <div class="min-h100 bg-faf">
    <pageTop ref="pageTop" :routerBack="false" @navBack="backEventHandler" classNames="page6-2"></pageTop>
    <div v-if="profile" class="page6-2 pb-5">
      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("account_settings") }}
      </div>
      <div class="">
        <ul class="list-group list-group-flush profileEdit">
          <li class="list-group-item bg-faf text-1d border-d7c">
            <div class="d-flex mb-0">
              <div class="text-nowrap mr-2 filter_title">
                {{ $t("Language") }}
              </div>
              <!-- <div class>{{userLanguage}}</div> -->
              <div v-if="orderLangs" class="line-h-24 text-7e" @click="showOption('lang_code')"
                style="height: auto; width: 80%">
                <!-- {{userLanguage}} -->
                <smart-select ref="lang_code" @beforeShow="showOption('lang_code')" :selected="curLangCode"
                  filed="lang_code" @updateValue="updateSelectedValue" idName="lang_code" descName="description"
                  titleKey="Language" :emptyString="$t('add_txt')" :options="orderLangs" :isStrId="true" />
              </div>
              <div @click="showOption('lang_code')" class="filter_arrow" style="top: unset; right: 20px">
                <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              </div>
            </div>
          </li>
          <li class="list-group-item bg-faf text-1d border-d7c">
            <div class="d-flex mb-0">
              <div class="text-nowrap mr-2 filter_title">
                {{ $t("mobile_number") }}
              </div>
              <div class="line-h-24 text-7e">
                +{{ profile.country_phone_code }} {{ profile.phone_number }}
              </div>
            </div>
          </li>
          <li @click="editProfile" class="list-group-item bg-faf text-1d border-d7c">
            <div class="d-flex mb-0">
              <div class="text-nowrap mr-2 filter_title">
                {{ $t("login_email_address") }}
              </div>
              <div class="line-h-24 text-7e">{{ profile.email }}</div>
            </div>
          </li>
          <li class="list-group-item bg-faf text-1d border-d7c">
            <div class="d-flex mb-0">
              <div class="text-nowrap mr-2 filter_title">
                {{ $t("account_status") }}
              </div>
              <div class="line-h-24 text-7e">
                <span class="fw-700 mr-2" :class="profile.client_status_id == -1 ? 'text-ee1' : 'text-7e'
                  ">{{ profile.client_status.description }}</span>
                <b-button @click="clientStatusChange('active')" v-if="profile.client_status_id == -1"
                  variant="outline-ee1" pill size="sm" class="py-0">{{ $t("unpause_account") }}</b-button>
                <b-button @click="clientStatusChange('pause')" v-if="profile.client_status_id > 0" variant="outline-ee1"
                  pill size="sm" class="py-0">{{ $t("pause_account") }}</b-button>
              </div>
              <!-- <div class="fa fa-angle-right filter_arrow"></div> -->
              <!-- <input type="text" readonly class="form-control-plaintext" value="name@example.com" /> -->
            </div>
          </li>
        </ul>
      </div>
      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("membership_text") }}
      </div>
      <div v-if="profile" class="">
        <ul class="list-group list-group-flush profileEdit">
          <li class="list-group-item bg-faf text-1d border-d7c">
            <div @click="goToMembershipPage" class="d-flex mb-0">
              <div class="text-nowrap mr-2 filter_title">
                {{ $t("membership_level_text") }}
              </div>
              <!-- :class="profile.membership_level_id <= 3 ? 'text-primary' : ''" -->
              <div class="line-h-24 text-7e">
                <span v-if="profile.membership_level">{{
                  profile.membership_level.description
                }}</span>
              </div>
              <!-- <div v-if="profile.membership_level_id<3" class="fa fa-angle-right filter_arrow"></div> -->
            </div>
          </li>
          <li class="list-group-item bg-faf text-1d border-d7c">
            <div class="d-flex mb-0">
              <div class="text-nowrap mr-2 filter_title">
                {{ $t("full_name") }}
              </div>
              <div class="line-h-24 text-7e">
                <span>{{ profile.last_name }}</span>
              </div>
              <!-- <div v-if="profile.membership_level_id<3" class="fa fa-angle-right filter_arrow"></div> -->
            </div>
          </li>
          <li v-if="profile.paid_amount > 0" class="list-group-item bg-faf text-1d border-d7c">
            <div class="d-flex mb-0">
              <div class="text-nowrap mr-2 filter_title">
                {{ $t("paid_amount") }}
              </div>
              <div class="line-h-24 text-7e">
                {{ currency.symbol }}{{ formatPrice2(profile.paid_amount) }}
              </div>
              <!-- <div v-if="profile.membership_level_id<3" class="fa fa-angle-right filter_arrow"></div> -->
            </div>
          </li>
          <!-- <li
            v-if="!privacyMode"
            @click="openUpgradePop('dia_privacy_mode')"
            class="list-group-item bg-faf text-1d border-d7c"
          >
            <span class="fw-400">{{ $t("dia_privacy_mode") }}</span>
            <switchc
              @change="openUpgradePop('dia_privacy_mode')"
              class="float-right ee1"
              name="xx"
              :value="privacyMode"
              :canClick="false"
            ></switchc>
            <p
              class="small font-weight-light mt-3 text-7e"
              v-html="$t('dia_privacy_mode_desc').replace('<br/>', ' ')"
            ></p>
          </li> -->

          <li v-if="privacyMode" class="list-group-item bg-faf text-1d border-d7c">
            <span class="fw-400">{{ $t("dia_privacy_mode") }}</span>
            <switchc class="float-right ee1" @change="changeSwitch" name="privacy_mode" v-model="profile.privacy_mode">
            </switchc>
            <p class="small font-weight-light mt-3 text-7e" v-html="$t('dia_privacy_mode_desc').replace('<br/>', ' ')">
            </p>
          </li>
        </ul>
      </div>
      <div v-if="showMatchBoost" class="">
        <ul class="list-group list-group-flush profileEdit bg-white">
          <li v-if="!matchBoost" @click="openUpgradePop('dia_match_boost')"
            class="list-group-item cursor bg-faf text-1d border-d7c">
            {{ $t("ls_gold_title5") }}

            <b-form-checkbox @input="openUpgradePop('dia_match_boost')" size="lg" switch></b-form-checkbox>
          </li>

          <li v-if="matchBoost" class="list-group-item bg-faf text-1d border-d7c">
            {{ $t("ls_gold_title5") }}

            <b-form-checkbox v-model="matchBoost" size="lg" switch></b-form-checkbox>
          </li>
          <b-img class="mt-0" style="border: none !important; width: 100%"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/dia_line.jpg" />
        </ul>
        <p class="small font-weight-light text-center mt-2 text-7e">
          {{ $t("ls_gold2_desc3") }}
        </p>
      </div>

      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("notify_txt") }}
      </div>
      <div class="">
        <ul class="list-group list-group-flush profileEdit">
          <li class="list-group-item bg-faf text-1d border-d7c">
            <span class="fw-400">{{ $t("push_notify") }}</span>
            <switchc class="float-right ee1" @change="changeSwitch" name="notification_push_all" v-model="pushNotify"
              :text="switchText"></switchc>
          </li>
          <li class="list-group-item bg-faf text-1d border-d7c">
            <span class="fw-400">{{ $t("email_notify") }}</span>
            <switchc class="float-right ee1" @change="changeSwitch" name="notification_email_all" v-model="emailNotify"
              :text="switchText"></switchc>
          </li>
        </ul>
      </div>

      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("legal_text") }}
      </div>
      <div class="">
        <ul class="list-group list-group-flush profileEdit">
          <a href="javascript:;" @click="privacyLink" class="list-group-item filter_title bg-faf text-1d border-d7c">
            {{ $t("privacy_policy_text") }}
          </a>
          <a href="javascript:;" @click="termsLink" class="list-group-item filter_title bg-faf text-1d border-d7c">
            {{ $t("terms_service_text") }}
          </a>
        </ul>
      </div>
      <div class="bg_image position-relative" style="
          height: 70px;
          z-index: 2;
          background-position: bottom;
          background-image: url(https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/setting_bottom_bg2.png);
        "></div>
      <div class="bg-ee1 text-center py-4 position-relative" style="z-index: 1; margin-top: -66px">
        <div style="height: 80px"></div>
        <p class="text-white fs-30 fw-700">LOVESTRUCK</p>
        <p class="fs-13 mb-0">Lovestruck Matchmaking v6.2</p>
      </div>
      <div class="bg-1d py-5 text-center">
        <b-button variant="outline-f6f" pill @click="loginOut" class="l-space2 text-uppercase px-5">{{
          $t("web_drop_logout") }}</b-button>

        <div class="mt-4">
          <a @click="clientStatusChange('delete')" href="javascript:;" class="text-ff0 fw-600 l-space2 fs-13">DELETE
            ACCOUNT</a>
        </div>
      </div>
      <!-- <div class="mt-3">
        <ul class="list-group list-group-flush profileEdit">
          <a
            href="javascript:;"
            @click="loginOut"
            class="list-group-item list-group-item-action text-center bg-black text-ls-y3 border-ls-6"
          >
            {{ $t("web_drop_logout") }}
          </a>
        </ul>
      </div> -->
      <!-- <div class="py-3 bg-ls-2f">
        <b-img
          src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/LSM_app_icon_200.png"
          center
          width="50"
        />
        <p class="text-center mt-2 text-muted">Lovestruck v6.2</p>
      </div> -->
      <!-- <div class="">
        <ul class="list-group list-group-flush profileEdit">
          <a
            href="javascript:;"
            @click="clientStatusChange('delete')"
            class="list-group-item list-group-item-action text-center bg-black text-ls-y3 border-ls-6"
          >
            {{ $t("delete_account") }}
          </a>
        </ul>
      </div> -->

      <!-- <div class="py-4 text-center">Version 1.4.1</div> -->
    </div>
    <alertModal ref="alertModal" @okEvent="alertOkClick" @cancelEvent="alertCancelClick" @thirdEvent="alertThirdClick" />
    <!-- <lsIframe ref="lsIframe" :iframeUrl="memberUrl"></lsIframe> -->
    <!-- <scheduleConsultation ref="scheduleConsultation" /> -->
    <!-- <diamondPopup ref="diamondPopup" @scheduleNow="scheduleNowHander" /> -->
  </div>
</template>

<script>
import pageTop from "../common/pageTop";
import switchc from "../common/switchc";
// import lsIframe from "../common/iframe";
// import scheduleConsultation from "../common/scheduleConsultation";
// import diamondPopup from "../common/diamondPopup";
import alertModal from "../common/alertModal";
// import UA from "ua-device";
import helper from "../../utils/helper";
import SmartSelect from "../common/smartSelect";
export default {
  name: "settings",
  components: {
    pageTop,
    switchc,
    // lsIframe,
    // scheduleConsultation,
    // diamondPopup,
    alertModal,
    SmartSelect,
  },
  data() {
    return {
      profile: null,
      card: {
        cardClass: "{plan}_card_bg border-{plan}",
        cardBodyClass: "plan_{plan} on",
        iconClass: "{plan}_icon",
      },
      plannames: {
        2: "silver",
        3: "gold",
        4: "diamond",
      },
      memberUrl: "",
      // switchText: "on|off",
      switchText: "",
      emailNotifyDesn: "",
      //   curSite: this.$store.getters.curSite,
      publicPath: process.env.BASE_URL,
      curCity: this.$store.getters.curCity,
      priorityMatch: false,
      matchBoost: false,
      privacyMode: false,
      pushNotify: true,
      emailNotify: true,
      paid_amount_string: "",
      // userLanguage: "",
      curLangCode: "",
      showMatchBoost: false,
      allLangs: [
        { code: "en_US", name: "EN" },
        { code: "zh_CN", name: "简体" },
        { code: "zh_HK", name: "繁體" },
        { code: "th", name: "ภาษาไทย" },
      ],
      orderLangs: null,
      currency: null,
    };
  },
  computed: {
    curLang() {
      return this.$store.getters.curLang;
    },
    curSite() {
      return this.$store.getters.curSite;
    },
    userLanguage() {
      let self = this;
      let index = _.findIndex(self.curSite.langs, {
        lang_code: self.profile.lang_code,
      });
      if (index == -1) {
        return "EN";
      } else {
        return _.get(self.curSite.langs, [index, "name"]);
      }
    },
  },
  methods: {
    showOption(field) {
      let self = this;
      self.$refs[field].showBox();
    },
    updateSelectedValue(fieldName, value, act) {
      let self = this;
      //   console.log('updateSelectedValue', fieldName, value, act)
      if (fieldName == "lang_code" && value != self.curLangCode) {
        self.changeLang(value);
      }

      // console.log(self.update);
    },
    formatPrice2(price_val) {
      return helper.currenyPriceFormat(
        price_val,
        _.get(this.currency, "show_decimals", 1)
      );
    },
    getCity() {
      let self = this;

      let ls_city = self.$storage.get("ls_city");
      if (ls_city) {
        return ls_city;
      } else if (self.curCity) {
        return self.curCity;
      } else {
        return 'hong-kong'
      }
    },
    termsLink() {
      let self = this;
      // let current_city = self.$storage.get("ls_city");
      // console.log('curSite', this.curSite)
      this.$router.push({ name: "terms", params: { city: self.getCity() } });
      //name: "terms"
      //   location.href = `https://www.lovestruck.com/${this.curSite.url_long}/en/terms/`
    },
    privacyLink() {
      let self = this;
      // let current_city = self.$storage.get("ls_city");
      this.$router.push({
        name: "privacy-policy",
        params: { city: self.getCity() },
      });
      //   location.href = `https://www.lovestruck.com/${this.curSite.url_long}/en/privacy-policy/`
    },
    clientStatusChange(actName) {
      let self = this;
      //   console.log("clientStatusChange", actName);
      if (actName == "pause") {
        self.$refs.alertModal.showModal({
          title: self.$t("pause_account_title"),
          message: self.$t("pause_account_desc"),
          okText: self.$t("pause_my_account"),
          cancelText: self.$t("cancel_txt"),
          metaData: {
            type: actName,
          },
        });
      } else if (actName == "active") {
        self.$refs.alertModal.showModal({
          title: self.$t("active_u_account"),
          message: self.$t("active_u_account_desc"),
          okText: self.$t("unpause_my_account"),
          cancelText: self.$t("cancel_txt"),
          metaData: {
            type: actName,
          },
        });
      } else if (actName == "delete") {
        if (self.profile.client_status_id == -1) {
          self.$refs.alertModal.showModal({
            title: self.$t("confirm_del_account_title"),
            message: self.$t("confirm_del_account_desc"),
            redMessage: self.$t("del_account_warning"),
            okText: self.$t("del_my_account"),
            cancelText: self.$t("cancel_txt"),
            metaData: {
              type: "confirm-delete",
            },
          });
        } else {
          self.$refs.alertModal.showModal({
            title: self.$t("pause_u_account"),
            message: self.$t("del_account_desc"),
            okText: self.$t("pause_my_account"),
            cancelText: self.$t("del_my_account"),
            thirdText: self.$t("cancel_txt"),
            showThirdBtn: true,
            metaData: {
              type: actName,
            },
          });
        }
      }
    },
    alertThirdClick() {
      let self = this;
      self.$refs.alertModal.hideModal();
    },
    alertCancelClick(data) {
      let self = this;
      //   console.log("alertCancelClick", data);
      self.$refs.alertModal.hideModal();
      if (_.get(data, "type") == "delete") {
        self.$refs.alertModal.showModal({
          title: self.$t("confirm_del_account_title"),
          message: self.$t("confirm_del_account_desc"),
          redMessage: self.$t("del_account_warning"),
          okText: self.$t("del_my_account"),
          cancelText: self.$t("cancel_txt"),
          metaData: {
            type: "confirm-delete",
          },
        });
      }
    },
    alertOkClick(data) {
      let self = this;
      //   console.log("alertOkClick", data);
      let actName = _.get(data, "type");
      let fdata = new FormData();

      if (actName == "pause" || actName == "delete") {
        fdata.append("operate", "pause");
      } else if (actName == "active") {
        fdata.append("operate", "active");
      } else if (actName == "confirm-delete") {
        fdata.append("operate", "delete");
      } else if (actName == "privacy_mode") {
        self.$router.push({
          name: "contextual-upgrades",
          query: { type: "diamond", page: 2 },
        });
      }
      self.$refs.alertModal.hideModal();

      if (actName != "privacy_mode") {
        self.postClientOperate(fdata, actName);
      }
    },
    postClientOperate(data, actName) {
      let self = this;

      self.$api.postClientOperate(data, self.$store.state).then((rs) => {
        if (actName == "confirm-delete") {
          self.loginOut();
        } else {
          self.loadData();
        }
      });
    },
    backEventHandler() {
      let self = this;
      //   let membership_level_id = self.profile.membership_level_id;

      //   if (membership_level_id <= 1) {
      //     self.$router.push({
      //       name: "free-index",
      //     });
      //   } else {
      //     // self.$router.back();
      //     self.$router.push({ name: "profile-me" });
      //   }
      self.$router.push({ name: "profile-me" });
    },
    changeLang(lang) {
      let self = this;

      //   let siteLang = _.findIndex(self.curSite.langs, { lang_code: lang });
      //   if (siteLang == -1) {
      //     // console.log("missing language");
      //     self.$layer.msg("missing language");
      //   }
      if (
        _.indexOf(["en_us", "zh_cn", "zh_hk", "th"], lang.toLowerCase()) == -1
      ) {
        lang = cur_site.lang_default;
      }

      self.$layer.loading();
      self.curLangCode = lang;
      let fdata = new FormData();
      fdata.append("lang_code", lang);

      let userInfo = self.$storage.get("userInfo");

      self.$api
        .postClientUpdate(fdata, self.$store.state)
        .then((rs) => {
          //userInfo.language_id = langId;
          location.href = `${location.pathname}?lang=${lang}`;
          userInfo.lang_code = lang;
          self.$storage.set("userInfo", JSON.stringify(userInfo));
          self.loadData();
          self.$layer.closeAll("loading");
        })
        .catch((err) => {
          console.log(["me error", err]);
          self.$layer.closeAll("loading");
        });
    },
    editProfile() {
      this.$router.push({ name: "profile-edit" });
    },
    scheduleNowHander() {
      //   this.$refs.diamondPopup.closeModel();
      //   this.$refs.scheduleConsultation.showModal(2);
    },
    openUpgradePop(optionName) {
      //
      let self = this;
      let membership_level_id = self.profile.membership_level_id;

      if (optionName == "dia_privacy_mode") {
        if (membership_level_id <= 2) {
          self.$router.push({
            name: "contextual-upgrades",
            query: { type: "gold", page: 1 },
          });
        } else {
          self.$refs.alertModal.showModal({
            title: `${self.$t("on_privacy_mode")}?`,
            message: `<p class="mb-3 text-center">${self.$t(
              "on_privacy_mode_desc1"
            )}</p>
                <p class="text-ls-red3 text-center">${self.$t(
              "on_privacy_mode_desc2"
            )}</p>
                `,
            okText: self.$t("on_privacy_mode"),
            cancelText: self.$t("cancel_txt"),
            metaData: {
              type: "privacy_mode",
            },
          });
        }

        // if (membership_level_id <= 1) {
        //   self.$router.push({
        //     name: "upgrade-regular",
        //     // query: { type: "gold" },
        //   });
        // } else {
        //   self.$router.push({
        //     name: "contextual-upgrades",
        //     query: { type: "premium", page: 4 },
        //   });
        // }
      } else if (optionName == "dia_match_boost") {
        if (membership_level_id <= 1) {
          self.$router.push({
            name: "upgrade-regular",
            // query: { type: "gold" },
          });
        } else {
          self.$router.push({
            name: "contextual-upgrades",
            query: { type: "gold", page: 2 },
          });
        }
      }
      //   else {
      //     self.$refs.diamondPopup.showModal();
      //   }

      //   self.$refs.scheduleConsultation.showModal(2);

      // let userAgent = new UA(navigator.userAgent);

      // let ffidx = 0;
      // if (optionName == "dia_privacy_mode") {
      //   ffidx = 6;
      // } else if (optionName == "dia_match_boost") {
      //   ffidx = 7;
      // }

      // if (userAgent.device.type == "mobile") {
      //   self.$router.push({
      //     // name: "payment-upgrade-pop",
      //     name: "book-consultation",
      //     query: { step: 1, fidx: ffidx, back: 1 },
      //     // query: { option: optionName }
      //   });
      // } else {
      //   self.memberUrl = `${process.env.BASE_URL}${
      //     self.curCity
      //   }/book_consultation?step=1&fidx=${ffidx}&back=1&t=${new Date().getTime()}`;

      //   self.$refs.lsIframe.show();
      // }
    },
    goToMembershipPage() {
      let self = this;
      //   let userAgent = new UA(navigator.userAgent);
      //   let plan_name = "";
      if (self.profile.membership_level_id <= 1) {
        //to silver
        // plan_name = "gold";
        self.$router.push({
          name: "upgrade-regular",
          //   query: { type: "gold" },
          //   name: "payment-plan",
          //   params: { planName: "gold" },
        });
      } else if (self.profile.membership_level_id == 2) {
        //to gold
        // plan_name = "gold";
        self.$router.push({
          name: "contextual-upgrades",
          query: { type: "gold", page: 2 },
          // query: { type: "gold" },
          //   name: "payment-plan",
          //   params: { planName: "platinum" },
        });
      } else if (self.profile.membership_level_id == 3) {
        self.$router.push({
          name: "contextual-upgrades",
          query: { type: "diamond", page: 2 },
        });
        // self.$refs.diamondPopup.showModal();
      }

      //   self.$router.push({
      //     name: "payment-plan",
      //     params: { planName: plan_name }
      // });

      //   if (userAgent.device.type == "mobile") {
      //     self.$router.push({
      //       name: "payment-plan",
      //       params: { planName: plan_name },
      //       query: { top_nav: 0 },
      //     });
      //   } else {
      //     //window.parent.$layer.closeAll();

      //     self.memberUrl =
      //       process.env.BASE_URL +
      //       self.curCity +
      //       "/payment/" +
      //       plan_name +
      //       "?t=" +
      //       new Date().getTime();

      //     self.$refs.lsIframe.show();
      //   }

      return false;
    },
    init() {
      let self = this;
      // let plan = _.get(
      //   self.plannames,
      //   self.profile.membership_level_id,
      //   "silver"
      // );
      // console.log(
      //   "cur site",
      //   self.curSite.langs,
      //   _.findIndex(self.curSite.langs, { lang_code: "zh_HK" })
      // );

      // let index = _.findIndex(self.curSite.langs, {
      //   lang_code: self.profile.lang_code
      // });

      let qLang = _.get(self.$route, "query.lang");
      if (qLang && qLang != self.curLangCode) {
        self.curLangCode = qLang;
      } else {
        self.curLangCode = self.profile.lang_code;
      }

      // self.profile.membership_level_id = 2;
      if (self.profile.membership_level_id >= 3) {
        self.priorityMatch = true;
      }
      if (self.profile.membership_level_id >= 2) {
        self.matchBoost = true;
      }
      if (self.profile.membership_level_id >= 2) {
        self.privacyMode = true;
      }
      // console.log('self.curLangCode', self.curLangCode)
      // self.privacyMode = true;//test
      // self.card.cardClass = _.replace(self.card.cardClass, /{plan}/g, plan);
      // self.card.cardBodyClass = _.replace(
      //   self.card.cardBodyClass,
      //   "{plan}",
      //   plan
      // );
      // self.card.iconClass = _.replace(self.card.iconClass, "{plan}", plan);
      //lang_on
      //self.switchText = `${self.$t("lang_on")}|${self.$t("lang_off")}`;
      if (self.profile.email) {
        self.emailNotifyDesn = self
          .$t("email_notify_desn")
          .replace("|EMAIL|", self.profile.email);
      }
      // self.$refs.head.setPageTitle(self.$t("settings_txt"));
      // console.log([
      // 	plan,self.card
      // ])
    },
    loginOut() {
      let self = this;

      self.$storage.clearLogin();
      let current_city = self.getCity();//self.$storage.get("ls_city");
      let curlang = self.curLangCode;
      // console.log("curlang=", curlang);
      let lsiLang = "en";
      // if (!current_city) {
      //   current_city = self.curCity;
      // }
      if (curlang != "en_US") {
        lsiLang = curlang.replace("_", "-");
      }

      if (typeof location == "undefined") {
        self.$router.push({
          name: "city-home",
          params: { city: current_city },
          query: { lang: curlang },
        });
      } else {
        let url = `https://${location.hostname}/${current_city}/${lsiLang}/`;

        location.href = url;
      }
      //   self.$router.push({ name: "sign-in", params:{'city': self.curCity} });

      //window.webkit.messageHandlers.jsHandler.postMessage("logout");

      // let id = self.$layer.confirm(
      // 'Are you sure you want to log out?',
      // {
      // 	title:'Confirm',
      // 	shade:false,
      // 	btn:['OK','Cancel']
      // },
      // ()=>{
      //
      //
      //
      // 	self.$router.push({
      // 		name:self.curCity
      // 	});
      // },()=>{
      // 	self.$layer.close(id)
      // });
    },
    //no used
    upgradeMemberLink(membership_level_id) {
      //   let self = this;
      //let url = `${this.publicPath}${this.curCity}/payment/choice`;
      // this.$router.push(url)
      // window.location.href = url;
      //   let userAgent = new UA(navigator.userAgent);
      //   let plan_name = _.get(
      //     { 2: "silver", 3: "gold" }, //, 4: "diamond"
      //     membership_level_id,
      //     "silver"
      //   );
      //   if (userAgent.device.type == "mobile") {
      //     self.$router.push({
      //       name: "membership-choose",
      //       // query: { type: "gold" },
      //       //   name: "payment-plan",
      //       //   params: { planName: plan_name },
      //       //   query: { top_nav: 0 },
      //     });
      //   } else {
      //     self.memberUrl =
      //       process.env.BASE_URL +
      //       self.curCity +
      //       "/payment/" +
      //       plan_name +
      //       "?t=" +
      //       new Date().getTime();
      //     self.$refs.lsIframe.show();
      //   }
      // self.$router.push({ name: "payment-choice" });
    },
    changeSwitch(val, name) {
      let self = this;
      // console.log([
      // 	val,name
      // 	]);
      //notification_email, notification_push
      self.profile[name] = val ? 1 : 0;
      self.save(name, val ? 1 : 0);
    },
    save(filed_name, value) {
      let self = this;

      let fdata = new FormData();

      fdata.append(filed_name, value);

      self.$layer.loading();

      self.$api
        .postClientUpdate(fdata, self.$store.state)
        .then((rs) => {
          console.log(rs);
          self.$layer.closeAll("loading");
        })
        .catch((err) => {
          console.log(["save error", err]);
          self.$layer.closeAll("loading");

          self.$layer.msg(err.response.data.error.body);
        });
    },
    loadData() {
      let self = this;

      self.$layer.loading();
      self.$api
        .getClientMe(self.$store.state)
        .then((rs) => {
          //   console.log(rs);
          if (rs.hasOwnProperty("client")) {
            self.profile = rs.client;
            // self.profile.membership_level_id = 2;
            //paid_amount
            self.currency = _.get(rs, "currency");
            self.paid_amount_string = _.get(rs, "client.paid_amount_string");
            self.init();
          }

          self.$layer.closeAll("loading");
        })
        .catch((err) => {
          self.$layer.closeAll("loading");

          console.log(["me error", err]);
        });
    },
  },
  mounted() {
    let self = this;
    // self.$layer.loading();
    if (!self.$parent.isLoading) {
      self.$parent.showLoading();
    }
    console.log("curCity", self.curCity);
    console.log('curSite', this.curSite)
    $("body").addClass("bg-light");
    self.$parent.closeLoading();

    self.$refs.pageTop.setPageTitle(self.$t("settings_txt"));

    self.$api.getLanguageOrder("", self.$store.state).then((rs) => {
      self.orderLangs = _.get(rs, "orderLanguages");
      //   console.log(self.orderLangs)
    });

    self.loadData();
  },
};
</script>

<style scoped>
.list-group-flush.profileEdit .list-group-item {
  border: none;
  border-bottom: 2px solid #f8f9fa;
}
</style>
