<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <div
        v-if="targetClient"
        style="
          position: absolute;
          top: -40px;
          text-align: center;
          width: 100%;
          left: 0;
          z-index: 99;
        "
      >
        <b-img
          :src="targetClient.photo_url"
          width="80"
          height="80"
          rounded="circle"
          center
          style="border: 4px solid #fff"
        />
      </div>
      <p
        class="text-center fw-400"
        style="margin-top: 40px"
        v-html="popupTitle"
      ></p>
      <!-- <p class="text-center mt-3">{{ popupDesc }}</p> -->
      <b-input-group class="my-3">
        <b-form-input
          v-model="message"
          @input="checkInput"
          :placeholder="$t('type_message')"
          id="greetInput"
          class="border pl-3"
        ></b-form-input>
        <b-input-group-append>
          <button
            type="button"
            @click="sendGreetingMessage"
            class="btn btn-ls-gold-g2"
            :class="message ? '' : 'disabled'"
          >
            {{ $t("button_sendfriend_send") }}
          </button>
        </b-input-group-append>
      </b-input-group>

      <div class="d-flex justify-content-around w-50 mx-auto">
        <button
          v-for="item in emojis"
          :key="item"
          @click="emojiClick(item)"
          type="button"
          class="btn btn-outline-secondary rounded-pill px-3"
        >
          {{ item }}
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "startDatePopupCom",
  data() {
    return {
      show: false,
      targetClient: null,
      popupTitle: "",
      popupDesc: "",
      message: "",
      emojis: ["👋", "😉", "❤️", "😍"],
      is_date_chat: 1,
    };
  },
  mounted() {},
  methods: {
    checkInput() {
      this.message = $.trim(this.message);
    },
    emojiClick(item) {
      this.message = `${this.message}${item}`;
    },
    sendGreetingMessage() {
      let self = this;
      if (!self.message) {
        return false;
      }
      self.$layer.loading();
      //   console.log("messaeg", self.message);
      let pdata = new FormData();
      //changeDateVenueInvite
      pdata.append("message", self.message);
      pdata.append("to_client_id", _.get(self.targetClient, "client_id", 0));
      pdata.append("is_date_chat", self.is_date_chat);
      pdata.append("use_tim", 1);

      self.$api
        .postClientMessage(pdata, self.$store.state)
        .then((rs) => {
            rs.target = self.targetClient
          self.$emit("afterSendGreetingMessage", rs);
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          self.$layer.msg(_.get(error, "response.data.error.body"));
          //self.afterAction(intro_id, type);
          console.log(error);
        });
    },
    closeModel() {
      let self = this;
      self.show = false;
      self.targetClient = null;
      self.message = "";
    },
    showModal(target, is_date_chat = 1) {
      let self = this;
      self.show = true;
      self.targetClient = target;
      self.is_date_chat = is_date_chat;

      self.popupTitle = self
        .$t("start_date_with")
        .replace(
          "[match_first_name]",
          `<span class="fw-700">${self.targetClient.first_name}</span>`
        );
      //   self.popupDesc = self
      //     .$t("start_date_with_desc")
      //     .replace("[match_first_name]", self.targetClient.first_name);

      setTimeout(function () {
        $("#greetInput").focus();
      }, 500);
    },
  },
};
</script>