<template>
  <div class="bg-faf min-h100">
    <div class="page6-2 px-3">
      <div class="receiptPhotoUpload" style="display: none">
        <h5 class="py-4 text-center text-1d">
          {{ $t("upload_payment_receipt") }}
        </h5>

        <receiptPhotoUpload
          ref="receiptPhotoUpload"
          photoType="receipt_photo"
          :red_ver="true"
          :photoNum="1"
          @photoStatus="photoEditorStatus"
        />

        <div class="text-center">
          <b-button
            @click="uploadReceipt"
            variant="ee1"
            class="mt-4 px-5 l-space2 text-uppercase"
            pill
            style="height: 53px"
          >
            {{ $t("upload_receipt") }}
          </b-button>
        </div>
      </div>
    </div>
    <!-- <div v-if="showPending" class="text-white" style="position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1111;
    top: 0;
    background: #000;
    padding: 20px;">
    <div
      class=""
      style="
        
        z-index: 1000;
        width: 100%;
        text-align: right;
      "
    >
      <a href="javascript:;" @click="closePage" class="p-2">
          <i class="lsicon lsicon-30 close_grey2 "></i>
        </a>
      </div>
        <div class="mx-auto" style="max-width: 400px;">
            <h5 class="my-5 text-center fw-600">{{$t('payment_pending_review')}}</h5>

            <div class="card bg-ls-2f mb-5 mx-auto p-3 border">
                <p class="fw-600 mb-3">{{$t('payment_pending_review_desc1')}}</p> 

                <p class="mb-3">{{$t('payment_pending_review_desc2')}}</p>
                <p>{{$t('payment_pending_review_desc3')}}</p>
            </div>

            

            <div class="text-center">
                <b-button @click="closePage" variant="ls-gold-g2" class="mt-4 fw-600 px-5 font-1rem text-uppercase" size="lg" pill style="min-width:220px;">
                    {{$t('close_txt')}}
                </b-button>
            </div>
        </div>
        </div> -->
  </div>
</template>
<script>
import receiptPhotoUpload from "../common/onePhotoUpload";
export default {
  name: "payment-receipt-upload",
  components: {
    receiptPhotoUpload,
  },
  data() {
    return {
      extras: null,
      showPending: false,
    };
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();
    //
    self.extras = self.$storage.get("payment_extras");
    // console.log('self.extras=', JSON.stringify(self.extras))
    self.$refs.receiptPhotoUpload.toggleReceiptFile();
  },
  methods: {
    closePage() {
      this.$router.push({ name: "profile-me" });
    },
    uploadReceipt() {
      let self = this;
      let files = self.$refs.receiptPhotoUpload.getSelectedFiles();
      // console.log('files', files.length)
      if (files.length == 0) {
        self.$refs.receiptPhotoUpload.toggleReceiptFile();
        return false;
      }
      self.$layer.loading();

      let formData = new FormData();
      formData.append("extras", JSON.stringify(self.extras));
      formData.append("photo", _.get(files, "0.file"));

      self.$api
        .postSaveBankReceipt(formData, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          if (_.get(rs, "success")) {
            self.afterUpload();
          } else {
            self.$layer.msg(_.get(rs, "message"));
          }
          // console.log(rs);
          //   self.photoStatus.photo_url.push(_.get(rs, "photo.photo_url"));
          //   self.photoStatus.uploadSuccess = true;
        })
        .catch((err) => {
          //     self.$layer.closeAll("loading");
          //   console.log(["upload error", err]);
          self.$layer.closeAll("loading");
          let message = _.get(
            err,
            "response.data.error.body",
            _.get(err, "message")
          );
          if (
            message.indexOf("timeout") > -1 ||
            message.indexOf("Network Error") > -1
          ) {
            // self.uploadNum++;
            self.afterUpload();
          } else {
            self.$layer.msg(message);
          }
        });
    },
    afterUpload() {
      // this.showPending = true;
      this.$router.push({ name: "pending-review" });
    },
    photoEditorStatus(data) {
      $(".receiptPhotoUpload").show();
      console.log("photoEditorStatus", data);
    },
  },
};
</script>