<template>
  <div class="bg-faf min-h100 text-1d">
    <topName ref="topNav" :showMobileTop="false" />

    <div class="page_container hideMobileTop">
      <div class="page6-2 p-3 text-center">
        <div v-if="!renewData" class="text-center py-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>

        <div v-if="renewData" class="mb-4">
          <div style="height: 3vh"></div>
          <div v-if="renewData.expire_in_days > 0">
            <p v-html="membership_ends_in_days"></p>

            <p class="text-e11 fw-700 fs-18">{{ $t("renew_now") }}!</p>
          </div>

          <div v-else>
            <p class="ff-prata fs-24 mb-4" v-html="y_member_expired"></p>
            <!-- <div class="my-4">
              <b-img
                center
                width="70"
                src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/lsm-app/renew/gift_icon.png"
              ></b-img>
            </div> -->
            <div v-if="renewData.discount > 0" class="fw-700 fs-20 text-ee1 discountDiv mb-2">
              <i class="fa-light fa-gift fs-23 mr-3"></i>
              {{ renew_with_discount }}
            </div>
            <div v-if="renewData.discount > 0">
              <!-- <p class="fw-700 fs-18">{{ renew_with_discount }}</p> -->
              <p class="text-ee1">
                {{ $t("today_only") }}
              </p>
            </div>
          </div>

          <div class="px-3 py-4 mt-4 bg-white rounded">
            <div class="mb-3">
              <div class="text-uppercase text-ee1 fw-700 fs-18"><b-img
                  height="18"
                  src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/Lovestruck-logo-black.png"
                ></b-img><br/>
                {{ renewData.membership_level.description }}
              </div>
              <!-- <b-img width="260" center :src="levelLogo"></b-img> -->
              <!-- <div>
                <b-img
                  height="36"
                  src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/LS_logo.png"
                ></b-img>
              </div>
              <div
                v-if="renewData.membership_level"
                class="text-uppercase"
                :style="`color:${renewData.membership_level.app_color_code}`"
              >
                {{ renewData.membership_level.description }}
              </div> -->
            </div>
            <!-- <div class="text-ls-y3 fw-700 d-flex align-items-center justify-content-center mt-4 mb-3 fs-18">
                <b-img height="40" class="mx-2" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/lsm-app/LSM_icon_large.png"></b-img>
                {{renewData.membership_level.description}}
            </div> -->
            <p v-if="renewData.discount > 0 && renewData.expire_in_days <= 0" class="text-7e mt-5 mb-0">
              {{ $t("normal_price") }}:
              <del>{{ renewData.currency.symbol
              }}{{ formatPrice(renewData.price) }}</del>
            </p>
            <p class="text-ee1 fw-600 fs-20">
              {{ selectMonth }} {{ monthText }}: {{ renewData.currency.symbol
              }}{{ formatPrice(renewData.discount_price) }}
            </p>
          </div>

          <!-- <p class="text-bc fw-600 fs-20">{{$t('previously_price')}}: {{renewData.currency.symbol}}{{formatPrice(renewData.price)}}</p> -->
        </div>

        <!-- <div class="my-4">
          <planCarousel ref="planCarousel"></planCarousel>
        </div> -->

        <div v-if="renewData" class="pb-5">
          <p v-if="renewData.expire_in_days > 0" class="mt-5 mb-4 font-italic text-7e"
            v-html="$t('renew_risk_more_later')"></p>
          <p v-else class="mt-5 mb-4 font-italic text-7e" v-html="offerEndInText"></p>

          <b-button @click="submitPayment" :variant="btnVariant" pill class="px-5 l-space2 text-uppercase w-100"
            style="height: 53px">
            {{ $t("m_continue_txt") }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../utils/helper";
// import planCarousel from "./planCarousel.vue";
import topName from "../common/topNav";
export default {
  name: "renew-payment",
  components: {
    // planCarousel,
    topName,
  },
  data() {
    return {
      client: null,
      selectedName: "plus",
      selectMonth: 3,
      monthText: "",
      timer: null,
      time_count: null,
      timeLeft: 0,
      offerEndIn: this.$t("offer_end_in"),
      offerEndInText: "",
      renewData: null,
      langCode: null,
      levelLogo: null,
      renew_with_discount: "",
      btnVariant: "ee1",
      membership_ends_in_days: "",
      y_member_expired: "",
    };
  },
  mounted() {
    let self = this;

    let top_nav = _.get(self.$route, "query.top_nav", 1);
    if (top_nav == 0) {
      self.$refs.topNav.hide();
    }
    self.y_member_expired = self
      .$t("y_member_expired")
      .replace(`<b class="text-ls-red3">`, "<br/>")
      .replace("</b>", "");
    //getRenewalPaymentData
    self.langCode = self.$storage.get("cur-lang");
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        // if (_.get(rs, "client.membership_has_expired") === 0) {
        //   self.$router.push({
        //     name: "profile-me",
        //   });
        // } else {
        self.$layer.closeAll("loading");
        self.client = _.get(rs, "client");

        return self.$api.getRenewalPaymentData("", self.$store.state);
        // }
      })
      .then((rs) => {
        self.renewData = rs;
        // self.renewData.discount = 30;
        self.renew_with_discount = self
          .$t("renew_with_discount")
          .replace("[discount]", self.renewData.discount);
        self.init();
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
        console.log(["me error", err]);
      });
  },
  methods: {
    init() {
      let self = this;

      if (self.renewData.expire_in_days > 0) {
        self.membership_ends_in_days = self
          .$t("membership_ends_in_days")
          .replace(
            "[X]",
            `<b class="text-ls-red3">${self.renewData.expire_in_days}</b>`
          );
      } else {
      }

      let mLevelId = _.get(
        self.renewData,
        "membership_level.membership_level_id"
      );
      // mLevelId = 4;
      self.levelLogo = helper.getMembershipPhotoById(mLevelId);
      self.btnVariant = "ee1";
         if (mLevelId == 2) {
           self.selectedName = "starter";
      //     self.btnVariant = "ls-gold-g2";
         } else if (mLevelId == 3) {
           self.selectedName = "plus";
      //     self.btnVariant = "ls-plus-g2";
         } else {
           self.selectedName = "diamond";
      //     self.btnVariant = "ls-dia-g2";
         }
      // self.selectedName = 'gold'
      self.selectMonth = _.get(self.renewData, "month", 3);

      self.monthText =
        self.selectMonth > 1 ? self.$t("n_month") : self.$t("1_month");

      //   self.$refs.planCarousel.changePlanName(`${self.selectedName}-renew`);

      self.timerRuner();
    },
    submitPayment() {
      let self = this;

      let product = {
        price_row: self.renewData.price_row,
        description: self.renewData.membership_level.description,
        color_code: self.renewData.membership_level.app_color_code,
      };
      product.price_row[`${self.renewData.month}_month`] =
        self.renewData.discount_price;
      product[`price_pre_${self.renewData.month}month`] = helper.divideNumber(
        self.renewData.discount_price,
        self.renewData.month,
        self.renewData.currency.show_decimals
      );

      self.$storage.setPaymentSelectedPlan(
        JSON.stringify({
          productType: "upgrade",
          plan_name: self.selectedName,
          plan_month: self.renewData.month,
          product: product,
          currency: self.renewData.currency,
          client_id: _.get(self.client, "client_id", 0),
          //   user_data: self.userData,
          discount_price: self.renewData.discount_price,
          original_price: self.renewData.price,
          discount: self.renewData.discount,
        })
      );

      self.$router.push({
        name: "payment-checkout",
        query: { lang: self.langCode, top_nav: 0 },
      });
      return false;
    },
    timerRuner() {
      let self = this;

      let time24 = 24 * 3600 * 1000;

      self.timeLeft = time24;

      self.runTimer();
    },
    runTimer() {
      let self = this;
      self.timer = setInterval(function () {
        if (!self.timeLeft || self.timeLeft <= -1) {
          clearInterval(self.timer);
          //   self.show_50_off = false;s
        } else {
          self.time_count = helper.setLeftSec(Math.floor(self.timeLeft / 1000));
          self.timeLeft -= 1000;
          self.offerEndInText = self.offerEndIn.replace(
            "[time_count]",
            `<span class="text-ee1 fw-700"
                    >${self.time_count.h}:${self.time_count.m}:${self.time_count.s}</span>`
          );
        }
      }, 1000);
    },

    formatPrice(price_val) {
      return helper.currenyPriceFormat(
        price_val,
        _.get(this.renewData.currency, "show_decimals")
      );
    },
  },
};
</script>