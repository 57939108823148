<template>
  <div v-if="client" :class="isCard ? 'card' : ''">
    <div
      v-if="verison == 'v1'"
      :class="isCard ? 'card-body' : ''"
      class="mx-auto"
      style="max-width: 500px"
    >
      <div class="bg-faf pb-3 rounded text-1d">
        <div
          v-if="client.primary_photo"
          class="position-relative mx-auto"
          style="max-width: 420px; width: 100%"
        >
          <img
            @click="viewPhoto(0)"
            :src="client.primary_photo"
            class="w-100 border-0"
          />

          <div class="user-info px-3 py-1">
            <h6 class="fw-700">
              {{ client.first_name }}, {{ client.age }}
              <!-- <span class="ml-1">
                <i class="fa fa-check-circle text-ls-y"></i>
              </span> -->
            </h6>
            <div class>
              <p
                v-if="client.job_title"
                class="font-weight-light small text-truncate"
              >
                <!-- <i class="fa fa-suitcase text-gold"></i> -->
                <i class="lsicon job_title_w lsicon-18 mr-1"></i>
                {{ client.job_title }}
              </p>
              <p v-if="client.university_name" class="text-truncate mb-0 small">
                <i class="lsicon schoolname_w mr-1"></i
                >{{ client.university_name }}
              </p>
            </div>
          </div>
        </div>
        <!-- </div> -->

        <!-- <div class="col-lg-8 col-md-8 col-sm-12"> -->
        <div v-if="client.self_intro" class="p-3">
          <p class="text-ee1 fw-500 mb-2">About Me</p>
          <p class="fw-500 text-7e text-nowrap">{{ client.self_intro }}</p>
        </div>
        <ul class="userInfoList">
          <p class="text-ee1 fw-500 mb-3">My Basics</p>
          <!-- <li class="self-info">
                    <p class="fs-12 text-muted mb-0">About Us</p>
                    {{ client.self_intro }}
                </li> -->
          <li v-if="client.height" class="self-height">
            <!-- <p class="fs-12 text-muted mb-0">Height</p> -->
            {{ client.height }}
          </li>
          <li v-if="client.ethnicity" class="self-ethnicity">
            <!-- <p class="fs-12 text-muted mb-0">Ethnicity</p> -->
            {{ client.ethnicity }}
          </li>
          <!-- <li v-if="client.nationality" class="self-nationality">
                    <p class="fs-12 text-muted mb-0">Nationality</p>
                    {{ client.nationality }}
                </li> -->
          <li v-if="client.education_level" class="self-education_level">
            <!-- <p class="fs-12 text-muted mb-0">Education</p> -->
            {{ client.education_level }}
          </li>
          <li v-if="client.annual_income" class="self-annual_income">
            <!-- <p class="fs-12 text-muted mb-0">Annual Income</p> -->
            {{ client.annual_income }}
          </li>
          <li
            v-if="client.relationship_status"
            class="self-relationship_status"
          >
            <!-- <p class="fs-12 text-muted mb-0">Relationship Status</p> -->
            {{ client.relationship_status }}
          </li>
          <!-- <li v-if="client.child_status" class="self-child_status">
                    {{ client.child_status }}
                </li> -->
          <li v-if="client.child_plans" class="self-child_plans">
            <!-- <p class="fs-12 text-muted mb-0">Child plans</p> -->
            {{ client.child_plans }}
          </li>
          <li v-if="client.religion" class="self-religion">
            <!-- <p class="fs-12 text-muted mb-0">Religion</p> -->
            {{ client.religion }}
          </li>
          <li v-if="client.drinking_status" class="self-drinking_status">
            <!-- <p class="fs-12 text-muted mb-0">Drinking</p> -->
            {{ client.drinking_status }}
          </li>
          <li v-if="client.smoking_status" class="self-smoking_status">
            <!-- <p class="fs-12 text-muted mb-0">Smoking</p> -->
            {{ client.smoking_status }}
          </li>
        </ul>

        <ul v-if="client.other_photos" class="profile_photo_list">
          <li v-for="(ophoto, index) in client.other_photos" :key="index">
            <img @click="viewPhoto(index + 1)" :src="ophoto" />
          </li>
        </ul>
      </div>
    </div>
    <div v-if="verison == 'v2'">
      <div class="position-relative">
        <b-img
          @click="viewPhoto(0)"
          :src="client.primary_photo"
          class="w-100 rounded-10"
        ></b-img>
        <div class="user-info px-3 py-1">
          <h5 class="fw-700">{{ client.first_name }}, {{ client.age }}</h5>
          <div class>
            <p v-if="client.job_title" class="text-truncate fw-400">
              <i class="lsicon job_title_w lsicon-18 mr-1"></i>
              {{ client.job_title }}
            </p>
            <p v-if="client.university_name" class="text-truncate mb-2 fw-400">
              <i class="lsicon schoolname_w mr-1"></i
              >{{ client.university_name }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="client.self_intro" class="py-3">
        <p class="text-ee1 fw-600 mb-2">{{ $t("about_me_txt") }}</p>
        <p class="fw-600 text-7e text-wrap">{{ client.self_intro }}</p>
      </div>

      <div class="mb-4">
        <p class="text-ee1 fw-600 mb-3">{{ $t("my_basics") }}</p>

        <div class="text-white">
          <span class="userAttrItem" v-if="client.horoscope">
            <i class="fa-light fa-star-of-david text-ee1"></i>
            {{ client.horoscope }}
          </span>
          <span class="userAttrItem" v-if="client.height">
            <i class="fa-light fa-ruler-vertical text-ee1"></i>
            {{ client.height }} cm
          </span>

          <span class="userAttrItem" v-if="client.ethnicity">
            <i class="fa-light fa-earth-europe text-ee1"></i>
            {{ client.ethnicity }}
          </span>

          <span class="userAttrItem" v-if="client.education_level">
            <i class="fa-light fa-graduation-cap text-ee1"></i>
            {{ client.education_level }}
          </span>
          <span class="userAttrItem" v-if="client.annual_income">
            <i class="fa-light fa-circle-dollar text-ee1"></i>
            {{ client.annual_income }}
          </span>
          <span class="userAttrItem" v-if="client.relationship_status">
            <i class="fa-light fa-rings-wedding text-ee1"></i>
            {{ client.relationship_status }}
          </span>
          <!-- <span class="userAttrItem" v-if="client.child_status">
                    <i class="lsicon self-child_status-gold lsicon-16" :class="isBlackBg?'self-child_status-gold':'self-child_status'"></i>
                    {{ client.child_status }}
                </span> -->
          <span class="userAttrItem" v-if="client.child_plans">
            <i class="fa-light fa-family-pants text-ee1"></i>
            {{ client.child_plans }}
          </span>

          <span class="userAttrItem" v-if="client.religion">
            <i class="fa-light fa-hands-praying text-ee1"></i>
            {{ client.religion }}
          </span>
          <span class="userAttrItem" v-if="client.drinking_status">
            <i class="fa-light fa-wine-glass text-ee1"></i>
            {{ client.drinking_status }}
          </span>
          <span class="userAttrItem" v-if="client.smoking_status">
            <i class="fa-light fa-smoking text-ee1"></i>
            {{ client.smoking_status }}
          </span>
        </div>
      </div>

      <div>
        <div v-for="(ophoto, index) in client.other_photos" :key="index">
          <img
            @click="viewPhoto(index + 1)"
            :src="ophoto"
            class="w-100 rounded-10 mb-4"
          />
        </div>
      </div>
    </div>
    <photoView ref="photoView" :carousels="clientPhotos" />
  </div>
</template>
<script>
// if (typeof window != "undefined") {
//   var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
// }
import verifiedTag from "./verifiedTag";
import photoView from "./photoView.vue";
export default {
  name: "profileViewCom",
  props: {
    isCard: {
      type: Boolean,
      default: false,
    },
    isBlackBg: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    verifiedTag,
    photoView,
  },
  data() {
    return {
      verison: "v2",
      clientPhotos: [],
      client: null,
      swiperWinStyle: null,
      profilePhotoSwiper: null,
      swiperOption: {
        //init:false,
        speed: 300,
        loop: false,
        initialSlide: 0,
        direction: "horizontal",
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  mounted() {
    // this.initView();
  },
  methods: {
    viewPhoto(i) {
      console.log("view photo", i);
      this.$refs.photoView.showModal(i);
    },
    hideView() {
      let self = this;
      if (self.profilePhotoSwiper) {
        if (self.profilePhotoSwiper[0]) {
          self.profilePhotoSwiper[0].observer.destroy();
        } else if (self.profilePhotoSwiper.observer) {
          self.profilePhotoSwiper.observer.destroy();
        }
        //   else if(_.has(self.profilePhotoSwiper, 'destroy')) {
        //     self.profilePhotoSwiper.destroy();
        //   }
      }
      self.client = null;
    },
    showView(client) {
      this.client = client;
      this.clientPhotos = this.client.photos;
      //   console.log("show", this.client, client);
      this.initView();

      //   console.log("show2", client.first_name);
    },
    setStyle() {
      let self = this;
      let win_wd = $(window).width();

      if (win_wd < 768) {
        let marginW = self.isCard ? 40 : 0;
        self.swiperWinStyle = {
          width: `${win_wd - marginW}px`,
          //   height: `${(win_wd - marginW) * 1.5}px`,
          overflow: "hidden",
        };
        // $("#swiperbox").css({
        //   width: `${win_wd - 30}px`,
        //   overflow: "hidden"
        // });
        // $(".swiper-container").css({ height: `${(win_wd - marginW) * 1.5}px` });
        $(".swiper-container")
          .removeClass("swiper-container-ios")
          .removeClass("swiper-container-android");
      }
    },
    initView() {
      let self = this;
      self.$layer.loading();

      setTimeout(function () {
        self.setStyle();
      }, 100);

      //   if (self.client && self.client.photos.length > 1 && VueAwesomeSwiper) {
      //     console.log(
      //       "self.profilePhotoSwiper=",
      //       //   self.profilePhotoSwiper,
      //       //   VueAwesomeSwiper
      //       new Date().getTime()
      //     );
      //     if (self.profilePhotoSwiper) {
      //       if (self.profilePhotoSwiper[0]) {
      //         self.profilePhotoSwiper[0].observer.destroy();
      //       } else if (self.profilePhotoSwiper.observer) {
      //         self.profilePhotoSwiper.observer.destroy();
      //       }
      //       //   else if(_.has(self.profilePhotoSwiper, 'destroy')) {
      //       //     self.profilePhotoSwiper.destroy();
      //       //   }
      //     }
      //     setTimeout(function () {
      //       self.profilePhotoSwiper = new VueAwesomeSwiper.Swiper(
      //         "#profilePhotoSwiper",
      //         self.swiperOption
      //       );
      //       console.log(
      //         "self.photoSwiper",
      //         // self.photoSwiper,
      //         new Date().getTime()
      //       );
      //     }, 300);
      //   }
      //   console.log("self.client", self.client);
      self.client.other_photos = [];
      if (self.client.photos && self.client.photos.length > 1) {
        for (let op of self.client.photos) {
          if (op != self.client.primary_photo) {
            self.client.other_photos.push(op);
          }
        }
        // target.other_photos = target.photos;
        // target.other_photos = _.pull(target.other_photos, target.primary_photo);
      }

      //   target.photos = [];
      // }

      setTimeout(function () {
        self.$layer.closeAll("loading");
      }, 500);
    },
  },
};
</script>
<style scoped>
/* .swiper-container img {
  border: none !important;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #53c6b0;
} */
.verifiedIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  /* background: rgba(255, 255, 255, 0.8); */
  z-index: 99;
}
.verifiedIcon i.verified {
  left: 2px;
  top: 3px;
}
.introStatusText {
  position: absolute;
  height: 38px;
  line-height: 38px;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9;
  color: #fff;
  background: rgba(73, 73, 73, 0.6);
  text-align: center;
  font-size: 90%;
  /* text-overflow: ellipsis; */
}
.introStatusText.gray {
  background: rgba(75, 73, 73, 0.6);
}
.introStatusText.red {
  background: rgba(229, 77, 86, 0.6);
}
.introStatusText.purple {
  background: rgba(134, 0, 194, 0.6);
}
.introStatusText.green {
  background: rgba(30, 195, 38, 0.6);
}
.profile_photo_list {
  list-style: none;
  padding: 15px;
  margin: 0 auto;
  text-align: center;
}
.profile_photo_list li {
  max-width: 400px;
  width: 100%;
  display: inline-block;
}
.profile_photo_list li img {
  width: 100%;
}
@media (max-width: 575.98px) {
  .profile_photo_list li {
    width: 100%;
  }
}
</style>