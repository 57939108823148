import { render, staticRenderFns } from "./photoEdit.vue?vue&type=template&id=1bcb6762&"
import script from "./photoEdit.vue?vue&type=script&lang=js&"
export * from "./photoEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "47781e9f"
  
)

export default component.exports