<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
      no-close-on-backdrop
      @close="modalClose('colse')"
      @hide="modalClose('hide')"
    >
      <p class="text-diamond text-center">
        <i class="fa fa-check-circle" style="font-size: 55px"></i>
      </p>
      <div class="text-center my-3">{{ confirmText }}</div>

      <div class="text-center mt-2">
        <a href="javascript:;" @click="closeModel" class="btn btn-ls-g px-4">{{
          $t("Ok")
        }}</a>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "inviteConfirmPopup",
  data() {
    return {
      show: false,
      target: {
        first_name: "Amy",
      },
      confirmText: null,
    };
  },
  methods: {
    modalClose(act) {
      //   console.log("modalClose", act);
      this.$emit("modalHide", { act: act });
    },
    closeModel() {
      let self = this;
      self.show = false;
      self.target = null;
    },
    showModal(target) {
      let self = this;
      self.show = true;
      self.target = target;

      self.confirmText = self
        .$t("invite_confirm_desc")
        .replace(/\[match_first_name\]/g, self.target.first_name);
    },
  },
};
</script>