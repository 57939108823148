<template>
  <div>
    <b-modal
      v-model="show"
      size="lg"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
      no-close-on-backdrop
      content-class="bg-transparent border-0"
      body-class="p-1"
      modal-class="bg-black"
    >
      <!-- <div v-if="coordinates" style="position: fixed;
    top: 0;
    left: 0;
    color: #aaa;">{{ coordinates.width }}x{{ coordinates.height }}</div> -->
      <cropper
        :src="copperImage"
        @change="change"
        :stencil-props="{
          aspectRatio: aspectRatio,
        }"
      />
      <div class="text-center mt-3" :style="btnStyle">
        <b-button
          @click="okClick"
          pill
          variant="ee1"
          class="px-5 fw-500 text-uppercase"
          >{{ $t("Ok") }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.compact.css";
export default {
  name: "image-copper",
  data() {
    return {
      show: false,
      file: null,
      copperImage: null,
      canvas: null,
      aspectRatio: 2 / 3,
      coordinates: null,
      btnStyle: {
        position: "absolute",
        width: "130px",
        bottom: 0,
        left: "50%",
        "margin-left": "-65px",
      },
    };
  },
  methods: {
    okClick() {
      let self = this;
      let base64 = "";
      // console.log(self.coordinates.width, self.coordinates.height)
      if (self.coordinates.width > 1000 || self.coordinates.height > 1500) {
        // console.log('resizeImage..')
        let newCan = self.resizeImage();
        base64 = newCan.toDataURL(self.file.type);
      } else {
        base64 = self.canvas.toDataURL(self.file.type);
      }

      let uint8 = self.getUint8Arr(base64);
      let newFile = new File([uint8.u8arr], self.file.name, {
        type: uint8.mime,
      });
      self.$emit("change", { imageData: base64, file: newFile });
      self.show = false;
      self.copperImage = null;
    },
    resizeImage() {
      let self = this;
      // //max size: 1000x1500
      let can = document.createElement("canvas");

      // console.log('resizeImage', self.aspectRatio)
      let s_w = 1000;
      let s_h = 1500;
      if (self.aspectRatio == 1.5) {
        can.width = 1200;
        can.height = 800;
        s_w = 1200;
        s_h = 800;
      } else {
        can.width = 1000;
        can.height = 1500;
      }

      var ctx = can.getContext("2d");

      ctx.drawImage(
        self.canvas,
        0,
        0,
        self.coordinates.width,
        self.coordinates.height,
        0,
        0,
        s_w,
        s_h
      );

      // let imgbase64 = can.toDataURL();

      return can;
    },
    change(data) {
      let self = this;
      // console.log(data);
      self.canvas = data.canvas;
      self.coordinates = data.coordinates;
      if (data.image && data.image.height >= $(window).height()) {
        // console.log('fixed...')
        self.btnStyle.position = "fixed";
      } else {
        self.btnStyle.position = "absolute";
      }

      //   console.log(data.canvas.toDataURL("image/jpeg"));
      //   this.dataUrl = data.canvas.toDataURL("image/jpeg");
    },
    showModal(file, aspectRatio) {
      this.show = true;
      this.file = file;
      this.aspectRatio = aspectRatio;
      // console.log('showModal', aspectRatio)
      let blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        blob = URL.createObjectURL(file);
      }

      this.copperImage = blob;
    },
    getUint8Arr(dataurl) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        // 获取解码后的二进制数据的长度，用于后面创建二进制数据容器
        n = bstr.length,
        // 创建一个Uint8Array类型的数组以存放二进制数据
        u8arr = new Uint8Array(n);
      // 将二进制数据存入Uint8Array类型的数组中
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return { u8arr, mime };
    },
  },
};
</script>