<template>
  <div class="bg-faf min-h100 text-1d">
    <!-- <topName
      ref="topNav"
      :showMobileTop="false"
      id="topNav"
      style="display:none"
    /> -->

    <div>
      <!-- <div class="pt-3 pl-3" style="position: absolute">
        <a href="javascript:;" @click="goback"
          ><i class="lsicon close_white lsicon-20"></i
        ></a>
      </div> -->
      <div class="page6-2 p-3 text-center">

        <div v-if="!upgradeData" class="text-center py-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>

        <div v-else>
          <div class="fw-700 fs-20 text-ee1 discountDiv">
          <!-- <b-img
            height="30"
            class="mr-2"
            src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/lsm-app/free_upgrade/gift_icon.png"
            style="vertical-align: text-bottom"
          ></b-img> --><i class="fa-light fa-gift fs-23 mr-4"></i>
          {{ upgradeData.discount }}% {{ $t("discount_txt") }}!
        </div>
        <p class="text-7e mt-4 mb-0">
          {{ $t("normal_price") }}
          <del
            >{{ upgradeData.currency.symbol
            }}{{ formatPrice(upgradeData.price) }}</del
          >
        </p>
        <p class="text-ee1 fw-700 fs-20 my-1">{{ n_month_price }}</p>
        <p class="text-7e fs-13 mb-4">{{ $t("renew_with_month") }}</p>

        <div class="p-3 bg-white rounded">
          <div
            class="text-ls-y3 fw-700 d-flex align-items-center justify-content-center"
          >
            <b-img width="220" class="mx-2" :src="levelLogo"></b-img>
          </div>
          <hr />
          <div
            v-if="upgradeItems"
            class="text-left mx-auto"
            style="max-width: 450px"
          >
            <div
              v-for="item in upgradeItems"
              :key="item.index"
              class="d-flex justify-content-star mt-3"
            >
              <div>
                <i :class="item.icon" class="fs-25 text-ee1"></i>
              </div>
              <div class="ml-3">
                <p class="text-7e fw-600">{{ item.title }}</p>
                <p class="text-7e fs-13">{{ item.desc }}</p>
              </div>
            </div>
          </div>
          <div></div>
        </div>

        <div class="pb-5">
          <p
            class="my-4 font-italic text-7e fw-500"
            v-html="offerEndInText"
          ></p>

          <b-button
            @click="submitPayment"
            :variant="btnVariant"
            pill
            class="px-5 l-space2 text-uppercase w-100"
            style="height: 53px"
          >
            {{ $t("m_continue_txt") }}</b-button
          >

          <p class="text-7e fs-14 mt-4 mb-3">
            Recurring billing, cancel anytime
          </p>
          <p class="text-7e fs-12">
            By tapping Continue, your payment will be charged to your iTunes
            account and your subscription will automatically renew for the same
            package length at the same price until you cancel it in settings in
            the iTunes store. <br />By tapping ‘Continue’, you agree to our
            Terms.
          </p>
        </div>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../utils/helper";
// import topName from "../common/topNav";
export default {
  name: "payment-free-upgrade",
  components: {
    // topName
  },
  data() {
    return {
      ref: "",
      type: "starter",
      upgradeData: null,
      upgradeItems: null,
      client: null,
      timer: null,
      time_count: null,
      timeLeft: 0,
      offerEndIn: this.$t("offer_end_in"),
      offerEndInText: "",
      langCode: null,
      levelLogo: "",
      n_month_price: "",
      renew_with_months: "",
      btnVariant: "ee1",
      utmParams: {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.ref = _.get(self.$route, "query.ref", "");
    // if(self.ref != 'onboarding'){
    //     // self.$refs.topNav.setShowAll();
    //     setTimeout(function(){
    //         $('#topNav').show();
    //     }, 200)
    // <i class="fa-regular fa-user-check"></i>
    // }

    self.utmParams.utm_source = _.get(self.$route.query, "utm_source");
    self.utmParams.utm_medium = _.get(self.$route.query, "utm_medium");
    self.utmParams.utm_campaign = _.get(self.$route.query, "utm_campaign");
    self.utmParams.utm_term = _.get(self.$route.query, "utm_term");
    self.utmParams.utm_content = _.get(self.$route.query, "utm_content");

    self.langCode = self.$storage.get("cur-lang");
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.$layer.closeAll("loading");
        self.client = _.get(rs, "client");

        return self.$api.getFreeUpgradePrice("", self.$store.state);
      })
      .then((rs) => {
        self.upgradeData = rs;
        // self.renew_with_months = self.$t('renew_with_months').replace('[month]', self.upgradeData.month)
        self.n_month_price = self
          .$t("n_month_price")
          .replace("[month]", self.upgradeData.month)
          .replace(
            "[prce]",
            `${self.upgradeData.currency.symbol}${self.formatPrice(
              self.upgradeData.discount_price
            )}`
          );

        if (self.upgradeData.month == 1) {
          self.n_month_price = self.n_month_price.replace("months", "month");
        }

        self.loadInitData();
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
        console.log(["me error", err]);
      });
  },
  methods: {
    goback() {
      this.$router.push({ name: "op-match-list" });
    },
    submitPayment() {
      let self = this;

      let product = {
        price_row: self.upgradeData.price_row,
        description: self.upgradeData.membership_level.description,
      };
      product.price_row[`${self.upgradeData.month}_month`] =
        self.upgradeData.discount_price;
      product[`price_pre_${self.upgradeData.month}month`] = helper.divideNumber(
        self.upgradeData.discount_price,
        self.upgradeData.month,
        self.upgradeData.currency.show_decimals
      );

      self.$storage.setPaymentSelectedPlan(
        JSON.stringify({
          productType: "upgrade",
          plan_name: self.type,
          plan_month: self.upgradeData.month,
          product: product,
          currency: self.upgradeData.currency,
          client_id: _.get(self.client, "client_id", 0),
          //   user_data: self.userData,
          discount_price: self.upgradeData.discount_price,
          original_price: self.upgradeData.price,
          discount: self.upgradeData.discount,
        })
      );
      //   self.utmParams
      let queryObj = { lang: self.langCode, top_nav: 0 }; //
      Object.assign(queryObj, self.utmParams);
      self.$router.push({
        name: "payment-checkout",
        query: queryObj,
      });
      return false;
    },
    timerRuner() {
      let self = this;

      let time24 = 24 * 3600 * 1000;

      self.timeLeft = time24;

      self.runTimer();
    },
    runTimer() {
      let self = this;
      self.timer = setInterval(function () {
        if (!self.timeLeft || self.timeLeft <= -1) {
          clearInterval(self.timer);
          //   self.show_50_off = false;s
        } else {
          self.time_count = helper.setLeftSec(Math.floor(self.timeLeft / 1000));
          self.timeLeft -= 1000;
          self.offerEndInText = self.offerEndIn.replace(
            "[time_count]",
            `<span class="text-ls-red3 fw-700"
                    >${self.time_count.h}:${self.time_count.m}:${self.time_count.s}</span>`
          );
        }
      }, 1000);
    },

    formatPrice(price_val) {
      return helper.currenyPriceFormat(
        price_val,
        _.get(this.upgradeData.currency, "show_decimals")
      );
    },
    loadInitData() {
      let self = this;
      self.timerRuner();
      let leveId = _.get(
        self.upgradeData,
        "membership_level.membership_level_id",
        2
      );

      // leveId = 3;
      if (leveId == 2) {
        self.type = "starter";
        self.btnVariant = "ee1"; //"ls-gold-g2";
      } else if (leveId == 3) {
        self.type = "plus";
        self.btnVariant = "ee1"; //"ls-plus-g2";
      }
      // console.log('leveId=', leveId)
      self.levelLogo = helper.getMembershipPhotoById(leveId);

      if (self.type == "starter") {
        self.upgradeItems = helper.freeStarterCarousel(self);
      } else if (self.type == "plus") {
        self.upgradeItems = helper.freePlusCarousel(self);
      }
    },
  },
};
</script>
<style>
.discountDiv {
  background: #efe6e1;
  border: 1px solid #d7c2bf;
  border-radius: 4px;
  padding: 6px;
}
</style>