const Emojis =  [
        "&#128512;",
        '&#128513;',
        '&#128514;',
        '&#128515;',
        '&#128516;',
        '&#128578;',
        '&#128577;',
        '&#128517;',
        '&#128518;',
        '&#128519;',
        '&#128522;',
        '&#128524;',
        '&#128525;',
        '&#128526;',
        '&#128556;',
        '&#128557;',
    
        '&#128559;',
        '&#128560;',
        '&#128076',
        '&#128077',
        '&#128078',
        '&#128079',
        '&#128561;',
        '&#128562;',
        '&#128563;',
        '&#128564;',
        '&#9757;',
        '&#9995;',
        '&#9996;',
        '&#128070;',
        '&#128072;',
        '&#128073;',

    ]


export default Emojis
  