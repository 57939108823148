import { render, staticRenderFns } from "./switchc.vue?vue&type=template&id=5cbc98b7&"
import script from "./switchc.vue?vue&type=script&lang=js&"
export * from "./switchc.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./switchc.vue?vue&type=style&index=0&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "41925c9c"
  
)

export default component.exports