<template>
	<div>
		Something was wrong, please try again.
	</div>
</template>

<script>
	export default{
		name:'webError',
		mounted(){
			
			
			//console.log(self.$route.params)
			
		}
	}
</script>

<style>
</style>
