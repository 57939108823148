<template>
  <b-modal
    v-model="show"
    hide-header
    hide-footer
    hide-header-close
    centered
    no-close-on-esc
  >
    <div class="p-3">
      <div class="fw-600 text-center mb-3">{{ title }}</div>
      <div class="text-muted mb-4 text-center" v-html="content"></div>
      <!-- <p class="fw-700 mb-4 text-center" v-html="content">
        </p> -->
      <div class="text-center mb-3">
        <b-button
          @click="greenClick"
          variant="ee1"
          pill
          class="px-2 fw-500 text-uppercase"
          style="width: 230px !important"
          >{{ greenBtnText }}</b-button
        >
      </div>
      <div class="text-center">
        <b-button
          @click="blueClick"
          variant="outline-1d"
          class="px-2 fw-500 text-uppercase"
          pill
          :class="canClick ? '' : 'disabled'"
          style="width: 230px !important"
          >{{ blueBtnText }} {{ timeText }}</b-button
        >
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "alert-layer",
  //   props: {

  //   },
  data() {
    return {
      show: false,
      title: "",
      content: "",
      greenBtnText: "",
      blueBtnText: "",
      useTimer: false,
      timeTotal: 60,
      btnTimer: null,
      timeText: "",
      canClick: false,
    };
  },
  methods: {
    showAlert(options) {
      let self = this;
      self.title = _.get(options, "title");
      self.content = _.get(options, "content");
      self.greenBtnText = _.get(options, "greenBtnText");
      self.blueBtnText = _.get(options, "blueBtnText");
      self.useTimer = _.get(options, "useTimer", false);

      self.show = true;
      if (self.useTimer) {
        if (self.btnTimer) {
          clearInterval(self.btnTimer);
        }
        self.btnTimer = null;
        self.canClick = false;

        self.btnTimer = setInterval(() => {
          self.dTime();
        }, 1000);
      } else {
        self.canClick = true;
      }
    },
    hideAlert() {
      this.show = false;
    },
    greenClick() {
      this.$emit("greenClick");
    },
    blueClick() {
      if (!this.canClick) {
        return false;
      }
      this.$emit("blueClick");
    },
    dTime() {
      let self = this;
      if (self.timeTotal == 0) {
        self.canClick = true;
        self.timeTotal = 60;

        //send_btn.removeClass('disabled');
        self.timeText = "";
        clearInterval(self.btnTimer);
        return false;
      } else {
        self.canClick = false;
        self.timeText = `(${self.timeTotal}s)`;
        self.timeTotal--;
      }
    },
  },
};
</script>