<template>
  <div class="bg-faf min-h100">
    <topName ref="topNav" :showMobileTop="false" />

    <div class="page_container hideMobileTop page6-2">
      <div class="px-3">
        <div class="d-flex justify-content-between mb-3 pt-3">
          <div>&nbsp;</div>
          <div>
            <b-img height="20"
              src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/logos/Lovestruck-Ruby-Wordmark-RGB.png"></b-img>
          </div>
          <div>
            <!-- <i
              @click="filterClick"
              class="lsicon match-filter-gold lsicon-30 cursor"
            ></i> -->
            <i @click="filterClick" class="fa-light fa-sliders-simple fs-30 text-1d"></i>
          </div>
        </div>

        <div v-if="isLoading && query.page == 1" class="center_center text-center" style="height: 140px">
          <div class="x-center">
            <b-spinner variant="secondary" class="ls_loading_spinner"></b-spinner>
            <b-img class="ls_loading_spinner_logo"
              src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/logos/logo6.2@3x.png"></b-img>
          </div>
        </div>

        <div v-if="!isLoading && !hasMatches" class="center_center text-center">
          <!-- <b-img
            width="80"
            class="mb-4"
            src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.0/wait_icon_white.png"
          ></b-img> -->
          <div style="height: 20vh; width: 100%"></div>
          <div class="mx-auto position-relative">
            <i class="fa-light fa-user-clock text-d7c" style="font-size: 80px"></i>
          </div>
          <div style="height: 10vh; width: 100%"></div>

          <h4 class="fw-600 text-ee1 mb-3 fs-18">
            {{ $t("we_finding_matches") }}
          </h4>
          <p class="mb-4 text-7e">{{ $t("check_later_time") }}</p>
        </div>

        <div v-if="matches.length > 0">
          <div class="list_box">
            <ul>
              <li v-for="item in matches" :key="item.target_client.client_id" class="pb-2 mb-4">
                <div class="headpicbox" :style="headpicboxStyle" @click="viewUser(item)">
                  <div class="headpicimg cursor" :style="`background-image:url(${item.target_client.photo_url})`"></div>

                  <div class="user-info p-2">
                    <div class="name_str fw-700">
                      {{ item.target_client.first_name }},
                      {{ item.target_client.age }}
                    </div>
                    <div class>
                      <p v-if="item.target_client.job_title" class="fw-300 small text-truncate">
                        <i class="fa fa-suitcase mr-1" style="width: 16px"></i>{{ item.target_client.job_title }}
                      </p>
                      <p v-if="item.target_client.university_name" class="fw-300 small text-truncate">
                        <i class="fa fa-graduation-cap mr-1"></i>{{ item.target_client.university_name }}
                    </p>
                    </div>
                  </div>
                  <div class="matchButtonDiv matchList" style="">
                    <!-- <button
                      v-if="item.intro.chat_total > 0"
                      @click.stop="chatBtnClick(item)"
                      class="btn-1d shadow o-btn"
                    >
                      <i class="fa-regular fa-comment fs-18"></i>
                    </button> -->
                    <button @click.stop="chatBtnClick(item)" class="btn-1d-ee1 shadow o-btn lg position-relative">
                      <i class="fa-regular fa-comment fs-24"></i>
                      <span v-if="item.target_client.msg_received_count > 0" class="countNum small">{{ item.target_client.msg_received_count }}</span>
                      <!-- <i class="lsicon chat_gold lsicon-80"></i> -->
                    </button>
                    <!-- <b-button @click.stop="chatBtnClick(item)" class="matchActBtn goldChatBtn" :class="item.intro.chat_total>0?'hasChat':''">
                        <i class="fa fa-comment"></i>
                    </b-button> -->
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <bottomDetector ref="bottomDetector" :loadingMore="isLoading" :noMore="isEnd" @arriveBottom="loadMore" />
      </div>
    </div>
    <matchFilterUpdatedPopup ref="matchFilterUpdatedPopup" />
  </div>
</template>
<script>
import topName from "../common/topNav";
import matchFilterUpdatedPopup from "../common/matchFilterUpdatedPopup";
import bottomDetector from "../common/bottomDetector";
import helper from "../../utils/helper";
export default {
  name: "lsm-match-list",
  components: {
    topName,
    matchFilterUpdatedPopup,
    bottomDetector,
    // likeChatSentPopup
  },
  computed: {
    headpicboxInStyle() {
      let list_box_w = this.resetPX();
      // console.log("=list_box_w=", list_box_w);
      return {
        height: `${Math.floor((list_box_w - 10) * 0.49 * 1.5)}px`,
      };
    },
    headpicboxStyle() {
      let list_box_w = this.resetPX();

      return {
        height: `${Math.floor((list_box_w - 10) * 0.49 * 1.5)}px`,
      };
    },
  },
  data() {
    return {
      client: null,
      isLoading: true,
      isEnd: false,
      query: {
        page: 1,
        page_size: 20,
        // is_verified: 0,
      },
      hasMatches: false,
      matches: [],
      matchesTest: [
        {
          client_id: 1234,
          first_name: "Jenny",
          age: 32,
          job_title: "Marketing manager",
          university_name: "Sydney University",
          photo_url:
            "https://cdn-cf2.lovestruck.com/upload-m/crop_1652631291.png",
        },
      ],
    };
  },
  mounted() {
    let self = this;

    let match_filter_updated = self.$storage.get("match_filter_updated");
    // match_filter_updated = 1
    if (match_filter_updated == 1) {
      self.$refs.matchFilterUpdatedPopup.showModal();
    }

    self.$api.getClientMe(self.$store.state).then((rs) => {
      self.client = _.get(rs, "client");

      if (self.client.membership_has_expired == 1) {
        self.$router.push({ name: "renew-payment" });
      } else if (self.client.membership_level_id < 2) {
        // self.$router.push({ name: "matchmaking-callback" });
        self.$router.push({ name: "op-match-list" });
      } else if (!self.client.is_verified) {
        self.$router.push({ name: "profile-unverified" });
      } else if (self.client.client_status_id == -1) {
        self.$router.push({ name: "profile-paused" });
      } else {
        self.getMatchmakingMatchList();
      }
    });
  },
  methods: {
    chatBtnClick(item) {
      let self = this;
      if(self.client.membership_level_id < 2){
        self.$router.push({ name: "renew-payment" });
        return false;
      }
      self.$router.push({
        name: "client-chat",
        query: {
          clientId: helper.lshash.encode(
            _.get(item, "target_client.client_id", 0)
          ),
        },
      });
    },
    filterClick() {
      this.$router.push({
        name: "profile-requirement",
      });
    },
    viewUser(item) {
      //   this.currentIntro = item;
      this.$router.push({
        name: "profile-view",
        query: { from: "ls6" },
        params: { clientId: _.get(item, "target_client.en_id", 0) },
      });
    },
    loadMore() {
      this.getMatchmakingMatchList();
    },
    getMatchmakingMatchList() {
      let self = this;

      if (self.isEnd) {
        return false;
      }
      self.isLoading = true;
      self.$api
        .getMatchmakingMatchList(
          `page=${self.query.page}&page_size=${self.query.page_size}`,
          self.$store.state
        )
        .then((rs) => {
          self.isLoading = false;
          // self.matches = _.get(rs, 'matches')
          // if(self.matches && self.matches.length > 0){
          //     self.hasMatches = true
          // }else{
          //     self.hasMatches = false
          // }

          if (_.has(rs, "matches")) {
            let len = _.get(rs, "matches", []).length;
            if (len > 0) {
              self.matches = [...self.matches, ..._.get(rs, "matches", [])];
              self.hasMatches = true;
              // if (len < self.query.page_size) {
              //   self.isEnd = true;
              // }
            } else {
              self.isEnd = true;
              if (self.query.page == 1) {
                self.hasMatches = false;
              }
            }
          } else {
            self.isEnd = true;
          }
          //   self.hasMatches = false;
          //test
          //   self.matches = []
          //   self.isEnd = true;

          //   if (self.client.membership_level_id < 2) {
          //     self.isEnd = true;
          //   }

          if (!self.isEnd) {
            self.query.page++;
          }
        });
    },
    resetPX() {
      //   console.log("resetPX...");
      //   if ($(".list_box").length == 0) {
      //     console.log("no found list_box");
      //     return false;
      //   }

      let w_w = $(window).width();
      let list_box_w = "";
      if (w_w <= 576) {
        list_box_w = w_w - 30;
      } else {
        list_box_w = 660;
      }

      //   console.log("list_box_w", list_box_w);
      setTimeout(function () {
        $(".list_box").css({ width: `${list_box_w}px` });
      }, 80);

      //   $(".filterbar").css({ width: `${list_box_w}px` });

      return list_box_w;
    },
  },
};
</script>