<template>
    <div>
        <iframe v-if="yotiUrl" :src="yotiUrl" style="height:100vh; width:100%; border:none;" allow="camera"></iframe>
        <div v-else class="text-center">
            <p>Yoti Demo</p>
        <b-button @click="startVerify" variant="primary">开始验证</b-button>
    </div>
    </div>
</template>
<script>
export default {
    name: "yoti-demo",
    data(){
        return {
            yotiUrl: null,
            client_id: 0
        }
    },
    mounted(){
        let self = this;
        self.client_id = _.get(self.$route.query, "client_id", 0)
    },
    methods:{
        startVerify(){
            let self = this;
            self.$api.getYotiCreate(self.$store.state, `client_id=${self.client_id}`)
            .then(rs=>{
                self.yotiUrl = _.get(rs, 'verify_url')
            })
        }
    }
    
}
</script>