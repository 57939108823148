<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <h5 class="text-center mb-4">{{ $t("cancel_this_date") }}?</h5>
      <div class="px-3">
        <p class="mb-3" v-html="cancelText1"></p>
        <p class="mb-3" v-html="cancelText2"></p>
      </div>

      <div class="text-center mb-3">
        <b-button @click="cancelThisDate" variant="ls-g" class="px-4">{{
          $t("cancel_txt")
        }}</b-button>
      </div>

      <div class="text-center">
        <b-button @click="closeModel" variant="light" class="px-3">{{
          $t("not_now")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "cancelDateModal",
  data() {
    return {
      show: false,
      intro: null,
      cancelText1: null,
      cancelText2: null,
    };
  },
  methods: {
    init() {
      let self = this;
      // console.log("intro=", self.intro);
      self.cancelText1 = self
        .$t("cancel_date_desc1")
        .replace("[match_first_name]", self.intro.target_user_name);
      if (self.intro.source_user_intro_status_id == 3) {
        self.cancelText2 = self.$t("cancel_date_desc2");
      }
    },
    cancelThisDate() {
      let self = this;
      //test
      // self.$emit("cancelDate", {
      //   introId: _.get(self.intro, "client_intro_id")
      // });
      // return false;

      let data = new FormData();
      data.append("act_type", "decline"); //cancel
      data.append("intro_id", _.get(self.intro, "client_intro_id"));

      self.$layer.loading();

      self.$api
        .postDateAction(data, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          //todo
          self.$emit("cancelDate", {
            introId: _.get(self.intro, "client_intro_id"),
          });
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          // console.log(err);
          self.$layer.msg(_.get(err, "response.data.error.body"));
        });
    },
    closeModel() {
      let self = this;
      self.show = false;
      self.intro = null;
    },
    showModal(intro) {
      let self = this;
      self.show = true;
      self.intro = intro;
      self.init();
    },
  },
};
</script>