<template>
  <div v-if="type == 4">
    <div v-if="dataList.length > 0">
      <b-card
        v-for="item in dataList"
        :key="item.client_intro_id"
        no-body
        class="mb-3 shadow"
      >
        <div class="px-3 pt-3 mb-2">
          <a
            @click="cancelDate(item.client_intro_id)"
            href="javascript:;"
            class="close_btn"
            >X</a
          >

          <p class="font-weight-bold font-1_3rem mb-2">
            {{ item.date_time_day }}
            <span class="text-8b">{{ item.date_time_month }}</span>
          </p>

          <b-media tag="li" class="my-2">
            <template v-slot:aside>
              <b-img
                width="80"
                rounded="circle"
                thumbnail
                fluid
                :src="item.photo_url"
              ></b-img>
            </template>
            <p>&nbsp;</p>
            <p class="font-weight-bold">
              {{ item.target_user_name }}, {{ item.target_user_age }}
            </p>
            <p class="text-truncate text-secondary">
              <i class="lsicon job_dc"></i>
              {{ item.target_user_job_title }}
            </p>
          </b-media>
          <p class="text-diamond font-1_2rem mb-3">
            {{ $t("time_txt") }}:
            <strong>{{ item.date_time_time }}</strong>
          </p>
          <p class="font-1_1rem font-weight-bold mb-2 text-secondary">
            {{ item.venue_name }}
          </p>
          <address v-if="item.venue_address" class="text-secondary mb-2">
            <i class="lsicon location"></i>
            {{ item.venue_address }}
          </address>

          <p
            v-if="item.venue && item.venue.date_credit_offer"
            class="text-truncate text-secondary"
          >
            <i class="lsicon offer"></i>
            {{ item.venue.date_credit_offer }}
          </p>
        </div>

        <div
          v-if="
            item.source_user_intro_status_id < 3 &&
            item.target_user_intro_status_id >= 2
          "
          class="text-center mt-2 pb-3"
        >
          <b-button
            @click="
              confirmInvite(item.client_intro_id, item.venue.date_credit_cost)
            "
            variant="ls-p"
            class="px-4"
            >{{ $t("Confirm") }}</b-button
          >
          <p class="text-truncate text-secondary mt-2">
            <i class="lsicon date_credit"></i>
            {{
              item.venue.date_credit_cost > 1
                ? `${item.venue.date_credit_cost} ${$t("Date_credits")}`
                : `1 ${$t("a_date_credit")}`
            }}
          </p>
        </div>

        <div
          v-if="
            item.source_user_intro_status_id >= 2 &&
            item.target_user_intro_status_id < 3
          "
          class="py-3 text-center bg-secondary text-white rounded-bottom"
          v-html="
            $t('waiting_for_confirm').replace(
              '[match_first_name]',
              item.target_user_name
            )
          "
        ></div>

        <div
          v-if="
            item.source_user_intro_status_id >= 3 &&
            item.target_user_intro_status_id >= 3
          "
          class="py-3 text-center bg-confirmed text-white rounded-bottom font-weight-normal"
        >
          {{ $t("Date confirmed") }}
        </div>
      </b-card>
    </div>
    <div class="text-center">
      <a
        href="javascript:;"
        @click="loadMore"
        class="btn btn-outline-dark btn-sm btn-lg mx-auto"
        :class="[page == 1 || (page != 1 && isEnd) ? 'd-none' : '']"
        >{{ $t("m_load_more") }}</a
      >
    </div>

    <div v-if="noDates && page == 1" class="text-center text-secondary mt-3">
      {{ noDataText }}
    </div>
    <cancelDateModal ref="cancelDateModal" @cancelDate="cancelDateHander" />
  </div>
</template>
<script>
import cancelDateModal from "./cancelDateModal";
export default {
  name: "oneOn1DateListCom",
  components: {
    cancelDateModal,
  },
  props: {
    type: {
      type: Number,
    },
  },
  data() {
    return {
      // type: 4,
      noDates: false,
      noDataText: "",
      isEnd: false,
      isLoading: false,
      page: 1,
      dataList: [],
      counter: null,
    };
  },
  mounted() {},
  methods: {
    cancelDateHander(data) {
      let self = this;
      let findIndex = _.findIndex(self.dataList, {
        client_intro_id: _.get(data, "introId"),
      });
      // console.log("cancelDateHander", findIndex);
      self.dataList.splice(findIndex, 1);

      self.$refs.cancelDateModal.closeModel();
      // _.remove(self.dataList, findIndex);
      // _.remove(self.dataList, (value, index) => {
      //   console.log("remove", index);
      //   return index == findIndex;
      // });
    },
    cancelDate(introId) {
      let self = this;
      let index = _.findIndex(self.dataList, { client_intro_id: introId });
      let intro = _.get(self.dataList, index);
      if (!intro) {
        return false;
      }

      self.$refs.cancelDateModal.showModal(intro);
    },
    changeStatus(introId, data) {
      let self = this;
      let index = _.findIndex(self.dataList, { client_intro_id: introId });
      if (index == -1) {
        return false;
      }
      self.dataList[index]["source_user_intro_status_id"] = _.get(
        data,
        "source_user_intro_status_id"
      );
    },
    confirmInvite(introId, dateCreditCost) {
      let self = this;
      let index = _.findIndex(self.dataList, { client_intro_id: introId });
      let intro = _.get(self.dataList, index);
      if (!intro) {
        return false;
      }
      this.$emit("confirmInvite", {
        introId,
        dateCreditCost,
        target: {
          client_id: _.get(intro, "target_user_id"),
          first_name: _.get(intro, "target_user_name"),
          age: _.get(intro, "target_user_age"),
          gender: _.get(intro, "target_user_gender"),
          photo_url: _.get(intro, "photo_url"),
        },
      });
    },
    initData(type) {
      let self = this;

      // self.type = type;
      if (self.page == 1) {
        self.loadDatas();
      } else {
        self.loadMore();
      }
    },
    loadMore() {
      let self = this;
      if (self.isLoading) {
        return false;
      }
      self.loadDatas();
    },
    loadDatas() {
      let self = this;
      self.$layer.loading();
      if (self.isEnd) {
        self.$layer.closeAll("loading");
        return false;
      }
      self.isLoading = true;
      self.$api
        .getDateLists(
          `type=${self.type}&page=${self.page}&tsql=1`,
          self.$store.state
        )
        .then((rs) => {
          self.$layer.closeAll("loading");
          self.isLoading = false;
          // console.log(_.get(rs, "dates"));
          if (self.page == 1) {
            self.counter = _.get(rs, "counts");
          }

          if (_.get(rs, "dates")) {
            if (rs.dates.length == 0) {
              console.log("no datas");
              if (self.page == 1) {
                //
              }
              //self.showCardBtns = false;
              self.isEnd = true;
              self.emptyData();
            } else {
              if (self.page == 1) {
              }

              self.dataList = [...self.dataList, ...rs.dates];
              self.page += 1;
            }
          }
        })
        .catch((err) => {
          self.isLoading = false;
          self.$layer.closeAll("loading");
          // self.$parent.closeLoading();
          console.log("list error", err);
        });
    },
    emptyData() {
      let self = this;

      self.noDates = true;
      self.noDataText = self.$t("empty_1on1_dates");
      // if (self.type == 1) {
      //   self.noDataText = self.$t("empty_date_upcoming");
      // } else if (self.type == 2) {
      //   if (self.dataList.length <= 0) {
      //     self.noDataText = self.$t("empty_date_past");
      //   } else {
      //     self.noDataText = self.$t("no_more_found");
      //   }
      // }
    },
  },
};
</script>