<template>
  <div>
    <div class="page6-2 rounded bg-white">
      <h3 class="text-diamond font-weight-bold text-center mt-5">
        <i class="fa fa-check-circle"></i> {{ $t("payment_confirmed") }}
      </h3>

      <div class="text-center my-5">
        {{ $t("thx_payment_success") }}
      </div>
      <!-- and your account has been upgraded -->

      <div class="my-5">
        <div class="text-center fw-500 mb-3">
          {{ $t("order_summary") }}
        </div>

        <div class="card bg-light">
          <div class="p-3 d-flex justify-content-between">
            <!-- <div class="d-flex justify-content-between"> -->
            <!-- v-if="productType == 'upgrade'" -->
            <div>
              <b-img
                height="20"
                style="border: none !important"
                :src="plan_logo"
              />
            </div>

            <div v-if="productType != 'event'">
              {{ orderCard.amount }}
            </div>
            <div v-if="productType == 'event'">{{ orderCard.title }}</div>
            <!-- </div> -->
          </div>
          <p class="ml-3 mt-0">{{ orderCard.amount_sub }}</p>
          <hr class="my-2" />
          <div class="card-body px-3 pt-1 bg-light">
            <div class="d-flex justify-content-between">
              <div class="font-weight-normal">{{ $t("total_txt") }}:</div>
              <div v-if="productType != 'event'" class="font-weight-normal">
                {{ orderCard.amount }}
              </div>
              <div v-if="productType == 'event'" class="font-weight-normal">
                {{ orderCard.amount }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="mt-5 text-center">
        <div class="mb-3 fw-500">{{ $t("start_with_app") }}:</div>
        <b-img
          @click="openApp"
          width="280"
          style="border: none !important"
          center
          src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/app_store_icons.png"
        />
      </div> -->

      <div class="mt-5 text-center">
        <router-link :to="{ name: 'profile-my-account' }">{{
          $t("return_my_account")
        }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../utils/helper";
export default {
  name: "paySuccess",
  data() {
    return {
      logos: {},
      plan_logo: "",
      productType: "",
      orderCard: {
        title: "",
        amount: "",
        amount_sub: "",
      },
      selectedPlan: null,
      styleType: "",
    };
  },
  mounted() {
    let self = this;

    self.$parent.closeLoading();
    self.logos = helper.membershipPhoto();
    self.initData();

    try {
      window.webkit.messageHandlers.jsHandler.postMessage("payment_success");
    } catch (err) {
      // if (_.has(window, "Bridge")) {
      //   window.Bridge.finish("close");
      // } else {
      //   history.back();
      // }
    }

    // if (_.has(window, "webkit.messageHandlers.jsHandler")) {
    //   window.webkit.messageHandlers.jsHandler.postMessage("payment_success");
    // }
  },
  methods: {
    formatPrice2(price_val) {
      return helper.currenyPriceFormat(
        price_val,
        _.get(self.selectedPlan, "currency.show_decimals")
      );
    },
    openApp() {
      location.href = "http://r80k.app.link";
    },
    initData() {
      let self = this;

      self.selectedPlan = self.$storage.getPaymentSelectedPlan();
      self.productType = _.get(self.selectedPlan, "productType", "upgrade");

      //   console.log("self.selectedPlan", self.selectedPlan);

      switch (self.productType) {
        case "date_credit":
          self.initDateCreditData();
          break;
        case "event_ticket":
          self.initEventTicketData();
          break;
        case "upgrade":
          self.initUpgradeData();
          break;
        case "speed_dating_membership":
          self.initSpeedDatingMembershipData();
          break;
        case "event":
          self.initEventData();
          break;
        case "extend_chat":
          self.initExtendData();
          break;
        case "lovestruck_user":
          self.initLovestruckData();
          break;
      }

      self.initStyle();
      self.$storage.remove("tmp-user");
    },
    initExtendData() {
      let self = this;

      self.styleType = "extend_chat";

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub:
          _.get(self.selectedPlan, "product.num_extends", 0) + " Credits",
      };
    },
    initLovestruckData() {
      let self = this;

      self.styleType = "lovestruck_user";

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub:
          _.get(self.selectedPlan, "product.num_lovestrucks", 0) + " Credits",
      };
    },
    initEventTicketData() {
      let self = this;

      self.styleType = "event_ticket";

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub:
          _.get(self.selectedPlan, "product.num_event_tickets", 0) + " Tickets",
      };
    },
    initDateCreditData() {
      let self = this;

      self.styleType = "date_credit";

      self.orderCard = {
        amount:
          _.get(self.selectedPlan, "currency.symbol", "") +
          _.get(self.selectedPlan, "product.price", 0),
        amount_sub:
          _.get(self.selectedPlan, "product.num_date_credits", 0) +
          " " +
          self.$t("credits_txt"),
      };
    },
    initEventData() {
      let self = this;
      self.styleType = "event";
      self.orderCard = {
        title: _.get(self.selectedPlan, "event.name", "Event ticket"),
        amount:
          _.get(self.selectedPlan, "extras.price", 0) *
            _.get(self.selectedPlan, "extras.num", 1) +
          " " +
          self.$t("credits_txt"),
        amount_sub:
          _.get(self.selectedPlan, "extras.num", 1) +
          " ticket(s)" +
          "/" +
          _.get(self.selectedPlan, "extras.price", 0) +
          " " +
          self.$t("credits_txt"),
      };
    },
    initSpeedDatingMembershipData() {
      let self = this;
      let month = _.get(self.selectedPlan, "plan_month", 1);
      let currency = _.get(self.selectedPlan, "currency.symbol", "HK$");
      self.styleType = `speed_${_.get(
        self.selectedPlan,
        "plan_name",
        "basic"
      )}`;
      self.orderCard = {
        title: _.get(self.selectedPlan, "product.description", ""),
        amount:
          currency +
          _.get(self.selectedPlan, `product.price.${month}_month`, ""),
        amount_sub:
          currency +
          _.get(self.selectedPlan, `product.price_pre_${month}month`, "") +
          " / " +
          //   month +
          self.$t("payment_3col_month"),
      };
    },
    initUpgradeData() {
      let self = this;
      let month = _.get(self.selectedPlan, "plan_month", 1);
      let currency = _.get(self.selectedPlan, "currency.symbol", "HK$");
      self.styleType = _.get(self.selectedPlan, "plan_name", "gold");
      self.orderCard = {
        title: _.get(self.selectedPlan, "product.description", ""),
        amount:
          currency +
          self.formatPrice2(
            _.get(self.selectedPlan, `product.prices.0.${month}_month`, "")
          ),
        amount_sub:
          currency +
          _.get(self.selectedPlan, `product.price_pre_${month}month`, "") +
          " / " +
          //   month +
          self.$t("payment_3col_month"),
      };
    },
    initStyle() {
      let self = this;
      //   console.log("self.styleType", self.styleType);
      switch (self.styleType) {
        case "speed_basic":
          self.plan_logo = _.get(helper.lsLogos(), "basic");
          break;
        case "speed_gold":
          self.plan_logo = _.get(helper.lsLogos(), "gold");
          //   console.log("self.plan_logo", self.plan_logo);
          break;
        case "gold":
        case "date_credit":
        case "event_ticket":
        case "extend_chat":
        case "lovestruck_user":
          self.plan_logo = self.logos["gold"];
          break;
        case "basic":
          self.plan_logo = self.logos["basic"];
          break;
        case "platinum":
          self.plan_logo = self.logos["platinum"];
          break;
        case "diamond":
        case "event":
          self.plan_logo = self.logos["diamond"];
          break;
      }
    },
  },
};
</script>

