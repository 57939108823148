<template>
  <div
    id="stepPage"
    class="height-in min-h100 text-1d"
    :class="step == 'about-us' ? 'bg-20' : 'bg-faf'"
  >
    <div
      class="page6-2 p-3 text-left"
      :class="step == 'about-us' ? 'bg_image' : ''"
      :style="
        step == 'about-us'
          ? `background-image: url('https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Comp_1_PosterImage.png');`
          : ''
      "
    >
      <div class="text-left mb-2">
        <a href="javascript:;" @click="prevStep">
          <!-- <i class="lsicon arrow_left_y2"></i> -->
          <i
            class="fa-regular fa-chevron-left fs-24"
            :class="step == 'about-us' ? 'text-faf' : 'text-ee1'"
          ></i>
        </a>
      </div>
      <div
        v-if="step != 'about-us'"
        class="w-100 d-flex align-items-center mb-4"
      >
        <div class="ls-progress-bar bg-d7c v6-2 w-100">
          <div
            class="ls-progress-completed"
            :style="`transition: 0.1s ease 0s; width:${progressValue}`"
          ></div>
        </div>
      </div>

      <!-- relationship_type-->
      <!-- <div v-if="step == 'user-goal'">
        <h4
          class="mb-4 fw-500 ff-prata fs-28"
          v-html="$t('what_relationship_type')"
        ></h4>
        <div class="mx-auto">
          <div v-if="attrs" class="mx-auto">
            <b-button
              v-for="item in attrs.relationshipType"
              :key="item.relationship_type_id"
              variant="white"
              class="w-100 mb-3 btn-big text-left"
              :class="
                profile.relationship_type_id == item.relationship_type_id
                  ? 'selected fw-600'
                  : ''
              "
              @click="
                selectAttrValue(
                  'relationship_type_id',
                  item.relationship_type_id,
                  'user-goal'
                )
              "
              >{{ item.description }}
            </b-button>
          </div>
        </div>
      </div> -->

      <!-- age -->
      <!-- <div v-if="step == 'age'">
        <h4 class="mb-4 fw-500 ff-prata fs-28" v-html="$t('what_u_age')"></h4>
        <div v-if="attrs" class="mx-auto">
          <b-button
            v-for="item in attrs.ages"
            :key="item.age_id"
            variant="white"
            class="w-100 mb-3 btn-big text-left"
            :class="profile.age_id == item.age_id ? 'selected fw-600' : ''"
            @click="selectAttrValue('age_id', item.age_id, 'age')"
            >{{ item.description }}
          </b-button>
        </div>
      </div> -->
      <!-- age-range -->
      <!-- <div v-if="step == 'age-range'">
        <h4 class="mb-4 fw-500 ff-prata fs-28">
          What is the preferred age range for your match?
        </h4>
        <div class="mx-auto">
              <div class="fs-20 fw-500 text-center text-ee1 mb-4">
                {{ age_ranges[0] }} - {{ age_ranges[1] }}
              </div>
              <vue-slider
                v-model="age_ranges"
                :min="18"
                :max="99"
                :min-range="5"
                :interval="1"
                :tooltip="'none'"
                :dotSize="30"
                :height="2"
                class="ee1"
                @change="ageRangeChange"
                :enable-cross="false"
              />
            </div>
      </div> -->
      <!--1 name-->
      <div v-if="step == 'first-name'">
        <h4 class="mb-4 fw-500 ff-prata fs-28" v-html="$t('what_u_name')"></h4>
        <div class="mx-auto">
          <b-form-input
            id="first_name"
            v-model="profile.first_name"
            class="v6-2"
            @input="checkInput('first_name', 'first-name')"
            maxlength="32"
            :placeholder="$t('first_name')"
          ></b-form-input>
        </div>
      </div>
      <!-- gender -->
      <div v-if="step == 'gender'">
        <h4
          class="mb-4 fw-500 ff-prata fs-28"
          v-html="$t('what_u_gender')"
        ></h4>
        <div v-if="attrs" class="mx-auto">
          <b-button
            v-for="item in attrs.gender"
            :key="item.gender_id"
            variant="white"
            class="w-100 mb-3 btn-big text-left"
            :class="profile.gender == item.gender_key ? 'selected fw-600' : ''"
            @click="selectAttrValue('gender', item.gender_key, 'gender')"
            >{{ item.description }}
          </b-button>
        </div>
      </div>
      <!-- job_title -->
      <div v-if="step == 'job-title'">
        <h4
          class="mb-4 fw-500 ff-prata fs-28"
          v-html="$t('m_what_job_title')"
        ></h4>
        <div class="mx-auto">
          <b-form-input
            id="job_title"
            v-model="profile.job_title"
            class="v6-2"
            @input="checkInput('job_title', 'job-title')"
            maxlength="32"
            :placeholder="$t('lang_jobtitle')"
          ></b-form-input>
        </div>
      </div>

      <!--3 birthday-->
      <div v-if="step == 'dob'">
        <h4 class="mb-4 fw-500 ff-prata fs-28" v-html="$t('w_you_dob')"></h4>
        <div class="mx-auto">
          <div class="d-flex justify-content-between" id="input-dob">
            <div class="text-center py-3">
              <b-spinner label="Loading..." variant="light"></b-spinner>
            </div>
          </div>
        </div>
        <!-- <p v-if="ageString" class="fw-600 mt-4">{{ ageString }}</p> -->
      </div>

      <!--email-->
      <div v-if="step == 'email'">
        <h4 class="mb-4 fw-500 ff-prata fs-28" v-html="$t('what_u_email')"></h4>
        <div class="mx-auto">
          <b-form-input
            id="email"
            v-model="profile.email"
            type="email"
            class="v6-2"
            @input="checkInput('email', 'email')"
            maxlength="64"
            :placeholder="$t('web_header_email')"
          ></b-form-input>
          <!-- <p>{{ $t("recover_account_email") }}</p> -->
        </div>
      </div>

      <!--about-us-->
      <div v-if="step == 'about-us'" class="text-center text-faf">
        <h4 class="mb-3 fw-500 ff-prata fs-28">{{ $t("about_us") }}</h4>
        <p class="">
          All our members are verified through a 3-step verification process.
        </p>

        <div class="my-5">
          <img
            :src="`https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/onboarding2/verified_${imgPath}.png`"
            width="180"
          />
        </div>
        <p class="mb-4">
          A phone call with our team is required to start the verification
          process
        </p>

        <div class="text-center">
          {{ $t("scammers_fake_profiles") }}
        </div>

        <div class="mt-5">
          <b-button
            pill
            variant="ee1"
            @click="goToStep('callback')"
            class="px-5 l-space2 text-uppercase"
            style="height: 53px"
          >
            {{ $t("got_it") }}
          </b-button>
        </div>
      </div>

      <div
        v-if="showNextBtn && step != 'about-us'"
        class="pt-4 pb-3 text-right"
      >
        <b-button
          pill
          variant="ee1"
          @click="submitProfile"
          :class="canSubmit ? '' : 'disabled'"
          class="o-btn lg"
        >
          <span><i class="fa-light fa-arrow-right"></i></span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../utils/helper";
import datePicker from "../../utils/ios_datepicker.js";
export default {
  name: "op-onboarding",
  data() {
    return {
      step: "",
      attrs: null,
      client: null,
      canSubmit: false,
      showNextBtn: true,
      profile: {
        first_name: "",
        job_title: "",
        gender: "",
        dob: "",
        age_id: null,
        relationship_type_id: "",
        email: "",
      },
      imgPath: "hk",
      currYear: null,
      minYear: null,
      maxYear: null,
      calendar: null,
      cancelText: this.$t("cancel_txt"),
      confirmText: this.$t("Ok"),
      ageString: null,
      curLang: this.$store.getters.curLang,
      booking: null,
      age_ranges: [20, 25],
    };
  },
  computed: {
    progressValue() {
      let self = this;
      let v = 0;
      switch (self.step) {
        case "first-name":
          v = 20;
          break;
        case "job-title":
          v = 40;
          break;
        case "gender":
          v = 60;
          break;
        // case "age":
        //   v = 64;
        //   break;
        case "dob":
          v = 80;
          break;
        // case "user-goal":
        //   v = 81;
        //   break;
        case "email":
          v = 100;
          break;
      }
      return `${v}%`;
    },
  },
  mounted() {
    let self = this;

    self.currYear = new Date().getFullYear();
    self.minYear = self.currYear - 100;
    self.maxYear = self.currYear - 18;

    let stepV = _.get(self.$route, "params.step", "first-name");

    self.fetchAttrs();
    self.$api.getClientMe(self.$store.state, "").then((rs) => {
      self.$layer.closeAll("loading");
      self.client = _.get(rs, "client");
      self.booking = _.get(rs, "booking");
      self.age_ranges = _.get(rs, "client.age_ranges");

      let cityId = _.get(rs, "client.city_id", 1);
      if (cityId == 4) {
        self.imgPath = "ldn";
      } else if (cityId == 6) {
        self.imgPath = "bkk";
      }

      let relationship_type_ids = _.get(
        rs,
        "client.client_requirement.relationship_type_ids",
        0
      );

      self.profile = {
        dob: _.get(rs, "client.dob", ""),
        job_title: _.get(rs, "client.job_title", ""),
        email: _.get(rs, "client.email", ""),
        first_name: _.get(rs, "client.first_name", ""),
        gender: _.get(rs, "client.gender", ""),
        relationship_type_id:
          relationship_type_ids && relationship_type_ids != "0"
            ? relationship_type_ids
            : "",
        age_id: _.get(rs, "client.age_id") > 0 ? _.get(rs, "client.age_id") : 0,
      };
      self.goToStep(stepV);
    });
  },
  methods: {
    initDatePicker() {
      let self = this;
      //   console.log("initDatePicker");
      self.calendar = new datePicker();

      let d_dob = self.profile.dob
        ? self.profile.dob
        : `${self.currYear - 30}-1-1`;

      // if(!self.profile.dob){
      //     self.profile.dob = d_dob
      // }

      self.calendar.init({
        trigger: "#input-dob",
        type: "date",
        minDate: `${self.minYear}-1-1`,
        maxDate: `${self.maxYear}-12-31`,
        dvalue: d_dob,
        langKey: self.curLang.indexOf("zh") > -1 ? "zh" : "en",
        okText: self.confirmText,
        cancelText: self.cancelText,
        onSubmit: function () {
          /*确认时触发事件*/
          self.profile.dob = self.calendar.date_value;
          //   console.log(
          //     self.calendar.value,
          //     self.calendar.date_value,
          //     self.profile.dob
          //   );

          // let parseRes = helper.parseDate(self.calendar.date_value);
          //   self.ageString = self
          //     .$t("u_are_age")
          //     .replace("[age]", self.currYear - parseRes.yy);
          //   self.ageString = `Your are ${self.currYear - parseRes.yy} years old`;
          //   self.checkSubmitStatus();
          //   console.log("parseRes", parseRes);
          //   calendar.value = `${parseRes.dd} ${calendar.month_name} ${parseRes.yy}`;
          // self.dob_year = parseRes.yy;
          // self.dob_month = parseRes.mm;
          // self.dob_day = parseRes.dd;

          self.canSubmit = true;
        },
        onClose: function () {
          /*取消时触发事件*/
        },
      });
    },
    checkBookAndGo() {
      let self = this;
      /**
       * if city_id==4 && membership_level_id<=1
       * && no upcoming consultation date then show consultation booking page
       */
      if (
        self.client.city_id == 4 &&
        self.client.membership_level_id <= 1 &&
        !self.booking
      ) {
        self.$router.push({ name: "book-consultation" });
      } else {
        self.$router.push({ name: "matchmaking-callback" });
      }
    },
    goToStep(v) {
      let self = this;

      if (v == "callback") {
        self.checkBookAndGo();
        return false;
      } else {
        self.step = v;
        if (self.step == "dob") {
          setTimeout(() => {
            self.initDatePicker();
          }, 300);
        }
        self.setUlr();
        self.setStyle();
        self.checkInput(false, self.step);
      }
    },
    setStyle() {
      let self = this;
      //   if (self.step == "about-us") {
      //     self.showNextBtn = false;
      //   }

      if (_.includes(["first-name", "dob", "job-title", "email"], self.step)) {
        self.showNextBtn = true;
      } else {
        self.showNextBtn = false;
      }
    },
    selectAttrValue(name, value, step) {
      let self = this;
      self.profile[name] = value;

      if (!self.showNextBtn) {
        self.submitProfile();
      }
    },
    prevStep() {
      let self = this;
      let pStep = "";

      if (self.step == "about-us") {
        //   pStep = "user-goal";
        // } else if (self.step == "user-goal") {
        pStep = "email";
      } else if (self.step == "email") {
        pStep = "dob";
      } else if (self.step == "dob") {
        pStep = "gender";
      } else if (self.step == "gender") {
        pStep = "job-title";
      } else if (self.step == "job-title") {
        pStep = "first-name";
      }
      if (pStep) {
        self.goToStep(pStep);
      }
    },
    nextStep() {
      let self = this;
      let step = null;
      if (!self.profile.first_name) {
        step = "first-name";
      } else if (!self.profile.job_title) {
        step = "job-title";
      } else if (!self.profile.gender) {
        step = "gender";
      }
      // else if(self.step == 'gender'){
      //   step = 'dob'
      // }
      else if (!self.profile.dob) {
        step = "dob";
      }
      // } else if (!self.profile.age_id) {
      //     step = "age";
      //   }

      // else if (!self.profile.relationship_type_id) {
      //   step = "user-goal";
      // }
      else if (!self.profile.email) {
        step = "email";
      } else if (self.step == "email") {
        step = "about-us";
      }

      if (step) {
        self.goToStep(step);
      } else {
        //go to match free cb page
        self.checkBookAndGo();
      }
    },
    submitProfile() {
      let self = this;

      let fdata = new FormData();

      if (self.step == "first-name") {
        fdata.append("first_name", self.profile.first_name);
      } else if (self.step == "job-title") {
        fdata.append("job_title", self.profile.job_title);
      } else if (self.step == "gender") {
        fdata.append("gender", self.profile.gender);
      } else if (self.step == "dob") {
        fdata.append("dob", self.profile.dob);
      } else if (self.step == "age") {
        fdata.append("age_id", self.profile.age_id);
      } else if (self.step == "user-goal") {
        fdata.append("relationship_type_id", self.profile.relationship_type_id);
        //requirement
      } else if (self.step == "email") {
        // add check
        if (!helper.checkEmail(self.profile.email)) {
          self.$layer.msg(self.$t("val_email_invalid"));
          self.canSubmit = false;
          return false;
        }

        fdata.append("email", self.profile.email);
      }

      self.$layer.loading();

      self.$api
        .postClientUpdate(fdata, self.$store.state)
        .then((rs) => {
          // console.log(rs);
          self.$layer.closeAll("loading");
          self.canSubmit = false;
          self.nextStep();
        })
        .catch((err) => {
          //   console.log(["save error", err]);
          self.$layer.closeAll("loading");
          self.$layer.msg(
            _.get(
              err,
              "response.data.error.body",
              _.get(err, "message", "Failed")
            )
          );
          //   self.$layer.msg(err.response.data.error.body);
        });
    },
    checkInput(name, step) {
      let self = this;

      self.canSubmit = false;
      //   console.log("step=", step);

      if (step == "first-name") {
        if (self.profile.first_name) {
          self.canSubmit = true;
        }
      } else if (step == "gender") {
        if (self.profile.gender_id) {
          self.canSubmit = true;
          //   self.genderbtnEvent();
        }
      } else if (step == "job-title") {
        if (self.profile.job_title) {
          self.canSubmit = true;
        }
      } else if (step == "email") {
        // console.log("check email", step, self.canSubmit);
        if (helper.checkEmail(self.profile.email)) {
          //   console.log("true");
          self.canSubmit = true;
        }
      }
    },

    setUlr() {
      let url = `/app/screening/${this.step}`;
      history.replaceState(null, null, url);
    },
    fetchAttrs() {
      let self = this;

      self.$api
        .getAttributes(self.$store.state)
        .then((rs) => {
          //   console.log("getAttributes success");
          self.attrs = rs;
          //   self.fillAttrs();
        })
        .catch((err) => {
          console.log(["error", rs]);
        });
    },
  },
};
</script>