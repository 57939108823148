import { render, staticRenderFns } from "./blockReportUserPopup.vue?vue&type=template&id=566ab9a0&scoped=true&"
import script from "./blockReportUserPopup.vue?vue&type=script&lang=js&"
export * from "./blockReportUserPopup.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./blockReportUserPopup.vue?vue&type=style&index=0&id=566ab9a0&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "566ab9a0",
  "18739be6"
  
)

export default component.exports