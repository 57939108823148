import { render, staticRenderFns } from "./view.vue?vue&type=template&id=242d10e0&scoped=true&"
import script from "./view.vue?vue&type=script&lang=js&"
export * from "./view.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./view.vue?vue&type=style&index=0&id=242d10e0&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "242d10e0",
  "3815dd9c"
  
)

export default component.exports