<template>
    <div>
         <div class="wrap">
            <div class="touch-item" 
            @click="testClick"
            @touchstart.capture="touchStart" 
            @touchmove.capture="touchMove" 
            @touchend.capture="touchEnd">
                    <div class="item" :style="swipeStyle">
                        <div class="d-flex justify-content-start">
                            <div class="headImgBox mr-3">
                            <img src="https://cdn-cf2.lovestruck.com/upload-xs/crop_1595339255.png" class="w-100">
                            </div>
                            <div data-v-56ec04a2="" class="pb-3 border-bottom" :style="rowStyle">
                            <div data-v-56ec04a2="" class="d-flex justify-content-between mb-2">
                                <p data-v-56ec04a2="" class="font-weight-bold font-14">
                        Vicky
                      </p><p class="small text-muted font-weight-light">
                        08/07/2022
                      </p></div><p class="text-truncate text-muted font-14">
                      test message test message
                    </p></div>

                    <div id="113682-report" class="ml-2">
                        <button @click.stop="reportUser" type="button" class="btn rounded-0 btn-warning" style="height: 100%;">Report</button>
                        </div>
                        <div id="113682-block">
                            <button @click.stop="blockUser" type="button" class="btn rounded-0 btn-danger" style="height: 100%;">Block</button>
                            </div>

                            </div>
                    </div>
                </div>
            </div>   
            <blockReportUserPopupVue ref="blockReportUserPopupVue"/>
    </div>
</template>
<script>
// import VueTouch from 'vue-touch';
import blockReportUserPopupVue from "../common/blockReportUserPopup.vue";
export default {
    name: 'test-touch',
    components:{
        blockReportUserPopupVue
    },
    data(){
        return {
            startX: 0, // 初始位置
          endX: 0, // 结束位置
          swipeStyle: "", // 滑动的样式
            wd: 140, // 编辑和删除按钮的宽度之和
            swipeWd: 0, // 已经滑动的距离
            activeId: "", // 实际是上一次的活动id
        }
    },
    computed: {
    rowStyle() {
        if (typeof window == 'undefined') {
            return {};
        }
      let l_span_w = $(window).width();
      if (l_span_w > 0) {
        return { "width": `${l_span_w - 10}px`, 'min-width': `${l_span_w - 10}px` };
      }

      return {};
    },
  },
    mounted(){

    },
    methods:{
        testClick(){
            return false;
        },
        reportUser(){
            console.log('..reportUser..')
            this.$refs.blockReportUserPopupVue.showReportOnly({
                en_id: 'test',
            });
        },
        blockUser(){
            console.log('..blockUser..')
            this.$refs.blockReportUserPopupVue.showBlockOnly({
              en_id: 'test',
              first_name: 'Text'
          })
        },
        getClientX(){
            let e = event || window.event
            return e.touches[0].clientX;
        },
        getChangedClientX(){
            let e = event || window.event
            return e.changedTouches[0].clientX;
        },
        touchStart(){
            let self = this;
            self.wd = $(`#113682-report`).outerWidth() + $(`#113682-block`).outerWidth();
            self.startX = self.getClientX();
            self.swipeStyle = 'transform:translateX(0px)'
        },
        touchMove(){
            let self = this;
            let moveX = self.getClientX();
            let diff = moveX - self.startX;

            //left
            if(diff < 0 && diff > -self.wd){
                self.swipeStyle = "transform:translateX(" + diff + "px)";
                self.swipeWd = diff;
            }

            //right
            if(diff > 0 && diff <= self.wd && self.swipeWd < 0){
                let wx = diff + self.swipeWd;
                self.swipeStyle = "transform:translateX(" + wx + "px)";
            }

            // console.log('touchMove', diff)
        },
        touchEnd(){
            let self = this;
            let endX = self.getChangedClientX();

            let diff = endX - self.startX;

            // console.log('touchEnd', diff)
            // console.log('self.swipeWd=', self.swipeWd)

            if (diff < 0) {

                if (diff <= -this.wd / 2) {

                    // 向左滑动超过二分之一

                    self.swipeStyle = "transform:translateX(" + -this.wd + "px)";

                    self.swipeWd = -this.wd;

                } else {

                    self.swipeStyle = "transform:translateX(0px)";

                    self.swipeWd = 0;

                }

            } else {
                
                //&& diff >= self.wd / 2
                if (self.swipeWd < 0 && diff > 10 ) {

                    // 向左滑动超过二分之一

                    self.swipeStyle = "transform:translateX(0px)";

                    self.swipeWd = 0;

                } 
                else {

                    self.swipeStyle = "transform:translateX(" + self.swipeWd + "px)";

                }

            }


        }

    }
}
</script>
<style scoped>

.wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.touch-item{}
.item {
  padding-left: 10px;
  height: 120px;
  background: #ffffff;
  align-items: center;
  flex-direction: inherit;
  }

.item {
  /* width: 120%;  */
  transition: 0.1s ease 0s; 
}

.edit-line {
  width: 2px;
  height: 80px;
  background: rgba(255, 255, 255, 1);
}

.edit-delete {
  width: 160px;
  height: 100%;
  background: rgba(255, 126, 34, 1);
    opacity: 0.8;
  align-items: center;

}


.add-btn {

  width: 200px;

  height: 80px;

  background: rgba(255, 126, 34, 1);

  box-shadow: 0px 0px 5px 0px rgba(221, 221, 236, 1);

  border-radius: 40px;

  text-align: center;

  line-height: 80px;

  color: #ffffff;

  font-size: 30px;

  position: fixed;

  bottom: 8%;

  left: 50%;

  transform: translateX(-50%);

}
</style>