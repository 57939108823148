<template>
  <div class="bg-faf min-h100">
    <topName
      ref="topNav"
      :showDateCredit="false"
      :showEventTicket="false"
      :showMobileTop="false"
    />

    <!--  -->
    <div class="pb-5 px-3 text-center page6-2 text-1d">
      <div class="d-flex justify-content-between mb-3 pt-3">
        <div>&nbsp;</div>
        <div>
          <b-img
            height="20"
            src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/v12/Logo-v12@2x.png"
          ></b-img>
        </div>
        <div>
          <!-- <i
              @click="filterClick"
              class="lsicon match-filter-gold lsicon-30 cursor"
            ></i> -->
          <i
            @click="filterClick"
            class="fa-light fa-sliders-simple fs-30 text-1d"
          ></i>
        </div>
      </div>
      <div style="height: 20vh; width: 100%"></div>
      <div class="mx-auto position-relative">
        <i
          class="fa-light fa-circle-pause text-d7c"
          style="font-size: 80px"
        ></i>
      </div>
      <div style="height: 10vh; width: 100%"></div>
      <!-- <b-img
      width="80"
      center
      src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/pause_icon.png"
      class="mb-4"
    /> -->

      <h4 class="text-ee1 fw-600 mb-3 fs-18">{{ $t("account_is_paused") }}</h4>
      <p class="text-7e mb-5">{{ $t("unpause_for_activate_matches") }}</p>

      <div>
        <b-button
          @click="unpauseAccount"
          variant="ee1"
          pill
          class="px-5 l-space2 text-uppercase"
          style="height: 53px"
          >{{ $t("unpause_account") }}</b-button
        >
      </div>
    </div>
    <alertModal
      ref="alertModal"
      @okEvent="alertOkClick"
      @cancelEvent="alertCancelClick"
    />
  </div>
</template>
<script>
import topName from "../common/topNav";
import alertModal from "../common/alertModal";
export default {
  name: "profile-paused",
  components: {
    topName,
    alertModal,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    filterClick() {
      this.$router.push({
        name: "profile-requirement",
      });
    },
    unpauseAccount() {
      let self = this;
      self.$refs.alertModal.showModal({
        title: self.$t("active_u_account"),
        message: self.$t("active_u_account_desc"),
        okText: self.$t("unpause_my_account"),
        cancelText: self.$t("cancel_txt"),
        metaData: {
          type: "active",
        },
      });
    },
    alertCancelClick(data) {
      let self = this;
      //   console.log("alertCancelClick", data);
      self.$refs.alertModal.hideModal();
    },
    alertOkClick(data) {
      let self = this;
      //   console.log("alertOkClick", data);
      //   let actName = _.get(data, "type");
      let fdata = new FormData();

      fdata.append("operate", "active");
      self.$refs.alertModal.hideModal();
      self.postClientOperate(fdata);
    },
    postClientOperate(data) {
      let self = this;

      self.$api.postClientOperate(data, self.$store.state).then((rs) => {
        // location.reload()
        //name: "matchmaking-match-list"
        setTimeout(function () {
          self.$router.push({ name: "matchmaking-match-list" });
        }, 800);
      });
    },
  },
};
</script>