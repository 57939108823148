<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
      no-close-on-backdrop
      id="listDiv"
    >
      <div class="d-flex justify-content-between mb-3">
        <a
          v-if="showVenueIds.length > 0 && showVenueList"
          href="javascript:;"
          @click="backToTimeList"
          class="px-2"
        >
          <i class="fa fa-arrow-left text-ls-y"></i>
        </a>

        <div class="" v-html="modalTitle"></div>

        <a href="javascript:;" @click="closeModel" class="px-2">
          <i class="fa fa-times text-ls-y"></i>
        </a>
      </div>

      <div v-if="!showVenueList" class="mb-3">
        <p class="font-weight-bold text-left">{{ selectMonth }}</p>

        <div v-if="days" class="mt-2">
          <div class="swiper-container" id="venueDaySwiper">
            <div class="swiper-wrapper">
              <div v-for="d in days" :key="d.full" class="swiper-slide">
                <button
                  @click="selectDay(d.full)"
                  :class="selectDate == d.full ? 'btn-info' : 'btn-light'"
                  class="btn"
                >
                  {{ d.w }}
                  <br />
                  {{ d.d }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="timeList && !showVenueList">
        <b-list-group :flush="true">
          <b-list-group-item
            v-for="tt in timeList"
            :key="tt"
            @click="selectTime(tt)"
            href="javascript:;"
            class="d-flex justify-content-between align-items-center"
            >{{ tt }}
            <i class="fas fa-angle-right"></i>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div v-if="showVenueIds.length > 0 && showVenueList">
        <p class="text-8b mb-2">
          {{ selectDateTimeStr }}
          <a
            href="javascript:;"
            @click="backToTimeList"
            class="text-ls-y font-weight-bold"
            >{{ $t("link_change") }}</a
          >
        </p>
        <p class="font-weight-bold text-8b mb-3">{{ $t("where_for_date") }}</p>
        <div v-for="vid in showVenueIds" :key="vid">
          <b-card class="mb-3 shadow-sm">
            <h6 class="font-1_2rem font-weight-bold mb-1">
              {{ venuesMaps[vid]["name"] }}
            </h6>
            <p v-if="venuesMaps[vid]['cuisine_type']">
              {{ venuesMaps[vid]["cuisine_type"]["description"] }}
            </p>

            <b-media tag="li" class="my-2">
              <template v-slot:aside>
                <b-img
                  style="width: 100px; max-height: 120px"
                  v-if="venuesMaps[vid]['primary_photo']"
                  :src="venuesMaps[vid]['primary_photo']['photo_url']"
                ></b-img>
              </template>
              <!-- <p
                v-if="venuesMaps[vid]['price_range']"
                class="text-truncate text-secondary"
              >
                <i class="lsicon price"></i>
                {{ venuesMaps[vid]["price_range"]["description"] }} /{{
                  $t("person")
                }}
              </p> -->
              <p
                v-if="venuesMaps[vid]['venue_price_range_id'] > 0"
                class="text-truncate text-secondary"
                v-html="
                  renderPriceRange(venuesMaps[vid]['venue_price_range_id'])
                "
              ></p>
              <p class="text-truncate text-secondary">
                <i class="lsicon date_credit"></i>
                {{
                  venuesMaps[vid]["date_credit_cost"] > 1
                    ? `${venuesMaps[vid]["date_credit_cost"]} ${$t(
                        "Date_credits"
                      )}`
                    : `${venuesMaps[vid]["date_credit_cost"]} ${$t(
                        "a_date_credit"
                      )}`
                }}
              </p>
              <!-- <p
                v-if="venuesMaps[vid]['date_credit_offer']"
                class="text-truncate text-secondary"
              >
                <i class="lsicon offer"></i>
                {{ venuesMaps[vid]["date_credit_offer"] }}
              </p> -->
            </b-media>
            <address class="text-secondary">
              <i class="lsicon location"></i>
              {{ venuesMaps[vid]["address"] }}
            </address>

            <div class="text-center">
              <b-button
                @click="selectVenue('', vid)"
                variant="ls-g"
                class="px-4"
                >{{ $t("Select") }}</b-button
              >
            </div>
          </b-card>
        </div>
      </div>

      <div v-if="newTimeList">
        <div v-for="t in timeList" :key="t[0]">
          <div v-for="tt in t[1]" :key="tt">
            <b-card class="mb-3 shadow-sm">
              <p class="font-weight-bold font-1_3rem text-8b mb-2">
                {{ t[0] }}
              </p>
              <h6 class="font-1_2rem font-weight-bold mb-1">
                {{ venuesMaps[tt]["name"] }}
              </h6>
              <p v-if="venuesMaps[tt]['cuisine_type']">
                {{ venuesMaps[tt]["cuisine_type"]["description"] }}
              </p>

              <b-media tag="li" class="my-2">
                <template v-slot:aside>
                  <b-img
                    v-if="venuesMaps[tt]['primary_photo']"
                    width="100"
                    :src="venuesMaps[tt]['primary_photo']['photo_url']"
                  ></b-img>
                </template>
                <p
                  v-if="venuesMaps[tt]['price_range']"
                  class="text-truncate text-secondary"
                >
                  <i class="lsicon price"></i>
                  {{ venuesMaps[tt]["price_range"]["description"] }} /{{
                    $t("person")
                  }}
                </p>

                <p class="text-truncate text-secondary">
                  <i class="lsicon date_credit"></i>
                  {{
                    venuesMaps[tt]["date_credit_cost"] > 1
                      ? `${venuesMaps[tt]["date_credit_cost"]} ${$t(
                          "Date_credits"
                        )}`
                      : `1 ${$t("a_date_credit")}`
                  }}
                </p>
                <!-- <p
                  v-if="venuesMaps[tt]['date_credit_offer']"
                  class="text-truncate text-secondary"
                >
                  <i class="lsicon offer"></i>
                  {{ venuesMaps[tt]["date_credit_offer"] }}
                </p> -->
              </b-media>
              <address class="text-secondary">
                <i class="lsicon location"></i>
                {{ venuesMaps[tt]["address"] }}
              </address>

              <div class="text-center">
                <b-button
                  @click="selectVenue(t[0], tt)"
                  variant="ls-g"
                  class="px-4"
                  >{{ $t("Select") }}</b-button
                >
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}
export default {
  name: "availabilityVenueList",
  data() {
    return {
      introId: null,
      targetClientId: null,
      targetClientName: null,
      modalTitle: "",
      show: false,

      daySwiper: null,
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 3,
        freeMode: true,
      },
      selectMonth: null,
      selectDate: null,
      selectTimeStr: null,
      days: null,
      timeList: null,
      dateList: [],
      currency: null,
      venuesMaps: null,
      newTimeList: null,
      dateItem: null,
      showVenueIds: [],
      showVenueList: false,
      selectDateTimeStr: "",
    };
  },
  methods: {
    renderPriceRange(rangeId) {
      let html =
        '<i class="lsicon money_gery"></i><i class="lsicon money_gery"></i><i class="lsicon money_gery"></i><i class="lsicon money_gery"></i><i class="lsicon money_gery"></i>';
      for (let i = 0; i < rangeId; i++) {
        html = html.replace("money_gery", "monery_gold");
      }
      return html;
    },
    selectVenue(time, venueId) {
      let self = this;
      let selectVenue = _.get(self.venuesMaps, venueId);

      // console.log("selectVenue", selectVenue, self.selectDate, time);

      self.$emit("selectVenue", {
        venue: selectVenue,
        dateTime: `${self.selectDate} ${self.selectTimeStr}`,
        introId: self.introId,
        targetClientId: self.targetClientId,
      });
    },
    closeModel() {
      let self = this;
      self.show = false;
      self.selectDate = null;
      self.selectTimeStr = null;
      self.selectMonth = null;
      self.timeList = null;
      self.showVenueList = false;
      self.days = [];
    },
    showModal(introId, targetId = null, targetName = null) {
      let self = this;
      self.show = true;
      self.introId = introId;
      self.targetClientId = targetId;
      self.targetClientName = targetName;
      self.modalTitle = self
        .$t("when_date_someone")
        .replace("[first_name]", targetName);
      self.loadDatas();
    },
    backToTimeList() {
      this.showVenueList = false;
      this.modalTitle = this.$t("when_date_someone").replace(
        "[first_name]",
        this.targetClientName
      );
      this.initDaySwiper();
    },
    selectTime(timeStr) {
      let self = this;

      //   console.log(`${self.selectDate} ${timeStr}`);
      self.selectTimeStr = timeStr;
      //   self.selectDateTimeStr = self
      //     .$t("select_date_venue")
      //     .replace("[date_time_str]", `${self.selectDate} ${timeStr}`);

      //   self.selectDateTimeStr = `Date time on ${self.selectDate} ${timeStr}`;
      //   self.selectDateTimeStr = self.$t("where_for_date");
      self.selectDateTimeStr = self
        .$t("date_time_on")
        .replace("[date_time]", `${self.selectDate} ${timeStr}`);

      let timeIndex = _.indexOf(self.timeList, timeStr);
      self.showVenueIds = _.get(self.dateItem, `times.new_list.${timeIndex}.1`);
      //   console.log("timeIndex=", timeIndex, self.showVenueIds);
      //   let venueIds =
      if (self.showVenueIds.length > 0) {
        self.showVenueList = true;
        self.modalTitle = "";
        $("#listDiv").animate({ scrollTop: 0 }, 500);
      }
    },
    selectDay(datestr) {
      let self = this;
      let newd = self.$moment(datestr);
      self.selectDate = newd.format("YYYY-MM-DD");
      self.selectMonth = newd.format("MMMM");

      let index = _.findIndex(self.dateList, { date: datestr });
      self.dateItem = _.get(self.dateList, index);
      // let time = _.get(self.dateList, index)
      //   self.timeList = _.get(_.get(self.dateList, index), "times.new_list", []);
      self.timeList = _.get(self.dateItem, "times.time_list", []);
      self.showVenueList = false;
    },
    initDaySwiper() {
      let self = this;
      if (VueAwesomeSwiper) {
        // self.daySwiper = null;
        // console.log("self.daySwiper=", self.daySwiper);
        if (self.daySwiper) {
          if (self.daySwiper[0]) {
            self.daySwiper[0].observer.destroy();
          } else {
            self.daySwiper.destroy();
          }
        }

        setTimeout(function () {
          self.daySwiper = new VueAwesomeSwiper.Swiper(
            "#venueDaySwiper",
            self.swiperOption
          );

          //   console.log("self.daySwiper=", self.daySwiper);
        }, 10);
      }
    },
    initDatas() {
      let self = this;
      self.days = [];
      for (let d of self.dateList) {
        let newd = self.$moment(d.date);
        if (!self.selectDate) {
          self.selectDate = newd.format("YYYY-MM-DD");
          self.selectMonth = newd.format("MMMM");
          //   self.timeList = _.get(d, "times.new_list");
          self.timeList = _.get(d, "times.time_list");
          self.dateItem = d;
        }

        self.days.push({
          m: newd.format("MMMM"),
          w: newd.format("ddd"),
          d: newd.format("DD"),
          full: newd.format("YYYY-MM-DD"),
        });
      }

      // console.log("selectDate", self.selectDate);
      // console.log("selectMonth", self.selectMonth);
      // console.log("timeList", self.timeList);
      self.$layer.closeAll("loading");

      self.initDaySwiper();
    },
    loadDatas() {
      let self = this;
      self.$layer.loading();
      //   if (self.dateList && self.venuesMaps) {
      //     self.initDatas();

      //     return;
      //   }

      self.$api.getAvailabilityList("", self.$store.state).then((rs) => {
        self.dateList = _.get(rs, "time_list");
        self.currency = _.get(rs, "currency");
        self.venuesMaps = _.get(rs, "venues_maps");
        self.initDatas();
      });
    },
  },
};
</script>