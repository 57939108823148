<template>
  <div class="calender">
    <div class="top">
      <div class="d-flex justify-content-between">
        <div>
          <a href="javascript:;" @click="handleShowLastMonth">上个月</a>
        </div>
        <div @click="ymClick" class="cursor">{{ year }}年{{ month }}月</div>
        <div>
          <a href="javascript:;" @click="handleShowNextMonth">下个月</a>
        </div>
      </div>
      <!-- <div class="top_date">{{ year }}年{{ month }}月</div> -->
      <!-- <div class="btn_wrap">
        <ul>
          <li @click="handleShowNextMonth">下个月</li>
          <li @click="handleShowToday">今天</li>
          <li @click="handleShowLastMonth">上个月</li>
        </ul>
      </div> -->
    </div>
    <div class="date_wrap">
      <div v-if="showYm">
        <div class="d-flex justify-content-between">
          <div
            class="w-100"
            style="height: 250px; overflow: scroll; padding: 10px"
          >
            <p>Year</p>
            <a
              @click="selectYear(item)"
              href="javascript:;"
              class="btn small mb-2 mr-2"
              :class="changeYear == item ? 'btn-ls-red2' : 'btn-light'"
              style="width: 62px"
              v-for="item in yearList"
              :key="item"
              >{{ item }}</a
            >
          </div>
          <div
            style="width: 80px; height: 250px; overflow: scroll; padding: 10px"
          >
            <p>Month</p>
            <ul>
              <li v-for="item in months" :key="item">
                <a
                  @click="selectMonth(item)"
                  href="javascript:;"
                  class="btn small mb-2"
                  :class="changeMonth == item ? 'btn-ls-red2' : 'btn-light'"
                  >{{ item }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <ul class="week">
          <li>日</li>
          <li>一</li>
          <li>二</li>
          <li>三</li>
          <li>四</li>
          <li>五</li>
          <li>六</li>
        </ul>
        <ul class="day">
          <li
            v-for="(item, index) in days"
            :key="index"
            class="fw-500 cursor"
            :class="{
              now:
                nowLi ==
                item.y.toString() + item.m.toString() + item.d.toString(),
              'text-979': item.m != month,
            }"
            @click="selectDate(item)"
          >
            {{ item.d }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "calender",
  props: {
    dateString: {
      type: String,
      default: "1990-01-01",
    },
  },
  data() {
    return {
      showYm: false,
      year: "",
      month: "",
      day: "",
      days: [],
      nowLi: "",
      yearList: [],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      changeYear: null,
      changeMonth: null,
      selectDateValue: null,
    };
  },
  methods: {
    selectMonth(m) {
      this.changeMonth = m;
      this.checkChangeValue();
    },
    selectYear(y) {
      this.changeYear = y;
      this.checkChangeValue();
    },
    checkChangeValue() {
      let self = this;
      if (self.changeYear && self.changeMonth) {
        self.year = self.changeYear;
        self.month = parseInt(self.changeMonth);
        self.showYm = false;

        self.changeYear = null;
        self.changeMonth = null;
        self.days = [];
        this.pushDays();
      }
    },
    ymClick() {
      this.showYm = true;
    },
    getYearList() {
      let self = this;
      let now = new Date();
      let minYear = now.getFullYear() - 100;
      let maxYear = now.getFullYear() - 18;

      for (let i = minYear; i <= maxYear; i++) {
        self.yearList.push(i);
      }
    },
    //控制当前日期显示特殊样式
    handleShowDateStyle() {
      let now = new Date();
      this.nowLi =
        now.getFullYear().toString() +
        (now.getMonth() + 1).toString() +
        now.getDate().toString();
      console.log(this.nowLi);
    },
    //得到当前年这个月分有多少天
    getDays(Y, M) {
      let day = new Date(Y, M, 0).getDate();
      return day;
    },
    //得到当前年，这个月的一号是周几
    getWeek(Y, M) {
      let now = new Date();
      now.setFullYear(this.year);
      now.setMonth(this.month - 1);
      now.setDate(1);
      let week = now.getDay();
      return week;
    },
    pushDays() {
      //将这个月多少天加入数组days
      for (let i = 1; i <= this.getDays(this.year, this.month); i++) {
        this.days.push({ y: this.year, m: this.month, d: i });
      }
      //将下个月要显示的天数加入days
      let now = new Date();
      now.setFullYear(this.year);
      now.setMonth(this.month);

      for (
        let i = 1;
        i <=
        42 -
          this.getDays(this.year, this.month) -
          this.getWeek(this.year, this.month);
        i++
      ) {
        this.days.push({
          y: now.getFullYear().toString(),
          m: now.getMonth() + 1,
          d: i,
        });
      }
      //将上个月要显示的天数加入days
      let now2 = new Date();
      now2.setFullYear(this.year);
      now2.setMonth(this.month - 1);
      for (let i = 0; i < this.getWeek(this.year, this.month); i++) {
        var lastMonthDays = this.getDays(this.year, this.month - 1);
        // this.days.unshift(lastMonthDays - i);
        this.days.unshift({
          y: now.getFullYear().toString(),
          m: now.getMonth() + 1,
          d: lastMonthDays - i,
        });
      }
      //   console.log(this.days);
      //   console.log(this.getWeek(this.year, this.month));
    },
    getDate() {
      //   let now = new Date();
      //   this.year = now.getFullYear();
      //   this.month = now.getMonth() + 1;

      let self = this;
      let dArr = self.dateString.split("-");
      this.year = parseInt(dArr[0]);
      this.month = parseInt(dArr[1]);
      this.pushDays();
    },
    selectDate(item) {
      //   console.log(item);
      this.selectDateValue = item;
      this.nowLi = `${item.y}${item.m}${item.d}`;
      console.log(this.nowLi);
      this.$emit("dateChange", item);
    },
    handleShowNextMonth() {
      this.days = [];
      if (this.month < 12) {
        this.month = this.month + 1;
        this.pushDays();
      } else {
        this.month = this.month = 1;
        this.year = this.year + 1;
        this.pushDays();
      }
    },
    handleShowToday() {
      this.days = [];
      let now = new Date();
      this.year = now.getFullYear();
      this.month = now.getMonth() + 1;
      this.pushDays();
    },
    handleShowLastMonth() {
      this.days = [];
      if (this.month > 1) {
        this.month = this.month - 1;
        this.pushDays();
      } else if (this.year > 1970) {
        this.month = 12;
        this.year = this.year - 1;
        this.pushDays();
      } else {
        alert("不能查找更远的日期");
      }
    },
  },
  mounted() {
    this.getDate();
    this.handleShowDateStyle();
    this.getYearList();
  },
};
</script>
<style scoped>
.calender {
  width: 300px;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
  border: 1px solid #ddd;
  padding: 0;
  padding-top: 10px;
}
.top {
  width: 100%;
  position: relative;
  /* display: flex; */
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.top_date {
  width: 100px;
  text-align: left;
  line-height: 42px;
}
.btn_wrap {
  flex: 1;
  text-align: right;
}
.btn_wrap ul {
  display: flex;
  flex-direction: row-reverse;
}
.btn_wrap ul li {
  padding: 10px 20px;
  border: 1px solid #ddd;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.btn_wrap ul li:hover {
  background: #ddd;
  color: red;
}
.btn_wrap ul li:first-child {
  border-left: none;
}
.btn_wrap ul li:last-child {
  border-right: none;
}
.date_wrap {
  position: relative;
}
.week {
  display: flex;
  flex-direction: row;
  padding: 0;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}
.week li {
  width: 14.28%;
  text-align: center;
}
.day {
  display: flex;
  flex-direction: row;
  padding: 5px;
  font-size: 16px;
  flex-wrap: wrap;
  margin: 0;
}
.day li {
  width: 14.28%;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.day li:nth-child(n + 8) {
  border-top: none;
}
.day li:nth-child(n + 1) {
  border-right: none;
}
.day li:nth-child(7n) {
  border-right: 1px solid #ddd;
}
.now {
  background: #e61e5a;
  color: #fff !important;
}
</style>