<template>
  <div class="bg-faf min-h100">
    <topName ref="topNav" id="topNav" :showMobileTop="false" style="display: none" />
    <div class="page_container hideMobileTop" :class="ref == 'onboarding' ? 'pt-0' : ''">
      <div class="page6-2">
        <div v-if="!isLoading && hasMatches" class="bg_image fw-500 py-3 text-center fs-22 ff-prata text-white mb-4 px-3"
          style="
            min-height: 20vh;
            background-image: url(https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/bg_starter.png);
            background-position: bottom;
          ">
          {{ here_match_for_you }}
        </div>
        <!-- <p
          
          class="fw-600 mb-3 text-center fs-20 ff-prata"
        >
          {{ here_match_for_you }}
        </p> -->

        <div v-if="!showProgress && isLoading" class="center_center text-center" style="height: 140px">
          <div class="x-center">
            <b-spinner variant="secondary" class="ls_loading_spinner"></b-spinner>
            <b-img class="ls_loading_spinner_logo"
              src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/logos/logo6.2@3x.png"></b-img>
          </div>
        </div>

        <div v-if="!isLoading && !hasMatches" class="center_center text-center">
          <!-- <b-img width="80" class="mb-4" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.0/wait_icon_white.png"></b-img>
            <p class="fw-600 text-e5c mb-4">{{$t('we_finding_matches')}}</p>
            <p class="mb-4 text-white fs-14">{{$t('check_later_time')}}</p> -->
          <p class="text-white">{{ $t("no_more_found") }}</p>
        </div>

        <div v-if="!isLoading && hasMatches" class="p-3">
          <div v-for="item in matches" :key="item.client_id" class="position-relative rounded-10 bg-white p-3 mb-4">
            <div class="rounded-10 overflow-hidden">
              <img @click="startMyMembership" class="w-100" :src="item.photo_url" />
            </div>
            <div class="text-left mt-3 text-1d">
              <p class="mb-0 fw-700 fs-18">
                {{ item.first_name }}, {{ item.age }}
              </p>
              <p v-if="item.job_title" class="mb-0 fw-300 fs-14 text-7e">
                <i class="lsicon job_title3 mr-1"></i>{{ item.job_title }}
              </p>
              <p v-if="item.university_name" class="mb-0 fw-300 fs-14 text-7e">
                <i class="lsicon school mr-1"></i>{{ item.university_name }}
              </p>
            </div>
            <!-- :style="item.university_name ? 'bottom: 15px;' : 'bottom: -7px;'" -->
            <div style="position: absolute; right: 20px; bottom: 15px">
              <button @click="startMyMembership" class="btn-1d shadow o-btn lg">
                <!-- <i class="lsicon chat_gold lsicon-80"></i> -->
                <i class="fa-regular fa-comment fs-25"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showProgress && !isLoading" class="text-center w-100" style="position: fixed; bottom: 70px">
      <b-button @click="startMyMembership" variant="ee1" pill size="md" class="px-5 border-0 text-uppercase l-space1"
        style="height: 53px">{{ $t("start_membership") }}</b-button>
    </div>

    <div v-if="showProgress" class="mask-white py-5 px-3 text-center">
      <h4 class="mb-5 fw-700">{{ $t("preparing_matches") }}</h4>
      <div>
        <div class="border border-333 mx-auto mb-3 rounded-10" style="width: 280px; padding: 12px">
          <div class="bg-ls-b fs-14 text-right text-white"
            :style="`height: 26px;line-height: 26px;width: ${almostProgress}%`">
            {{ almostProgress }}%
          </div>
        </div>
        <p class="text-muted">{{ preparingStatusText }}</p>
      </div>
    </div>
  </div>
</template>
<script>
//has_diamond=0 and membership_level_id<=1 and is_verified=0
import topName from "../common/topNav";
export default {
  name: "matches-op",
  components: {
    topName,
  },
  data() {
    return {
      matches: null,
      client: null,
      isLoading: true,
      hasMatches: false,
      here_match_for_you: "",
      almostProgress: 20,
      preparingStatusText: "",
      creating_account: this.$t("creating_account"),
      collecting_match: this.$t("collecting_match"),
      showProgress: false,
      ref: "",
      utmParams: {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.ref = _.get(self.$route, "query.ref", "");
    if (self.ref == "onboarding") {
      self.showProgress = true;
      self.progressPreparing();
    } else {
      setTimeout(function () {
        $("#topNav").show();
      }, 200);
    }
    self.utmParams.utm_source = _.get(self.$route.query, "utm_source");
    self.utmParams.utm_medium = _.get(self.$route.query, "utm_medium");
    self.utmParams.utm_campaign = _.get(self.$route.query, "utm_campaign");
    self.utmParams.utm_term = _.get(self.$route.query, "utm_term");
    self.utmParams.utm_content = _.get(self.$route.query, "utm_content");
    self.$api.getClientMe(self.$store.state).then((rs) => {
      self.client = _.get(rs, "client");
      self.here_match_for_you = self
        .$t("here_match_for_you")
        .replace("[first_name]", self.client.first_name);
      // if(self.client.membership_level_id < 2){
      //     self.$router.push({ name: "matchmaking-callback" });
      // }else
      // if (self.client.membership_has_expired == 1) {
      //   self.$router.push({ name: "renew-payment" });
      // }
      // else if(!self.client.is_verified){
      //     self.$router.push({ name: "profile-unverified" });
      // }
      // else if(self.client.client_status_id == -1){
      //     self.$router.push({ name: "profile-paused" });
      // }
      // else {
        self.getOPMatchList();
      // }
    });
  },
  methods: {
    progressPreparing() {
      let self = this;
      self.almostProgress = 0;
      self.preparingStatusText = self.creating_account;

      var almostInterval = setInterval(function () {
        self.almostProgress += 1;
        if (self.almostProgress >= 50) {
          self.preparingStatusText = self.collecting_match;
        }
        if (self.almostProgress >= 100) {
          clearInterval(almostInterval);
          self.showProgress = false;
        }
      }, 50);
    },
    chatClick(){
      let self = this;
      self.$router.push({ name: "client-chat" });
    },
    startMyMembership() {
      let self = this;
     
      
      if (self.client.membership_has_expired == 1) {
        self.$router.push({ name: "renew-payment" });
      }else{
        // self.$router.push({ name: "client-chat" });
        if (self.client.age_id > 1 && self.client.annual_income_id >= 4) {
          self.$router.push({ name: "upgrade-regular", query: self.utmParams });
        } else {
          self.$router.push({ name: "upgrade-starter", query: self.utmParams });
        }
      }
      
    },
    getOPMatchList() {
      let self = this;
      self.isLoading = true;

      // return false;
      self.$api.getOPMatchList("", self.$store.state).then((rs) => {
        self.isLoading = false;
        self.matches = _.get(rs, "preview_matches");
        if (self.matches && self.matches.length > 0) {
          self.hasMatches = true;
        } else {
          self.hasMatches = false;
        }
      });
    },
  },
};
</script>