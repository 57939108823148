import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export function createStore() {
	
	let config = {
		state: {
			sites: null,
			cities: null,
			curCity: '',
			curLang: '',
			ipCity: '',
			curSite: {},
			translation: {},
			token: '',
			attrs: {}
		},
		actions,
		mutations,
		getters}
	
	
	
	if(typeof window != 'undefined'){
		config.plugins = [createPersistedState()]
	}
	
	return new Vuex.Store(config)
	
}
