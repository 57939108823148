<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <div class="text-center my-3">
        <b-img height="30" :src="platinum_logo" />
      </div>

      <div v-if="!showSchedule">
        <div
          v-if="carousels && carousels.length > 1"
          class="mb-2 w-100"
          id="swiperbox"
        >
          <div class="swiper-container" id="diaSwiper">
            <div class="swiper-wrapper mb-5">
              <div
                class="swiper-slide text-center"
                v-for="(item, key) in carousels"
                :key="`diaswiper-${key}`"
              >
                <div class="d-block mx-auto mb-3 plan_carousel_icon">
                  <!--rounded-circle-->
                  <img :src="item.icon" class="w-100 border-0" />
                </div>
                <p class="font-weight-bold text-dark px-2">{{ item.title }}</p>
                <p class="d-block mt-3 font-weight-light px-3">
                  {{ item.desc }}
                </p>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <!-- <div v-if="navigation" class="swiper-button-prev"></div>
            <div v-if="navigation" class="swiper-button-next"></div>-->
          </div>
        </div>
      </div>

      <div class="text-center">
        <a
          href="javascript:;"
          @click="upgradeClick"
          class="btn btn-lg text-capitalize px-4 mt-2 bg-platinum text-white"
          >{{ $t("get_platinum") }}</a
        >
      </div>

      <div class="text-center mt-2">
        <a
          href="javascript:;"
          @click="closeModel"
          class="btn px-4 font-1rem text-muted"
          >{{ $t("not_now") }}</a
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import helper from "../../utils/helper";
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}

export default {
  name: "platinumPopup",

  data() {
    return {
      show: false,
      platinum_logo:
        "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/lovestruck_platinum.png",
      carousels: [],
      platinumSwiper: null,
      swiperOption: {
        //init:false,
        speed: 500,
        loop: true,
        initialSlide: 0,
        direction: "horizontal",
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "ls-silver-bullet-active",
        },
      },
      showSchedule: false,
      initialSlide: 0,
    };
  },
  mounted() {
    let self = this;
    // let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/";
    // let cdnBase2 = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/";

    self.carousels = helper.platinumCarouselItems(self);
    // self.carousels = [
    //   {
    //     index: 0,
    //     title: self.$t("join_unlimit_date"),
    //     desc: self.$t("join_unlimit_date_desc"),
    //     icon: `${cdnBase2}2021/arranged_dates_black.png`,
    //     circle: 0,
    //   },
    //   {
    //     index: 1,
    //     title: self.$t("dia_perfect_match"),
    //     desc: self.$t("dia_perfect_match_desc"),
    //     icon: `${cdnBase2}2021/perfect_match_filters_2_black.png`,
    //     circle: 0,
    //   },
    //   {
    //     index: 2,
    //     title: self.$t("date_elite_user"),
    //     desc: self.$t("date_elite_user_desc"),
    //     icon: `${cdnBase2}2021/unlimited_elite_invite_black.png`,
    //     circle: 0,
    //   },
    //   {
    //     index: 3,
    //     title: self.$t("date_again"),
    //     desc: self.$t("date_again_desc"),
    //     icon: `${cdnBase2}2021/second_chances_black.png`,
    //     circle: 0,
    //   },
    //   {
    //     index: 4,
    //     title: self.$t("search_boost"),
    //     desc: self.$t("search_boost_desc"),
    //     icon: `${cdnBase}2020/match_boost.png`,
    //     circle: 0,
    //   },
    //   {
    //     index: 5,
    //     title: self.$t("dia_privacy_mode"),
    //     desc: self.$t("dia_privacy_mode_desc"),
    //     icon: `${cdnBase}2020/privacy_mode.png`,
    //     circle: 0,
    //   },
    //   //   {
    //   //     index: 6,
    //   //     title: self.$t("discounted_event_ticket"),
    //   //     desc: self.$t("discounted_event_ticket_desc"),
    //   //     icon: `${cdnBase}2020/join_arranged_dates.png`,
    //   //     circle: 0,
    //   //   },
    // ];
  },
  methods: {
    upgradeClick() {
      let self = this;
      self.$emit("upgradeClick", { act: "platinum_upgrade" });
    },

    startSwiper() {
      let self = this;
      if (VueAwesomeSwiper && self.carousels.length > 1) {
        //!self.lsSwiper &&
        // if (self.lsSwiper) {
        //   self.lsSwiper.destroy();
        // }
        if (self.platinumSwiper) {
          if (self.platinumSwiper[0]) {
            self.platinumSwiper[0].observer.destroy();
          } else {
            self.platinumSwiper.destroy();
          }
        }

        // if (self.navigation) {
        //   self.swiperOption.navigation = {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev"
        //   };
        // }
        // if (self.carouselLen == 1) {
        //   self.swiperOption.autoplay = false;
        //   self.swiperOption.navigation = false;
        // }

        // let page = _.get(self.$route, "query.page", 1);

        self.swiperOption.initialSlide = self.initialSlide;

        setTimeout(function () {
          self.platinumSwiper = new VueAwesomeSwiper.Swiper(
            "#diaSwiper",
            self.swiperOption
          );
        }, 10);
      }
    },
    closeModel() {
      let self = this;
      self.show = false;

      self.initialSlide = 0;
    },
    showModal(initialSlide = 0) {
      let self = this;
      self.show = true;
      self.initialSlide = initialSlide;

      self.startSwiper();
    },
  },
};
</script>