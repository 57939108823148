export const Url = {
  MATCHES: 'https://www.lovestruck.com/[city_url]/match/list',
  DATES: 'https://www.lovestruck.com/member/dates',
  ME: 'https://www.lovestruck.com/[city_url]/profile/myaccount',
  DATETIX: 'https://v1.datetix.com/employee/clients/add/',
  FILESTORAGE: 'https://cdn-cf.lovestruck.com/upload/',

}
export const API_URL = {
  LIVE: 'https://api.lovestruck.com',
  DEV: 'https://api-dev.lovestruck.com'
}
export const ImgURL = {
  ASSISTANT: 'https://cdn-cf.lovestruck.com/singapore/photo/profile_228717_5b6ea2b507444.jpg'
}
export const JobRoles = {
  roles: [3, 4, 7]
}
export const StoreIds = {
  store_ids: [14]
}
export const DefaultValue = {
  employee_city_id: 99999,
  membership_level_id: 4
}
export const PhoneCodes = {
  HK: '+852'
}
export const CallMethods = {
  MANNUALY: 1,
  VOIP: 2
}
export const CallPurpose = {
  SellLoveAcademy: 1,
  SellEvent: 2,
  SellRenewal: 3,
  CustomerService: 4
}
export const ChatOptions = {
  All: 1,
  MyChats: 2,
  Members: 3
}

export const StripePKs = {
  live: 'pk_live_CHqBQtR7Q0EeF87R2YEf5wPh',
  test: 'pk_test_RPt7faAbWHbe0mXrcFITHZLZ',
  testcxh: 'pk_test_CS8VCEDPJ9Q44VTxQknT6mpm00mlFs7SA1'
}
