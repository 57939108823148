<template>
  <div class="min-h100" :class="useBlackBg ? 'bg-faf' : 'bg-faf'">
    <topName ref="topNav" :showMobileTop="false" />
    <div class="page_container">
      <!-- <div v-if="match">
        <div
          v-if="
            match.source_user_intro_status_id >= 2 &&
            match.target_user_intro_status_id < 3
          "
          class="py-3 text-center bg-secondary text-white"
          v-html="
            $t('waiting_for_confirm').replace(
              '[match_first_name]',
              client.first_name
            )
          "
        ></div>

        <div
          v-if="
            match.source_user_intro_status_id >= 3 &&
            match.target_user_intro_status_id >= 3
          "
          class="py-3 text-center bg-confirmed text-white font-weight-normal"
        >
          {{ $t("Date Confirmed") }}
        </div>
      </div> -->
      <div class="page6-2">
        <div class="">
          <div class="d-flex justify-content-between px-3">
            <a href="javascript:;" @click="goback" class="p-2">
              <!-- <i class="lsicon arrow_left_y2"></i> -->
              <i class="fa-regular fa-chevron-left text-ee1 fs-24"></i>
            </a>

            <div>
              <!-- <a href="javascript:;" @click="blockReportUser" class="text-ls-y fw-600 font-1_7rem">
          ...
        </a> -->
            </div>
          </div>
        </div>
        <div v-if="!canView" class="py-5 text-center text-ee1 fw-600">
          {{ $t("permission_limit_view") }}
        </div>

        <div class="px-3">
          <!-- <profileViewCom ref="profileViewCom" :isCard="false" /> -->
          <profileViewCom ref="profileViewCom" :isBlackBg="useBlackBg" />
        </div>
        <div
          v-if="client && pageFrom != 'chat'"
          class="fixed-bottom text-right"
          style="bottom: 65px; z-index: 98"
        >
          <div class="page6-2 px-3">
            <button
              v-if="match && client"
              @click.stop="chatBtnClick"
              class="btn-1d shadow o-btn lg60 position-relative"
              style=""
            >
              <i class="fa-light fa-comment fs-30"></i>
              <span v-if="client.msg_received_count > 0" class="countNum">{{
                client.msg_received_count
              }}</span>
            </button>

            <!-- <button
              v-if="match && match.chat_total > 0"
              @click.stop="chatBtnClick"
              class="shadow o-btn mr-5"
            >
              <i class="lsicon chat_blue2 lsicon-80"></i>
            </button>
            <button v-else @click.stop="chatBtnClick" class="shadow o-btn mr-5">
              <i class="lsicon chat_gold lsicon-80"></i>
            </button> -->
            <!-- <b-button @click.stop="chatBtnClick" class="matchActBtn goldChatBtn" :class="match && match.chat_total>0?'hasChat':''">
                        <i class="fa fa-comment"></i>
                    </b-button> -->
          </div>
        </div>
        <!-- <div v-if="buttons || intro_label_html" class="emptyDiv"></div> -->
        <div
          v-if="verison == 'v1' && clientMe && (buttons || intro_label_html)"
          class="text-center labelButtonRow py-2"
        >
          <!-- clientMe.membership_level_id > 1 &&<p v-if="dateTitle" class="mb-2 font-weight-bold font-1_1rem">
          {{ dateTitle }}
        </p> -->
          <p v-html="intro_label_html" class="mb-2"></p>
          <!-- <div v-if="client && clientMe.membership_level_id > 1 && buttons">

          <b-button
            v-if="buttons.go_chat_red_upgrade"
            @click="goPaymentPage"
            variant="ls-red2"
            pill
            class="px-3 fw-600 text-uppercase py-0 mr-2"
            ><i class="lsicon lsicon-30 message_icon" style="top: 4px"></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("Chat")
            }}</span></b-button
          >
          <b-button
            v-if="buttons.go_chat"
            @click="goToChat"
            variant="ls-red2"
            pill
            class="px-3 fw-600 text-uppercase py-0 mr-2"
            ><i class="lsicon lsicon-30 message_icon" style="top: 4px"></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("Chat")
            }}</span></b-button
          >

          <b-button
            v-if="buttons.go_chat_upgrade"
            @click="goToChatUpgrade"
            pill
            class="px-3 fw-600 text-uppercase py-0 mr-2 bg-gold"
            style="border-color: transparent"
            ><i class="lsicon lsicon-30 message_icon_gold" style="top: 4px"></i>
            <span
              class="ml-2 text-dred"
              style="position: relative; top: -6px"
              >{{ $t("Chat") }}</span
            ></b-button
          >

          <b-button
            v-if="buttons.match_elite_upgrade"
            @click.stop="dateEliteClient(2)"
            pill
            class="px-3 text-uppercase getMatchBoostBtn py-0 mr-2"
          >
            <i
              class="lsicon lsicon-30 message_elite_upgrade fw-600"
              style="top: 4px"
            ></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("Chat")
            }}</span></b-button
          >


          <b-button
            v-if="buttons.match_elite"
            @click.stop="dateEliteClient(2)"
            variant="ls-red2"
            pill
            class="px-3 fw-600 text-uppercase py-0 mr-2"
            ><i
              class="lsicon lsicon-30 message_elite_upgrade fw-600"
              style="top: 3px"
            ></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("Chat")
            }}</span></b-button
          >

          <b-button
            v-if="buttons.date_concierge"
            @click="dateConcierge"
            variant="ls-red2"
            pill
            class="px-3 fw-600 text-uppercase py-0 mr-2"
            ><i class="lsicon lsicon-30 date_concierge" style="top: 3px"></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("date_text")
            }}</span></b-button
          >

          <b-button
            v-if="buttons.date_concierge_upgrade"
            @click.stop="dateEliteClient(5)"
            pill
            class="px-3 text-uppercase getMatchBoostBtn py-0"
          >
            <i
              class="lsicon lsicon-30 date_concierge fw-600"
              style="top: 3px"
            ></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("date_text")
            }}</span></b-button
          >

          <b-button
            v-if="buttons.re_match"
            @click="chatAgainClick"
            variant="ls-red2"
            pill
            class="px-4 fw-600 text-uppercase py-0"
            ><i class="lsicon lsicon-30 rematch_icon_dia" style="top: 4px"></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("rematch_txt")
            }}</span></b-button
          >

          <b-button
            v-if="buttons.re_match_upgrade"
            @click="chatAgainClick"
            pill
            class="px-4 text-uppercase getMatchBoostBtn py-0"
            style="border-color: transparent"
          >
            <i class="lsicon lsicon-30 rematch_icon_dia" style="top: 2px"></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("rematch_txt")
            }}</span></b-button
          >

        
         </div> -->
        </div>
        <!-- <div v-if="verison=='v1' && clientMe && clientMe.membership_level_id <= 1">
        <div class="text-center labelButtonRow py-2">
          <b-button
            @click="goPaymentPage('gold')"
            variant="ls-red2"
            pill
            class="px-4 fw-600 text-uppercase py-0"
            ><i class="lsicon lsicon-30 message_icon" style="top: 4px"></i>
            <span class="ml-2" style="position: relative; top: -6px">{{
              $t("Chat")
            }}</span></b-button
          >
          
        </div>
      </div> -->
      </div>
    </div>

    <!-- <availabilityVenueList
      ref="availabilityVenueList"
      @selectVenue="selectVenueHander"
    />
    <selectVenueDate
      ref="selectVenueDate"
      @sendInvite="sendInviteHander"
      @changeVenueDate="changeVenueDateHander"
      @confirmInvite="confirmInviteHander"
    /> -->

    <!-- <feedbackModal2
      ref="feedbackModal2"
      @afterSendFeedback="afterSendFeedback"
    /> -->
    <!-- <diamondPopup ref="diamondPopup" @scheduleNow="scheduleNowHander" /> -->
    <!-- <startDatePopupCom
      ref="startDatePopupCom"
      @afterSendGreetingMessage="afterSendGreetingMessageHandler"
    /> -->
    <!-- <platinumPopup ref="platinumPopup" @upgradeClick="platinumUpgradeClick" /> -->
    <!-- <scheduleConsultation ref="scheduleConsultation" /> -->
    <!-- <inviteConfirmPopup
      ref="inviteConfirmPopup"
      @modalHide="inviteConfirmPopupHide"
    /> -->
    <!-- <reMatchPopup ref="reMatchPopup" @rematchClick="reMatchClick" /> -->
    <!-- <dateConciergePopup ref="dateConciergePopup" /> -->
    <!-- <membershipUpgradePopup ref="membershipUpgradePopup" /> -->
    <!-- <chatUpgradePopup ref="chatUpgradePopup" @getGold="upgradeChatClick" /> -->
    <!-- <blockReportUserPopupVue
      ref="blockReportUserPopupVue"
      @afterBlock="afterBlockHandle"
    /> -->
  </div>
</template>
<script>
//import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import inviteConfirmPopup from "../common/inviteConfirmPopup";
// import commonHead from "../common/head";
import profileViewCom from "../common/profileViewCom.vue";
// import verifiedTag from "../common/verifiedTag";
// import selectVenueDate from "../common/selectVenueDate.vue";
// import availabilityVenueList from "../common/availabilityVenueList.vue";
// import diamondPopup from "../common/diamondPopup";
// import feedbackModal2 from "../dates/feedbackModal2.vue";
// import startDatePopupCom from "../common/startDatePopupCom.vue";
// import platinumPopup from "../common/platinumPopup.vue";
// import scheduleConsultation from "../common/scheduleConsultation";
// import chatUpgradePopup from "../common/chatUpgradePopup";
import topName from "../common/topNav";
// import reMatchPopup from "../common/reMatchPopup.vue";
// import dateConciergePopup from "../common/dateConciergePopup";
// import membershipUpgradePopup from "../payment/membershipUpgradePopup.vue";
// import blockReportUserPopupVue from "../common/blockReportUserPopup.vue";
import helper from "../../utils/helper";
//import UA from 'ua-device';
export default {
  name: "ViewProfile",
  components: {
    // blockReportUserPopupVue,
    profileViewCom,
    // selectVenueDate,
    // availabilityVenueList,
    // verifiedTag,
    // diamondPopup,
    // feedbackModal2,
    // commonHead, //swiper,swiperSlide
    topName,
    // startDatePopupCom,
    // platinumPopup,
    // scheduleConsultation,
    // inviteConfirmPopup,
    // reMatchPopup,
    // membershipUpgradePopup,
    // chatUpgradePopup,
    // dateConciergePopup,
  },
  data() {
    return {
      verison: "v2",
      useBlackBg: true,
      client: null,
      clientMe: null,
      match: null,
      dateHimHer: "",
      dateHim: "",
      dateHer: "",
      dateHimAgain: "",
      dateHerAgain: "",
      swiperWinStyle: null,
      lsSwiper: null,
      canView: true,
      countdownLeftTimer: null,
      canChat: false,
      chatCloseLeftSec: 0,
      firstLabelText: "",
      labelText: "",
      dateTitle: null,
      labelColor: "",
      intro_label_html: null,
      buttons: {
        skip: 0,
        date: 0,
        date_again: 0,
        confirm: 0,
        change: 0,
        submit_feedback: 0,
        view_feedback: 0,
      },
      currentDateTimeString: "",
      currentDateVenue: "",
      pageTitle: "",
      clientDateCredit: 0,
      clientEventTicket: 0,
      pageFrom: "",
      label_btn: null,
    };
  },
  methods: {
    chatBtnClick() {
      let self = this;
      if(self.clientMe.membership_level_id < 2){
        self.$router.push({ name: "renew-payment" });
        return false;
      }
      self.$router.push({
        name: "client-chat",
        query: {
          clientId: helper.lshash.encode(_.get(this.client, "client_id", 0)),
        },
      });
    },
    // afterBlockHandle() {
    //   //   this.$refs.blockReportUserPopupVue.closeModal();
    //   this.goback();
    // },
    // blockReportUser() {
    //   this.$refs.blockReportUserPopupVue.showModal({
    //     target_id: this.client.en_id,
    //   });
    // },
    goback() {
      this.$router.back();
    },
    // dateConcierge() {
    //   let self = this;

    //   self.$refs.dateConciergePopup.showModal(
    //     self.client,
    //     _.get(self.clientMe, "matchmaker_chat_id")
    //   );
    // },
    // dateEliteClient(pageV) {
    //   let self = this;

    //   //   self.clientMe.membership_level_id = 4;
    //   if (self.clientMe.membership_level_id < 4) {
    //     // self.$router.push({
    //     //   name: "membership-upgrade",
    //     //   query: { type: "diamond", page: pageV },
    //     // });

    //     self.goToDiamondPayment(pageV);
    //     return false;
    //   }
    //   //   else if (self.clientMe.membership_level_id == 4) {
    //   //     self.$refs.diamondPopup.showModal(1, self.clientMe.membership_level_id);
    //   //     return false;
    //   //   }
    //   self.goToChat();

    //   //   self.$refs.startDatePopupCom.showModal(self.client);
    // },
    // chatExtend() {
    //   /**
    //    * 2021.09.23
    //    * 3) When [Extend] is tapped,
    //    * if user has <=0 Extends, show Extends payment page,
    //    * else set chat_end_time=chat_end_time+24hours and send push notification template "chat_extend" to match user
    //    */
    //   let self = this;

    //   if (_.get(self.clientMe, "extends", 0) <= 0) {
    //     console.log("show Extends payment page...");
    //     self.$router.push({
    //       name: "payment-extend",
    //       query: { top_nav: 0 },
    //     });
    //     return false;
    //   }

    //   let introId = _.get(self.match, "client_intro_id", 0);
    //   let pdata = new FormData();

    //   pdata.append("intro_id", introId);
    //   pdata.append("target_client_id", _.get(self.client, "client_id"));
    //   pdata.append("act_type", "chat_extend");

    //   self.$layer.loading();
    //   self.matchAction(introId, pdata, "chat_extend");
    // },
    // lovestruckMatch() {
    //   /**
    //    * 2021.09.23
    //    * 4) When [Lovestruck] is tapped,
    //    * if user have <= Lovestrucks, show Lovestrucks payment page,
    //    * else send client_msg template "lovestruck" to match user
    //    */
    //   let self = this;

    //   if (_.get(self.clientMe, "lovestrucks", 0) <= 0) {
    //     console.log("show Lovestrucks payment page...");
    //     self.$router.push({
    //       name: "payment-lovestruck",
    //       query: { top_nav: 0 },
    //     });
    //     return false;
    //   }

    //   let introId = _.get(self.match, "client_intro_id", 0);
    //   let pdata = new FormData();

    //   pdata.append("intro_id", introId);
    //   pdata.append("target_client_id", _.get(self.client, "client_id"));
    //   pdata.append("act_type", "lovestruck");

    //   self.$layer.loading();
    //   self.matchAction(introId, pdata, "lovestruck");
    // },
    // confirmInviteHander(data) {
    //   let self = this;

    //   let membership_level_id = _.get(self.clientMe, "membership_level_id", 0);
    //   if (!membership_level_id || membership_level_id <= 1) {
    //     self.$router.push({
    //       name: "membership-choose",
    //       query: { type: "gold" },
    //       //   name: "payment-plan",
    //       //   params: { planName: "gold" },
    //       //   query: { top_nav: 0 },
    //     });

    //     return false;
    //   }

    //   let cost = parseInt(_.get(data, "dateCreditCost", 0));

    //   let dateCredits =
    //     _.get(self.clientMe, "date_credits", 0) > 0
    //       ? parseInt(_.get(self.clientMe, "date_credits", 0))
    //       : 0;

    //   //   console.log(
    //   //     "dateCredits=",
    //   //     dateCredits,
    //   //     _.get(self.client, "date_credits", 0),
    //   //     data
    //   //   );

    //   if (dateCredits < cost) {
    //     // self.$layer.msg(self.$t("insufficient_date_credit"));
    //     //jump to buy date credit page
    //     self.$storage.set(
    //       "venue-d-user",
    //       _.get(data, {
    //         client_id: _.get(self.client, "client_id"),
    //         first_name: _.get(self.client, "first_name"),
    //         age: _.get(self.client, "age"),
    //         gender: _.get(self.client, "gender"),
    //         photo_url: _.get(self.client, "photo_url"),
    //       }),
    //       60
    //     );

    //     setTimeout(function () {
    //       self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
    //     }, 800);

    //     return false;
    //   }
    //   self.currentDateTimeString = _.get(data, "dateTimeString");
    //   self.currentDateVenue = _.get(data, "venueName");
    //   // return false;
    //   let introId = _.get(self.match, "client_intro_id", 0);
    //   let pdata = new FormData();
    //   //changeDateVenueInvite
    //   pdata.append("intro_id", introId);
    //   pdata.append("target_client_id", _.get(data, "targetClientId"));
    //   pdata.append("act_type", "doubleConfirm");

    //   self.$layer.loading();
    //   self.matchAction(introId, pdata, "doubleConfirm");
    // },
    // confirmDatePopup() {
    //   let self = this;
    //   self.$bvModal
    //     .msgBoxOk(
    //       self
    //         .$t("date_confirmed_text")
    //         .replace("[match_first_name]", self.client.first_name)
    //         .replace("[first_date_time]", self.currentDateTimeString)
    //         .replace("[date_venue_name]", self.currentDateVenue),
    //       {
    //         title: false,
    //         headerClass: "text-secondary",
    //         bodyClass: "text-secondary",
    //         size: "md",
    //         buttonSize: "sm",
    //         okVariant: "primary",
    //         okTitle: self.$t("Ok"),
    //         //   cancelTitle: self.$t("not_now"),
    //         footerClass: "p-2",
    //         hideHeaderClose: true,
    //         centered: true,
    //       }
    //     )
    //     .then((value) => {
    //       // console.log("value=", value);

    //       self.$router.push({
    //         name: "client-chat",
    //         query: { clientId: helper.lshash.encode(self.client.client_id) },
    //       });
    //     })
    //     .catch((err) => {
    //       // An error occurred
    //       console.log(err);
    //     });
    // },
    // inviteConfirmPopupHide() {
    //   let self = this;
    //   self.$router.push({
    //     name: "client-chat",
    //     query: { clientId: helper.lshash.encode(self.client.client_id) },
    //   });
    // },
    // upgradeChatClick() {
    //   let self = this;
    //   this.gotPayment("gold", 1);
    //   setTimeout(function () {
    //     self.$refs.chatUpgradePopup.closeModal();
    //   }, 1500);
    // },
    // gotPayment(type, pageV) {
    //   this.$refs.membershipUpgradePopup.showModal({
    //     initialSlideVal: pageV,
    //     membershipType: type,
    //     client: this.clientMe,
    //   });
    // },
    goPaymentPage(typeV) {
      //   this.currentIntro = item;
      //   this.gotPayment("diamond", 3);
      //     return false;
      this.$router.push({
        name: "membership-choose",
        query: { type: typeV },
      });
    },
    // goToDiamondPayment(pageV = 1) {
    //   //   this.$router.push({
    //   //     name: "membership-upgrade",
    //   //     query: { type: "diamond", page: pageV },
    //   //     // name: "payment-plan",
    //   //     // params: { planName: "diamond" },
    //   //     // query: { top_nav: 0, page: 9 },
    //   //   });
    //   this.gotPayment("diamond", pageV);
    // },
    // getPlatinumPayment() {
    //   //   this.$router.push({
    //   //     name: "membership-upgrade",
    //   //     // query: { type: "gold" },
    //   //     // name: "payment-plan",
    //   //     // params: { planName: "platinum" },
    //   //     // query: { top_nav: 0, page: 1 },
    //   //   });
    //   this.gotPayment("gold", 1);
    // },
    // getGoldPayment(pageV = 1) {
    //   //   this.$router.push({
    //   //     name: "membership-upgrade",
    //   //     query: { type: "gold", page: pageV },
    //   //     // name: "payment-plan",
    //   //     // params: { planName: "gold" },
    //   //     // query: { top_nav: 0, page: 6 },
    //   //   });
    //   this.gotPayment("gold", pageV);
    // },
    // scheduleNowHander() {
    //   console.log("scheduleNowHander");
    //   this.$refs.diamondPopup.closeModel();
    //   this.$refs.scheduleConsultation.showModal(2);
    // },
    // platinumUpgradeClick() {
    //   //   this.$router.push({
    //   //     name: "membership-upgrade",
    //   //     // query: { type: "gold" },
    //   //     // name: "payment-plan",
    //   //     // params: { planName: "platinum" },
    //   //     // query: { top_nav: 0 },
    //   //   });
    //   this.gotPayment("gold", 1);

    //   return false;
    // },
    // afterSendGreetingMessageHandler(data) {
    //   let self = this;
    //   //   console.log("data", data);

    //   self.$router.push({
    //     name: "client-chat",
    //     query: { clientId: helper.lshash.encode(self.client.client_id) },
    //   });

    // //   self.$refs.startDatePopupCom.closeModel();
    // },
    startDate() {
      //   this.$refs.startDatePopupCom.showModal(this.client);
    },
    reviewChat() {
      this.$router.push({
        name: "client-chat",
        query: { clientId: helper.lshash.encode(this.client.client_id) },
      });
    },
    // goToChatUpgrade() {
    //   let self = this;
    //   //   self.getGoldPayment(1);
    //   self.client.photo_url = self.client.primary_photo;
    //   self.$refs.chatUpgradePopup.showModal(self.client, self.label_btn);

    //   return false;
    // },
    goToChat() {
      let self = this;
      //   self.goToChatUpgrade();
      //   return false;
      //   if (_.get(self.client, "msg_sent_count", 0) == 0) {
      //     self.$refs.startDatePopupCom.showModal(self.client, 0);

      //     return false;
      //   }

      //   if (self.clientMe.membership_level_id <= 2) {
      //     self.getGoldPayment(1);

      //     return false;
      //   }

      self.$router.push({
        name: "client-chat",
        query: { clientId: helper.lshash.encode(self.client.client_id) },
      });
    },
    reMatchClick() {
      let self = this;

      let introId = _.get(self.match, "client_intro_id");
      let pdata = new FormData();
      //   console.log("reMatchClick", introId, _.get(self.client, "client_id", 0));
      //   return false;

      pdata.append("intro_id", introId);
      pdata.append("target_client_id", _.get(self.client, "client_id", 0));
      pdata.append("act_type", "re_match");

      self.$layer.loading();
      self.matchAction(introId, pdata, "re_match");
    },
    // chatAgainClick() {
    //   let self = this;

    //   let membership_level_id = _.get(self.clientMe, "membership_level_id", 0);
    //   if (membership_level_id <= 3) {
    //     // self.$refs.diamondPopup.showModal(6);
    //     self.goToDiamondPayment(3);
    //     // self.getGoldPayment(4);
    //     return false;
    //   }
    //   //   console.log('client', self.client)
    //   self.client.photo_url = self.client.primary_photo;
    //   self.$refs.reMatchPopup.showModal(self.client);

    //   //   let introId = _.get(self.match, "client_intro_id");
    //   //   let pdata = new FormData();

    //   //   pdata.append("intro_id", introId);
    //   //   pdata.append("target_client_id", _.get(self.client, "client_id", 0));
    //   //   pdata.append("act_type", "chat_again");

    //   //   self.$layer.loading();
    //   //   self.matchAction(pdata, "chat_again");
    // },
    afterSendFeedback(intro_id, data) {
      let self = this;
      console.log("afterSendFeedback", intro_id, data);
      //   let index = _.findIndex(self.pastdates, { client_intro_id: intro_id });
      //   let intro = _.get(self.pastdates, index);
      //   if (!intro) {
      //     return false;
      //   }

      //   self.pastdates[index]["source_user_feedback_created_time"] = 1;
      //   self.pastdates[index]["source_user_enjoy_date"] = _.get(
      //     data,
      //     "enjoy_date"
      //   );
      //   self.pastdates[index]["source_user_chemistry_rating"] = _.get(
      //     data,
      //     "chemistry_rating"
      //   );
      //   self.pastdates[index]["source_user_date_again"] = _.get(
      //     data,
      //     "date_again"
      //   );
    },
    sendFeedback() {
      let self = this;

      //   self.match.photo_url = _.get(self.client, "primary_photo");
      //   self.$refs.feedbackModal2.showModal(self.match, "send");
    },
    viewFeedback() {
      let self = this;

      //   self.targetIntro.first_name = _.get(self.targetClient, "first_name");
      //   self.match.photo_url = _.get(self.client, "primary_photo");
      //   self.$refs.feedbackModal2.showModal(self.match, "view");
    },
    cancelDate() {
      let self = this;
      //   console.log("skip date");

      self.$bvModal
        .msgBoxConfirm(self.$t("cancel_date_desc"), {
          // title: self.$t("cancel_date_txt"),
          title: false,
          headerClass: "text-secondary",
          bodyClass: "text-secondary",
          size: "md",
          buttonSize: "sm",
          okVariant: "ls-y",
          cancelVariant: "light",
          okTitle: self.$t("cancel_txt"),
          cancelTitle: self.$t("not_now"),
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          //   console.log("value=", value);
          if (value) {
            let introId = _.get(self.match, "client_intro_id");
            let fdata = new FormData();
            fdata.append("act_type", "cancel");
            fdata.append("intro_id", introId);
            self.$layer.loading();
            self.matchAction(introId, fdata, "");
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    skipDate() {
      let self = this;
      //   console.log("skip date");
      let introId = _.get(self.match, "client_intro_id");
      let fdata = new FormData();
      fdata.append("act_type", "decline");
      fdata.append("intro_id", introId);
      self.$layer.loading();
      self.matchAction(introId, fdata, "");
    },
    // confirmDate() {
    //   this.confirmInvite();
    // },
    // confirmDate() {
    //   let self = this;
    //   let data = {
    //     venue: self.match.venue,
    //     dateTime: self.match.first_date_time,
    //     introId: self.match.client_intro_id,
    //     targetClientId: _.get(self.client, "client_id"),
    //     statusName: "match_confirmed",
    //     target: {
    //       client_id: _.get(self.client, "client_id"),
    //       first_name: _.get(self.client, "first_name"),
    //       photo_url: _.get(self.client, "primary_photo"),
    //     },
    //   };

    //   self.$refs.selectVenueDate.showModal(data);
    // },
    uploadPhotoPopup() {
      let self = this;

      self.$bvModal
        .msgBoxOk(self.$t("require_photo_desc"), {
          title: false,
          headerClass: "text-secondary",
          bodyClass: "text-secondary",
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: self.$t("Ok"),
          //   cancelTitle: self.$t("not_now"),
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          // console.log("value=", value);
          //   self.nextMatchCard();
          if (value) {
            self.$router.push({
              name: "photo-edit",
            });
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    // startDate() {
    //   let self = this;

    //   // check photo photo_verified
    //   if (!_.get(self.clientMe, "photo_verified", 0)) {
    //     //alert
    //     self.uploadPhotoPopup();
    //     return false;
    //   }

    //   let membership_level_id = _.get(self.clientMe, "membership_level_id", 0);
    //   if (membership_level_id <= 2 && self.client.rating_level_id >= 2) {
    //     // self.$refs.platinumPopup.showModal(2);
    //     return false;
    //   } else if (membership_level_id <= 3 && self.client.is_verified == 1) {
    //     self.$refs.diamondPopup.showModal(2);
    //     return false;
    //   }

    //   self.$refs.startDatePopupCom.showModal(this.client);

    //   return false;
    // },
    // openVenueSelectPop() {
    //   //   this.dateInvite(0);
    //   let self = this;
    //   let introId = _.get(self.match, "client_intro_id");

    //   // check photo photo_verified
    //   if (!introId && !_.get(self.clientMe, "photo_verified", 0)) {
    //     //alert
    //     self.uploadPhotoPopup();
    //     return false;
    //   }

    //   let membership_level_id = _.get(self.clientMe, "membership_level_id", 0);
    //   // && self.client.rating_level_id >= 2
    //   if (membership_level_id <= 2) {
    //     // self.$refs.platinumPopup.showModal(2);
    //     return false;
    //   } // && self.client.is_verified == 1
    //   else if (membership_level_id <= 3) {
    //     self.$refs.diamondPopup.showModal(2);
    //     return false;
    //   }

    //   self.$refs.availabilityVenueList.showModal(
    //     introId,
    //     self.client.client_id,
    //     self.client.first_name
    //   );
    // },
    // dateInvite(introId) {
    //   let self = this;

    //   let membership_level_id = _.get(self.clientMe, "membership_level_id", 0);
    //   //   if (membership_level_id <= 2 && self.client.rating_level_id >= 2) {
    //   //     self.$refs.platinumPopup.showModal(2);
    //   //     return false;
    //   //   } else if (membership_level_id <= 3 && self.client.is_verified == 1) {
    //   //     self.$refs.diamondPopup.showModal(2);
    //   //     return false;
    //   //   }

    //   //   self.$refs.startDatePopupCom.showModal(this.client);

    //   //   return false;

    //   if (membership_level_id <= 1) {
    //     // self.$refs.diamondPopup.showModal();
    //     self.$router.push({
    //       name: "membership-choose",
    //       query: { type: "gold" },
    //       //   name: "payment-plan",
    //       //   params: { planName: "gold" },
    //       //   query: { top_nav: 0 },
    //     });

    //     return false;
    //   }
    //   let dateCredits =
    //     _.get(self.clientMe, "date_credits", 0) > 0
    //       ? parseInt(_.get(self.clientMe, "date_credits", 0))
    //       : 0;

    //   if (dateCredits <= 0) {
    //     // self.$layer.msg(self.$t("insufficient_date_credit"));
    //     //jump to buy date credit page
    //     self.$storage.set(
    //       "venue-d-user",
    //       {
    //         client_id: _.get(self.client, "client_id"),
    //         first_name: _.get(self.client, "first_name"),
    //         age: _.get(self.client, "age"),
    //         gender: _.get(self.client, "gender"),
    //         photo_url: _.get(self.client, "primary_photo"),
    //       },
    //       60
    //     );

    //     setTimeout(function () {
    //       self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
    //     }, 800);

    //     return false;
    //   }

    //   //   this.$refs.startDatePopupCom.showModal(this.client);

    //   self.$refs.availabilityVenueList.showModal(
    //     introId,
    //     self.client.client_id,
    //     self.client.first_name
    //   );
    // },
    // selectVenueHander(data) {
    //   let self = this;

    //   //   let intro = self.match;

    //   //   if (!intro) {
    //   //     return false;
    //   //   }
    //   //   console.log("data=", data);

    //   this.$refs.availabilityVenueList.closeModel();
    //   data.statusName = "";
    //   data.target = {
    //     client_id: _.get(self.client, "client_id"),
    //     first_name: _.get(self.client, "first_name"),
    //     photo_url: _.get(self.client, "primary_photo"),
    //   };
    //   this.$refs.selectVenueDate.showModal(data);
    // },
    sendInviteHander(data) {
      let self = this;

      let pdata = new FormData();
      //changeDateVenueInvite
      //   pdata.append("intro_id", _.get(data, "introId"));
      pdata.append("target_client_id", _.get(self.client, "client_id"));
      pdata.append("act_type", _.get(data, "actType", "dateVenueInvite"));
      pdata.append("date_time", _.get(data, "dateTime"));
      pdata.append("venue_id", _.get(data, "venueId"));
      self.$layer.loading();
      self.matchAction(
        _.get(data, "introId"),
        pdata,
        _.get(data, "actType", "dateVenueInvite") ////changeDateVenueInvite
      );
    },
    confirmInvite() {
      let self = this;
      let cost = parseInt(_.get(self.match, "venue.date_credit_cost", 0));

      let dateCredits =
        _.get(self.clientMe, "date_credits", 0) > 0
          ? parseInt(_.get(self.clientMe, "date_credits", 0))
          : 0;

      // console.log(
      //   "dateCredits=",
      //   dateCredits,
      //   _.get(self.client, "date_credits", 0)
      // );

      if (dateCredits == 0 || dateCredits < cost) {
        // self.$layer.msg(self.$t("insufficient_date_credit"));
        //jump to buy date credit page
        self.$storage.set(
          "venue-d-user",
          {
            client_id: _.get(self.client, "client_id"),
            first_name: _.get(self.client, "first_name"),
            age: _.get(self.client, "age"),
            gender: _.get(self.client, "gender"),
            photo_url: _.get(self.client, "primary_photo"),
          },
          60
        );

        setTimeout(function () {
          self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
        }, 800);

        return false;
      }

      // return false;
      let introId = _.get(self.match, "client_intro_id", 0);
      let pdata = new FormData();
      //changeDateVenueInvite
      pdata.append("intro_id", introId);
      pdata.append("act_type", "doubleConfirm");

      self.$layer.loading();
      self.matchAction(introId, pdata, "doubleConfirm");
    },
    // changeVenueDateHander(data) {
    //   this.$refs.availabilityVenueList.showModal(
    //     _.get(data, "introId"),
    //     self.client.client_id,
    //     self.client.first_name
    //   );
    // },
    matchAction(intro_id, fdata, type) {
      let self = this;
      // let index = _.findIndex(self.list, { client_intro_id: intro_id });

      self.$api
        .postDateAction(fdata, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          //   console.log(rs);
          if (rs.success) {
            if (type == "chat_extend") {
              self.$bvModal
                .msgBoxOk("Your chat has been extended by another 24 hours!", {
                  size: "md",
                  buttonSize: "sm",
                  okVariant: "primary",
                  okTitle: self.$t("Ok"),
                  hideHeaderClose: true,
                  centered: true,
                })
                .then((rs) => {
                  location.reload();
                });
            }
            // else if (type == "lovestruck" || type == "chat_again") {
            //   self.$refs.reMatchPopup.hideModal();
            //   self.$router.push({
            //     name: "client-chat",
            //     query: {
            //       clientId: helper.lshash.encode(
            //         _.get(self.client, "client_id")
            //       ),
            //     },
            //   });
            // }
            else if (type == "re_match") {
              self.$router.push({
                name: "match-intro",
              });
            } else {
              location.reload();
            }

            // if (type == "dateVenueInvite" || type == "changeDateVenueInvite") {
            //   // self.$layer.closeAll("loading");
            //   self.$refs.selectVenueDate.closeModel(false);
            //   self.$refs.inviteConfirmPopup.showModal(self.client);
            // } else if (type == "doubleConfirm") {
            //   //todo
            //   self.confirmDatePopup();
            // }
            //   else {
            //     self.$router.push({
            //       name: "client-chat",
            //       query: { clientId: self.client.client_id },
            //     });
            //   }
            //   location.reload();
            // self.match.source_user_intro_status_id = _.get(
            //   rs,
            //   "newUpdates.source_user_intro_status_id"
            // );
            // self.$refs.selectVenueDate.closeModel(false);
          } else {
            // if (_.get(rs, "popup_url", false)) {
            //   self.gotoUpgrade(rs);
            // } else {
            //   self.$layer.msg(_.get(rs, "message", ""));
            // }
          }
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          //   self.$layer.msg(_.get(err, "response.data.error.body"));
          //self.afterAction(intro_id, type);
          self.$bvModal.msgBoxOk(_.get(err, "response.data.error.body"), {
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: self.$t("Ok"),
            hideHeaderClose: true,
            centered: true,
          });
          console.log(err);
        });
    },
    dateSomeone() {
      let self = this;
      self.$router.go(-1);
      //self.$layer.msg('coming soon')
    },

    countdownLeftTime() {
      let self = this;
      self.countdownLeftTimer = setInterval(function () {
        self.secLeft--;
        if (self.secLeft < 0) {
          clearInterval(self.countdownLeftTimer);
        } else {
          let str = self.setLeftSec2(self.secLeft);
          self.labelText = self.firstLabelText.replace(
            "[left_time]",
            str
            // `<span class="text-danger ml-1">${str}</span>`
          );
          //   self.labelText = self.labelText.replace(
          //     /\(.*\)/,
          //     `(<span class="text-danger">${str}</span>)`
          //   );
        }
      }, 1000);
    },
    setLeftSec2(leftSec) {
      let self = this;
      //   console.log("self.secLeft=", self.secLeft);
      if (isNaN(leftSec) && leftSec <= 0) {
        return "";
      }
      let hours = Math.floor(leftSec / 3600);
      let minutes = Math.floor((leftSec - hours * 3600) / 60);
      let sec = leftSec - hours * 3600 - minutes * 60;

      let str = `${hours >= 10 ? hours : "0" + hours}:${
        minutes >= 10 ? minutes : "0" + minutes
      }:${sec >= 10 ? sec : "0" + sec}`;

      return str;
      //   console.log("left sec: ", str);
    },
  },
  //        computed: {
  //            swiper() {
  //                return this.$refs.lsSwiper.swiper
  //            }
  //        },
  mounted() {
    let self = this;
    self.$parent.loadBranchBar();
    let clientId = self.$route.params.clientId;
    let win_wd = $(window).width();
    self.pageFrom = _.get(self.$route, "query.from", "");

    // if (!self.$parent.isLoading) {
    //   self.$parent.showLoading();
    // }
    // self.useBlackBg = false
    // if(self.pageFrom == 'lsm' || self.pageFrom == 'ls6'){
    //     self.useBlackBg = true
    // }
    self.$parent.closeLoading();

    self.$layer.loading();
    // self.dateHim = self.$t("date_him");
    // self.dateHer = self.$t("date_her");
    // self.dateHimAgain = self.$t("date_him_again");
    // self.dateHerAgain = self.$t("date_her_again");

    // console.log("win_wd", win_wd, {
    //   width: `${win_wd - 30}px`,
    //   overflow: "hidden",
    // });
    // if (win_wd < 768) {
    //   self.swiperWinStyle = {
    //     width: `${win_wd - 30}px`,
    //     overflow: "hidden",
    //   };
    //   // $("#swiperbox").css({
    //   //   width: `${win_wd - 30}px`,
    //   //   overflow: "hidden"
    //   // });
    //   $(".swiper-container").css({ height: "360px" });
    //   $(".swiper-container")
    //     .removeClass("swiper-container-ios")
    //     .removeClass("swiper-container-android");
    // }
    //let userAgent = new UA(navigator.userAgent);

    //console.log('swiper',swiper,self.swiper);
    //if (swiper && swiper.destroy) swiper.destroy();
    //console.log()
    setTimeout(function () {
      $("body").removeClass("noy-scroll");
    }, 500);
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.clientMe = _.get(rs, "client");

        //test
        // self.clientMe.membership_level_id = 1;

        self.clientDateCredit =
          _.get(self.clientMe, "date_credits", 0) > 0
            ? parseInt(_.get(self.clientMe, "date_credits", 0))
            : 0;

        self.clientEventTicket =
          _.get(self.clientMe, "event_tickets", 0) > 0
            ? parseInt(_.get(self.clientMe, "event_tickets", 0))
            : 0;

        return self.$api.getClient(
          `id=${clientId}&from=${self.pageFrom}`,
          self.$store.state
        );
      })
      .then((rs) => {
        //console.log(rs)
        self.$layer.closeAll("loading");
        // self.$parent.closeLoading();
        // rs.can_view = false;
        if (!rs.can_view) {
          self.canView = false;
          //   self.$layer.msg("You can't view the profile.");
          return;
        }
        // console.log("rs=", rs);
        if (rs.client) {
          self.client = rs.client;

          self.match = _.get(rs, "match");
          self.label_btn = _.get(rs, "label_btn");
          //   self.$refs.head.setPageTitle(rs.client.first_name);
          //   self.pageTitle = rs.client.first_name;
          //   self.dateHimHer =
          //     rs.client.gender == "m" ? self.$t("date_him") : self.$t("date_her");

          self.canChat = _.get(rs, "label_btn.can_chat");
          self.buttons = _.get(rs, "label_btn.buttons");

          //   self.buttons = {
          //     go_chat: 1,
          //     // go_chat_upgrade: 1,
          //     //   match_elite: 1,
          //     // match_elite_upgrade: 1,
          //     date_concierge: 1,
          //     // date_concierge_upgrade: 1,
          //     //   re_match: 1,
          //     //   re_match_upgrade: 1,
          //   };
          //   self.buttons = null;

          let label_array = _.get(rs, "label_btn.label_array");
          //   console.log("label_array=", label_array);
          if (label_array) {
            let htmlText = "";
            for (let lab of label_array) {
              htmlText += `<span class="${
                lab.bold == "1" ? "fw-700" : ""
              }" style="color:${lab.color}">${lab.text}</span>`;
            }
            htmlText = htmlText.replace("[icon_chat_expired]", ``);
            //<i class="lsicon chat_expired"></i>
            self.intro_label_html = htmlText;
          }

          //   console.log("self.buttons.length=", self.buttons.length);
          // self.targetIntro = _.get(rs, "client_intro");
          //   self.firstLabelText = _.get(rs, "label_btn.label");
          //   self.dateTitle = _.get(rs, "label_btn.date_title");
          //   if (self.firstLabelText.indexOf("[left_time]") == -1) {
          //     self.labelText = _.get(rs, "label_btn.label")
          //       .replace(/\r\n/g, "<hr/>")
          //       .replace(/\n/g, "<hr/>");
          //   }

          //   self.secLeft = parseInt(_.get(rs, "label_btn.time_left", 0));
          //   self.labelColor = _.get(rs, "label_btn.label_color");
          //   if (self.secLeft > 0) {
          //     self.countdownLeftTime();
          //   }

          //   if (VueAwesomeSwiper) {
          //     if (self.lsSwiper) {
          //       self.lsSwiper[0].observer.destroy();
          //     }
          //     setTimeout(function () {
          //       self.lsSwiper = new VueAwesomeSwiper.Swiper(
          //         "#photoSwiper",
          //         self.swiperOption
          //       );
          //     }, 20);
          //   }
          rs.client.match = self.match;
          //   self.$refs.profileViewCom.showView(rs.client);
          self.$refs.profileViewCom.showView(rs.client);
        }
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
        // self.$parent.closeLoading();
        console.log(["client error", err]);
      });
  },
};
</script>
<style scoped>
.labelButtonRow {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  z-index: 9;
}
.emptyDiv {
  height: 87px;
  width: 100%;
}
@media (max-width: 767px) {
  .labelButtonRow {
    /* position: unset; */
    /* bottom: 58px; */
  }
  /* .emptyDiv {
    display: none;
  } */
}
</style>