<template>
  <div>
    <!-- <div class="text-center py-3">
      <b-spinner label="Loading..." variant="light"></b-spinner>
    </div> -->
    <!-- <div class="bg-black">
      <div class="d-flex justify-content-between" id="input-dob">
        <div class="text-center py-3">
          <b-spinner label="Loading..." variant="light"></b-spinner>
        </div>
      </div>
    </div> -->
    <button
                  
                    class="o-btn" @click="btnClick"
                  >
                    <i class="lsicon chat_full_red lsicon-50"></i>
                  </button>
    <!-- <b-form-checkbox
      v-model="checked1"
      size="lg"
      name="check-button"
      switch
    ></b-form-checkbox>

    <button @click="showForm" class="btn btn-primary">show form</button> -->
    <!-- <div class="mx-auto col-lg-5 col-md-7 col-sm-12 mt-5">
      <calender />
      <div class="d-flex justify-content-start text-center">
        <div>
          <div class="plan_item_arrow">
            <img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/arrow_down.png"
              width="20"
            />
          </div>
          <div class="plan_item diamond">
            <p class="plan_item_tag">BEST VALUE</p>
            <p class="plan_item_month">6</p>
            <p class="plan_item_month_name">months</p>
            <p class="plan_item_month_price">$HK 288.11/mo</p>
            <p class="plan_item_save">SAVE 23%</p>
            <div class="plan_item_line"></div>
            <p class="plan_item_price">HK$ <b>9977.99</b></p>
          </div>
          <div class="plan_item_arrow">
            <img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/arrow_up.png"
              width="20"
            />
          </div>
        </div>
        <div class="plan_selected">
          <div class="plan_item_arrow">
            <img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/arrow_down.png"
              width="20"
            />
          </div>
          <div class="plan_item diamond">
            <p class="plan_item_tag">MOST POPULAR</p>
            <p class="plan_item_month">3</p>
            <p class="plan_item_month_name">months</p>
            <p class="plan_item_month_price">$HK 288.11/mo</p>
            <p class="plan_item_save">SAVE 23%</p>
            <div class="plan_item_line"></div>
            <p class="plan_item_price">HK$ <b>177.99</b></p>
          </div>

          <div class="plan_item_arrow">
            <img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/arrow_up.png"
              width="20"
            />
          </div>
        </div>

        <div>
          <div class="plan_item_arrow">
            <img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/arrow_down.png"
              width="20"
            />
          </div>
          <div class="plan_item diamond">
            <p class="plan_item_tag">&nbsp;</p>
            <p class="plan_item_month">1</p>
            <p class="plan_item_month_name">months</p>
            <p class="plan_item_month_price">$HK 288.11/mo</p>
            <p class="plan_item_save">SAVE 23%</p>
            <div class="plan_item_line"></div>
            <p class="plan_item_price">HK$ <b>177.99</b></p>
          </div>

          <div class="plan_item_arrow">
            <img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/arrow_up.png"
              width="20"
            />
          </div>
        </div>
      </div>

      <a
        href="javascript:;"
        class="btn btn-lg btn-lg-pill px-5 text-uppercase bg-gold text-dred"
        >{{ $t("m_continue_txt") }}</a
      >
      <a
        href="javascript:;"
        class="
          btn btn-lg btn-lg-pill
          px-5
          text-uppercase
          bg-platinum
          text-white
        "
        >{{ $t("m_continue_txt") }}</a
      >

      <a
        href="javascript:;"
        class="btn btn-lg btn-lg-pill px-5 text-uppercase bg-diamond text-white"
        >{{ $t("m_continue_txt") }}</a
      >
    </div> -->
    <!-- <onePhotoUpload ref="onePhotoUpload" /> -->
    <!-- <profileCardCom ref="profileCardCom" /> -->
    <!-- Local -->
    <!-- <button @click="callAppjs">原生js相互调用</button> -->
    <!-- <button @click="openX">open</button> -->
    <!-- <button @click="showPicker">show picker</button> -->
    <!-- <div>
      <input type="text" id="date" placeholder="dob input" />
    </div>  -->
    <!-- <VuePicker
      :data="pickData"
      :showToolbar="true"
      @cancel="cancel"
      @confirm="confirm"
      @change="change"
      :defaultIndex="defaultIndex"
      :visible.sync="pickerVisible"
    /> -->
    <!-- <div class="p-3">
      <Eventlist ref="eventlist" /> -->
    <!-- <oneOn1DateList ref="oneOn1DateList" /> -->
    <!-- </div> -->

    <!-- <diamondPopup ref="diamondPopup" /> -->
    <!-- <dateTimeVenueSelect ref="dateTimeVenueSelect" /> -->
    <!-- <availabilityVenueList
      ref="availabilityVenueList"
      @selectVenue="selectVenueHander"
    />
    <selectVenueDate
      ref="selectVenueDate"
      @changeVenueDate="changeVenueDateHander"
    />
    <bookConsultation ref="bookConsultation" /> -->
    <inviteConfirmPopup ref="inviteConfirmPopup" />
    <regForm ref="regForm" />
    <chatUpgradePopup ref="chatUpgradePopup" />
    <membershipUpgradePopup ref="membershipUpgradePopup" />
    <blockReportUserPopupVue ref="blockReportUserPopupVue"/>
  </div>
</template>
<script>
// import Vue from "vue";
import helper from "../../utils/helper";
import diamondPopup from "../common/diamondPopup";
import chatUpgradePopup from "../common/chatUpgradePopup";
import inviteConfirmPopup from "../common/inviteConfirmPopup";
import dateTimeVenueSelect from "../match/selectDateTimeVenue";
import availabilityVenueList from "../common/availabilityVenueList.vue";
import oneOn1DateList from "../common/oneOn1DateList.vue";
import selectVenueDate from "../common/selectVenueDate.vue";
import Eventlist from "../common/eventlist.vue";
import planCarousel from "../payment/planCarousel.vue";
import bookConsultation from "../common/scheduleConsultation";
import profileCardCom from "../common/profileCardCom.vue";
import onePhotoUpload from "../common/onePhotoUpload.vue";
import calender from "../common/calender.vue";
import regForm from "../common/registerForm.vue";
// import "../../utils/ios_datepicker";
// import VuePicker from "vue-pickers";
import datePicker from "../../utils/ios_datepicker.js";
import membershipUpgradePopup from "../payment/membershipUpgradePopup.vue";
import blockReportUserPopupVue from "../common/blockReportUserPopup.vue";
import { accountStatus } from "../../utils/mixin";
export default {
  name: "localVue",
  components: {
      blockReportUserPopupVue,
    membershipUpgradePopup,
    regForm,
    calender,
    dateTimeVenueSelect,
    availabilityVenueList,
    selectVenueDate,
    oneOn1DateList,
    Eventlist,
    diamondPopup,
    planCarousel,
    bookConsultation,
    inviteConfirmPopup,
    profileCardCom,
    chatUpgradePopup,
    // VuePicker,
    onePhotoUpload,
  },
  data() {
    return {
      checked1: false,
      picker: {
        anchor: null,
        textTitle: "",
        textConfirm: "",
        textCancel: "",
        colorTitle: "#000",
        colorConfirm: "#000",
        colorCancel: "#999",
        swipeTime: 1000,
      },
      birthday: "",
      selectedData: "",
      pickerVisible: false,
      pickData: [[], [], []],
      defaultIndex: [10, 10, 10],
      result: "",
      testClient: {
        client_id: 124469,
        language_id: 1,
        city_id: 1,
        is_verified: 1,
        height: 151,
        job_title: "Clinical Research Senior Manager",
        last_active_time: "2021-06-07 13:26:52",
        first_name: "KK",
        last_name: null,
        lovestruck_username: null,
        rating_level_id: 1,
        dob: "1986-11-17",
        membership_level_id: 9,
        membership_level_expiry_date: "2021-06-22 13:02:56",
        identity_card: "Z749144(2)",
        preferred_call_time_slot_ids: null,
        personal_matchmaker_employee_ids: "478",
        child_status_id: 1,
        child_plans_id: 1,
        ethnicity_id: 1,
        nationality_id: 136,
        education_level_id: 2,
        annual_income_id: 5,
        relationship_status_id: 3,
        religion_id: 2,
        drinking_id: 3,
        smoking_id: 1,
        food_habit_id: 1,
        university_name: "UIUC",
        self_intro:
          "Nice meeting you. I am looking for a stable relationship for marriage. I love my job for drug development that can make contributions to the world.\r\nI am a foodie, coffee and wine lover. Love painting, reading and exercising. Recently more into rock climbing and hiking. Let’s meet and chat~",
        last_active_timestamp: 1623043612,
        lang_code: "en_US",
        other_photos: [
          //   "https://cdn-cf2.lovestruck.com/upload-m/crop_1604144946.png",
          "https://cdn-cf2.lovestruck.com/upload-m/chat/2020/10/ae1440ea6b27ef15dfe82ea4a34027c4.png",
        ],
        primary_photo:
          "https://cdn-cf2.lovestruck.com/upload-s/crop_1604144946.png",
        photo_cdn_base: "https://cdn-cf2.lovestruck.com/upload-s/",
        username: "KK",
        name: "KK",
        firstname: "KK",
        surname: null,
        age: 34,
        default_currency_id: 1,
        rating_level_name: "Regular",
        cur_membership_level: {
          membership_level_id: 9,
          description: "Diamond member - VIP",
          color_code: "#5576ba",
          date_credits_per_month: 4,
          language_id: 1,
          view_order: 2,
        },
        cur_membership_level_active: 1,
        cur_membership_level_expiry_date: "2021-06-22",
        identity_card_verified: 1,
        photo_verified: 0,
        profile_verified: 1,
        call_time_slots: "",
        personal_matchmakers: null,
        city_name: "Hong Kong",
        child_status: "No children",
        child_plans: "Would like children",
        ethnicity: "Chinese",
        nationality: "Hong Kong\r",
        education_level: "Bachelor's degree",
        annual_income: "HK$700k to 1.0m",
        relationship_status: "Separated",
        religion: "Atheist (not religious or spiritual)",
        drinking_status: "Socially",
        smoking_status: "Non-smoker",
        food_habit: "Can eat anything",
      },
    };
  },
  mixins: [accountStatus],
  methods: {
      btnClick(){
          this.$refs.blockReportUserPopupVue.showModal({target_id: 1234});
      },
    initPicker() {
      let self = this;
      self.calendar = new datePicker();
      self.calendar.init({
        trigger: "#input-dob",
        type: "onelist",
        minY: 120,
        maxY: 230,
        dvalue: 170,
        langKey: "en",
        okText: "OK",
        cancelText: "Cancel",
        onSubmit: function () {
          /*确认时触发事件*/
          //   self.profile.dob = self.calendar.date_value;
          console.log(
            self.calendar.value,
            self.calendar.date_value
            //   self.profile.dob
          );

          //   let parseRes = helper.parseDate(self.calendar.date_value);
          //   self.ageString = self
          //     .$t("u_are_age")
          //     .replace("[age]", self.currYear - parseRes.yy);
          //   self.ageString = `Your are ${self.currYear - parseRes.yy} years old`;
          //   self.checkSubmitStatus();
          //   console.log("parseRes", parseRes);
          //   calendar.value = `${parseRes.dd} ${calendar.month_name} ${parseRes.yy}`;
          //   self.dob_year = parseRes.yy;
          //   self.dob_month = parseRes.mm;
          //   self.dob_day = parseRes.dd;

          //   self.canSubmit = true;
        },
        onClose: function () {
          /*取消时触发事件*/
        },
      });
    },
    showForm() {
      this.$refs.membershipUpgradePopup.showModal({
        initialSlideVal: 2,
        membershipType: "gold",
        client: {
          client_id: 1084,
          membership_level_id: 2,
        },
      });
      //   this.$refs.chatUpgradePopup.test();
      //   this.$refs.regForm.showModal();
    },
    callAppjs() {
      if (_.has(window, "webkit.messageHandlers.jsHandler")) {
        window.webkit.messageHandlers.jsHandler.postMessage("payment_failed");
      } else if (_.has(window, "Bridge")) {
        window.Bridge.finish("close");
      } else {
        alert("jsHandler and Bridge null");
      }
    },
    handlePickerCancel(res) {
      console.log("handlePickerCancel", res);
    },
    handlePickerConfirm(res) {
      console.log("handlePickerConfirm", res);
    },
    showPicker() {
      this.pickerVisible = true;
      //   this.$ref.picker.show();
      //   VuePicker.default.install(Vue);
    },
    cancel() {
      this.result = "click cancel result: null";
    },
    confirm(res) {
      this.result = JSON.stringify(res);

      console.log("res", res);
    },
    change(res) {
      console.log("change", res);
      let year = _.get(res, "0.value");
      let month = parseInt(_.get(res, "1.value"));
      let days = this.calcDays(year, month);
      console.log("days=", days);
    },
    calcDays(year, month) {
      if (month == 2) {
        if (
          (year % 4 == 0 && year % 100 != 0) ||
          (year % 400 == 0 && year % 4000 != 0)
        ) {
          return 29;
        } else {
          return 28;
        }
      } else {
        if (month == 4 || month == 6 || month == 9 || month == 11) {
          return 30;
        } else {
          return 31;
        }
      }
    },
    openX() {
      // this.$refs.dateTimeVenueSelect.showModal(920760, "accept");
      //this.$refs.oneOn1DateList.showModal(920760);
      // this.$refs.availabilityVenueList.showModal(920760);
      // this.$refs.diamondPopup.showModal();
      // this.$refs.selectVenueDate.showModal(920760);
      //   this.$refs.bookConsultation.showModal(3);
      this.$refs.inviteConfirmPopup.showModal({ first_name: "Text99" });
    },
    selectVenueHander(data) {
      this.$refs.availabilityVenueList.closeModel();
      data.target = {
        client_id: 12323,
        first_name: "XiaoHui",
        photo_url:
          "https://xavatar.imedao.com/community/20199/1570584141885-1570584143982.jpg",
      };
      this.$refs.selectVenueDate.showModal(data);
    },
    changeVenueDateHander(data) {
      this.$refs.availabilityVenueList.showModal(_.get(data, "introId"));
    },
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();
    self.$refs.blockReportUserPopupVue.showModal({target_id: 1234});
    // self.deletedAccountAlert()
    // self.$storage.set("match_filter_updated", 1, 3600);
    // self.$refs.chatUpgradePopup.test();
    // self.initPicker();
    //w224e4a4  1084  
    //w224e4a4
    console.log(helper.lshash.encode(1084))
    console.log(helper.lshash.decode('w224e4a4'))

    // let res = helper.checkMobile2(852, "92913895");
    // console.log("res", res);

    // console.log("VuePicker", VuePicker);
    // self.$refs.profileCardCom.showView(self.testClient);
    // self.$refs.regForm.showModal();
    return false;
    self.pickData[0] = [];
    for (let y = 1930; y <= 2003; y++) {
      self.pickData[0].push({
        label: y,
        value: y,
      });
    }

    for (let m = 1; m <= 12; m++) {
      let jm = m < 10 ? `0${m}` : m;
      self.pickData[1].push({
        label: jm,
        value: jm,
      });
    }
    for (let d = 1; d <= 31; d++) {
      let jd = d < 10 ? `0${d}` : d;
      self.pickData[2].push({
        label: jd,
        value: jd,
      });
    }
    // console.log(self.pickData);
    // let calendar = new datePicker();
    // calendar.init({
    //   trigger: "#date" /*按钮选择器，用于触发弹出插件*/,
    //   type: "date" /*模式：date日期；datetime日期时间；time时间；ym年月；*/,
    //   minDate: "1980-1-1" /*最小日期*/,
    //   maxDate: "2020-12-31" /*最大日期*/,
    //   dvalue: "1996-09-07",
    //   okText: "OK",
    //   cancelText: "Cancel",
    //   onSubmit: function () {
    //     /*确认时触发事件*/
    //     // console.log(calendar.value)
    //     self.selectedData = calendar.value;
    //   },
    //   onClose: function () {
    //     /*取消时触发事件*/
    //   },
    // });
    var da = new Date();
    // setTimeout(function () {
    // console.log("mobiscroll", mobiscroll);
    // $("#dob-date")
    //   .mobiscroll()
    //   .date({
    //     theme: "ios",
    //     lang: "en",
    //     //        lang: 'en',
    //     display: "bottom", // Specify display mode like: display: 'bottom' or omit setting to use default
    //     mode: "datetimeDate", // More info about mode: https://docs.mobiscroll.com/3-0-0_beta2/datetime#!opt-mode
    //     defaultValue: new Date(da.getFullYear() - 35, 0, 1),
    //     //        format: 'yy/mm/dd',
    //     min: new Date(da.getFullYear() - 90, da.getMonth(), da.getDate()),
    //     max: new Date(da.getFullYear() - 16, da.getMonth(), da.getDate()),
    //     buttons: [
    //       {
    //         // More info about buttons: https://docs.mobiscroll.com/3-0-0_beta2/widget#!opt-buttons
    //         text: "OK",
    //         handler: "set",
    //       },
    //       {
    //         text: "Cancel",
    //         handler: "cancel",
    //       },
    //     ],
    //     onSet: function (event, inst) {
    //       // More info about onSet: https://docs.mobiscroll.com/3-0-0_beta2/color#!event-onSet
    //       //            $(this).text(event.valueText).css('background-color', event.valueText);
    //       //            console.log('inst=', inst)
    //       //            var dateText = event.valueText;
    //       var dateArr = inst.getArrayVal();

    //       console.log("eevnt", dateArr);
    //       // if(lang_key.indexOf('zh')>-1){
    //       //     self.dob_day = dateArr[2]
    //       //     self.dob_month = dateArr[1]+1
    //       //     self.dob_year = dateArr[0]
    //       // }else{
    //       //     self.dob_day = dateArr[1]
    //       //     self.dob_month = dateArr[0]+1
    //       //     self.dob_year = dateArr[2]
    //       // }

    //       // self.bulid_dob();
    //     },
    //   });
    // }, 500);
  },
};
</script>
<style scoped>
</style>