<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <div
        class=""
        style="
          position: absolute;
          width: 100px;
          height: 100px;
          top: -50px;
          left: 50%;
          margin-left: -50px;
          overflow: hidden;
          border-radius: 50px;
        "
      >
        <b-img
          src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/lsm-app/blue_check_icon.png"
          class="w-100"
        />
      </div>
      <div class="mb-4 text-center">
        <div style="height: 50px"></div>
        <h5 class="fw-700">{{ $t("match_filters_updated") }}</h5>
        <p class="mb-4" v-html="$t('match_filters_updated_desc1')"></p>
        <p v-html="$t('match_filters_updated_desc2')"></p>
      </div>

     <div class="text-center">
        <b-button
          @click="hideModal"
          variant="ls-gold-g2"
          size="md"
          class="fw-600 text-uppercase px-5"
          >{{ $t("Ok") }}</b-button
        >
      </div>
    </b-modal>
     
  </div>
</template>
<script>
export default {
  name: "match-filter-updated-popup",
  data() {
    return {
      show: false,
    };
  },
  mounted() {},
  methods: {
    showModal() {
      let self = this;

      self.show = true;
    },
    hideModal() {
      this.$storage.remove("match_filter_updated");
      this.show = false;
    },
  },
};
</script>