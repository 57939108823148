<template>
    <div class="bg-ls-eee">
        <topName
      ref="topNav"
      :showMobileTop="false"
    />
    <div class="page_container hideMobileTop">
        <div class="page6-2 px-3 text-center">
        
            <div class="position-relative bg-black">
                <b-img class="w-100" :src="`https://cdn-cf2.lovestruck.com/upload/gen/img/ls_com_black/mobile/top_section_bg_${topImageName}.jpg`"></b-img>
            
                <div class="position-absolute text-white" style="top: 15px;left: 0;width: 100%;">
                    <b-img class="mb-4" center width="200" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.1/LS6.1_logo_black_bg.png"></b-img>
                    <p class="fs-20 mb-4">{{$t('lsm_page_header')}}</p>
                    <p class="fs-20 mb-2 text-e5c">Join our</p><!--join_matchmaking_poll-->
                    <p class="fw-600 fs-20">Exclusive Matchmaking Pool</p>
                </div>
                <div class="position-absolute text-white fs-20 text-left" style="bottom: 25px;left: 0;width: 100%;">
                <div class="mx-auto" style="max-width:300px">
                    <div class="fw-700 mb-2">
                    <b-img width="40" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/benefit_checkmark.png"/>
                Tailored Matching
                </div>
                <div class="fw-700 mb-2">
                    <b-img width="40" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/benefit_checkmark.png"/>
                100% Verified Singles
                </div>
                <div class="fw-700 mb-2">
                    <b-img width="40" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/benefit_checkmark.png"/>
                Dates Arranged for You
                </div>
                <div class="fw-700">
                    <b-img width="40" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/benefit_checkmark.png"/>
                Personal Guidance
                </div>
                </div>
                
                
                </div>
            </div>
            <!-- success stories -->
            <div v-if="successStories" class="py-5 bg-white">
                <h2 class="mb-4 px-3 fw-600 text-36">Success Stories</h2>

                <div v-for="(item, index) in successStories" :key="index" class="successStoryItemV10 mb-4">
            
                    <div>
                        <div class="px-3 position-relative">
                            <span class="position-absolute" style="left: 1rem;top: 10px;"><i class="lsicon story_quote_icon_gold lsicon-36"></i> </span>
                            <div class="px-3 pt-3 position-relative">
                        <p class="fs-18 fw-600 text-left ml-3">{{item.head}}</p>
                                <div class="mb-3 text-center">
                                    <img :src="item.photo_url" width="220"/>
                                </div>
                        <p class="">{{item.desc}}
                            <span class="position-absolute" style="bottom: -12px;"><i class="lsicon story_quote_icon_gold lsicon-36"></i> </span></p>
                            </div>

                        <p class="fw-600 text-right pr-4 text-ls-y4">{{item.names}}</p>
                        </div>
                    </div>
                </div>

            </div>
            <!-- how it works -->
            <div class="py-5">
                <h2 class="text-center mb-5 px-3 fw-600 text-36">{{$t('how_works')}}</h2>
                    <div class="howItWorkItemV10">
                        <div class="howItWorkItemV10-title">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/how_it_works_step1.png"/>
                            Receive a Callback
                        </div>
                        <div class="bg-ls-2f p-3">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/how_it_works_img1.jpg" class="w-100"/>
                            <div class="p-2 text-white text-left">Our team will call you to schedule a consultation with one of our matchmakers.</div>
                        </div>
                    </div>

                    <div class="howItWorkItemV10">
                        <div class="howItWorkItemV10-title">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/how_it_works_step2.png"/>
                            Meet your Matchmaker
                        </div>
                        <div class="bg-ls-2f p-3">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/how_it_works_img2.jpg" class="w-100"/>
                            <div class="p-2 text-white text-left">Meet with a dating consultant to get verified, learn more about your dating goals and start your membership.</div>
                        </div>
                    </div>

                    <div class="howItWorkItemV10">
                        <div class="howItWorkItemV10-title">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/how_it_works_step3.png"/>
                            Receive Quality Matches
                        </div>
                        <div class="bg-ls-2f p-3">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/how_it_works_img3.jpg" class="w-100"/>
                            <div class="p-2 text-white text-left">Get introduced to high quality singles that fit with your lifestyle, interests and values.</div>
                        </div>
                    </div>

                    <div class="howItWorkItemV10">
                        <div class="howItWorkItemV10-title">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/how_it_works_step4.png"/>
                            Attend Arranged Dates
                        </div>
                        <div class="bg-ls-2f p-3">
                            <img src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/how_it_works_img4.jpg" class="w-100"/>
                            <div class="p-2 text-white text-left">Go on dates arranged for you with your favourite matches. Meet and get to know them.</div>
                        </div>
                    </div>
            </div>
            <!-- meet our matchmaker -->
            <div class="py-5 bg-white px-3">
                <h2 class="mb-3 px-3 fw-600 text-36">{{$t('meet_our_makers')}}</h2>
                <p class="mb-5 text-6e">{{$t('frontpage_section6_header2')}}</p>

                <div v-if="matchmakers" class="row">
                    <div v-for="(item, index) in matchmakers" :key="index" class="col-md-6 ls-col-sm-6 mb-4">
                        <div class="px-3">
                            <b-img class="w-100" :src="item.photo_url"></b-img>
                            <div class="mt-3">
                                <p class="text-ls-y4 fs-18 fw-600 mb-2">{{item.name}}</p>
                                <p class="text-6e">{{item.job_title}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 col-sm-6 mb-4">
                        <div class="px-3">
                            <b-img class="w-100" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/matchmaker_frances.jpg"></b-img>
                            <div class="mt-3">
                                <p class="text-ls-y4 fs-18 fw-600 mb-2">Frances</p>
                                <p class="text-6e">VIP Matchmaker</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 mb-4">
                        <div class="px-3">
                            <b-img class="w-100" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/matchmaker_sharlene.jpg"></b-img>
                            <div class="mt-3">
                                <p class="text-ls-y4 fs-18 fw-600 mb-2">Sharlene</p>
                                <p class="text-6e">Dating Consultant</p>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6 col-sm-6 mb-4">
                        <div class="px-3">
                            <b-img class="w-100" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls.com/hongkong/matchmaker_evian.jpg"></b-img>
                            <div class="mt-3">
                                <p class="text-ls-y4 fs-18 fw-600 mb-2">Evian</p>
                                <p class="text-6e">Matchmaker</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div style="height:50px;width:100%"></div>
            
        </div>
        <div
          
          class="text-center w-100"
          style="position: fixed; bottom: 55px"
        >
          <b-button
            @click="showCallPopup"
            variant="ls-gold-g2"
            pill
            size="lg"
            class="px-5 border-0 fw-600"
            >Join now</b-button
          >
        </div>


        
        </div>
        <diaCallbackPopup ref="diaCallbackPopup" @afterPost="afterCallbackPost"/>
    </div>
</template>
<script>
import topName from "../common/topNav";
import diaCallbackPopup from "../common/diaCallbackPopup";
export default {
    name: "matchmaking-index",
    components:{
        topName,
        diaCallbackPopup
    },
    data(){
        return {
            topImageName:'hk',
            cdnDir: 'hongkong',
            curSite: this.$store.getters.curSite,
            showCallbackPopup:false,
            successStories: null,
            matchmakers: null,
            top_image_url: null,
            client: null
        }
    },
    mounted(){
        let self = this

        // console.log(self.curSite)
        if(self.curSite.iso2 == 'SG'){
            self.topImageName = 'sg'
        }else if(self.curSite.iso2 == 'TH'){
            self.topImageName = 'bkk'
            self.cdnDir = 'bangkok';
        }

        self.$api.getClientMe(self.$store.state)
        .then(rs=>{
            self.client = _.get(rs, 'client')
            if(self.client.membership_level_id >= 2){
                self.$router.push({ name: "matchmaking-new-matches" });
            }else if(self.client.city.has_diamond == 1 && self.client.membership_level_id<=1 ){//
                self.$router.push({ name: "matchmaking-callback" });
            }else if(self.client.dia_call_back == 1){
                self.$router.push({ name: "matchmaking-callback" });
            }else{
                return self.$api.getPageData('', self.$store.state);
            }
        }).then(rs=>{
            // console.log('getSuccessStories', rs)
            self.successStories = _.get(rs, 'successStories')
            self.matchmakers = _.get(rs, 'matchmakers')
            self.top_image_url = _.get(rs, 'top_image_url')
        })

        
    },
    methods: {
        afterCallbackPost(){
            let self = this;
            self.$router.push({ name: "matchmaking-callback" });
        },
        
        showCallPopup(){
            this.$refs.diaCallbackPopup.showModal();
        },
        
    }
}
</script>