<template>
  <div class="min-h100 bg-faf text-1d">
    <pageTop
      ref="pageTop"
      classNames="page6-2"
    ></pageTop>
    <div v-if="content" class="page6-2 pb-5 px-3">

    <!-- <h5 class="d-block text-center py-4 text-white">{{title}}</h5> -->

    <div class="container py-5 text-white-" v-html="content"></div>
</div>
  </div>
</template>

<script>
import pageTop from "../common/pageTop";
export default {
  name: "singlePage",
  components: {
    pageTop
  },
  data() {
    return {
      title: "",
      content: "",
      curSite: this.$store.getters.curSite,
      curLang: this.$store.getters.curLang,
      // curCity:localStorage.getItem('cur-city'),
      // curSite:JSON.parse(localStorage.getItem('cur-site')),
      // curLang:localStorage.getItem('cur-lang'),
    };
  },
  mounted() {
    let self = this;

    console.log(self.curLang)
    let page_name = '';

    if (self.$route.name == "terms") {
      page_name = 'terms_html_content';
      self.title = self.$t("terms_service_text");
    } else if (self.$route.name.indexOf("privacy") > -1) {
        page_name = 'privacy_html_content';
      self.title = self.$t("privacy_policy_text");
    }else if (self.$route.name == 'cookies') {
        page_name = 'cookie_html_content';
      self.title = self.$t("cookie_privacy_text");
    }

    self.$refs.pageTop.setPageTitle(self.title);
    // self.$layer.loading();
    if (!self.$parent.isLoading) {
      self.$parent.showLoading();
    }

    let langMaps = {
        'en_US': 1,
        'zh_HK': 3,
        'zh_CN': 2,
        'th': 4,
    }
    let curLangId = _.get(langMaps, self.curLang, 1)

    self.$api.getPhraseInfo(`name=${page_name}`, self.$store.state)
    .then(rs=>{
        console.log(_.get(rs, 'phrase.translations', []), curLangId)
        for(let ph of _.get(rs, 'phrase.translations', [])){
            if(ph.language_id == curLangId){
                self.content = ph.translation;

                self.content = self.content.replace(
                    /`${self.curSite.url_long}@lovestruck.com`/g,
                    "payment@lovegroup.co"
                );
            }
        }
    })

    // self.$api
    //   .getPageContent(page_id, self.$store.state)
    //   .then(rs => {
    //     if (page_id == 5) {
    //       rs = rs.replace(
    //         /`${self.curSite.url_long}@lovestruck.com`/g,
    //         "payment@lovegroup.co"
    //       );
    //     }

    //     self.content = rs;
    //     // self.$layer.closeAll('loading');
    //     self.$parent.closeLoading();
    //   })
    //   .catch(err => {
    //     // self.$layer.closeAll('loading');
    //     self.$parent.closeLoading();
    //     console.log(["page error", err]);
    //   });
  }
};
</script>

<style>
</style>
