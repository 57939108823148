<template>
  <div>
    <b-modal
      v-model="show"
      hide-footer
      :hide-header="hideHeaderValue"
      centered
      no-close-on-esc
      :title="titleText"
      header-class="text-center"
      no-close-on-backdrop
    >
      <div v-if="step == 1">
        <!-- <p class="my-3 text-center">{{ $t("pls_schedule_matchmaker") }}</p> -->
        <p class="my-3 text-center">{{ $t("schedule_phone_consultation") }}</p>

        <p class="mb-2 text-center">{{ $t("meeting_dolist") }}</p>
        <div class="mx-auto mt-3" style="max-width: 240px">
          <p class="mt-2">
            <i class="fa fa-check-circle text-diamond mr-2"></i>
            {{ $t("meeting_dolist_item1") }}
          </p>
          <p class="mt-2">
            <i class="fa fa-check-circle text-diamond mr-2"></i>
            {{ $t("meeting_dolist_item3") }}
          </p>
          <p class="mt-2">
            <i class="fa fa-check-circle text-diamond mr-2"></i>
            {{ $t("meeting_dolist_item2") }}
          </p>
        </div>

        <div class="text-center mt-4">
          <a
            href="javascript:;"
            @click="scheduleNow"
            class="btn btn-ls-g text-capitalize px-4 mt-2"
            >{{ $t("schedule_now") }}</a
          >
        </div>

        <div class="text-center mt-3">
          <a
            href="javascript:;"
            @click="closeModel"
            class="btn px-4 font-1rem text-muted"
            >{{ $t("not_now") }}</a
          >
        </div>
      </div>

      <div v-if="step == 1.1">
        <div class="text-center my-3">
          <b-img height="30" :src="dia_logo" />

          <div class="py-5">
            {{ $t("schedule_phone_daimond") }}
          </div>

          <div class="text-center mt-4">
            <a
              href="javascript:;"
              @click="scheduleNow"
              class="btn btn-ls-g text-capitalize px-4 mt-2"
              >{{ $t("schedule_now") }}</a
            >
          </div>

          <div class="text-center mt-3">
            <a
              href="javascript:;"
              @click="closeModel"
              class="btn px-4 font-1rem text-muted"
              >{{ $t("not_now") }}</a
            >
          </div>
        </div>
      </div>
      <div v-if="step == 2" class="text-center">
        <!-- <p v-if="verifyAccount">{{ $t("verify_u_ccount_desc") }}</p> -->
        <!-- <div class="mt-3 font-weight-bold">{{ $t("book_meet_date_q") }}</div> -->
        <b-img
          src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/phone_icon.png"
          center
          width="50"
          class="mb-3"
        />
        <p class="mb-3">
          {{ $t("diamond_phone_call") }}
        </p>
        <!-- <p class="text-muted">
          {{ $t("pls_select_time_call") }}
        </p> -->
        <div class="mt-4 mb-4">
          <p class="font-weight-bold text-left">{{ selectMonth }}</p>

          <div
            v-if="days"
            class="my-3 position-relative mx-auto"
            style="width: 80%"
          >
            <div class="swiper-container" id="daySwiper">
              <div class="swiper-wrapper">
                <div v-for="d in days" :key="d.full" class="swiper-slide">
                  <button
                    :class="bookDate == d.full ? 'btn-ls-blue2' : 'btn-light'"
                    @click="selectDay(d.full)"
                    class="btn"
                  >
                    {{ d.w }}
                    <br />
                    {{ d.d }}
                  </button>
                </div>
              </div>
            </div>
            <div class="swiper-button-prev day-prev"></div>
            <div class="swiper-button-next day-next"></div>
          </div>
        </div>

        <!-- <div class="mt-5 font-weight-bold">{{ $t("book_meet_time_q") }}</div> -->
        <!-- <b-select v-model="bookTime" class="mt-2" style="width:120px">
          <option v-for="t in times" :key="t" :value="t">{{t}}</option>
        </b-select>-->
        <div v-if="times" class="my-3">
          <!-- <div class="swiper-container" id="timeSwiper">
          <div class="swiper-wrapper">-->
          <div>
            <button
              v-for="t in times"
              :key="t"
              :class="bookTime == t ? 'btn-ls-blue2' : 'btn-light'"
              @click="selectTime(t)"
              class="btn px-2 mr-1 mb-1"
            >
              {{ t }}
            </button>
            <!-- </div>
            </div>-->
            <!-- <div class="swiper-button-prev time-prev"></div>
            <div class="swiper-button-next time-next"></div>-->
          </div>
        </div>

        <!-- <div class="mt-5 font-weight-bold">{{ $t("our_address") }}:</div>
        <p v-if="curStore" class="my-3 text-secondary">
          {{ curStore.address }}
        </p>

        <div v-if="curStore" class="text-center my-3">
          <a
            :href="curStore.map_url"
            @click="goToMapUrl(curStore.map_url)"
            target="_blank"
            class="btn btn-light text-capitalize px-4 mt-2"
          >
            <b-img height="30" :src="mapIconUrl" center />
          </a>
        </div> -->

        <div class="text-center mt-4 pb-4">
          <!-- <a
            href="javascript:;"
            @click="confirmBook"
            class="btn bg-dia text-white text-capitalize px-4 mt-2"
            >{{ $t("schedule_now") }}</a
          > -->
          <b-button
            @click="confirmBook"
            pill
            class="bg-dia text-white text-uppercase px-5 mt-2 fw-600"
            >{{ $t("Book consultation") }}</b-button
          >
        </div>
        <!-- <div class="text-center mt-2">
          <a
            href="javascript:;"
            @click="closeModel"
            class="btn px-4 font-1rem text-muted"
            >{{ $t("not_now") }}</a
          >
        </div> -->
      </div>
      <div v-if="step == 3" class="text-center">
        <div class="my-3">
          <i class="fas fa-check text-ls-g font-2rem"></i>
        </div>
        <div class="fw-700 mb-4">{{ $t("consultation_confirmed") }}</div>
        <div class="mb-4">
          <b-img center height="32" :src="dia_logo" />
        </div>
        <p class="mb-4">
          {{ $t("booked_consultation_for") }}
          <b class="text-ls-g">{{ book_time_str }}</b>
        </p>
        <p class="mb-4">
          {{ $t("team_contact_phone") }}
          <b class="text-ls-g">{{ client_phone_str }}</b>
        </p>
        <!-- {{ $t("appointment_success_msg") }} -->
        <p class="text-muted" v-html="$t('appointment_success_msg')"></p>

        <!-- <div
          class="rounded-circle border border-ls-y border-size-2 mx-auto"
          style="width: 100px; height: 100px"
        >
          <b-img
            width="50"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2020/calender_icon_large.png"
            center
            class="mt-3"
          />
        </div>

        <p class="text-black-50 font-weight-bold mt-5">
          {{ $t("consultation_appointment") }}
        </p>
        <p class="mt-2 mb-3">{{ bookDateFormat }}</p> -->

        <!-- <div class="text-black-50 mt-5 font-weight-bold">
          {{ $t("our_address") }}:
        </div>
        <p v-if="curStore" class="mt-2 mb-1 text-secondary">
          {{ curStore.address }}
        </p> -->

        <!-- <div v-if="curStore" class="text-center my-3">
          <a
            :href="curStore.map_url"
            @click="goToMapUrl(curStore.map_url)"
            target="_blank"
            class="btn btn-light text-capitalize px-4 mt-2"
          >
            <b-img height="30" :src="mapIconUrl" center />
          </a>
        </div> -->

        <div class="text-center mt-5 pb-5">
          <b-button
            @click="closeModel"
            pill
            variant="secondary"
            class="text-uppercase px-5 fw-600"
            >{{ $t("close_txt") }}</b-button
          >
          <!-- <a
            href="javascript:;"
            @click="closeModel"
            class="btn btn-secondary text-capitalize px-4 mt-2"
            >{{ $t("close_txt") }}</a
          > -->
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}
export default {
  name: "scheduleConsultation",
  //   props: {
  //     verifyAccount: {
  //       type: Boolean,
  //       default: false,
  //     },
  //   },
  data() {
    return {
      step: 1,
      verifyAccount: false,
      show: false,
      titleText: "",
      days: null,
      selectMonth: "",
      bookDate: "",
      bookTime: "11:00",
      book_time_str: "",
      client_phone_str: "",
      daySwiper: null,
      timeSwiper: null,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 3,
        freeMode: true,
      },
      times: [
        "10:00",
        //        "10:30",
        "11:00",
        //         "11:30",
        "12:00",
        //         "12:30",
        "13:00",
        //         "13:30",
        "14:00",
        //         "14:30",
        "15:00",
        //         "15:30",
        "16:00",
        //         "16:30",
        "17:00",
        //         "17:30",
        "18:00",
        //         "18:30",
        "19:00",
        //        "19:30",
        "20:00",
      ],
      client: null,
      booking: null,
      curStore: null,
      hideHeaderValue: true,
      bookDateFormat: "",
      cdnImgBaseUrl: "https://cdn-cf2.lovestruck.com/upload-s/gen/img/2020/",
      mapIconUrl: "",
      dia_logo:
        "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/level_diamond_text.png",
    };
  },
  methods: {
    goToMapUrl(url) {
      //   window.top.location.href = url;
      window.open(url);
    },
    getClient() {
      let self = this;
      self.$layer.loading();

      let medata = self.$storage.get("medata");
      //   console.log("medata", medata);
      if (medata) {
        self.client = _.get(medata, "client");
        self.booking = _.get(medata, "booking");
        self.curStore = _.get(medata, "store");
        self.initData();
        self.$layer.closeAll("loading");
        return;
      }

      self.$api
        .getClientMe(self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");

          self.$storage.set("medata", rs);

          self.client = _.get(rs, "client");
          self.booking = _.get(rs, "booking");
          self.curStore = _.get(rs, "store");
          self.initData();
        })
        .catch((err) => {
          self.$layer.closeAll("loading");

          // console.log(['me error', err]);
        });
    },
    initBooking() {
      let self = this;
      let consultation_time = _.get(self.booking, "consultation_time");
      // console.log("initBooking");
      if (consultation_time) {
        let d = self.$moment(consultation_time);
        self.bookDate = d.format("YYYY-MM-DD");
        self.bookTime = d.format("HH:mm");
      }
    },
    confirmBook() {
      let self = this;

      if (!self.bookDate || !self.bookTime) {
        self.$layer.msg(self.$t("invalid_date"));
        return false;
      }

      let dateString = `${self.bookDate} ${self.bookTime}`;
      let md = self.$moment(dateString);
      if (!md.isValid()) {
        self.$layer.msg(self.$t("invalid_date"));
        return false;
      }
      //consultation_time
      let fdata = new FormData();
      fdata.append("consultation_time", md.format("YYYY-MM-DD HH:mm:ss"));
      self.$layer.loading();
      self.$api
        .postBookConsultation(fdata, self.$store.state)
        .then((rs) => {
          // console.log("postBookConsultation", rs);
          self.$layer.closeAll("loading");
          // self.bookResContent = _.get(rs, "book_consultation_res").replace(
          //   /\n/g,
          //   "<br/><br/>"
          // );
          self.client_phone_str = _.get(rs, "your_phone");
          self.book_time_str = _.get(rs, "book_time");
          self.bookDateFormat = md.format("DD MMMM, HH:mm");

          self.hideHeaderValue = true;
          self.titleText = null;
          self.mapIconUrl = _.get(
            self.curStore,
            "map_icon_url",
            `${self.cdnImgBaseUrl}google_maps_icon.png`
          );
          self.step = 3;
          self.$storage.remove("userInfoData");
          // self.showClose = true;
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    initDaySwiper() {
      let self = this;
      if (VueAwesomeSwiper) {
        // if (self.daySwiper) {
        //   self.daySwiper[0].observer.destroy();
        // }
        setTimeout(function () {
          self.swiperOption.navigation = {
            nextEl: ".day-next",
            prevEl: ".day-prev",
          };
          self.daySwiper = new VueAwesomeSwiper.Swiper(
            "#daySwiper",
            self.swiperOption
          );

          //   self.swiperOption.navigation = {
          //     nextEl: ".time-next",
          //     prevEl: ".time-prev",
          //   };
          //   self.timeSwiper = new VueAwesomeSwiper.Swiper(
          //     "#timeSwiper",
          //     self.swiperOption
          //   );
        }, 10);
      }
    },
    selectTime(time) {
      let self = this;
      self.bookTime = time;
    },
    selectDay(date) {
      let self = this;
      self.bookDate = date;
      let i = _.findIndex(self.days, { full: date });
      self.selectMonth = _.get(self.days, `${i}.m`);
    },
    createDays() {
      let self = this;
      // console.log("createDays", self.lang);
      self.$moment.locale(self.lang);
      // self.$moment.locale("th");
      // self.$moment.locale("zh_CN");
      // self.$moment.locale("zh_HK");
      let now = self.$moment();
      let days = [
        // {
        //   m: now.format("MMMM"),
        //   w: now.format("ddd"),
        //   d: now.format("DD"),
        //   full: now.format("YYYY-MM-DD")
        // }
      ];
      // console.log(now.format("YYYY-MM-DD"));

      for (let d = 1; d <= 12; d++) {
        let newd = now.add(1, "d");
        days.push({
          m: newd.format("MMMM"),
          w: newd.format("ddd"),
          d: newd.format("DD"),
          full: newd.format("YYYY-MM-DD"),
        });
      }

      // console.log("days", days);
      self.days = days;
      self.selectMonth = _.get(self.days, `0.m`);
      self.bookDate = _.get(self.days, `0.full`);
      self.initDaySwiper();
    },
    scheduleNow() {
      let self = this;

      self.hideHeaderValue = false;
      self.step = 2;
      self.titleText = self.verifyAccount
        ? // ? self.$t("schedule_phone_consultation")
          self.$t("verify_u_ccount")
        : self.$t("schedule_consultation_txt");

      self.initBooking();
      self.createDays();
    },
    closeModel() {
      let self = this;
      self.show = false;
      self.$emit("closePopup");
    },
    showModal(step) {
      let self = this;
      self.show = true;
      self.step = step;
      //self.startSwiper();
      this.getClient();
    },
    showVerifyAccountModal(step) {
      this.verifyAccount = true;
      this.showModal(step);
    },
    initData() {
      let self = this;

      // console.log(self.$moment.locales());

      if (self.step == 1) {
        self.hideHeaderValue = false;
        self.titleText = self.$t("verify_u_ccount");
        // self.titleText = self.$t("u_account_inactive");
      } else if (self.step == 2) {
        self.hideHeaderValue = false;
        // self.titleText = "Schedule your Consultation";
        self.titleText = self.$t("phone_consultation");
        // self.titleText = self.$t("schedule_consultation_txt");
        // self.titleText = self.verifyAccount
        //   ? //   ? self.$t("schedule_phone_consultation")
        //     self.$t("verify_u_ccount")
        //   : self.$t("schedule_consultation_txt");
        self.initBooking();
        self.createDays();
      } else if (self.step == 3) {
        self.hideHeaderValue = true;
        self.titleText = null;

        // let md = self.$moment("2020-09-22 15:30:11");
        // self.bookDateFormat = md.format("DD MMMM, HH:mm");

        //baidu_map_icon,google_maps_icon
      }

      self.mapIconUrl = _.get(
        self.curStore,
        "map_icon_url",
        `${self.cdnImgBaseUrl}google_maps_icon.png`
      );
    },
  },
};
</script>
<style scoped>
.swiper-button-next,
.swiper-button-prev {
  background-image: none;
  color: #d5b26f;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
}
.swiper-button-prev {
  left: -35px;
  top: 42px;
}
.swiper-button-next {
  right: -35px;
  top: 42px;
}
</style>