<template>
  <div class="text-center">
    <pre>
          Android: window.Bridge.postMessage('payment_success')
          iOS: window.webkit.messageHandlers.jsHandler.postMessage('payment_success');
      </pre
    >
    <button @click="payHander('payment_success')" class="btn btn-primary">
      支付成功
    </button>
    <hr />
    <pre>
        Android: window.Bridge.postMessage('payment_failed')
        iOS: window.webkit.messageHandlers.jsHandler.postMessage('payment_failed');
    </pre>
    <button @click="payHander('payment_failed')" class="btn btn-danger">
      支付失败
    </button>
  </div>
</template>
<script>
export default {
  name: "js-handler",
  data() {
    return {};
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();
  },
  methods: {
    payHander(txt) {
      try {
        if (window.Bridge) {
          window.Bridge.postMessage(txt);
        } else if (window.webkit.messageHandlers) {
          window.webkit.messageHandlers.jsHandler.postMessage(txt);
        } else {
          alert("no Bridge or webkit.messageHandlers");
        }
      } catch (err) {
        alert("no jsHandler");
      }
    },
  },
};
</script>