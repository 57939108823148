<template>
  <div>
    <pageTop ref="pageTop"></pageTop>

    <div
      class="
        mx-auto
        col-lg-6 col-md-8 col-sm-10
        mt-4
        rounded
        bg-white
        py-4
        text-center
      "
    >
      <img
        src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/failed.svg"
        style="width: 100px; border: none"
      />
      <p class="text-muted mt-3">{{ $t("pay_failed") }}</p>

      <router-link
        :to="{ name: 'payment-methods' }"
        class="btn btn-outline-primary mt-3"
        >{{ $t("button_back_generic") }}</router-link
      >
    </div>
  </div>
</template>

<script>
import pageTop from "../common/pageTop";
export default {
  name: "payFailed",
  components: {
    pageTop,
  },
  mounted() {
    this.$refs.head.setPageTitle(this.$t("pay_failed"));
  },
};
</script>

<style></style>
