import axios from 'axios';


export default function setup() {
    axios.interceptors.request.use(function (config) {
        if (typeof localStorage == 'undefined') {
            return config;
        }
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });
}