<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <div class="text-center my-3">
        <b-img height="30" :src="dia_logo" />
      </div>

      <div v-if="!showSchedule">
        <div
          v-if="carousels && carousels.length > 1"
          class="mb-2 w-100"
          id="swiperbox"
        >
          <div class="swiper-container" id="diaSwiper">
            <div class="swiper-wrapper mb-5">
              <div
                class="swiper-slide text-center"
                v-for="(item, key) in carousels"
                :key="`diaswiper-${key}`"
              >
                <div class="d-block mx-auto mb-3 plan_carousel_icon">
                  <!--rounded-circle-->
                  <img :src="item.icon" class="w-100 border-0" />
                </div>
                <p class="font-weight-bold text-dark px-2">{{ item.title }}</p>
                <p class="d-block mt-3 font-weight-light px-3">
                  {{ item.desc }}
                </p>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <!-- <div v-if="navigation" class="swiper-button-prev"></div>
            <div v-if="navigation" class="swiper-button-next"></div>-->
          </div>
        </div>
      </div>

      <div v-if="showSchedule" class="text-center">
        <p class="font-weight-normal mb-3 font-1rem">
          {{ $t("schedule_confultation") }}
        </p>
        <p class="mb-3 font-1rem">{{ $t("schedule_confultation_desc") }}</p>
      </div>

      <div v-if="!showSchedule" class="text-center">
        <a
          href="javascript:;"
          @click="getDia"
          class="btn btn-ls-g text-capitalize px-4 mt-2"
          >{{ $t("get_diamond_txt") }}</a
        >
      </div>

      <div v-if="showSchedule" class="text-center">
        <a
          href="javascript:;"
          @click="scheduleNow"
          class="btn btn-ls-g text-capitalize px-4 mt-2"
          >{{ $t("schedule_now") }}</a
        >
      </div>

      <div class="text-center mt-2">
        <a
          href="javascript:;"
          @click="closeModel"
          class="btn px-4 font-1rem text-muted"
          >{{ $t("not_now") }}</a
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import helper from "../../utils/helper";
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}

export default {
  name: "diamondPopup",
  // components: {
  //   planCarousel
  // },

  data() {
    return {
      show: false,
      dia_logo:
        "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/DIA_logo.png",
      carousels: [],
      diaSwiper: null,
      swiperOption: {
        //init:false,
        speed: 500,
        loop: true,
        initialSlide: 0,
        direction: "horizontal",
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "ls-diamond-bullet-active",
        },
      },
      showSchedule: false,
      initialSlide: 0,
    };
  },
  mounted() {
    let self = this;
    // let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/";
    // let cdnBase2 = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/";
  },
  methods: {
    chatLink() {
      this.$storage.set("chat-ref", location.href);
      this.$router.push({
        name: "client-chat",
        query: { clientId: "employee" },
      });
      //   let self = this;
      //   let paths = location.pathname.replace("/", "").split("/");
      //   let urlpart = [paths[0], paths[1], "employee/chat"].join("/");
      //   let token = self.$storage.get("token");
      //   // console.log(`/${urlpart}?token=${token}`);
      //   self.$storage.set("ls-ref", location.href);
      //   window.top.location.href = `/${urlpart}?token=${token}`;
    },
    scheduleNow() {
      let self = this;

      //record api
      // let pdata = new FormData();
      // //membership_level_id
      let userInfo = self.$storage.get("userInfo");
      if (userInfo) {
        if (_.get(userInfo, "is_verified") == 1) {
          self.$router.push({
            // name: "membership-choose",
            name: "membership-upgrade",
            query: { type: "diamond" },
            // name: "payment-plan",
            // params: { planName: "diamond" },
            // query: { top_nav: 0 },
          });

          return false;
        }
      }

      self.$emit("scheduleNow", { act: "diamond_upgrade" });
      //   if (_.get(userInfo, "client_status_id") == 0) {
      //     pdata.append("chat_key", "activate_account");
      //   } else {
      //     pdata.append("chat_key", "diamond_upgrade");
      //   }

      //   self.$api
      //     .postStartAutoChat(pdata, self.$store.state)
      //     .then((rs) => {
      //       self.chatLink();
      //     })
      //     .catch((err) => {
      //       self.$layer.closeAll("loading");

      //       // console.log(['me error', err]);
      //     });
      //   }
    },
    getDia() {
      this.scheduleNow();
      //   this.showSchedule = true;
    },
    startSwiper() {
      let self = this;
      if (VueAwesomeSwiper && self.carousels.length > 1) {
        //!self.lsSwiper &&
        // if (self.lsSwiper) {
        //   self.lsSwiper.destroy();
        // }
        if (self.diaSwiper) {
          if (self.diaSwiper[0]) {
            self.diaSwiper[0].observer.destroy();
          } else {
            self.diaSwiper.destroy();
          }
        }

        // if (self.navigation) {
        //   self.swiperOption.navigation = {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev"
        //   };
        // }
        // if (self.carouselLen == 1) {
        //   self.swiperOption.autoplay = false;
        //   self.swiperOption.navigation = false;
        // }

        // let page = _.get(self.$route, "query.page", 1);

        self.swiperOption.initialSlide = self.initialSlide;

        setTimeout(function () {
          self.diaSwiper = new VueAwesomeSwiper.Swiper(
            "#diaSwiper",
            self.swiperOption
          );
        }, 10);
      }
    },
    closeModel() {
      let self = this;
      self.show = false;
      self.showSchedule = false;
      self.initialSlide = 0;
    },
    showModal(initialSlide = 0, membershipLevelId = 3) {
      let self = this;
      self.show = true;
      self.initialSlide = initialSlide;
      if (membershipLevelId >= 4) {
        self.carousels = helper.diamondPlusCarouselItems(self);
      } else {
        self.carousels = helper.diamondCarouselItems(self, false);
      }

      self.startSwiper();
    },
  },
};
</script>