<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <b-container fluid>
        <div v-if="step==1" id="stpe1">
          <h5 class="text-center">{{$t('suggest_a_time')}}</h5>
          <div class="mt-5">
            <b-input-group>
              <b-form-select
                @change="dateChange"
                v-model="selectedDate"
                :options="dateoptions"
                class="mt-3"
              ></b-form-select>
              <b-form-select v-model="selectedTime" :options="timeOptions" class="mt-3"></b-form-select>
            </b-input-group>
          </div>
          <div class="mt-5 text-center">
            <b-button variant="ls-g" @click="clickStep1">{{$t('m_continue_txt')}}</b-button>
          </div>
        </div>

        <div v-if="step==2" id="step2">
          <h5 class="text-center">{{$t('choose_location')}}</h5>
          <div class="mt-3 overflow-auto">
            <ul class="list-unstyled" v-if="venueList">
              <div
                v-for="ven in venueList"
                @click="selectVenue(ven.venue_id)"
                :key="ven.venue_id"
                :class="selectedVenueId==ven.venue_id ? 'diamond_card_bg' : ''"
              >
                <b-media tag="li">
                  <template v-slot:aside>
                    <b-img
                      v-if="ven.primary_photo"
                      thumbnail
                      fluid
                      :src="ven.primary_photo.photo_url"
                      style="min-width:80px;border: 1px solid #dee2e6 !important;"
                    ></b-img>
                  </template>
                  <p class="mt-0 font-weight-normal text-truncate">{{ven.name}}</p>
                  <p class="small font-weight-light text-truncate">{{ven.cuisine_type.description}}</p>
                  <p class="small font-weight-light text-truncate">
                    <i class="fa fa-tag text-black-50"></i>
                    {{currency.symbol}}{{ven.price_range.description}}
                  </p>
                  <p class="small font-weight-light text-truncate">
                    <i class="fa fa-map-marker-alt text-black-50"></i>
                    {{ven.neighborhood.neighborhood_name}}
                  </p>
                </b-media>
                <li class="border-bottom mt-1 mb-2 pb-2 text-danger text-center">
                  *{{$t('min_charge_full')}}
                  <span
                    class="font-weight-normal"
                  >{{currency.symbol}}{{ven.minimum_charge_amount}}</span>
                  /{{$t('person')}}
                </li>
                <!-- <li class="border-bottom mb-2 pb-2">{{ven.address}}</li> -->
              </div>
            </ul>
            <!-- <div v-if="emptyVenue">
              <a href="javascript:;" @click="reChooseTime">Re-choose time</a>
            </div>-->
            <!-- <b-list-group v-if="venueList">
              <b-list-group-item
                @click="selectVenue(ven.venue_id)"
                :variant="selectedVenueId==ven.venue_id ? 'success' : ''"
                v-for="ven in venueList"
                :key="ven.venue_id"
              >
                
                <p class="font-weight-bold">{{ven.name}}</p>
                <p class="text-muted">{{ven.address}}</p>
              </b-list-group-item>
            </b-list-group>-->
          </div>

          <div class="mt-3 text-center">
            <b-button variant="light" class="mr-2" @click="reChooseTime">{{$t('reselect_time')}}</b-button>
            <b-button variant="ls-g" @click="clickStep2">{{$t('Send')}}</b-button>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "selectDateTimeVenue",
  data() {
    return {
      step: 1,
      introId: null,
      show: false,
      emptyVenue: false,
      dateTime: null,
      selectedDate: null,
      selectedTime: null,
      selectedVenueId: null,
      venueList: null,
      currency: null,
      actType: "accept",
      curLang: this.$store.getters.curLang,
      weeks: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      dateoptions: [],
      timeList: [],
      timeOptionFirst: { value: null, text: this.$t("select_time") },
      timeOptions: [],
      timeOptionsDefault: [
        { value: null, text: this.$t("select_time") },
        { value: "11:00", text: "11:00" },
        { value: "11:30", text: "11:30" },
        { value: "12:00", text: "12:00" },
        { value: "12:30", text: "12:30" },
        { value: "13:00", text: "13:00" },
        { value: "13:30", text: "13:30" },
        { value: "14:00", text: "14:00" },
        { value: "14:30", text: "14:30" },
        { value: "15:00", text: "15:00" },
        { value: "15:30", text: "15:30" },
        { value: "16:00", text: "16:00" },
        { value: "16:30", text: "16:30" },
        { value: "17:00", text: "17:00" },
        { value: "17:30", text: "17:30" },
        { value: "18:00", text: "18:00" },
        { value: "18:30", text: "18:30" },
        { value: "19:00", text: "19:00" },
        { value: "19:30", text: "19:30" }
        // { value: "20:00", text: "20:00" }
      ]
    };
  },
  methods: {
    reChooseTime() {
      let self = this;
      self.step = 1;
      // self.selectedDate = null;
      // self.selectedTime = null;
    },
    clickStep2() {
      let self = this;
      if (!self.dateTime) {
        self.$layer.msg(self.$t("pls_select_date_time"));

        return false;
      }
      if (!self.selectedVenueId) {
        self.$layer.msg(self.$t("pls_choose_location"));

        return false;
      }
      let fdata = new FormData();
      fdata.append("intro_id", self.introId);
      fdata.append("act_type", self.actType);
      fdata.append("request_datetime", self.dateTime);
      fdata.append("request_venue_id", self.selectedVenueId);
      self.postAction(fdata);
    },
    dateChange() {
      let self = this;
      if (!self.selectedDate) {
        return false;
      }
      // console.log("dateChange", self.selectedDate, self.timeList);
      for (let list of self.timeList) {
        // console.log("list", list);
        if (_.get(list, "date") == self.selectedDate) {
          // console.log("list", list);
          if (_.get(list, ["times", "time_list"]).length == 0) {
            self.timeOptions = self.timeOptionsDefault;
            break;
            return false;
          }
          let timelist = [self.timeOptionFirst];
          for (let t of _.get(list, ["times", "time_list"])) {
            timelist.push({ value: t, text: t });
          }

          self.timeOptions = timelist;
        }
      }

      if (self.timeOptions.length == 0) {
        console.log("default list");
        self.timeOptions = self.timeOptionsDefault;
      }
    },
    postAction(fdata) {
      let self = this;
      self.$emit("postAction", self.introId, fdata, self.actType);
    },
    clickStep1() {
      let self = this;
      if (!self.selectedDate) {
        self.$layer.msg(self.$t("pls_select_date_time"));

        return false;
      }
      if (!self.selectedTime) {
        self.$layer.msg(self.$t("pls_select_date_time"));
        return false;
      }
      self.dateTime = `${self.selectedDate} ${self.selectedTime}:00`;
      //console.log(self.dateTime);
      self.initVeune();
    },
    selectVenue(venue_id) {
      let self = this;

      self.selectedVenueId = venue_id;
    },
    initVeune() {
      let self = this;
      self.$layer.loading();
      self.$api
        .getVenueList(`datetime=${self.dateTime}`, self.$store.state)
        .then(rs => {
          // console.log(rs);
          self.venueList = _.get(rs, "venues", false);
          // console.log("self.venueList=", self.venueList.length, self.venueList);
          if (self.venueList.length > 0) {
            self.emptyVenue = false;
            for (let i in self.venueList) {
              self.venueList[i].price_range.description = self.venueList[
                i
              ].price_range.description.replace("$", "");
              if (
                !_.get(
                  self.venueList,
                  [i, "cuisine_type", "description"],
                  false
                )
              ) {
                self.venueList[i].cuisine_type = { description: "" };
              }
            }
          } else {
            self.emptyVenue = true;
          }

          self.currency = _.get(rs, "currency", null);
          self.$layer.closeAll("loading");
          self.step = 2;
        })
        .catch(err => {
          self.$layer.closeAll("loading");
          console.log(err);
        });
    },
    initTimeSelect() {
      let self = this;
      let times = [{ value: null, text: "select a time" }];
    },
    initDateSelect() {
      let self = this;
      let dates = [{ value: null, text: self.$t("select_date") }];
      for (let i = 1; i <= 10; i++) {
        let td = self.$moment().add(i, "days");
        let text = "";
        if (i == 1) {
          text = self.curLang == "en_US" ? "Tomorrow" : "明天";
        } else if (i > 1 && i <= 4) {
          text =
            self.curLang == "en_US"
              ? td.format("ddd")
              : _.get(self.weeks, td.format("d"), "");
        } else {
          text =
            self.curLang == "en_US" ? td.format("MMM D") : td.format("M月D日");
        }

        dates.push({ value: td.format("YYYY-MM-DD"), text: text });
      }
      self.dateoptions = dates;
      //console.log(dates);
    },
    showModal(introId, actType) {
      let self = this;
      self.show = true;
      self.introId = introId;
      self.actType = actType;
      self.step = 1;
      self.dateTime = null;
      self.selectedDate = null;
      self.selectedTime = null;
      self.selectedVenueId = null;
      console.log("showModal", introId);
      // $("#mobile").focus();
    },
    hideModal() {
      this.show = false;
      this.introId = null;
      this.actType = "accept";
    }
  },
  mounted() {
    let self = this;
    self.$api
      .getDateTimeList("", self.$store.state)
      .then(rs => {
        self.timeList = rs;
        self.initDateSelect();
      })
      .catch(err => {
        console.log("date time list error", err);
      });
  }
};
</script>