<template>
  <div>
    <div @click="divClick" class="text-truncate text-7e" :class="textRight==1? 'text-right':''">{{ selectedString }}</div>
    
    <transition name="fade">
        
      <div v-show="isShow" class="smartSelectBox" :id="`smartSelect_${filed}`">
      
        <div class="px-3">
            <div class="d-flex justify-content-between">
        <a href="javascript:;" @click.stop="hideBox" class="p-2">
          <!-- <i class="lsicon arrow_left_y2"></i> -->
          <i class="fa-regular fa-chevron-left text-ee1 fs-20"></i>
        </a>


          <h6 class="text-center fw-600 text-capitalize text-truncate text-1d py-2">
            {{ title }}
          </h6>
          <div>&nbsp;</div>
          <!-- <a
            href="javascript:;"
            @click.stop="hideBox"
            class="text-uppercase text-ls-red2 p-2"
            >{{ $t("Ok") }}</a
          > -->
          </div>
        </div>
        <hr class="my-0"/>
        <div class="bg-d7c py-3 px-4 text-1d mb-4">
        <h4 v-if="multiple" class="fw-600 fs-16 mb-0">{{$t('select_one_more')}}</h4>
        <h4 v-else class="fw-600 fs-16 mb-0">{{$t('select_one')}}</h4>
    </div>
    <div class="px-4">
      <b-button
    @click.stop="itemClick(item.id)"
            v-for="item in listItems"
            :key="item.id"
            variant="white"
            class="w-100 mb-3 btn-big text-left d-block"
            :class="item.selected ? 'selected fw-600' : ''"
           
            >{{ item.desc }} <span class="ssselectedIcon" v-if="item.selected">
              <i class="fa-light fa-circle-check text-faf"></i>
            </span>
          </b-button>
    </div>
    

        <!-- <ul class="list-group">
          <li
            class="list-group-item text-1d bg-faf border-ls-6"
            @click.stop="itemClick(item.id)"
            v-for="item in listItems"
            :key="item.id"
          >
            {{ item.desc }}
            <span class="text-primary ssselectedIcon" v-if="item.selected">
              <i class="lsicon checkmark_gold"></i>
            </span>
          </li>
        </ul> -->
      </div>
    </transition>
    <div v-if="isShow" @click.stop="hideBox" class="ssmask"></div>
  </div>
</template>
<script>
export default {
  name: "smartSelect",
  props: {
    idName: {
      type: String,
    },
    descName: {
      type: String,
      default: "description",
    },
    options: {
      type: Array,
    },
    selected: {
      type: String | Number,
      default: "",
    },
    filed: {
      type: String,
    },
    multiple: {
      type: Number | String,
      default: 0,
    },
    textRight: {
        type: Number,
      default: 0,
    },
    titleKey: {
      type: String,
      default: "",
    },
    emptyString: {
      type: String,
      default: "",
    },
    isStrId:{
        type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      //options: [],
      value: "",
      selectedString: "",
    //   defaultEmptyString: this.$t("please_choose_txt"),
      defaultEmptyString: '',
      selectedDescs: [],
      selectedIds: null,
      //listItems:[],
      isShow: false,
      inited: false,
      isZH: this.$store.getters.curLang.indexOf("zh_") > -1,
    };
  },
  computed: {
    title() {
      if (this.titleKey) {
        return this.$t(this.titleKey);
      }
      return "Smart Select";
    },
    // options(){
    //     console.log('computed--options')
    //     //return this.options;
    // },
    listItems() {
      let self = this;
        console.log('listItems...')
      if (!self.inited) {
        console.log('listItems', self.idName, self.selected)
        if (self.selected) {
          self.selectedIds =
            !self.multiple || self.multiple == "0"
              ? self.selected
              : _.map(self.selected.split(","), function (n) {
                  return parseInt(n);
                });
        } else {
          self.selectedIds = !self.multiple ? "" : [];
        }

        self.inited = true;
        // console.log('selectedIds', self.selectedIds)
      }

      return this.resetItems("init");
    },
  },
  watch: {
    // options(){
    //     console.log('watch--options')
    //     //return this.options;
    // },
  },
  methods: {
    itemClick(id) {
      let self = this;

      if (self.multiple && self.multiple != "0") {
        //let removed = [];
        if (_.indexOf(self.selectedIds, id) > -1) {
          _.pull(self.selectedIds, id);
          //self.selectedIds = self.selectedIds;
          self.resetItems("click");
        } else {
          self.selectedIds.push(id);
        }
      } else {
        self.selectedIds = id;
      }

      //console.log(self.selectedIds)
      //self.listItems = self.resetItems();
    },
    resetItems(act) {
      let self = this;
      if (!self.options || self.options.length == 0) {
        console.log('resetItems----0')
        return false;
      }
    //   console.log('listItems', self.idName,self.descName,self.options, self.selectedIds, self.multiple);
      let items = [];
      self.selectedDescs = [];
      //console.log('resetItems----1')
      for (let da of self.options) {
        let seled = false;
        let val = self.isStrId ? da[self.idName] : parseInt(da[self.idName]);
        if (
          self.multiple &&
          self.multiple != "0" &&
          _.indexOf(self.selectedIds, val) > -1
        ) {
          self.selectedDescs.push(da[self.descName]);
          seled = true;
        }


        if (
          (!self.multiple || self.multiple == "0") &&
          self.selectedIds == val
        ) {
          self.selectedDescs.push(da[self.descName]);
          seled = true;
        }


        items.push({
          id: da[self.idName],
          desc: da[self.descName],
          selected: seled,
        });
      }

      if (self.selectedDescs.length > 0) {
        self.selectedString = self.selectedDescs.join(",");
      } else {
        // console.log("isZH", self.isZH);
        self.selectedString = self.emptyString
          ? self.isZH
            ? self.emptyString.replace(/\s*/g, "")
            : self.emptyString
          : self.defaultEmptyString;
      }
      //self.updateValue(act);
      return items;
    },
    updateValue(act) {
      //console.log('call updateValue---');
      this.$emit(
        "updateValue",
        this.filed,
        !this.multiple || this.multiple == "0"
          ? this.selectedIds
          : this.selectedIds.join(","),
        act
      );
    },
    divClick() {
      this.$emit("beforeShow", {});
    },
    showBox() {
      let self = this;
      let wh = $(window).height();
      let ww = $(window).width();
      let smartbox = $(`#smartSelect_${self.filed}`);
      let smartH = smartbox.height();
      let smartW = smartbox.width();
      //console.log(smartH,wh);
      let css = {};
      if (ww > 500) {
        css.left = (ww - smartW) / 2 + "px";
      }

      if (smartH >= wh) {
        // if(wh > 750){
        //     css.height = '750px';
        // }else{
        //     css.height = wh+'px';
        // }
        // css.height = wh - 10 + "px";
        // css.top = 0;
        css["overflow-y"] = "scroll";
      } else {
        // css.top = (wh - smartH) / 2 + "px";
      }

      smartbox.css(css);

      self.isShow = true;
    },
    hideBox() {
      let self = this;
      //   console.log("hideBox", self.value);
      
      self.updateValue("click");
      self.isShow = false;
    },
    clearSelected() {
      let self = this;
      self.selectedString = self.emptyString
        ? self.isZH
          ? self.emptyString.replace(/\s*/g, "")
          : self.emptyString
        : self.defaultEmptyString;
      self.selectedDescs = [];
      self.selectedIds = [];
    },
  },

  // mounted() {
  //     let self = this;

  //     self.selectedIds = _.map(self.selected.split(','), function(n){
  //         return parseInt(n);
  //     });
  //     console.log(self.selectedIds)
  //     self.listItems = self.resetItems();
  // },
};
</script>
<style scoped>
.smartSelectBox {
  max-width: 500px;
  position: fixed;
  z-index: 99999;
  width: 100%;
height: 100vh;
    left: 0;
  top:0;
  background: #FAF8F1;
}
.closeBtn {
  position: absolute;
  top: 1rem;
  right: 10px;
}
.ssmask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 16;
  background: #000;
  opacity: 0.4;
}
.ssselectedIcon {
  position: absolute;
  right: 20px;
}

.fade-enter,
.fade-leave-to {
  transform: translate3d(0, 100%, 0);
}
.fade-enter-to,
.face-leave {
  transform: translate3d(0, 0, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s linear;
}
</style>