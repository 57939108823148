import { render, staticRenderFns } from "./photoView.vue?vue&type=template&id=74e0c885&scoped=true&"
import script from "./photoView.vue?vue&type=script&lang=js&"
export * from "./photoView.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./photoView.vue?vue&type=style&index=0&id=74e0c885&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "74e0c885",
  "6bc1b33a"
  
)

export default component.exports