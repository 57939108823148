<template>
  <div v-if="isShow" class="row mx-auto mt-1">
    <div v-if="carousels && carouselLen > 1" class="mb-2 w-100" id="swiperbox">
      <div class="swiper-container" id="planSwiper" style="min-height: 225px">
        <div class="swiper-wrapper mb-3">
          <div
            class="swiper-slide text-center"
            v-for="(item, key) in carousels"
            :key="`swiper-${key}`"
          >
            <!-- <div
              v-if="item.extra_icon"
              class="d-block mx-auto mb-3 plan_carousel_icon"
              style="width: 160px"
            >
              <img
                :src="item.icon"
                class="border-0"
                :class="item.circle == 1 ? 'rounded-circle' : ''"
                width="100"
              />
              <img
                :src="item.extra_icon"
                class="border-0 rounded-circle"
                width="60"
              />
            </div> -->
            <!-- <div class="d-block mx-auto mb-3 plan_carousel_icon">
              <img
                :src="item.icon"
                class="border-0"
                style="max-height: 60px"
                :class="item.circle == 1 ? 'rounded-circle' : ''"
              />
            </div> -->
            <div class="o-72-border-ee1 mx-auto mb-3">
              <i :class="item.icon" class="fs-25 text-ee1"></i>
            </div>
            <p class="fw-700 text-7e px-2 ff-open mb-3" v-html="item.title"></p>
            <p
              class="d-block fw-300 text-7e px-2 ff-open"
              v-html="item.desc"
            ></p>
          </div>
        </div>
        <div
          class="swiper-pagination"
          :class="paginationClass"
          style="bottom: -2px"
        ></div>
        <div v-if="navigation" class="swiper-button-prev"></div>
        <div v-if="navigation" class="swiper-button-next"></div>
      </div>
    </div>
    <div v-if="carousels && carouselLen == 1" class="mb-4 text-center w-100">
      <!-- <p class="font-weight-bold text-center text-dark px-2">
        {{ carousels[0]["title"] }}
      </p> -->
      <div class="d-block mx-auto plan_carousel_icon">
        <img
          :src="carousels[0]['icon']"
          class="border-0"
          style="max-height: 60px"
        />
      </div>
      <p class="fw-700 text-white px-2 ff-open">
        {{ carousels[0]["title"] }}
      </p>
      <p class="d-block mt-3 fw-300 ff-open text-white">
        {{ carousels[0]["desc"] }}
      </p>
    </div>
  </div>
</template>

<script>
import helper from "../../utils/helper";
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}
export default {
  name: "planCarousel",
  props: {
    navigation: {
      type: Boolean,
      default: true,
    },
    addOn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isShow: true,
      planName: "",
      carouselLen: 0,
      carousels: [],
      swiperOption: {
        //init:false,
        speed: 500,
        loop: true,
        initialSlide: 0,
        direction: "horizontal",
        autoplay: {
          delay: 7000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "ls-silver-bullet-active",
        },
      },
      paginationClass: "ee1",
      membership_level_id: 1,
      initialSlide: 0,
    };
  },
  methods: {
    hideModal() {
      this.isShow = false;
    },
    changePlanName(planName, initialSlide = 0, membership_level_id = 2) {
      let self = this;
      self.planName = planName;
      self.membership_level_id = membership_level_id;
      self.initialSlide = initialSlide;
      console.log("changePlanName", self.planName, self.membership_level_id);
      self.changeCarousel();
    },
    carouselSlideEnd(aindex) {
      let self = this;
      // console.log("carouselSlideEnd", aindex);
      self.$emit("slideEnd", { len: self.carouselLen, index: aindex });
    },
    changeCarousel() {
      let self = this;
      let cdnBase = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/";
      let cdnBase2 = "https://cdn-cf2.lovestruck.com/upload-s/gen/img/";
      //   console.log(["carousel", self.planName]);
      if (self.planName == "plus") {
        self.swiperOption.pagination.bulletActiveClass = "ls-ee1-bullet-active";
        self.carousels = helper.mePageCarousels(self, 2);
      } else if (self.planName == "matchmaking") {
        self.swiperOption.pagination.bulletActiveClass = "ls-ee1-bullet-active";
        self.carousels = helper.mePageCarousels(self, 3);
      } else if (self.planName == "starter-renew") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.renewStarterCarousel(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "plus-renew") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.renewPlusCarousel(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "diamond-renew") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.renewDiamondCarousel(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "any-diamond") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.mePageCarousels(self, self.membership_level_id);
        // console.log("diamond", self.carousels);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "diamond-upgrade") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.upgradeDiamondCarousel(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "plus-upgrade") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.upgradeGoldCarousel(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "diamond-upgrade-plus") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.upgradeDiamondCarouselForGold(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "guest-starter") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.guest2StarterCarousels(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "starter-gold") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.starter2GoldCarousels(self);
        // self.carouselLen = self.carousels.length;
      } else if (self.planName == "silver-gold") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-white-bullet-active";
        self.carousels = helper.silver2GoldCarousels(self);
        // self.carouselLen = self.carousels.length;
      }

      self.carouselLen = self.carousels.length;
      //   console.log("add-on-0", self.addOn);
      if (
        self.addOn &&
        (self.planName == "gold" ||
          self.planName == "date_credit" ||
          self.planName == "platinum")
      ) {
        // self.carousels[0]["title"] = `Send Date Invite to ${self.addOn.title}`;
        if (self.planName == "gold" || self.planName == "platinum") {
          self.carousels[0]["title"] = self
            .$t("send_date_to")
            .replace("[fist_name]", self.addOn.title);

          self.carousels[0]["extra_icon"] = self.addOn.icon;
        }
      }

      if (VueAwesomeSwiper && self.carouselLen > 1) {
        //!self.lsSwiper &&
        // if (self.lsSwiper) {
        //   self.lsSwiper.destroy();
        // }
        // if (self.lsSwiper) {
        //   self.lsSwiper[0].observer.destroy();
        // }
        if (self.navigation) {
          self.swiperOption.navigation = {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          };
        }
        if (self.carouselLen == 1) {
          self.swiperOption.autoplay = false;
          self.swiperOption.navigation = false;
        }

        // let index = _.get(self.$route, "query.index", 0);
        let page = _.get(self.$route, "query.page", 1);

        self.swiperOption.initialSlide = page - 1;

        (self.swiperOption.on = {
          slideChangeTransitionEnd: function () {
            //alert(this.activeIndex);
            self.carouselSlideEnd(this.activeIndex);
          },
        }),
          setTimeout(function () {
            self.lsSwiper = new VueAwesomeSwiper.Swiper(
              "#planSwiper",
              self.swiperOption
            );
          }, 10);
      }
    },
  },
  mounted() {
    // console.log("navigation", this.navigation);
    //this.changeCarousel();
  },
};
</script>

<style scoped>
.swiper-button-next,
.swiper-button-prev {
  background-image: none;
  color: #ccc;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}
.swiper-container img {
  border: none !important;
}

/* .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active{background: #d5b06e;} */
</style>
