<template>
  <div class="bg-faf min-h100 text-1d">
    <div class="page6-2 px-3 pb-5 position-relative">
      <div class="pt-2">
        <div class="ls-progress-bar bg-d7c v6-2">
          <div
            class="ls-progress-completed"
            style="transition: 0.1s ease 0s; width: 10%"
          ></div>
        </div>
      </div>
      <div class="fs-30 ff-prata fw-500 line-h-35 my-4">
        What’s your<br />phone number?
      </div>
      <div class="text-7e">
        Lovestruck will send a text with a<br />
        verification code. The verified phone<br />
        number can be used to log in.
      </div>
      <div style="height: 5vh"></div>

      <div class="register_box position-relative mx-auto-">
        <div class="form-group text-center">
          <div class="">
            <button
              @click="showCountrySmsCode"
              v-html="selectCountryHtml"
              type="button"
              class="btn dropdown-toggle d-inline-block v6-2 icode_btn mr-1"
              style="position: relative; top: -2px"
            ></button>
            <!-- b-lxxxform-control d-inline-block rounded pl-2 bg-white 】 -->
            <b-input
              type="tel"
              class="phoneInput d-inline-block v6-2 l-space1"
              @keyup.enter="submitBtn"
              @input="checkInput"
              v-model="mobile"
              autocomplete="off"
              :placeholder="phoneNumberPlaceholder"
              maxlength="15"
              style="width: calc(100% - 125px)"
              id="phoneMobile"
              ref="phoneInputRef"
            ></b-input>
          </div>
        </div>
        <div>
          <countrySmsCodeList
            :topx="topX"
            @selectCountry="selectCountry"
            ref="countrySmsCodeList"
            class="page6-2"
            style="height: 200px"
          ></countrySmsCodeList>
        </div>
        <div class="text-right">
          <b-button
            pill
            variant="ee1"
            @click="submitBtn"
            :class="canSubmit ? '' : 'disabled'"
            class="o-btn lg"
            ><i class="fa-light fa-arrow-right"></i
          ></b-button>
        </div>
      </div>
    </div>
    <alertModal
      ref="alertModal"
      @okEvent="alertOkClick"
      @cancelEvent="alertCancelClick"
    />
  </div>
</template>
  <script>
import countrySmsCodeList from "./common/countrySmsCodeList";
import helper from "../utils/helper";
import alertModal from "./common/alertModal";
export default {
  name: "lsm-home",
  components: {
    countrySmsCodeList,
    alertModal,
  },
  data() {
    return {
      showLogin: false,
      topX: 60,
      icode: "",
      mobile: "",
      dob: "",
      selectCountryHtml: "",
      canSubmit: false,
      phoneNumberPlaceholder: this.$t("phone_number"),
      curSite: this.$store.getters.curSite,
      sign_term_privacy_cookie: "",
      langCode: "",
      selectedLang: "",
      langNames: [
        {
          id: 1,
          key: "en_US",
          name: "ENG",
        },
        {
          id: 3,
          key: "zh_HK",
          name: "中文繁体",
        },
        {
          id: 2,
          key: "zh_CN",
          name: "中文简体",
        },
        {
          id: 4,
          key: "th",
          name: "ภาษาไทย",
        },
      ],
      utmParams: {
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
      },
    };
  },
  mounted() {
    let self = this;

    // if(self.$route.name == 'home-lsm'){
    //     console.log('auto login')
    //     self.checkAutoLogin()
    // }
    // self.showLogin = _.get(self.$route.query, "token", null) ? false : true;
    self.utmParams.utm_source = _.get(self.$route.query, "utm_source");
    self.utmParams.utm_medium = _.get(self.$route.query, "utm_medium");
    self.utmParams.utm_campaign = _.get(self.$route.query, "utm_campaign");
    self.utmParams.utm_term = _.get(self.$route.query, "utm_term");
    self.utmParams.utm_content = _.get(self.$route.query, "utm_content");

    // setTimeout(function () {
    //   self.showLogin = true;
    // }, 500);
    self.langCode = self.$storage.get("cur-lang");
    // console.log(self.langCode, self.curSite)
    self.setLangName();
    let lang = self.curSite.lang_default.replace("_", "-");
    if (lang.indexOf("en") > -1) {
      lang = "en";
    }

    self.icode = _.get(self.curSite, "dial_code", "852");
    self.selectCountryHtml = `<img src="${self.curSite.flag_url}" class="border-0"/> +${self.icode}`;

    let current_city = self.$storage.get("ls_city");

    let partUrl = `/${current_city}/${lang}`;

    // self.sign_term_privacy_cookie = self.$t("sign_term_privacy_cookie");
    // self.sign_term_privacy_cookie = 'By tapping Sign in, you agree with our <a-terms>Terms</a-terms>. Learn how we process your data in our <a-privacy>Privacy Policy</a-privacy> and <a-cookie>Cookies Policy</a-cookie>.';
    //https://www.lovestruck.com/hong-kong/en/terms/
    //https://www.lovestruck.com/hong-kong/en/privacy/
    //https://www.lovestruck.com/hong-kong/en/cookies/
    // self.sign_term_privacy_cookie = self.sign_term_privacy_cookie
    //   .replace("[tap_name]", self.$t("web_lbl_nextsteps"))
    //   .replace(
    //     "<a-terms>",
    //     `<a href="${partUrl}/terms/" class="text-7e underline" target="_blank">`
    //   )
    //   .replace("</a-terms>", "</a>")
    //   .replace(
    //     "<a-privacy>",
    //     `<a href="${partUrl}/privacy/" class="text-7e underline" target="_blank">`
    //   )
    //   .replace("</a-privacy>", "</a>")
    //   .replace(
    //     "<a-cookie>",
    //     `<a href="${partUrl}/cookies/" class="text-7e underline" target="_blank">`
    //   )
    //   .replace("</a-cookie>", "</a>");
    // self.$refs.phoneInputRef.focus();
    setTimeout(function () {
      //     console.log('set focus..')
      self.$refs.phoneInputRef.focus();
      //     // document.getElementById('phoneMobile').focus();
      //   $("#phoneMobile").focus();
    }, 500);
  },
  methods: {
    checkAutoLogin() {
      let self = this;
      //token
      let sToken = self.$storage.get(token);
      if (!sToken) {
        return false;
      }
      self.$layer.loading();
      self.$api
        .postLoginByToken(sToken, self.$store.state)
        .then((rs) => {
          if (_.has(rs, "client_id")) {
            helper.setLoginData(rs);
            self.$store.commit("SET_TOKEN", _.get(rs, "access_token", ""));
            self.$store.commit("SET_CUR_LANG", {
              curlang: _.get(rs, "lang_code", ""),
            });

            let jump = helper.parseFirstLoadPage(rs);

            if (jump.type == "link") {
              // console.log(url)
              if (typeof location != "undefined") {
                location.href = jump.route_name;
              }
              return false;
            } else {
              self.$router.push({
                name: jump.route_name,
                params: jump.params ? jump.params : {},
                query: jump.query ? jump.query : {},
              });
            }
          }
          self.$layer.closeAll("loading");
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
        });
    },
    setLangName() {
      let self = this;

      for (let lang of self.curSite.langs) {
        if (lang.lang_code == self.langCode) {
          self.selectedLang = lang.name;
          break;
        }
      }
    },
    startClick() {
      this.$router.push({ name: "sign-in" });
    },
    alertOkClick() {
      this.$refs.alertModal.hideModal();
    },
    alertCancelClick() {
      this.$refs.alertModal.hideModal();
    },
    alertLimit(message, aTitle = "") {
      let self = this;

      let htmlText = `<p>${message}</p>`;

      self.$refs.alertModal.showModal({
        title: aTitle ? aTitle : self.$t("error_title"),
        message: htmlText,
        okText: self.$t("Ok"),
        showCancel: false,
        metaData: {
          type: "limit-reached",
        },
      });
    },
    checkInput() {
      let self = this;
      console.log("input..");
      if (self.mobile.length > 0) {
        self.canSubmit = true;
      } else {
        self.canSubmit = false;
      }
      //   if (helper.checkMobile(self.mobile)) {
      //   if (helper.checkPhoneMobile(self.icode, self.mobile)) {
      //     self.canSubmit = true;
      //   } else {
      //     self.canSubmit = false;
      //   }
    },
    submitBtn() {
      var self = this;
      if (!self.canSubmit) {
        console.log("can not send");
        return false;
      }

      let realMobile = self.mobile.replace(/[^\d]/g, "");

      if (!self.icode || !helper.checkMobile2(self.icode, realMobile)) {
        //console.log('mobile error');
        self.$layer.msg(self.$t("val_mobile_invaild"));

        return false;
      }
      //   self.goToSmsVerify();
      //   return false;

      let lid = self.$layer.loading();

      //   self.$api
      //     .getAccountExist(
      //       `icode=${self.icode}&mobile=${self.mobile}&add_new_user=1`,
      //       self.$store.state
      //     )
      //     .then((rs) => {
      //       if (_.get(rs, "is_exist")) {
      //         let fdata = new FormData();
      //         fdata.append("mobile_international_code", self.icode);
      //         fdata.append("mobile_phone_number", self.mobile);

      //          return self.$api.postRequestWithPhone(fdata, self.$store.state)
      //       } else {
      //         let lang = self.langCode.replace('_', '-');
      //         if(lang.indexOf('en') > -1){
      //             lang = 'en'
      //         }

      //         self.$storage.set("user-goal", {
      //             i: self.icode,
      //             m: self.mobile,
      //         });

      //         let url = `https://www.lovestruck.com/${self.curSite.city_url}/${lang}/screening/user-goal`
      //         // console.log(url)
      //         if (typeof location != 'undefined') {
      //             location.href = url
      //         }
      //       }
      //     })

      let fdata = new FormData();
      fdata.append("mobile_international_code", self.icode);
      fdata.append("mobile_phone_number", realMobile);

      fdata.append("utm_source", self.utmParams.utm_source);
      fdata.append("utm_medium", self.utmParams.utm_medium);
      fdata.append("utm_campaign", self.utmParams.utm_campaign);
      fdata.append("utm_term", self.utmParams.utm_term);
      fdata.append("utm_content", self.utmParams.utm_content);

      self.$api
        .postRequestWithPhone(fdata, self.$store.state)
        .then((response) => {
          console.log(response);
          self.$layer.closeAll("loading");

          if (response.hasOwnProperty("success") && !response.success) {
            if (typeof response.output == "string") {
              let message = JSON.parse(response.output);
              // console.log("message", message);
              self.alertLimit(
                _.get(message, "error.body"),
                _.get(message, "error.header")
              );
            } else {
              self.alertLimit(
                _.get(response, "output.error.body"),
                _.get(response, "output.error.header")
              );
            }

            // self.$layer.msg(_.get(response, "output"));
            return false;
          } else {
            self.goToSmsVerify();
          }
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          // console.log(["sms error", error]);
          if (_.has(error, "response.data")) {
            let output = _.get(error, "response.data.output");
            if (output == "string") {
              let message = JSON.parse(output);
              // console.log("message", message);
              self.alertLimit(
                _.get(message, "error.body"),
                _.get(message, "error.header")
              );
            } else {
              self.alertLimit(
                _.get(output, "error.body"),
                _.get(output, "error.header")
              );
            }
            // self.alertLimit(_.get(error, "response.data.output.error.body"))
            // self.$layer.msg(_.get(error, "response.data.output.error.body"));
          } else if (
            _.has(error, "message") &&
            _.get(error, "message").indexOf("timeout") > -1
          ) {
            // self.step = 2;
            // $("#icode").focus();
            // self.ls_timer = setInterval(() => {
            //   self.dTime();
            // }, 1000);
            self.goToSmsVerify();
          }
        });
    },
    goToSmsVerify() {
      let self = this;

      self.$storage.set("signIn", {
        i: self.icode,
        m: self.mobile,
      });
      self.$router.push({ name: "sms-verify" });
    },
    showCountrySmsCode() {
      this.$refs.countrySmsCodeList.showList();
    },
    selectCountry(data) {
      this.icode = data.dial_code;
      //   console.log("daat", data);
      //   this.selectCountryHtml = `${data.emoji} +${data.dial_code}`;
      this.selectCountryHtml = `<img src="${data.flag_url}" class="border-0"/> +${data.dial_code}`;
      //   this.selectCountryHtml = `<img src="${data.flag_url}" class="border-0"/> +${data.dial_code}`;
    },
    mobileFormat(phone) {
      return /^[0-9]{6,15}$/.test(phone);
    },
  },
};
</script>