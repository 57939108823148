<template>
  <div>
    <topName
      ref="topName"
      v-if="showTopNav"
      :pageTitle="$t('Dates')"
      :dateCredit="clientDateCredit"
      :eventTicket="clientEventTicket"
      :showMobileTop="false"
    />
    <!--:class="targetClientId == employeeTag ? 'matchmaker' : ''"-->
    <div class="chatWin">
      <div v-if="isNoDates" class="text-center py-5">
        <p>{{ $t("no_date_found") }}</p>
        <div class="mt-3">
          <b-button
            @click="goToSearchPage"
            variant="ls-y"
            class="mr-3 mb-2 text-capitalize px-5"
            >{{ $t("search_txt") }}</b-button
          >
        </div>
      </div>

      <div v-if="showLeftPlan" class="leftPlan">
        <!-- <div class="bg-white p-3 border-bottom border-right">
          <div class="d-inline-block">
            <a
              href="javascript:;"
              @click="chatToEmployee"
              class="p-2 text-muted"
            >
              <i class="fa fa-arrow-left text-ls-y"></i>
              Matchmaker
            </a>
            <div class="ml-2 d-inline-block text-capitalize">My Chats</div> 
          </div>
        </div> -->
        <div id="listContent" class="chatListBox" :style="listContentStyle">
          <div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item px-2">
                <div class="d-flex justify-content-star">
                  <div><i class="lsicon search_red"></i></div>
                  <div class="w-100">
                    <input
                      type="text"
                      v-model="listQuery.search_text"
                      @keyup.enter="goSearch"
                      class="border-bottom w-100 pl-3"
                      :placeholder="$t('search_matches')"
                    />
                  </div>
                  <div v-if="listQuery.search_text">
                    <i @click="clearSearch" class="lsicon cursor">X</i>
                  </div>
                </div>
              </li>
              <div v-if="employeeContacts">
                <li
                  v-for="item in employeeContacts"
                  :key="item.client_msg_id"
                  @click="chatToEmployee()"
                  class="list-group-item px-2 border-0 mb-2"
                  :class="targetClientId == employeeTag ? 'bg-light' : ''"
                >
                  <div class="d-flex justify-content-start">
                    <div class="headImgBox mr-3">
                      <b-img class="w-100" :src="myEmployee.photo_url"></b-img>
                    </div>
                    <div class="pb-3 border-bottom w-100" :style="rowStyle">
                      <div class="d-flex justify-content-between mb-2">
                        <p class="font-weight-bold font-14">
                          {{ myEmployee.first_name }}
                        </p>
                        <p class="small text-muted font-weight-light">
                          {{ item.sent_time | timestampFormat }}
                        </p>
                      </div>

                      <p
                        v-if="item.type == 2"
                        class="text-truncate text-muted font-14"
                      >
                        [{{ $t("Photo") }}]
                      </p>
                      <p v-else class="text-truncate text-muted font-14">
                        {{ item.msg }}
                      </p>

                      <div
                        v-if="item.to_client_id > 0 && !item.is_read"
                        class="redBot"
                      ></div>
                    </div>
                  </div>

                  <!-- <div class="position-relative">
                  <div style="width: 50px; position: absolute">
                    <div class="headImgBox">
                      <b-img class="w-100" :src="myEmployee.photo_url"></b-img>
                    </div>

                    <div
                      v-if="item.to_client_id > 0 && !item.is_read"
                      class="redBot"
                    ></div>
                  </div>
                  <div class="w-100">
                    <div
                      style="
                        height: 50px;
                        padding-left: 55px;
                        padding-right: 10px;
                      "
                    >
                      <div class="d-flex justify-content-between mb-2">
                        <p class="font-weight-bold font-14">
                          {{ myEmployee.first_name }}
                          <i
                            v-if="myEmployee.name_icon == 'gold'"
                            class="chat_gold_tag"
                            >GOLD</i
                          >
                        </p>
                        <p class="small text-muted font-weight-light">
                          {{ item.sent_time | timestampFormat }}
                        </p>
                      </div>
                      
                      <div>
                        <p
                          v-if="item.type == 2"
                          class="text-truncate text-muted font-14"
                        >
                          [{{ $t("Photo") }}]
                        </p>
                        <p v-else class="text-truncate text-muted font-14">
                          {{ item.msg }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div> -->
                </li>
              </div>

              <div v-if="hasContacts">
                <li
                  v-for="item in contacts"
                  :key="`${item.target_client_id}`"
                  @click="chatToClient(item)"
                  class="list-group-item px-2 border-0 mb-2"
                  :style="`background-color:${item.row_bg}`"
                  :class="
                    targetClientId == item.target_client_id ? 'bg-light' : ''
                  "
                >
                  <div class="d-flex justify-content-start">
                    <!-- :class="item.is_chat_expired ? 'opacity-5' : ''" -->
                    <div class="headImgBox mr-3">
                      <b-img class="w-100" :src="item.target.photo_url"></b-img>
                      <!-- <div v-if="item.is_chat_expired" class="expired_tag">
                        <i class="lsicon chat_expired"></i>
                      </div> -->
                    </div>
                    <div class="pb-3 border-bottom w-100" :style="rowStyle">
                      <div class="d-flex justify-content-between mb-2">
                        <p class="font-weight-bold font-14">
                          {{ item.target.first_name }}
                        </p>
                        <p class="small text-muted font-weight-light">
                          {{ item.sent_time | timestampFormat }}
                        </p>
                      </div>

                      <p
                        v-if="item.type == 2"
                        class="text-truncate text-muted font-14"
                      >
                        [{{ $t("Photo") }}]
                      </p>
                      <p v-else class="text-truncate text-muted font-14">
                        {{ item.msg }}
                      </p>

                      <div v-if="item.unread_count > 0" class="redBot">
                        {{ item.unread_count > 99 ? 99 : item.unread_count }}
                      </div>
                    </div>
                  </div>

                  <!-- <div class="position-relative">
                    <div style="width: 50px; position: absolute">
                      <div class="headImgBox">
                        <b-img
                          class="w-100"
                          :class="item.is_chat_expired ? 'opacity-5' : ''"
                          :src="item.target.photo_url"
                        ></b-img>
                      </div>
                      <div v-if="item.is_chat_expired" class="expired_tag">
                        <i class="lsicon chat_expired"></i>
                      </div>
                      
                    </div>
                    <div class="w-100">
                      <div
                        style="
                          height: 55px;
                          padding-left: 55px;
                          padding-right: 10px;
                        "
                      >
                        <div
                          class="d-flex justify-content-between"
                          style="height: 20px"
                        >
                          <p
                            class="font-weight-bold font-14"
                            style="margin-top: 0"
                          >
                            {{ item.target.first_name }}
                           
                          </p>
                          <div
                            class="
                              text-muted
                              font-weight-light
                              position-relative
                            "
                          >
                            <span class="small">{{
                              item.sent_time | timestampFormat
                            }}</span>

                            <div v-if="item.unread_count > 0" class="redBot">
                              {{
                                item.unread_count > 99 ? 99 : item.unread_count
                              }}
                            </div>
                          </div>
                        </div>
                        
                        <p
                          v-if="item.type == 2"
                          class="text-truncate text-muted font-14"
                          style="height: 20px; margin-top: 0"
                        >
                          [{{ $t("Photo") }}]
                        </p>
                        <p
                          v-else
                          class="text-truncate text-muted font-14"
                          style="margin-top: 0"
                        >
                          {{ item.msg }}
                        </p>
                        
                      </div>
                    </div>
                  </div> -->
                </li>
              </div>
              <div v-if="!hasContacts && isLoadingContact">
                <div
                  v-for="n in skeletonTotal"
                  :key="n"
                  class="position-relative my-4"
                >
                  <div style="width: 50px; position: absolute">
                    <div class="headImgBox">
                      <div
                        class="
                          b-skeleton b-skeleton-avatar b-skeleton-animate-fade
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="w-100">
                    <div
                      style="
                        height: 50px;
                        padding-left: 55px;
                        padding-right: 10px;
                      "
                    >
                      <div class="d-flex justify-content-between mb-2">
                        <div
                          class="
                            b-skeleton b-skeleton-text b-skeleton-animate-fade
                          "
                          style="width: 30%"
                        ></div>
                        <div
                          class="
                            b-skeleton b-skeleton-text b-skeleton-animate-fade
                          "
                          style="width: 30%"
                        ></div>
                      </div>

                      <div
                        class="
                          b-skeleton b-skeleton-text b-skeleton-animate-fade
                        "
                        style="width: 100%"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <li
                class="list-group-item text-center border-0"
                v-if="hasContacts && isLoadingContact"
              >
                {{ $t("Loading") }}...
              </li>
              <li
                class="list-group-item text-center border-0"
                v-if="!isLoadingContact && isEnd"
              >
                {{ $t("no_more_found") }}
              </li>
            </ul>
            <!-- <div v-if="showTopNav && !showRightPlan" style="height: 76px"></div> -->
          </div>
        </div>
      </div>
      <!--:class="targetClientId == employeeTag ? 'matchmaker' : ''"-->
      <div
        v-if="showRightPlan"
        id="contentBox"
        class="rightPlan"
        :style="contentBoxStyle"
      >
        <!--content head-->
        <div
          id="contentHead"
          :style="`height: ${isChatConfirmed ? 92 : 67}px`"
          class="border-bottom position-relative"
        >
          <div
            v-if="targetClient"
            class="d-flex justify-content-start p-2 bg-ls-red"
          >
            <div class="d-flex align-items-center mr-3 backbtn2">
              <a @click="backToList" href="javascript:;">
                <i class="lsicon arrow_left_w"></i>
              </a>
            </div>
            <div>
              <div
                @click="viewProfile"
                class="headImgBox"
                style="cursor: pointer"
              >
                <b-img class="w-100" :src="targetClient.photo_url"></b-img>
              </div>
            </div>
            <div
              @click="viewProfile"
              class="d-flex align-items-center ml-3"
              style="cursor: pointer"
            >
              <div class="font-14">
                <p class="font-weight-bold text-white">
                  {{ targetClient.first_name }}
                  <!-- <span v-if="targetClientId != employeeTag"
                    >({{ targetClient.age }},
                    {{ targetClient.job_title }})</span
                  > -->
                  <i
                    v-if="targetClient.is_verified == 1"
                    class="lsicon verified_bule lsicon-14"
                    style="top: 3px"
                  ></i>
                </p>
                <p
                  v-if="dateTimeHtml"
                  class="small font-weight-normal bg-white px-2"
                  v-html="dateTimeHtml"
                ></p>
                <p v-else class="small font-weight-normal text-white">
                  {{ targetClient.last_active_text }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="text-center ttt" v-html="dateTimeHtml"></div> -->
          <div
            v-if="
              targetClientId == employeeTag &&
              client &&
              client.membership_level_id < 4
            "
            class="text-center py-3 border-bottom bg-white"
          >
            <p class="mb-3">{{ $t("receive_better_matches") }}</p>
            <div>
              <b-button
                @click="goToDiamondPayment(3)"
                pill
                size="sm"
                class="btn getMatchBoostBtn text-white px-3 text-uppercase"
                >{{ $t("get_personal_matchmaker") }}</b-button
              >
            </div>
          </div>
        </div>

        <!--content body-->
        <div
          id="contentBody"
          class="mt-3 p-2 pb-4"
          style="height: auto; overflow: scroll"
        >
          <!-- <div class="profileCard">
            <profileViewCom ref="profileViewCom" :isCard="true" />
          </div> -->
          <div v-if="!showTargetProfile && conversations.length > 0">
            <div v-for="msg in conversations" :key="msg.client_msg_id">
              <div
                v-if="
                  msg.msg_type == 2 &&
                  msg.from_client_id == targetClient.client_id
                "
                class="text-center my-3"
              >
                <div
                  v-if="msg.type == 1"
                  class="p-2 font-weight-normal text-muted"
                  :style="chatLineColor(msg.extras)"
                  v-html="htmlFormat(msg.msg)"
                >
                  <!-- {{ msg.msg | htmlFormat }} -->
                  <!-- <a href="" class="font-weight-bold">Date Him</a> -->
                </div>
                <div v-if="msg.extras" class="text-center mb-2">
                  <a
                    v-if="msg.extras.id == 'date_feedback_for_you'"
                    href="javascript:;"
                    @click="viewFeedback"
                    class="font-weight-bold"
                    >{{ $t("view_feedback_txt") }}</a
                  >
                </div>
                <p class="small text-muted font-weight-light">
                  {{ msg.sent_time | timestamp }}
                </p>
              </div>

              <div
                v-if="
                  msg.msg_type != 2 &&
                  (msg.from_client_id == targetClient.client_id ||
                    targetClientId == employeeTag) &&
                  msg.to_client_id == userInfo.client_id
                "
                class="d-flex justify-content-star mb-3"
              >
                <div class="targetImg">
                  <b-img class="w-100" :src="targetClient.photo_url"></b-img>
                </div>
                <div class="chat-row">
                  <div
                    v-if="msg.type == 1"
                    class="p-2 chat-content"
                    :style="chatLineColor(msg.extras)"
                    v-html="htmlFormat(msg.msg)"
                  >
                    <!-- {{ msg.msg | htmlFormat }} -->
                  </div>
                  <div
                    v-if="msg.type == 2"
                    class="chat-content"
                    @click="imgClick(msg.extras)"
                  >
                    <b-img-lazy
                      blank-color="#777"
                      :blank="true"
                      width="200"
                      :src="msg.photo_url"
                    ></b-img-lazy>
                  </div>
                  <p class="small text-muted font-weight-light text-right">
                    {{ msg.sent_time | timestamp }}
                  </p>
                </div>
              </div>

              <div
                v-if="
                  msg.msg_type != 2 &&
                  msg.from_client_id == userInfo.client_id &&
                  (msg.to_client_id == targetClient.client_id ||
                    targetClientId == employeeTag)
                "
                class="d-flex ls-justify-content-end mb-3 mr-3"
              >
                <div class="chat-row">
                  <div
                    v-if="msg.type == 1"
                    class="p-2 chat-content me-chat"
                    :style="chatLineColor(msg.extras)"
                    v-html="htmlFormat(msg.msg)"
                  >
                    <!-- {{ msg.msg | htmlFormat }} -->
                  </div>
                  <div
                    v-if="msg.type == 2"
                    class="chat-content me-chat"
                    @click="imgClick(msg.extras)"
                  >
                    <b-img-lazy
                      blank-color="#777"
                      :blank="true"
                      width="200"
                      :src="msg.photo_url"
                    ></b-img-lazy>
                  </div>
                  <p class="small text-muted font-weight-light text-right">
                    {{ msg.sent_time | timestamp }}
                  </p>
                </div>
              </div>
            </div>
            <div id="msgBottom"></div>
          </div>
          <!-- <div
            v-if="!canChat && targetClient && targetClient.is_verified == 1"
            class="text-center labelButtonRow py-2"
            :class="fullViewLabel ? 'full-view' : ''"
          >
            <div v-if="targetClient">
              <div
                @click="viewProfile"
                class="headImgBox mx-auto mb-3"
                style="
                  cursor: pointer;
                  width: 100px;
                  height: 100px;
                  border-radius: 50px;
                "
              >
                <b-img
                  class="w-100"
                  :src="targetClient.photo_url"
                  center
                ></b-img>
              </div>
            </div>
            <p v-if="dateTitle" class="mb-2 font-weight-bold font-1_1rem">
              {{ dateTitle }}
            </p>
            <p
              v-html="labelText"
              class="mb-2 font-weight-bold"
              :style="`color:${labelColor}`"
            ></p>
            <p
              v-if="labelText2"
              v-html="labelText2"
              class="mb-2 font-weight-bold"
            ></p>
            <div v-if="buttons">
              
              <button
                @click="skipDate"
                v-if="buttons.skip"
                class="shadow o-btn"
                style="bottom: -15px"
              >
                <i class="lsicon match_skip_red lsicon-40"></i>
              </button>
              <button
                @click="cancelDate"
                v-if="buttons.cancel"
                class="shadow o-btn"
                style="bottom: -15px"
              >
                <i class="lsicon cancel_date_red lsicon-40"></i>
              </button>

              <b-button
                @click="getGoldPayment"
                v-if="buttons.get_gold"
                variant=""
                class="mr-3 mb-2 bg-gold text-dred text-capitalize"
                >{{ $t("get_gold") }}</b-button
              >

              <b-button
                @click="getPlatinumPayment"
                v-if="buttons.get_platinum"
                variant=""
                class="mr-3 mb-2 bg-platinum text-white text-capitalize"
                >{{ $t("get_platinum") }}</b-button
              >

              <button
                v-if="buttons.date"
                @click="openVenueSelectPop"
                class="shadow o-btn"
                style="bottom: -15px"
              >
                <i class="lsicon arrange_date_red lsicon-40"></i>
              </button>

              <button
                v-if="buttons.arrange_date"
                @click="openVenueSelectPop"
                class="shadow o-btn"
                style="bottom: -15px"
              >
                <i class="lsicon arrange_date_red lsicon-40"></i>
              </button>

              <b-button
                v-if="buttons.change"
                @click="openVenueSelectPop"
                variant="ls-g"
                class="mr-3 mb-2 text-capitalize"
                >{{ $t("change_date_txt") }}</b-button
              >

              <button
                v-if="buttons.confirm"
                @click="confirmDate"
                class="shadow o-btn"
                style="bottom: -15px"
              >
                <i class="lsicon confirm_date_red lsicon-40"></i>
              </button>

              <b-button
                v-if="
                  targetClient &&
                  buttons.submit_feedback &&
                  !targetIntro.source_feedback_created_time
                "
                @click="sendFeedback"
                variant="ls-g"
                class="mr-3 mb-2 text-capitalize"
                >{{ $t("send_feedback") }}</b-button
              >
              <b-button
                v-if="
                  targetClient &&
                  buttons.view_feedback &&
                  !targetIntro.target_feedback_created_time
                "
                @click="viewFeedback"
                variant="ls-y"
                class="mr-3 mb-2 text-capitalize"
                >{{ $t("view_feedback_txt") }}</b-button
              >

              <button
                v-if="buttons.date_again"
                @click="dateAgainClick"
                class="o-btn"
                style="bottom: -15px"
              >
                <i class="lsicon date_again_red lsicon-40"></i>
              </button>

              <button
                v-if="buttons.chat_again"
                @click="dateAgainClick"
                class="shadow o-btn"
                style="bottom: -15px"
              >
                <i class="lsicon chat_again_red lsicon-40"></i>
              </button>

              <b-button
                @click="getVerify"
                v-if="buttons.get_verify"
                variant="ls-y"
                class="mr-3 mb-2 text-capitalize"
                >{{ $t("m_get_verified_txt") }}</b-button
              >
            </div>
          </div> -->
          <div>
            <p v-if="loadingConversation" class="text-center mt-5 text-muted">
              {{ $t("Loading") }}...
            </p>

            <!-- <div v-if="conversations.length == 0">
              <p class="text-center mt-5 text-muted">
                {{ $t("no_conversation") }}
              </p>
            </div> -->
          </div>

          <!-- <div class="d-flex justify-content-star mb-3">
            <div class="mr-2">
              <b-img
                rounded="circle"
                width="40"
                src="https://cdn.iisuart.cn/dev/images/artists/202011/8785dd56fd27155a8631196fd3c8906f.png?imageslim|imageView2/1/w/260/h/260/interlace/1"
              ></b-img>
            </div>
            <div class="chat-row">
              <div class="p-2 chat-content">
                Hello, are you there?Hello, are you there?Hello, are you
                there?Hello, are you there?Hello, are you there?
              </div>
              <p class="small text-muted font-weight-light text-right">09:33</p>
            </div>
          </div> -->

          <!-- <div class="d-flex justify-content-end mb-3">
            <div class="chat-row">
              <div class="p-2 chat-content me-chat">
                Hello, are you there?Hello, are you there?Hello, are you
                there?Hello, are you there?
              </div>
              <p class="small text-muted font-weight-light text-right">09:33</p>
            </div>
          </div> -->

          <div v-if="isLoading" class="text-center">
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </div>
        <!-- <div
          v-if="isChatExpried == 1"
          class="text-center labelButtonRow py-3 bg-ls-red text-white"
          :class="fullViewLabel ? 'full-view' : ''"
        >
          <p class="fw-600">Chat time has Expired</p>
          <p class="mb-3">Need some more time?</p>
          <b-button
            v-if="client.membership_level_id <= 2"
            @click="chatAgain"
            size="sm"
            class="bg-platinum fw-600 border-0"
            >CHAT AGAIN</b-button
          >

          <b-button
            v-if="client.membership_level_id > 2"
            @click="chatAgain"
            size="sm"
            variant="ls-white-red"
            class="fw-600 border-0"
            >CHAT AGAIN</b-button
          >
        </div> -->
        <!--content foot-->
        <div v-if="canChat" class="chat-input-box">
          <!-- <div class="text-center" v-html="showHtmlLable(labelArray)"></div> -->

          <div class="d-flex justify-content-between">
            <!-- <div>
              <a href="javascript:;" @click="toggleEmoji" class="btn px-2"
                >&#128512;</a
              >
            </div> -->
            <div class="bg-light w-100 rounded">
              <b-form-input
                @keyup.enter="sendMsg"
                v-model="message"
                class="pl-2"
                :disabled="isSending || isChatConfirmed ? true : false"
                id="messageInput"
              ></b-form-input>
            </div>
            <div class="d-flex align-items-end ml-2" style="min-width: 60px">
              <b-button
                @click="sendMsg"
                variant="ls-g"
                :disabled="
                  message.length == 0 || isChatConfirmed ? true : false
                "
                >{{ $t("button_sendfriend_send") }}</b-button
              >
            </div>
          </div>
          <!-- <div v-if="chatCloseLeftSec > 0" class="chatCloseText mt-3">
            <span class="text-danger font-weight-bold">{{
              chatCloseText
            }}</span>
          </div> -->
          <div v-if="showEmoji" class="pt-2">
            <span
              style="cursor: pointer; margin: 5px"
              v-for="emo in emojis"
              :key="emo"
              v-html="emo"
              @click="addEmo"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <scheduleConsultation ref="scheduleConsultation" />
    <availabilityVenueList
      ref="availabilityVenueList"
      @selectVenue="selectVenueHander"
    />
    <selectVenueDate
      ref="selectVenueDate"
      @sendInvite="sendInviteHander"
      @changeVenueDate="changeVenueDateHander"
      @confirmInvite="confirmInviteHander"
    />

    <feedbackModal2
      ref="feedbackModal2"
      @afterSendFeedback="afterSendFeedback"
    />
    <diamondPopup ref="diamondPopup" @scheduleNow="scheduleNowHander" />
    <platinumPopup ref="platinumPopup" @upgradeClick="getPlatinumPayment" />
    <startDatePopupCom
      ref="startDatePopupCom"
      @afterSendGreetingMessage="afterSendGreetingMessageHandler"
    />
    <inviteConfirmPopup ref="inviteConfirmPopup" />
  </div>
</template>
<script>
import io from "socket.io-client";
import topName from "../common/topNav";
import Emojis from "../../assets/js/emoji.js";
import scheduleConsultation from "../common/scheduleConsultation";
import selectVenueDate from "../common/selectVenueDate.vue";
import availabilityVenueList from "../common/availabilityVenueList.vue";
import feedbackModal2 from "../dates/feedbackModal2.vue";
import diamondPopup from "../common/diamondPopup";
// import profileViewCom from "../common/profileViewCom.vue";
import startDatePopupCom from "../common/startDatePopupCom.vue";
import platinumPopup from "../common/platinumPopup.vue";
import inviteConfirmPopup from "../common/inviteConfirmPopup";

export default {
  name: "chat-index",
  components: {
    topName,
    scheduleConsultation,
    selectVenueDate,
    availabilityVenueList,
    feedbackModal2,
    diamondPopup,
    // profileViewCom,
    startDatePopupCom,
    platinumPopup,
    inviteConfirmPopup,
  },
  data() {
    return {
      skeletonTotal: 12,
      client: null,
      clientDateCredit: 0,
      clientEventTicket: 0,
      showBackBtn: true,
      chatTextBoxRow: 1,
      isLoading: false,
      isLoadingContact: false,
      contacts: [],
      matchedContacts: [],
      skippedContacts: [],
      page: 1,
      isEnd: false,
      hasContacts: false,
      conversations: [],
      employeeContacts: [],
      showLeftPlan: true,
      showRightPlan: true,
      showTopNav: true,
      targetClientId: 0,
      message: "",
      targetClient: null,
      userInfo: this.$storage.get("userInfo"),
      listContentClass: "",
      listContentStyle: null,
      contentBoxStyle: null,
      socket: null,
      isSending: false,
      myEmployee: {
        first_name: "Matchmaker",
        photo_url:
          "https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls_favicon@2x.png",
      },
      employeeTag: "employee",
      loadingConversation: false,
      showEmoji: false,
      emojis: Emojis,
      contactType: 1,
      canChat: false,
      chatCloseLeftSec: 0,
      firstLabelText: "",
      labelArray: [],
      labelText: "",
      labelText2: "",
      labelColor: "",
      dateTitle: null,
      targetIntro: null,
      buttons: {
        skip: 0,
        date: 0,
        confirm: 0,
        change: 0,
        submit_feedback: 0,
        view_feedback: 0,
        go_verify: 0,
      },
      dateHim: "",
      dateHer: "",
      dateHimAgain: "",
      dateHerAgain: "",
      secLeft: 0,
      countdownLeftTimer: null,
      countdownLeftTimer2: null,
      refPage: "",
      chatCloseText: "",
      chatCloseDesc: "",
      dateStartIndex: [],
      dateStartTimer: null,
      listQuery: {
        page: 1,
        page_size: 30,
        search_text: "",
        // test: 1,
      },
      listItemLen: 0,
      targetProfile: null,
      dateCompletedText: "",
      dateExpiredText: "",
      dateStartedFor: "",
      dateStartIn: "",
      showTargetProfile: false,
      fullViewLabel: false,
      currentDateTimeString: "",
      currentDateVenue: "",
      isNoDates: false,
      isChatExpried: 0,
      isChatConfirmed: 0,
      dateTimeHtml: "",
    };
  },
  computed: {
    rowStyle() {
      let l_span_w = $("#listContent").width();
      if (l_span_w > 0) {
        return { "max-width": `${l_span_w - 16 - 66}px` };
      }

      return {};
    },
  },
  mounted() {
    let self = this;
    let planNotNow = self.$storage.get("plan-no-now");
    self.$parent.closeLoading();
    // console.log("token=", _.get(self.$storage.get("user"), "token"));
    self.socket = io(
      //   "http://localhost:3002",
      "https://im.lovestruck.com",
      {
        //   socket: io("http://localhost:3002", {
        path: "/chat.io",
        query: {
          token: _.get(self.$storage.get("user"), "token"),
        },
      }
    );

    // setTimeout(function () {
    //   self.currentDateTimeString = "明天";
    //   self.currentDateVenue = "家里";
    //   self.confirmDatePopup();
    // }, 3000);

    $(window).on("resize", function () {
      self.resizeWin();
    });

    // self.dateHim = self.$t("date_him");
    // self.dateHer = self.$t("date_her");
    // self.dateHimAgain = self.$t("date_him_again");
    // self.dateHerAgain = self.$t("date_her_again");
    self.chatCloseDesc = self.$t("chat_close_desc");
    self.dateCompletedText = self.$t("date_completed");
    self.dateExpiredText = self.$t("date_expired");
    self.dateStartedFor = self.$t("date_started_for");
    // self.dateStartedFor = "Date ends in [left_time]";
    self.dateStartIn = self.$t("date_start_in");
    // console.log("userInfo", self.userInfo);

    let clientId = _.get(self.$route, "query.clientId", 0);
    let view = _.get(self.$route, "query.view", 0);
    let ref = _.get(self.$route, "query.ref", "");
    // console.log(clientId, self.employeeTag);

    if (!clientId && self.$route.name == "matchmaker-chat") {
      clientId = self.employeeTag;
      self.targetClient = self.myEmployee;
    }
    self.targetClientId = clientId;

    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.client = rs.client;
        if (self.client.membership_level_id <= 1) {
          self.$router.push({ name: "free-index" });
          return false;
        }
        self.myEmployee = {
          first_name: _.get(
            rs,
            "client.chat_matchamker_info.name",
            "Matchmaker"
          ),
          photo_url: _.get(
            rs,
            "client.chat_matchamker_info.photo_url",
            self.myEmployee.photo_url
          ),
        };
        // self.client.is_verified = 0;
        // if (clientId == self.employeeTag && self.client.is_verified == 0) {
        //   self.fullViewLabel = true;
        //   //   console.log("self.fullViewLabel=", self.fullViewLabel);
        //   self.canChat = false;
        //   self.buttons = {
        //     get_verify: 1,
        //   };
        //   self.labelText = self.$t("verify_profile_title");
        //   self.labelText2 = self.$t("verify_profile_desc");
        //matchmaker-chat-unerified
        //   self.$router.push({
        //     name: "matchmaker-chat-unerified",
        //   });
        //   return false;
        // } else {
        self.clientDateCredit =
          _.get(self.client, "date_credits", 0) > 0
            ? parseInt(_.get(self.client, "date_credits", 0))
            : 0;

        self.clientEventTicket =
          _.get(self.client, "event_tickets", 0) > 0
            ? parseInt(_.get(self.client, "event_tickets", 0))
            : 0;

        // console.log(self.targetClientId, self.client.membership_level_id);
        // self.client.membership_level_id = 3;
        //   if (
        //     (self.$route.name == "matchmaker-chat" ||
        //       clientId == self.employeeTag) &&
        //     self.client.membership_level_id < 3
        //   ) {
        //     self.canChat = false;
        //     self.$refs.diamondPopup.showModal();
        //     return false;
        //   }

        if (ref == "reg") {
          //   self.alertWelcome();
        }

        self.getMatchContacts();

        if (clientId == self.employeeTag) {
          //   if (self.client.membership_level_id < 2) {
          //     self.getGoldPayment(2);
          //     return false;
          //   } else {
          self.targetClient = self.myEmployee;
          self.getClientConversations(0, true);
          //   }
          //   console.log("getClientConversations for employee");
        }

        if (clientId > 0) {
          self.addContact(clientId);
          self.getClientConversations(clientId, false);
          //   if (view == 1) {
          //     self.showTargetPrifile(clientId);
          //   }
        }
        // }
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
      });

    // self.getContacts();
    // if (self.$route.name == "client-chat") {
    //   self.getMatchContacts();
    // }

    self.targetProfile = null;

    //self.$route.query

    if (ref == "reg") {
      //   self.alertWelcome();
    } else {
      self.$storage.set("chat-ref", ref);
    }

    self.refPage = self.$route.name;

    // self.getMatchContacts();

    // if (!clientId && $(window).width() >= 576) {
    //   self.chatToEmployee();
    // } else {
    // console.log("clientId=", clientId);

    // else if (clientId == self.employeeTag) {
    //   self.targetClient = self.myEmployee;
    //   self.getClientConversations(0, true);
    // }
    // }

    self.displayPlan();
    setTimeout(function () {
      self.resizeWin();
    }, 80);

    // setTimeout(function () {
    //   self.alertWelcome();
    //   self.sendFeedback();
    // }, 5000);

    // let test = [
    //   { a: "aaaa", sent_time: 99999 },
    //   { a: "bbbb", sent_time: 399999 },
    //   { a: "ccccc", sent_time: 111 },
    //   { a: "ddddd", sent_time: 799999 },
    //   { a: "eeee", sent_time: 444 },
    // ];
    // test = _.sortBy(test, [
    //   function (o) {
    //     return -o.sent_time;
    //   },
    // ]);

    // console.log("tes=", test);

    this.socket.on("message_finish", (data) => {
      console.log("message_finish from employee", data);
    });
    this.socket.on("warn", (data) => {
      console.log("warn from im", data);
    });

    this.socket.on("message", (data) => {
      console.log("get message", data);

      let fromClientId = _.get(data, "fromClientId", 0);
      let modeType = _.get(data, "modeType");
      let msgType = _.get(data, "msgType", 3);
      //   console.log("employee", self.targetClientId, modeType, data);
      if (!fromClientId) {
        let extras = JSON.parse(_.get(data, "extras", null));
        if (_.has(extras, "target_id")) {
          fromClientId = _.get(extras, "target_id", 0);
        }
      }
      //当前用户
      let hasRead = 0;
      if (fromClientId == self.targetClientId || msgType == 2) {
        hasRead = 1;
        self.conversations.push({
          type: _.get(data, "photoUrl") ? 2 : 1,
          msg: unescape(_.get(data, "message")),
          msg_type: msgType == 2 ? 2 : 1,
          to_client_id: _.get(data, "clientId", 0),
          from_client_id: msgType == 2 ? 0 : fromClientId,
          extras: _.get(data, "extras"),
          sent_time: new Date().toString(),
        });
        self.scrollToBottom();
      } //from employee
      else if (self.targetClientId == self.employeeTag && modeType == "e2c") {
        // console.log("sdfs...........d");
        hasRead = 1;
        self.conversations.push({
          type: _.get(data, "photoUrl") ? 2 : 1,
          msg: unescape(_.get(data, "message")),
          to_client_id: self.userInfo.client_id,
          from_client_id: 0,
          extras: _.get(data, "extras"),
          sent_time: new Date().toString(),
        });
        self.scrollToBottom();
      }

      if (modeType == "c2c" || msgType == 2) {
        let i = _.findIndex(self.contacts, {
          target_client_id: fromClientId,
        });
        if (i > -1) {
          let unreadCount = self.contacts[i]["unread_count"];

          self.contacts[i]["unread_count"] = hasRead ? 0 : unreadCount + 1;

          self.contacts[i]["is_read"] = hasRead;
          self.contacts[i]["extras"] = _.get(data, "extras");
          self.contacts[i]["msg"] = _.get(data, "message");
          self.contacts[i]["sent_time"] = parseInt(new Date().getTime() / 1000);
          self.contacts[i]["type"] = _.get(data, "photoUrl") ? 2 : 1;
          //   if (!hasRead) {
          //     self.setUnread("add");
          //   }

          self.contacts = self.sortBySentTime(self.contacts);
        }
      } else {
        self.employeeContacts[0]["is_read"] = hasRead;
        self.employeeContacts[0]["extras"] = _.get(data, "extras");
        self.employeeContacts[0]["msg"] = _.get(data, "message");
        self.employeeContacts[0]["sent_time"] = parseInt(
          new Date().getTime() / 1000
        );
        self.employeeContacts[0]["type"] = _.get(data, "photoUrl") ? 2 : 1;
      }
    });

    this.socket.on("message_sent", (data) => {
      console.log("message sent!", data);
    });
  },
  methods: {
    goToDiamondPayment(pageV = 9) {
      this.$refs.scheduleConsultation.showModal(2);
      //   this.$router.push({
      //     name: "membership-choose",
      //     query: { type: "diamond" },
      //     // name: "payment-plan",
      //     // params: { planName: "diamond" },
      //     // query: { top_nav: 0, page: pageV },
      //   });
    },
    saveMessageToServer() {
      let self = this;
      if (!self.message && !self.tplMessage) {
        return false;
      }
      //   self.$layer.loading();
      //   console.log("messaeg", self.message);
      let pdata = new FormData();
      //changeDateVenueInvite
      pdata.append("message", self.message ? self.message : self.tplMessage);
      pdata.append("to_client_id", _.get(self.targetClient, "client_id", 0));
      //   pdata.append("is_date_chat", 1);

      self.$api
        .postClientMessage(pdata, self.$store.state)
        .then((rs) => {
          //   console.log("success", rs);
          //   self.$layer.closeAll("loading");
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          self.$layer.msg(_.get(error, "response.data.error.body"));
          //self.afterAction(intro_id, type);
          console.log(error);
        });
    },
    chatAgain() {
      let self = this;
      if (self.client.membership_level_id <= 2) {
        self.getPlatinumPayment(0);
        return false;
      }

      let introId = _.get(self.targetIntro, "client_intro_id");
      let pdata = new FormData();

      pdata.append("intro_id", introId);
      pdata.append("target_client_id", self.targetClientId);
      pdata.append("act_type", "chat_again");

      self.$layer.loading();
      self.matchAction(introId, pdata, "chat_again");
    },
    showHtmlLable(label_array) {
      //   let label_array = _.get(label_button, "label_array");
      let htmlText = "";
      if (label_array) {
        for (let lab of label_array) {
          htmlText += `<span class="${
            lab.bold == "1" ? "fw-700" : ""
          }" style="color:${lab.color}">${lab.text}</span>`;
        }
        htmlText = htmlText.replace("[icon_chat_expired]", ``);

        return htmlText;
      }
    },
    htmlFormat(msg) {
      msg = msg.replace(/\r\n/g, "<br/>").replace(/\n/g, "<br/>");

      if (msg.indexOf("[icon_lovestruck]") > -1) {
        msg = msg.replace(
          "[icon_lovestruck]",
          `<img height="28" src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/icon_lovestruck_red.png"/>`
        );
      }

      return msg;
    },
    chatLineColor(extras) {
      let e_color = _.get(extras, "color", "");
      if (e_color) {
        return {
          color: e_color,
        };
      }
      return null;
    },
    goToSearchPage() {
      this.$router.push({
        // name: "search-list",
        name: "match-new",
      });
    },
    noDatesFound() {
      let self = this;
      self.showLeftPlan = false;
      self.showRightPlan = false;
      self.isNoDates = true;
    },
    getVerify() {
      this.$refs.scheduleConsultation.showVerifyAccountModal(1);
    },
    afterSendGreetingMessageHandler(data) {
      let self = this;
      //   console.log("data", data);
      let targetClientId = _.get(data, "to_client_id", 0);
      if (self.targetClientId == targetClientId) {
        self.getClientConversations(targetClientId, false);
      } else {
        self.$router.push({
          name: "client-web-chat",
          query: {
            clientId: targetClientId ? targetClientId : self.targetClientId,
          },
        });
      }

      self.$refs.startDatePopupCom.closeModel();
    },
    getPlatinumPayment(pageV = 1) {
      this.$router.push({
        name: "membership-upgrade",
        // name: "membership-choose",
        //     query: { type: "gold" },
        // name: "payment-plan",
        // params: { planName: "platinum" },
        // query: { top_nav: 0, page: pageV },
      });
    },
    getGoldPayment(pageV = 6) {
      this.$router.push({
        // name: "membership-choose",
        name: "membership-upgrade",
        query: { type: "gold" },
        // name: "payment-plan",
        // params: { planName: "gold" },
        // query: { top_nav: 0, page: pageV },
      });
    },
    //no use
    alertWelcome() {
      let self = this;
      let cacheKey = `isshowwel-${self.client.client_id}`;
      let isshowwel = self.$storage.get(cacheKey);
      if (isshowwel) {
        return false;
      }

      let message = self.$t("welcom_new_user_desc");
      // "Welcome to Lovestruck! Please review your new matches and suggest times to go on dates with them!";
      self.$bvModal.msgBoxOk(message, {
        title: false,
        size: "md",
        buttonSize: "sm",
        hideFooter: true,
        // okDisabled: true,
        // okTitle: false,
        okTitle: self.$t("Ok"),
        okVariant: "ls-y",
        centered: true,
        hideHeaderClose: true,
      });

      self.$storage.set(cacheKey, 1);
    },
    chatListScroll() {
      let self = this;
      let listContent = $("#listContent");
      let boxHeight = listContent.height();

      let listItemHeight = 76;
      console.log("chatListScroll");

      $("#listContent").on("scroll", function () {
        // console.log("listContent", $(this).scrollTop());
        let contentHeight = (self.listItemLen + 1) * listItemHeight;

        if (
          !self.isLoadingContact &&
          !self.isEnd &&
          contentHeight - boxHeight <= $(this).scrollTop() + 300
        ) {
          console.log("get more");
          self.getMatchContacts();
        }
      });
    },
    viewProfile() {
      let self = this;
      if (self.targetClientId == self.employeeTag) {
        return false;
      }
      //   if (self.targetProfile) {
      //     return false;
      //   }

      //profile-view
      self.$router.push({
        name: "profile-view",
        query: { from: "chat" },
        params: { clientId: self.targetClientId },
      });
    },
    scheduleNowHander() {
      this.$refs.diamondPopup.closeModel();
      this.$refs.scheduleConsultation.showModal(2);
    },
    cancelDate() {
      let self = this;
      //   console.log("skip date");

      self.$bvModal
        .msgBoxConfirm(
          self
            // .$t("cancel_date_desc")
            .$t("cancel_date_confirm_desc")
            .replace("[match_first_name]", self.targetClient.first_name),
          {
            // title: self.$t("cancel_date_txt"),
            title: false,
            headerClass: "text-secondary",
            bodyClass: "text-secondary",
            size: "md",
            buttonSize: "sm",
            okVariant: "ls-y",
            cancelVariant: "light",
            okTitle: self.$t("cancel_txt"),
            cancelTitle: self.$t("not_now"),
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          //   console.log("value=", value);
          if (value) {
            let introId = _.get(self.targetIntro, "client_intro_id");
            let fdata = new FormData();
            fdata.append("act_type", "cancel");
            fdata.append("intro_id", introId);
            console.log("cancel intro:", introId);
            self.$layer.loading();
            self.matchAction(introId, fdata, "");
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    skipDate() {
      let self = this;

      self.$bvModal
        .msgBoxConfirm(self.$t("date_skip_desn"), {
          title: `${self.$t("date_skip_title")}`,
          headerClass: "text-secondary",
          bodyClass: "text-secondary",
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: self.$t("Ok"),
          cancelTitle: self.$t("not_now"),
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          // console.log("value=", value);
          if (value) {
            //   console.log("skip date");
            let introId = _.get(self.targetIntro, "client_intro_id");
            let fdata = new FormData();
            fdata.append("act_type", "decline");
            fdata.append("intro_id", introId);
            self.$layer.loading();
            self.matchAction(introId, fdata, "");
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    afterSendFeedback(intro_id, data) {
      let self = this;
      console.log("afterSendFeedback", intro_id, data);
      //   let index = _.findIndex(self.pastdates, { client_intro_id: intro_id });
      //   let intro = _.get(self.pastdates, index);
      //   if (!intro) {
      //     return false;
      //   }

      //   self.pastdates[index]["source_user_feedback_created_time"] = 1;
      //   self.pastdates[index]["source_user_enjoy_date"] = _.get(
      //     data,
      //     "enjoy_date"
      //   );
      //   self.pastdates[index]["source_user_chemistry_rating"] = _.get(
      //     data,
      //     "chemistry_rating"
      //   );
      //   self.pastdates[index]["source_user_date_again"] = _.get(
      //     data,
      //     "date_again"
      //   );
    },
    sendFeedback() {
      let self = this;
      //   let index = _.findIndex(self.pastdates, { client_intro_id: intro_id });
      //   let intro = _.get(self.pastdates, index);
      //   if (!intro) {
      //     return false;
      //   }
      self.targetIntro.first_name = _.get(self.targetClient, "first_name");
      self.targetIntro.photo_url = _.get(self.targetClient, "photo_url");
      self.$refs.feedbackModal2.showModal(self.targetIntro, "send");
    },
    viewFeedback() {
      let self = this;
      //   let index = _.findIndex(self.pastdates, { client_intro_id: intro_id });
      //   let intro = _.get(self.pastdates, index);
      //   if (!intro) {
      //     return false;
      //   }
      self.targetIntro.first_name = _.get(self.targetClient, "first_name");
      self.targetIntro.photo_url = _.get(self.targetClient, "photo_url");
      self.$refs.feedbackModal2.showModal(self.targetIntro, "view");
    },
    startDate() {
      //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
      //   if (membership_level_id <= 2 && self.targetClient.rating_level_id >= 2) {
      //     self.$refs.platinumPopup.showModal(3);
      //     return false;
      //   } else if (
      //     membership_level_id <= 3 &&
      //     self.targetClient.is_verified == 1
      //   ) {
      //     self.$refs.diamondPopup.showModal(2);
      //     return false;
      //   }
      self.$refs.startDatePopupCom.showModal(self.targetClient);
    },
    dateAgainClick() {
      let self = this;
      let membership_level_id = _.get(self.client, "membership_level_id", 0);
      //&& self.targetClient.rating_level_id >= 2
      if (membership_level_id <= 2) {
        self.$refs.platinumPopup.showModal(3);
        return false;
      } // && self.targetClient.is_verified == 1
      else if (membership_level_id <= 3) {
        self.$refs.diamondPopup.showModal(2);
        return false;
      }
      //   self.$refs.startDatePopupCom.showModal(self.targetClient);
      self.$refs.availabilityVenueList.showModal(
        null,
        self.targetClientId,
        self.targetClient.first_name
      );
    },
    openVenueSelectPop() {
      let self = this;
      //   console.log("self.client=", self.client);
      //   return false;
      //if date not yet started, when "Change" is tapped,
      //show select date/time popup and do NOT check membership_level_id.

      let date_timestamp = _.get(self.targetIntro, "first_date_timestamp", 0);
      let nowSec = Math.round(new Date().getTime() / 1000);
      if (date_timestamp > nowSec) {
        self.$refs.availabilityVenueList.showModal(
          null,
          self.targetClientId,
          self.targetClient.first_name
        );

        return false;
      }

      let membership_level_id = _.get(self.client, "membership_level_id", 0);
      if (membership_level_id <= 2 && self.targetClient.rating_level_id >= 2) {
        self.$refs.platinumPopup.showModal(2);
        return false;
      } else if (
        membership_level_id <= 3 &&
        self.targetClient.is_verified == 1
      ) {
        self.$refs.diamondPopup.showModal(2);
        return false;
      }
      //   self.$refs.startDatePopupCom.showModal(self.targetClient);
      self.$refs.availabilityVenueList.showModal(
        null,
        self.targetClientId,
        self.targetClient.first_name
      );

      return false;

      //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
      //   if (!membership_level_id || membership_level_id <= 1) {
      //     self.$router.push({
      //       name: "payment-plan",
      //       params: { planName: "gold" },
      //       query: { top_nav: 0 },
      //     });

      //     return false;
      //   }
      //todo
      //   let dateCredits =
      //     _.get(self.client, "date_credits", 0) > 0
      //       ? parseInt(_.get(self.client, "date_credits", 0))
      //       : 0;
      //   if (dateCredits <= 0) {
      //     // self.$layer.msg(self.$t("insufficient_date_credit"));
      //     //jump to buy date credit page
      //     self.$storage.set(
      //       "venue-d-user",
      //       {
      //         client_id: _.get(self.targetClient, "client_id"),
      //         first_name: _.get(self.targetClient, "first_name"),
      //         age: _.get(self.targetClient, "age"),
      //         gender: _.get(self.targetClient, "gender"),
      //         photo_url: _.get(self.targetClient, "photo_url"),
      //       },
      //       60
      //     );

      //     setTimeout(function () {
      //       self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
      //     }, 800);

      //     return false;
      //   }

      //   self.$refs.availabilityVenueList.showModal(
      //     null,
      //     self.targetClientId,
      //     self.targetClient.first_name
      //   );
      //   self.$refs.selectEvent.showModal(0, "eventInvite", self.client);
    },
    confirmDate() {
      let self = this;
      let data = {
        venue: self.targetIntro.venue,
        dateTime: self.targetIntro.first_date_time,
        introId: self.targetIntro.client_intro_id,
        targetClientId: self.targetClientId,
        statusName: "match_confirmed",
        target: {
          client_id: _.get(self.targetClient, "client_id"),
          first_name: _.get(self.targetClient, "first_name"),
          photo_url: _.get(self.targetClient, "photo_url"),
        },
      };

      self.$refs.selectVenueDate.showModal(data);
    },
    selectVenueHander(data) {
      let self = this;

      //   let intro = self.match;

      //   if (!intro) {
      //     return false;
      //   }
      //   console.log("data=", data);

      self.$refs.availabilityVenueList.closeModel();
      data.statusName = "";
      //todo
      data.target = {
        client_id: _.get(self.targetClient, "client_id"),
        first_name: _.get(self.targetClient, "first_name"),
        photo_url: _.get(self.targetClient, "photo_url"),
      };
      //   console.log('data', data)
      self.$refs.selectVenueDate.showModal(data);
    },
    sendInviteHander(data) {
      let self = this;

      let pdata = new FormData();
      //changeDateVenueInvite
      pdata.append("intro_id", _.get(data, "introId"));
      pdata.append("target_client_id", _.get(data, "targetClientId"));
      pdata.append("act_type", _.get(data, "actType", "dateVenueInvite"));
      pdata.append("date_time", _.get(data, "dateTime"));
      pdata.append("venue_id", _.get(data, "venueId"));
      self.$layer.loading();
      self.matchAction(
        _.get(data, "introId"),
        pdata,
        _.get(data, "actType", "dateVenueInvite") ////changeDateVenueInvite
      );
    },
    changeVenueDateHander(data) {
      let self = this;
      self.$refs.availabilityVenueList.showModal(
        null,
        self.targetClientId,
        self.targetClient.first_name
      );
    },
    confirmInviteHander(data) {
      let self = this;

      let membership_level_id = _.get(self.client, "membership_level_id", 0);
      //   membership_level_id = 2;
      if (!membership_level_id || membership_level_id <= 1) {
        self.$router.push({
          //   name: "membership-choose",
          name: "membership-upgrade",
          query: { type: "gold" },
          //   name: "payment-plan",
          //   params: { planName: "gold" },
          //   query: { top_nav: 0 },
        });

        return false;
      }

      let cost = parseInt(_.get(data, "dateCreditCost", 0));

      let dateCredits =
        _.get(self.client, "date_credits", 0) > 0
          ? parseInt(_.get(self.client, "date_credits", 0))
          : 0;

      //   console.log(
      //     "dateCredits=",
      //     dateCredits,
      //     _.get(self.client, "date_credits", 0),
      //     data
      //   );

      if (dateCredits < cost) {
        // self.$layer.msg(self.$t("insufficient_date_credit"));
        //jump to buy date credit page
        self.$storage.set(
          "venue-d-user",
          _.get(data, {
            client_id: _.get(self.targetClient, "client_id"),
            first_name: _.get(self.targetClient, "first_name"),
            age: _.get(self.targetClient, "age"),
            gender: _.get(self.targetClient, "gender"),
            photo_url: _.get(self.targetClient, "photo_url"),
          }),
          60
        );

        setTimeout(function () {
          self.$router.push({ name: "payment-date", query: { top_nav: 0 } });
        }, 800);

        return false;
      }
      self.currentDateTimeString = _.get(data, "dateTimeString");
      self.currentDateVenue = _.get(data, "venueName");
      // return false;
      let introId = _.get(self.match, "client_intro_id", 0);
      let pdata = new FormData();
      //changeDateVenueInvite
      pdata.append("intro_id", introId);
      pdata.append("target_client_id", _.get(data, "targetClientId"));
      pdata.append("act_type", "doubleConfirm");

      self.$layer.loading();
      self.matchAction(introId, pdata, "doubleConfirm");
    },
    confirmDatePopup() {
      let self = this;

      self.$bvModal
        .msgBoxOk(
          self
            .$t("date_confirmed_text")
            .replace("[match_first_name]", self.targetClient.first_name)
            .replace("[first_date_time]", self.currentDateTimeString)
            .replace("[date_venue_name]", self.currentDateVenue),
          {
            title: false,
            headerClass: "text-secondary",
            bodyClass: "text-secondary",
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: self.$t("Ok"),
            //   cancelTitle: self.$t("not_now"),
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          // console.log("value=", value);
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    matchAction(intro_id, fdata, type) {
      let self = this;
      // let index = _.findIndex(self.list, { client_intro_id: intro_id });

      self.$api
        .postDateAction(fdata, self.$store.state)
        .then((rs) => {
          // console.log(rs);
          if (rs.success) {
            // console.log("rs", rs);
            let membership_level_id = _.get(
              self.client,
              "membership_level_id",
              0
            );
            if (type == "decline" && membership_level_id <= 1) {
              self.$router.push({
                // name: "membership-choose",
                name: "membership-upgrade",
                query: { type: "gold" },
                // name: "payment-plan",
                // params: { planName: "gold" },
                // query: { top_nav: 0 },
              });

              return false;
            } else {
              if (type == "cancel") {
                self.$layer.msg(
                  self
                    .$t("date_cancelled_desc")
                    .replace("[match_first_name]", self.targetClient.first_name)
                );
                setTimeout(function () {
                  location.reload();
                }, 1500);

                return false;
              }

              if (
                type == "dateVenueInvite" ||
                type == "changeDateVenueInvite"
              ) {
                // self.$layer.closeAll("loading");
                self.$refs.selectVenueDate.closeModel(false);
                self.$refs.inviteConfirmPopup.showModal(self.targetClient);
              } else if (type == "doubleConfirm") {
                //todo
                self.confirmDatePopup();
              } else if (type == "chat_again") {
                location.reload();
                return false;
              }

              setTimeout(function () {
                self.$layer.closeAll("loading");
                self.getClientConversations(self.targetClientId, false);

                if (type == "dateVenueInvite" || type == "doubleConfirm") {
                  self.$refs.selectVenueDate.closeModel(false);
                }
              }, 1000);
            }

            // self.$router.push({ name: "date-1on1" });
            // self.match.source_user_intro_status_id = _.get(
            //   rs,
            //   "newUpdates.source_user_intro_status_id"
            // );
            // self.$refs.selectVenueDate.closeModel(false);
          } else {
            self.$layer.closeAll("loading");
            // if (_.get(rs, "popup_url", false)) {
            //   self.gotoUpgrade(rs);
            // } else {
            self.$layer.msg(_.get(rs, "message", ""));
            // }
          }
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          //   self.$layer.msg(_.get(err, "response.data.error.body"));
          self.$bvModal.msgBoxOk(_.get(err, "response.data.error.body"), {
            size: "md",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: self.$t("Ok"),
            hideHeaderClose: true,
            centered: true,
          });
          //self.afterAction(intro_id, type);
          console.log(err);
        });
    },
    changeType(type) {
      let self = this;

      if (self.contacts.length > 0) {
        if (self.contactType == 2) {
          self.skippedContacts = self.contacts;
        } else {
          self.matchedContacts = self.contacts;
        }
      }
      self.contactType = type;
      self.backToList();
      if (type == 2 && self.skippedContacts.length > 0) {
        self.contacts = self.skippedContacts;
      } else if (type == 1 && self.matchedContacts.length > 0) {
        self.contacts = self.matchedContacts;
      } else {
        self.isEnd = false;
        // self.contactType = type;
        // self.backToList();
        self.getMatchContacts();
      }
    },
    toggleEmoji() {
      this.showEmoji = !this.showEmoji;
    },
    addEmo(e) {
      this.message = this.message + e.target.innerHTML;
    },
    goback() {
      //   history.back();
      let ref = this.$storage.get("chat-ref");
      if (ref) {
        location.href = ref;
      } else {
        this.$router.push({ name: "elite-list" });
      }
    },
    sortBySentTime(list) {
      return _.sortBy(list, [
        (o) => {
          return o.sent_time;
        },
      ]).reverse();
    },
    scrollToBottom() {
      let self = this;
      //   console.log("scrollToBottom");
      if (self.showTargetProfile) {
        return false;
      }
      setTimeout(function () {
        $("#contentBody").scrollTop($("#contentBody")[0].scrollHeight);
        $("#messageInput").focus();
      }, 500);

      setTimeout(function () {
        self.resizeWin();
      }, 500);
    },
    imgClick(extras) {
      let self = this;
      //   console.log("extras=", extras);
      let id = _.get(extras, "id");
      let t = _.get(extras, "t");
      switch (t) {
        case "link":
          switch (id) {
            case "schedule_consultation":
              self.$refs.scheduleConsultation.showModal(2);
              break;

            case "upgrade_gold":
              self.getGoldPayment();
              break;
          }

          break;
        case "profile":
          if (id > 0) {
            self.$router.push({
              name: "profile-view",
              query: {
                from:
                  self.targetClientId == self.employeeTag
                    ? "matchamker"
                    : "chat",
              },
              params: { clientId: id },
            });
          }
          break;
      }
    },
    backToList() {
      let self = this;

      if (self.refPage == "matchmaker-chat") {
        history.back();
        return false;
      }

      self.showLeftPlan = true;
      self.showTopNav = true;
      self.showRightPlan = false;
      self.targetClientId = 0;
      self.targetClient = null;
      self.conversations = [];
      if (_.get(self.$route, "query.clientId", 0)) {
        self.$router.push({ name: "client-web-chat" });
      }
      self.resizeWin();
    },
    chatToEmployee() {
      let self = this;

      //   if (self.client.membership_level_id < 3) {
      //     self.$refs.diamondPopup.showModal();
      //     return false;
      //   }
      //   if (self.client.membership_level_id < 2) {
      //     self.getGoldPayment(2);
      //     return false;
      //   }

      self.targetClient = self.myEmployee;
      self.targetClientId = self.employeeTag;
      self.showLeftPlan = false;
      self.showTopNav = false;
      self.showRightPlan = true;

      self.$router.push({
        name: "client-web-chat",
        query: { clientId: self.employeeTag },
      });

      self.showTargetProfile = false;
      //   if (self.$refs.profileViewCom) {
      //     self.$refs.profileViewCom.hideView();
      //   }

      self.getClientConversations(0, true);
      setTimeout(function () {
        self.resizeWin();
      }, 100);
    },
    showTargetPrifile(clientId) {
      let self = this;
      self.$layer.loading();
      self.$api
        .getClient(`id=${clientId}&from=chat`, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          self.targetProfile = rs.client;
          self.showTargetProfile = true;

          //   self.targetClient = target;
          //   self.targetClientId = "view";
          //   //   self.showLeftPlan = false;
          //   //   self.showTopNav = false;
          //   self.showRightPlan = true;
          // self.$refs.profileViewCom.showView(self.targetProfile);

          //   self.$router.push({
          //     name: "client-chat",
          //     query: { clientId: "view" },
          //   });

          //   self.resizeWin();
        });
    },
    chatToClient(item) {
      let self = this;
      let vprofile = 0;
      let nowSec = Math.round(new Date().getTime() / 1000);

      //   console.log(
      //     item.first_date_timestamp,
      //     nowSec,
      //     item.first_date_timestamp - nowSec
      //   );

      //   if (
      //     (item.first_date_timestamp == 0 ||
      //       item.first_date_timestamp - nowSec > 0) &&
      //     _.get(item, "target.client_id") > 0
      //   ) {

      // self.showTargetPrifile(target);
      // self.$router.push({
      //   name: "profile-view",
      //   params: { clientId: _.get(target, "client_id") },
      // });
      // return false;

      //     vprofile = 1;
      //     self.showTargetPrifile(_.get(item, "target.client_id"));
      //   } else {
      self.showTargetProfile = false;
      //   if (self.$refs.profileViewCom) {
      //     self.$refs.profileViewCom.hideView();
      //   }
      //   }

      self.targetClient = _.get(item, "target");
      self.targetClientId = _.get(item, "target.client_id");
      self.showLeftPlan = false;
      self.showTopNav = false;
      self.showRightPlan = true;

      self.$router.push({
        name: "client-web-chat",
        query: { clientId: self.targetClientId, view: vprofile },
      });

      self.getClientConversations(self.targetClientId, false);
      self.resizeWin();
      //   setTimeout(function () {
      //     self.resizeWin();
      //   }, 100);
    },
    setUnread(type) {
      let self = this;
      if (!self.targetClientId || self.targetClientId == self.employeeTag) {
        return false;
      }

      let index = _.findIndex(self.contacts, {
        target_client_id: self.targetClientId,
      });

      if (index > -1) {
        if (type == "clear") {
          if (_.get(self.$refs, "topName")) {
            self.$refs.topName.setClientUnread(
              "clear",
              self.contacts[index]["unread_count"]
            );
          }

          self.contacts[index]["unread_count"] = 0;
          self.contacts[index]["is_read"] = 1;
        } else {
          if (_.get(self.$refs, "topName")) {
            self.$refs.topName.setClientUnread("add", 1);
          }
          self.contacts[index]["unread_count"] += 1;
          self.contacts[index]["is_read"] = 0;
        }
      }
    },
    sendMsg() {
      let self = this;
      //   console.log(
      //     "message=",
      //     self.message,
      //     "targetClientId=",
      //     self.targetClientId
      //   );
      self.showEmoji = false;
      if (self.isSending) {
        return false;
      }
      if ((!self.message && !self.tplMessage) || !self.targetClientId) {
        return false;
      }

      self.isSending = true;
      if (self.targetClientId > 0) {
        // console.log(self.socket);
        self.socket.emit("message", {
          modeType: "c2c",
          fromClientId: self.userInfo.client_id,
          toClientId: self.targetClientId,
          message: escape(self.message),
          cityId: self.userInfo.city_id,
          extras: null,
          autoInsert: false,
        });
      } else if (self.targetClientId == self.employeeTag) {
        self.socket.emit("message", {
          modeType: "c2e",
          fromClientId: self.userInfo.client_id,
          toClientId: self.targetClientId,
          message: self.message
            ? escape(self.message)
            : escape(self.tplMessage),
          cityId: self.userInfo.city_id,
          extras: null,
          autoInsert: false,
        });

        // let i = _.findIndex(self.contacts, {
        //   target_client_id: self.targetClientId,
        // });

        // self.employeeContacts[0]["unread_count"] = 0;
        // self.employeeContacts[0]["is_read"] = 1;
        // self.employeeContacts[0]["extras"] = null;
        // self.employeeContacts[0]["msg"] = self.message;
        // self.employeeContacts[0]["sent_time"] = parseInt(
        //   new Date().getTime() / 1000
        // );
        // self.employeeContacts[0]["type"] = 1;
      }

      let i = _.findIndex(self.contacts, {
        target_client_id: self.targetClientId,
      });
      if (i > -1) {
        self.contacts[i]["unread_count"] = 0;
        self.contacts[i]["is_read"] = 1;
        self.contacts[i]["extras"] = null;
        self.contacts[i]["msg"] = self.message;
        self.contacts[i]["sent_time"] = parseInt(new Date().getTime() / 1000);
        self.contacts[i]["type"] = 1;
        self.contacts = self.sortBySentTime(self.contacts);
      }

      //   self.postClientMessage();

      self.conversations.push({
        type: 1,
        msg: self.message,
        to_client_id: self.targetClientId,
        from_client_id: self.userInfo.client_id,
        sent_time: new Date().toString(),
      });
      self.saveMessageToServer();

      self.message = "";
      self.isSending = false;

      self.scrollToBottom();
    },
    postClientMessage() {
      let self = this;

      self.$layer.loading();
      let pdata = new FormData();
      pdata.append("to_client_id", self.targetClientId);
      pdata.append("message", self.message);

      self.$api
        .postClientMessage(pdata, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          console.log("rs", rs);
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    getClientConversations(targetClientId, forEmployee) {
      let self = this;
      console.log("getClientConversations", targetClientId, forEmployee);

      self.$layer.loading();
      self.loadingConversation = true;
      //   self.targetIntro = null;
      self.$api
        .getClientConversations(
          `target_client_id=${targetClientId}&order=asc&for_employee=${forEmployee}&test=1`,
          self.$store.state
        )
        .then((rs) => {
          self.$layer.closeAll("loading");

          //   if (!self.showTargetProfile && self.$refs.profileViewCom) {
          //     self.$refs.profileViewCom.hideView();
          //   }

          self.loadingConversation = false;
          self.conversations = [];

          if (!self.targetClient && _.get(rs, "target_client")) {
            self.targetClient = _.get(rs, "target_client");
          }
          self.canChat = _.get(rs, "can_chat");
          self.buttons = _.get(rs, "buttons");
          self.targetIntro = _.get(rs, "client_intro");
          self.firstLabelText = _.get(rs, "label");
          self.dateTitle = _.get(rs, "date_title");
          self.labelColor = _.get(rs, "label_color");
          self.labelArray = _.get(rs, "label_array");
          self.isChatExpried = _.get(rs, "is_chat_expired");
          self.isChatConfirmed = _.get(rs, "is_date_confirmed");
          //   self.isChatConfirmed = 1;

          //   if (self.isChatConfirmed) {
          // let time_array = [
          //   { text: "Date on ", color: "#8d8d8d", bold: "0" },
          //   { text: "12 ", color: "#13bd50", bold: "1" },
          //   { text: "Nov ", color: "#8d8d8d", bold: "0" },
          //   { text: "15:00", color: "#13bd50", bold: "1" },
          //   { text: " @ Hong Kong", color: "#8d8d8d", bold: "0" },
          // ];
          // self.dateTimeHtml = self.showHtmlLable(time_array);
          self.dateTimeHtml = self.showHtmlLable(
            _.get(rs, "client_intro.date_time_array")
          );
          //   }

          // self.labelText = self.labelText.replace('[line]', '')
          //   self.canChat = false;
          //   self.isChatExpried = 1;

          self.secLeft = parseInt(_.get(rs, "time_left", 0));
          self.chatCloseLeftSec = parseInt(_.get(rs, "chat_close_left", 0));
          //   console.log("self.secLeft=", self.secLeft);
          if (self.secLeft > 0) {
            // self.countdownLeftTime();
          } else {
            self.labelText = _.get(rs, "label")
              .replace(/\r\n/g, "<br/>")
              .replace(/\n/g, "<br/>");
          }

          //   if (self.chatCloseLeftSec > 0) {
          //     self.countdownLeftTime2();
          //   }

          self.conversations = _.get(rs, "messages", []);
          //   console.log(
          //     "test=====",
          //     self.targetClient,
          //     self.targetClientId,
          //     self.conversations
          //   );
          //2021.09.15
          //if neither side has sent any message yet,
          //then show chat greeting popup with message "Your chat with Amy will expire in 23:12:59. Send a greeting message now!"
          if (
            self.canChat &&
            self.targetClientId > 0 &&
            self.conversations.length == 0
          ) {
            self.chatGreetingPopup();
            // if (_.get(self.targetClient, "is_verified", 0) == 0) {
            //   self.chatGreetingPopup();
            // } else {
            //   self.openVenueSelectPop();
            // }
          }
          //   console.log("conversations", self.conversations);
          //   self.setUnread("clear");
          if (forEmployee) {
            return self.$api.getClientAutoChat("", self.$store.state);
          }
          setTimeout(function () {
            self.resizeWin();

            if (self.canChat) {
              $("#msgBottom").css({
                height: `${$(".chat-input-box").outerHeight(true) + 20}px`,
              });
              //   console.log(
              //     "set 0",
              //     `${$(".chat-input-box").outerHeight(true) + 30}px`
              //   );
            } else {
              $("#msgBottom").css({
                height: `${$(".labelButtonRow").outerHeight(true) + 20}px`,
              });
              //   console.log(
              //     "set 1",
              //     `${$(".labelButtonRow").outerHeight(true) + 30}px`
              //   );
            }
          }, 800);

          self.scrollToBottom();
        })
        .then((rs) => {
          let autoChat = _.get(rs, "chat");
          if (_.get(autoChat, "type") == 1 && _.get(autoChat, "msg")) {
            self.tplMessage = _.get(autoChat, "msg", "");
            // self.sendTplMessage({ tpl_text: _.get(autoChat, "msg", "") });
            self.sendMsg();
          }
          self.scrollToBottom();
        })
        .catch((err) => {
          self.loadingConversation = false;
          self.$layer.closeAll("loading");
          //   self.setUnread("clear");
          console.log("err", err);
        });
    },
    chatGreetingPopup() {
      let self = this;

      self.$refs.startDatePopupCom.showModal(self.targetClient);
      return false;

      //   let title = self.$t("chat_greeting_popup_title");
      //   self.$bvModal
      //     .msgBoxOk(
      //       title.replace("[match_first_name]", self.targetClient.first_name),
      //       {
      //         title: false,
      //         headerClass: "text-secondary",
      //         bodyClass: "text-secondary",
      //         size: "md",
      //         buttonSize: "sm",
      //         okVariant: "primary",
      //         okTitle: self.$t("Ok"),
      //         //   cancelTitle: self.$t("not_now"),
      //         footerClass: "p-2",
      //         hideHeaderClose: true,
      //         centered: true,
      //       }
      //     )
      //     .then((value) => {
      //       // console.log("value=", value);
      //     })
      //     .catch((err) => {
      //       // An error occurred
      //       console.log(err);
      //     });
    },
    countdownLeftTime2() {
      let self = this;
      if (self.countdownLeftTimer2) {
        clearInterval(self.countdownLeftTimer2);
        self.countdownLeftTimer2 = null;
      }

      self.countdownLeftTimer2 = setInterval(function () {
        self.chatCloseLeftSec--;

        if (self.chatCloseLeftSec < 0) {
          self.chatCloseText = null;
          self.canChat = false;
          clearInterval(self.countdownLeftTimer2);
          self.countdownLeftTimer2 = null;
          self.getClientConversations(self.targetClientId, false);
        } else {
          let str = self.setLeftSec2(self.chatCloseLeftSec, false, true);
          self.chatCloseText = self.chatCloseDesc.replace("[left_time]", str);
          //   self.chatCloseText = `This chat line is closing in ${str}`;
          //   self.chatCloseText = self.chatCloseText.replace(
          // /\(.*\)/,
          // `(<span class="text-danger">${str}</span>)`
          //   );
        }
      }, 1000);
    },
    countdownLeftTime() {
      let self = this;
      if (self.countdownLeftTimer) {
        clearInterval(self.countdownLeftTimer);
        self.countdownLeftTimer = null;
      }

      self.countdownLeftTimer = setInterval(function () {
        self.secLeft--;
        if (self.secLeft < 0) {
          clearInterval(self.countdownLeftTimer);
          self.countdownLeftTimer = null;
        } else {
          let str = self.setLeftSec2(self.secLeft, false, true);
          self.labelText = self.firstLabelText
            .replace(/\r\n/g, "<br/>")
            .replace(/\n/g, "<br/>")
            .replace(
              "[left_time]",
              str
              //   `<span class="text-danger ml-1">${str}</span>`
            );
          //   self.labelText = self.labelText.replace(
          //     /\(.*\)/,
          //     `(<span class="text-danger">${str}</span>)`
          //   );
        }
      }, 1000);
    },
    setLeftSec2(leftSec, abs, showHour) {
      //   let self = this;

      if (abs) {
        leftSec = Math.abs(leftSec);
      }
      //   console.log("self.secLeft=", self.secLeft);
      if (isNaN(leftSec) && leftSec <= 0) {
        return "";
      }
      let hours = 0;
      if (showHour) {
        hours = Math.floor(leftSec / 3600);
      }
      let minutes = Math.floor((leftSec - hours * 3600) / 60);
      let sec = leftSec - hours * 3600 - minutes * 60;

      let str = `${minutes >= 10 ? minutes : "0" + minutes}:${
        sec >= 10 ? sec : "0" + sec
      }`;

      if (showHour) {
        str = `${hours >= 10 ? hours : "0" + hours}:${str}`;
      }

      //   if (hours < 0) {
      //     console.log("con", hours, minutes, sec, str);
      //   }

      return str;
      //   console.log("left sec: ", str);
    },
    setLeftSec() {
      let self = this;
      console.log("self.secLeft=", self.secLeft);
      if (isNaN(self.secLeft) && self.secLeft <= 0) {
        return false;
      }
      let hours = Math.floor(self.secLeft / 60);
      let minutes = Math.floor((self.secLeft - hours * 3600) / 60);

      self.labelText.replace("[hour_num]", hours).replace("[min_num]", minutes);
    },
    clearSearch() {
      let self = this;
      self.isEnd = false;
      self.listQuery.page = 1;
      self.listQuery.search_text = "";

      self.contacts = [];

      self.getMatchContacts();
    },
    goSearch() {
      let self = this;
      let text = $.trim(self.listQuery.search_text);
      if (!text) {
        console.log("no text");
        return false;
      }

      self.isEnd = false;
      self.listQuery.page = 1;
      self.listQuery.search_text = text;

      self.contacts = [];

      //   console.log("self.listQuery=", self.listQuery);
      //   return false;

      self.getMatchContacts();
    },
    getMatchContacts() {
      let self = this;

      //   self.contacts = [];
      if (self.isEnd || self.isLoadingContact) {
        console.log("is end=", self.isEnd);
        return false;
      }
      self.$layer.loading();
      self.isLoadingContact = true;
      let query = _.map(self.listQuery, function (value, key) {
        return `${key}=${value}`;
      }).join("&");

      self.$api
        .getClientMatchContacts(query, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          self.isLoadingContact = false;
          let len = _.get(rs, "contacts", []).length;
          let employeeContact = null;
          if (
            self.employeeContacts.length == 0 &&
            _.size(rs, "employee_contacts") > 0
          ) {
            self.hasContacts = true;
            // let employeeContact = _.get(rs, "employee_contacts.0");
            employeeContact = {
              is_matchmaker_chat: true,
              can_chat: true,
              client_msg_id: _.get(rs, "employee_contacts.0.client_msg_id"),
              extras: _.get(rs, "employee_contacts.0.extras"),
              intro: null,
              is_chat_expired: 0,
              is_date_confirmed: 0,
              label: "",
              label_array: [],
              label_color: "",
              is_read: _.get(rs, "employee_contacts.0.is_read"),
              msg: _.get(rs, "employee_contacts.0.msg"),
              sent_time: _.get(rs, "employee_contacts.0.sent_time"),
              type: _.get(rs, "employee_contacts.0.type"),
              tencent_im_groupID: _.get(
                rs,
                "employee_contacts.0.tencent_im_groupID"
              ),
              unread_count: _.get(rs, "employee_contacts.0.unread_count"),
              target_client_id: self.employeeTag,
              target: {
                client_id: self.employeeTag,
                first_name: self.myEmployee.first_name,
                photo_url: self.myEmployee.photo_url,
              },
            };

            // console.log("self.employeeContacts", employeeContact);
          }
          if (self.contacts.length == 0) {
            self.contacts.push(employeeContact);
          }

          //   self.myEmployee = {
          //     first_name: _.get(rs, "employee.name", ""),
          //     photo_url: _.get(rs, "employee.photo_url", ""),
          //     name_icon: _.get(rs, "employee.name_icon", ""),
          //   };
          //   len = 0;

          self.listItemLen += len;
          if (len > 0) {
            self.hasContacts = true;

            self.contacts = [...self.contacts, ..._.get(rs, "contacts", [])];
            self.contacts = _.sortBy(self.contacts, [
              function (o) {
                return -o.sent_time;
              },
            ]);
            // self.getDateStartIndex();

            // setTimeout(function () {
            //   self.runCountDownDateStart();
            // }, 1000);

            // console.log("dddd", self.contacts);
            if (
              self.listQuery.page == 1 &&
              !self.targetClientId &&
              $(window).width() >= 576
            ) {
              self.chatToClient(self.contacts[0]);
            }

            if (self.listQuery.page == 1) {
              self.chatListScroll();
            }
            self.listQuery.page++;
          } else {
            // self.contacts = [];
            self.isEnd = true;
            if (self.listQuery.page == 1) {
              self.noDatesFound();
            }
          }
        })
        .catch((err) => {
          self.isLoadingContact = false;
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    getDateStartIndex() {
      let self = this;
      let dateStartIndex = [];
      for (var i in self.contacts) {
        // self.contacts[i].date_completed = false;
        // self.contacts[i].date_start_in_text = null;
        // if (self.contacts[i].date_start_in > 0) {
        //   if (
        //     self.contacts[i]["intro"]["source_intro_status_id"] >= 3 &&
        //     self.contacts[i]["intro"]["target_intro_status_id"] >= 3
        //   ) {
        //     self.contacts[i]["date_start_in_text"] = self.dateCompletedText;
        //     self.contacts[i].date_completed = true;
        //     self.contacts[i]["color"] = "text-success";
        //   } else {
        //   self.contacts[i]["date_start_in_text"] = self.dateExpiredText;
        //   self.contacts[i]["color"] = "text-ls-red";
        //   self.contacts[i].date_expired = true;
        //   }
        //   self.contacts[i].date_start_in_text = self.dateCompletedText;
        //   continue;
        // }
        let label = self.contacts[i]["label"];
        self.contacts[i]["label_text"] = null;

        if (label && label.indexOf("[left_time]") == -1) {
          self.contacts[i]["label_text"] = label;
        }

        if (
          label.indexOf("[left_time]") > -1 &&
          self.contacts[i].first_date_timestamp > 0
        ) {
          dateStartIndex.push(self.contacts[i].target_client_id);
        }
      }

      self.dateStartIndex = _.uniq(dateStartIndex);
      console.log("self.dateStartIndex=", self.dateStartIndex);
    },
    runCountDownDateStart() {
      let self = this;

      if (self.dateStartTimer) {
        clearInterval(self.dateStartTimer);
        self.dateStartTimer = null;
      }
      self.dateStartTimer = setInterval(function () {
        self.countDownDateStart();
      }, 1000);
    },
    countDownDateStart() {
      let self = this;
      //   console.log("run countDownDateStart");
      if (self.dateStartIndex.length == 0) {
        console.log("dateStartIndex empty");
        if (self.dateStartTimer) {
          clearInterval(self.dateStartTimer);
          self.dateStartTimer = null;
        }

        return false;
      }
      let nowSec = Math.round(new Date().getTime() / 1000);
      let dateTime = 72 * 3600;
      for (var item of self.dateStartIndex) {
        let i = _.findIndex(self.contacts, { target_client_id: item });
        if (i > -1) {
          if (!_.has(self.contacts, `${i}.first_date_timestamp`)) {
            console.log("target_client_id has no first_date_timestamp", item);
            continue;
          }

          let date_start_in =
            self.contacts[i]["first_date_timestamp"] + dateTime - nowSec;
          let label = self.contacts[i]["label"];

          //   if (item == 137726) {
          //     console.log("label", label);
          //   }

          if (date_start_in > 0) {
            // self.contacts[i]["date_start_in"] = date_start_in;
            //   nowSec - self.contacts[i]["first_date_timestamp"] - 72 * 3600; //259200

            //started & not expired
            if (date_start_in <= dateTime) {
              if (item == self.targetClientId && !self.canChat) {
                if (!self.buttons) {
                  self.canChat = true;
                }

                // self.targetProfile = null;
                // self.showTargetProfile = false;
                // if (self.$refs.profileViewCom) {
                //   self.$refs.profileViewCom.hideView();
                // }

                if (self.canChat) {
                  self.chatCloseLeftSec = dateTime;
                  self.countdownLeftTime2();
                }

                setTimeout(function () {
                  self.resizeWin();
                }, 200);
              }

              let str = self.setLeftSec2(date_start_in, true, true);
              self.contacts[i]["can_chat"] = true;
              self.contacts[i]["label_color"] = "red";
              //   self.contacts[i][
              //     "date_start_in_text"
              //   ] = self.dateStartedFor.replace("[left_time]", str);
              self.contacts[i]["label_text"] = label.replace(
                "[left_time]",
                str
              );
            } //not yet start
            else {
              self.contacts[i]["label_color"] = "#169f46";

              date_start_in = nowSec - self.contacts[i]["first_date_timestamp"];

              self.contacts[i]["can_chat"] = false;
              let str = self.setLeftSec2(date_start_in, true, true);

              //   if (item == 137726) {
              //     console.log("str", str);
              //   }

              self.contacts[i]["label_text"] = label.replace(
                "[left_time]",
                str
              );
            }
          } //expired
          else {
            if (item == self.targetClientId && self.canChat) {
              self.chatCloseText = null;
              self.canChat = false;
              // clearInterval(self.countdownLeftTimer2);
              self.showTargetProfile = true;
              //   self.$refs.profileViewCom.showView(self.targetProfile);
              self.getClientConversations(self.targetClientId, false);
            }

            self.contacts[i]["can_chat"] = false;

            // if (
            //   self.contacts[i]["intro"]["source_intro_status_id"] >= 3 &&
            //   self.contacts[i]["intro"]["target_intro_status_id"] >= 3
            // ) {
            //   self.contacts[i]["date_start_in_text"] = self.dateCompletedText;
            //   self.contacts[i]["date_completed"] = true;
            //   self.contacts[i]["color"] = "text-success";
            // } else {
            self.contacts[i]["label_text"] = self.dateExpiredText;

            self.contacts[i]["label_color"] = "red";
            self.contacts[i]["date_expired"] = true;
            // }

            self.dateStartIndex = _.pull(self.dateStartIndex, item);
          }
        } else {
          console.log("can not found", item);
          self.contacts[i]["date_start_in"] = 0;
          self.dateStartIndex = _.pull(self.dateStartIndex, item);
        }
        self.$forceUpdate();
        // console.log(
        //   "date_start_in_text",
        //   item,
        //   self.contacts[i]["date_start_in_text"]
        // );
      }
    },
    getContacts() {
      let self = this;
      if (self.isEnd) {
        console.log("is end=", self.isEnd);
        return false;
      }
      self.$layer.loading();
      self.$api
        .getClientContacts(`page=${self.page}`, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          let len = _.get(rs, "contacts", []).length;
          if (
            self.employeeContacts.length == 0 &&
            _.size(rs, "employee_contacts") > 0
          ) {
            self.hasContacts = true;
            self.employeeContacts = _.get(rs, "employee_contacts");
          }

          if (len > 0) {
            self.hasContacts = true;
            self.contacts = [...self.contacts, ..._.get(rs, "contacts")];

            self.page++;
          } else {
            self.isEnd = true;
          }
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    addContact(targetClientId) {
      let pdata = new FormData();
      pdata.append("target_client_id", targetClientId);

      this.$api.postAddContact(pdata, this.$store.state);
    },
    removeContact(targetClientId) {
      let pdata = new FormData();
      pdata.append("target_client_id", targetClientId);

      this.$api.postRemoveContact(pdata, this.$store.state);
    },
    displayPlan() {
      let self = this;

      //   if (self.targetClientId == self.employeeTag) {
      //     self.showLeftPlan = false;
      //     self.showTopNav = false;
      //     self.showRightPlan = true;

      //     return false;
      //   }

      let wW = $(window).width();

      if (wW >= 576) {
        self.showLeftPlan = true;
        self.showTopNav = true;
        self.showRightPlan = true;
      } else {
        if (self.targetClientId) {
          self.showLeftPlan = false;
          self.showTopNav = false;
          self.showRightPlan = true;
        } else {
          self.showLeftPlan = true;
          self.showTopNav = true;
          self.showRightPlan = false;
        }
      }
    },
    resizeWin() {
      let self = this;
      let wW = $(window).width();
      let wH = $(window).height();
      let listContent = $("#listContent");
      //   console.log("can chat", self.canChat);
      //   console.log("wW=", wW, "wH=", wH, listContent.height());
      //   console.log($("#contentBox").length);
      //   console.log("userInfo", self.userInfo);
      this.displayPlan();
      let topnavH = 0;
      if (wW >= 576) {
        let contentW = $("#contentBox").outerWidth(true);

        $(".chat-input-box").css({ width: `${contentW}px` });
        // console.log("ww", contentW);
        if ($(".labelButtonRow").length > 0) {
          $(".labelButtonRow").css({ width: `${contentW}px` });
        }

        self.listContentClass = "border-right overflow-auto";
        if (wW <= 768) {
          self.listContentStyle = { height: `${wH - 56}px` };
        } else {
          topnavH = 90;
          self.listContentStyle = { height: `${wH}px` };
        }
        // if (self.targetClientId == self.employeeTag) {
        //   topnavH = 0;
        // }

        self.contentBoxStyle = null;

        // $("#listContent")
        //   .addClass("border-right overflow-auto")
        //   .css({ height: `${wH}px` });
      } else {
        $(".chat-input-box").css({ width: `${wW}px` });
        if ($(".labelButtonRow").length > 0) {
          $(".labelButtonRow").css({ width: `${wW}px` });
        }
        self.listContentClass = "";
        // listContent.removeClass("border-right overflow-auto");
        // self.contentBoxStyle = { width: `100%` };
        let lsitH = listContent.height(); //
        if (typeof lsitH == "undefined") {
          lsitH = 0;
        }
        // if (lsitH >= 0 && lsitH < wH) {
        // self.listContentStyle = { height: `${wH - 57}px` };
        let barH = $(".pageTitleBar").outerHeight(true);
        //(barH > 0 ? barH : 49) -
        let navH = $("#topNav").outerHeight(true);
        self.listContentStyle = {
          height: `${wH - (navH > 0 ? navH : 66)}px`,
        };
        // console.log(
        //   "show left",
        //   wH,
        //   $(".pageTitleBar").outerHeight(true),
        //   $("#topNav").outerHeight(true)
        // );
        //   listContent.css({ height: `${wH}px` });
        // }
        // else {
        //   self.listContentStyle = { height: `auto` };
        //   //   listContent.css({ height: `auto` });
        // }
        // listContent.parent("div").css({ width: "100%" });
      }

      let clientHeadHeight = $("#contentHead").outerHeight(true);

      $("#contentBody").css({ height: `${wH - clientHeadHeight - topnavH}px` });
      //   console.log(
      //     "contentBody=",
      //     wH -
      //       $("#contentHead").outerHeight(true) -
      //       $(".chat-input-box").outerHeight(true)
      //   );
      //   if (this.showRightPlan) {
      //     $("#contentBody").css({
      //       height: `${wH - 65 - $(".chat-input-box").outerHeight(true)}px`,
      //     });
      //   }
    },
  },
};
</script>
<style scoped>
.ls-justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.targetImg {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
}
.chatWin {
  margin-top: 90px;
}
.chatWin.matchmaker {
  margin-top: 0;
}
.redBot {
  position: absolute;
  width: 20px;
  height: 20px;
  /* background: #f52751; */
  background: #21c353;
  border-radius: 50%;
  top: 27px;
  right: 0;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.headImgBox {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  min-width: 50px;
}
.leftPlan,
.rightPlan {
  -webkit-box-flex: 0;
  position: relative;
  width: 100%;
  min-height: 1px;
  float: left;
}
.leftPlan {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}
.rightPlan {
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;
}
.rightPlan.matchmaker {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.labelButtonRow {
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #ccc;
  z-index: 3;
}
.full-view {
  bottom: 40% !important;
  border: none !important;
}
.rightPlan.matchmaker .chat-input-box {
  width: 100%;
}
.chatListBox {
  border-right: 1px solid #dee2e6;
  overflow-y: auto;
}
.backbtn2 {
  display: none !important;
}
.rightPlan.matchmaker .backbtn2 {
  display: flex !important;
}
.chatCloseText {
  /* position: absolute; */
  width: 100%;
  /* bottom: 10px; */
  text-align: center;
}
.profileCard {
  margin-bottom: 80px;
}
.expired_tag {
  position: absolute;
  bottom: -10px;
  right: 0;
}
@media (max-width: 768px) {
  .leftPlan {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .rightPlan {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .chatWin {
    margin-top: 0;
  }
}
@media (max-width: 575.98px) {
  .backbtn2 {
    display: flex !important;
  }
  .chatListBox {
    border: none;
  }
  .leftPlan {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 60px;
  }
  .rightPlan {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .chat-input-box,
  .labelButtonRow {
    width: 100%;
  }
  #contentHead {
    position: fixed;
    width: 100%;
    z-index: 9;
  }
  #contentBody {
    /* margin-top: 70px !important; */
  }
  /* .chatCloseText {
    bottom: -26px;
  } */

  .profileCard {
    margin-bottom: 125px;
  }
}
@media (max-width: 440px) {
}
</style>