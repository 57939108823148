<template>
  <div class="bg-faf min-h100">
    <topName
      ref="topNav"
      :showDateCredit="false"
      :showEventTicket="false"
      :showMobileTop="false"
    />

    <div class="pb-5 px-3 text-center page6-2">
      <div class="d-flex justify-content-between mb-3 pt-3">
        <div>&nbsp;</div>
        <div>
          <b-img
            height="20"
            src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/v12/Logo-v12@2x.png"
          ></b-img>
        </div>
        <div>
          <!-- <i
              @click="filterClick"
              class="lsicon match-filter-gold lsicon-30 cursor"
            ></i> -->
          <i
            @click="filterClick"
            class="fa-light fa-sliders-simple fs-30 text-1d"
          ></i>
        </div>
      </div>
      <div style="height: 20vh; width: 100%"></div>
      <div class="mx-auto position-relative" style="width: 80px">
        <i class="fa-thin fa-id-card text-d7c" style="font-size: 80px"></i>
        <i
          class="fa-solid fa-clock text-d7c"
          style="font-size: 40px; position: absolute; top: -12px; right: -28px"
        ></i>
      </div>
      <div style="height: 10vh; width: 100%"></div>

      <h4 class="text-ee1 mb-2 fw-600 fs-18">{{ $t("verifying_your_ID") }}</h4>
      <!-- <b-img
      width="180"
      center
      :src="`https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/${image_name}`"
      class="mb-4"
    /> -->

      <!-- <h4 class="my-4">{{$t('unverified_page_title')}}</h4> -->

      <!-- <p class="text-white">{{$t('unverified_page_desc')}}</p> -->
      <p class="text-7e">{{ $t("verifying_your_ID_desc") }}</p>

      <!-- <button @click="dateListLink" class="btn btn-ls-g rounded-pill px-4">{{$t('select_a_devent')}}</button> -->
    </div>
  </div>
</template>
<script>
import topName from "../common/topNav";
import { webSocket } from "../../utils/mixin";
export default {
  name: "matchUnverified",
  components: {
    topName,
  },
  data() {
    return {
      client: null,
      curSite: this.$store.getters.curSite,
      image_name: "verification_pending_HK.png",
    };
  },
  mounted() {
    let self = this;

    if (self.curSite.iso2 == "GB") {
      self.image_name = "verification_pending_LDN.png";
    } else if (self.curSite.iso2 == "TH") {
      self.image_name = "verification_pending_BKK.png";
    }

    self.$parent.closeLoading();
    self.initSocket();
    self.$api.getClientMe(self.$store.state).then((rs) => {
      self.client = _.get(rs, "client");
    });
  },
  mixins: [webSocket],
  methods: {
    filterClick() {
      this.$router.push({
        name: "profile-requirement",
      });
    },
    dateListLink() {
      this.$router.push({ name: "date-event-list" });
    },
  },
};
</script>