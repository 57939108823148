<template>
    <div class="bg-black min-h100 text-white">
        <div class="p-4">
        Yoti verify result:
        <p v-for="(item, index) in result" :key="index">
        {{index}}:{{item}}
        </p>
    </div>
    </div>
</template>
<script>
export default {
    name: "yoti-result",
    data(){
        return {
            result: null
        }
    },
    mounted(){
        let self = this;
        self.result = _.get(self.$route, "query", '');
    }
}
</script>