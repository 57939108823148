<template>
  <div>
    <!-- <pageTop ref="pageTop" classNames="mx-auto col-lg-4 col-md-5 col-sm-12" /> -->
    <div class="" style="
        max-width: 480px;
        display: block;
        position: absolute;
        top: 12px;
        z-index: 1000;
        width: 100%;
        text-align: right;
        left: 50%;
    transform: translateX(-50%);
      ">
      <div class="">
        <a href="javascript:;" @click="goback" class="p-2 mr-2">
          <!-- <i class="fa fa-close text-999 fw-300 fs-40"></i> -->
          <i class="lsicon lsicon-30 close_grey2 "></i>
        </a>

        <div></div>
      </div>
    </div>
    <div v-if="client && plans" class="py-4">
      <div class="mx-auto col-lg-4 col-md-5 col-sm-12 px-0 page-div" style="max-width: 480px !important">
        <!-- <div class="text-center fw-700 mb-4 text-capitalize">
          {{ $t("choose_membership") }}
        </div> -->
        <div v-if="verison == 'v2'" class="d-flex justify-content-around">
          <!-- :variant="chooseMembership == 'basic' ? 'ls-red2' : 'secondary'" -->
          <!-- <div v-if="membership_level_id <= 1">
            
            <a
              href="javascript:;"
              @click="changeMembership('basic', 0)"
              class="fw-600 border-0"
            >
              <b-img
                v-if="chooseMembership == 'basic'"
                :src="`${cdnBase}${membershipTags.basic[langCode]['active']}`"
                height="28"
              />
              <b-img
                v-else
                :src="`${cdnBase}${membershipTags.basic[langCode]['inactive']}`"
                height="28"
              />
            </a>
            <div
              style="height: 3px; width: 60px"
              class="mx-auto mt-2"
              :class="chooseMembership == 'basic' ? 'bg-ls-red' : ''"
            ></div>
          </div> -->

          <div v-if="membership_level_id <= 2">
            <!-- :class="chooseMembership == 'gold' ? 'bg-gold text-dred' : ''" -->
            <a href="javascript:;" @click="changeMembership('gold', 0)" class="fw-600 border-0">
              <b-img v-if="chooseMembership == 'gold'" :src="`${cdnBase}${membershipTags.gold[langCode]['active']}`"
                height="28" />
              <b-img v-else :src="`${cdnBase}${membershipTags.gold[langCode]['inactive']}`" height="28" />
            </a>
            <div style="height: 3px; width: 60px" class="mx-auto mt-2"
              :class="chooseMembership == 'gold' ? 'bg-ls-red' : ''"></div>
          </div>
          <div v-if="membership_level_id <= 9">
            <!-- :class="chooseMembership == 'diamond' ? 'bg-dia' : ''" -->
            <a href="javascript:;" @click="changeMembership('diamond', 1)" class="fw-600 border-0">
              <b-img v-if="chooseMembership == 'diamond'" :src="`${cdnBase}${membershipTags.diamond[langCode]['active']}`"
                height="28" />
              <b-img v-else :src="`${cdnBase}${membershipTags.diamond[langCode]['inactive']}`" height="28" />
            </a>
            <div style="height: 3px; width: 60px" class="mx-auto mt-2"
              :class="chooseMembership == 'diamond' ? 'bg-ls-red' : ''"></div>
          </div>
        </div>

        <div class="swiper-container" id="membershipSwiper">
          <div class="swiper-wrapper mb-3">
            <!-- <div
              v-if="membership_level_id <= 1"
              class="swiper-slide membership-item"
            >
              <div class="basic basic_gradient_bg mb-5">
                <div
                  class="
                    basic_items
                    bg-light
                    w-75
                    mx-auto
                    rounded
                    p-3
                    border border-ls-c
                  "
                >
                  <p class="mb-3 mt-0">
                    <i class="fas fa-check text-ls-red2 mr-2"></i>
                    {{ $t("receive_custom_matches") }}
                  </p>
                  <p class="mb-3">
                    <i class="fas fa-check text-ls-red2 mr-2"></i>
                    {{ $t("chat_with_matches") }}
                  </p>
                  <p>
                    <i class="fas fa-check text-ls-red2 mr-2"></i>
                    {{ $t("profile_improvement") }}
                  </p>
                </div>
              </div>
              
              <div class="text-center mb-4">
                <p class="fw-600 font-1_1rem">
                  {{ selectedMonth }} {{ $t("n_month") }}: {{ currency.symbol
                  }}{{
                    formatPrice(plans.basic.prices[0][`${selectedMonth}_month`])
                  }}
                </p>
                <p>
                  {{ currency.symbol
                  }}{{
                    formatPrice(plans.basic[`price_pre_${selectedMonth}month`])
                  }}/{{ $t("1_month") }}
                </p>
              </div>
              <div class="text-center">
                <p class="text-muted fw-600 mb-3">
                  {{ $t("recurring_billing") }}
                </p>
                <p class="text-muted mb-3 font-13">
                  {{ $t("recurring_billing_desc") }}
                </p>

                <b-button
                  @click="submitPayment('basic')"
                  variant="ls-red2"
                  class="px-5 fw-600 border-0 text-uppercase"
                  pill
                  >{{ $t("subscribe_txt") }}</b-button
                >
              </div>
            </div> -->

            <div v-if="membership_level_id <= 2" class="swiper-slide membership-item">
              <div class="px-4">
                <img :src="goldLogo" style="width: 80%;" />
                <div class="bg-gold my-2" style="height: 8px;width: 80%;"></div>
                <div v-if="show_100_off">
                  <h4 class="fw-700 mb-1">{{ $t('1m_free_trial') }}</h4>
                  <h4 class="text-ls-y2 fw-700 font-italic mb-3">{{ $t('today_only') }}</h4>
                  <p class="fw-600 font-1_1rem mb-0">{{ $t('get_1stm_free') }}</p>
                  <p class="fw-600 font-1_1rem my-0 text-lsgray">{{ $t('then_txt') }} {{ currency.symbol
                  }}{{
  formatPrice(plans.gold[`price_pre_${selectedMonth}month`])
}}/{{ $t("pre_month") }}</p>
                </div>
                <div v-if="show_50_off">
                  <h4 class="fw-700 mb-1">{{ $t('get_50_off') }}</h4>
                  <h4 class="text-ls-y2 fw-700 font-italic mb-1">{{ $t('today_only') }}</h4>
                  <div>
                    <p class="fw-600 font-1_1rem my-1">
                      {{ $t("first_month") }}: {{ currency.symbol
                      }}{{
  formatPrice(
    plans.gold[`price_50off_${selectedMonth}month`]
  )
}}
                    </p>
                    <p class="fw-600 font-1_1rem my-1 text-lsgray">
                      {{ $t("event_normally") }}: {{ currency.symbol
                      }}{{
  formatPrice(plans.gold[`price_pre_${selectedMonth}month`])
}}/{{ $t("pre_month") }}
                    </p>
                    <!-- <p class="fw-600 font-1_1rem d-inline-block ml-3">
                    <span
                      class="text-lsgray"
                      style="text-decoration: line-through"
                      >{{ currency.symbol
                      }}{{
                        formatPrice(
                          plans.gold.prices[0][`${selectedMonth}_month`]
                        )
                      }}</span
                    ><br />
                    {{ currency.symbol
                    }}{{
                      formatPrice(
                        plans.gold[`price_50off_${selectedMonth}month`]
                      )
                    }}
                  </p> -->
                    <!-- <p>
              {{ currency.symbol
              }}{{
                formatPrice(plans.gold[`price_pre_${selectedMonth}month`])
              }}/{{ $t("1_month") }}
            </p> -->
                  </div>
                </div>
              </div>

              <div class="my-3 px-4">
                <div v-for="item in goldcarousels" :key="item.index">
                  <div class="d-flex justify-content-start mb-3">
                    <div class="mr-3">
                      <b-img :src="item.icon" width="40" />
                    </div>
                    <div>
                      <h6 class="mb-0">{{ item.title }}</h6>
                      <p class="line_height_20 fs-13 mt-1">{{ item.desc }}</p>
                    </div>
                  </div>
                </div>

                <!-- <div class="gold gold_gradient_bg">
                  <div
                    class="
                      gold_items
                      bg-light
                      w-75
                      mx-auto
                      rounded
                      p-3
                      border border-ls-c
                    "
                  >
                    <p class="mb-2 mt-0">
                      <i class="fas fa-check text-ls-y mr-2"></i>
                      {{ $t("receive_custom_matches") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-y mr-2"></i>
                      {{ $t("chat_with_matches") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-y mr-2"></i>
                      {{ $t("profile_improvement") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-y mr-2"></i>
                      {{ $t("dia_perfect_match") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-y mr-2"></i>
                      {{ $t("rematch_txt") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-y mr-2"></i>
                      {{ $t("activity_reports") }}
                    </p>
                    <p>
                      <i class="fas fa-check text-ls-y mr-2"></i>
                      {{ $t("city_travel") }}
                    </p>
                  </div>
                </div> -->
              </div>
              <!-- style="margin-top: 45px" -->
              <div v-if="fromAndroid" style="height: 30px"></div>
              <div class="text-center mb-3">
                <p v-if="show_50_off && time_count" class="font-italic fw-500" v-html="offerEndInText">
                  <!-- Offer ends in
                  <span class="fw-600"
                    >{{ time_count.h }}:{{ time_count.m }}:{{
                      time_count.s
                    }}</span
                  > -->
                </p>
                <!-- <p class="fw-600 font-1_1rem">
                  {{ selectedMonth }} {{ $t("n_month") }}: {{ currency.symbol
                  }}{{
                    formatPrice(plans.gold.prices[0][`${selectedMonth}_month`])
                  }}
                </p>
                <p>
                  {{ currency.symbol
                  }}{{
                    formatPrice(plans.gold[`price_pre_${selectedMonth}month`])
                  }}/{{ $t("1_month") }}
                </p> -->
              </div>
              <div class="text-center">
                <!-- <p class="text-muted fw-600 mb-3">
                  {{ $t("recurring_billing") }}
                </p>
                <p class="text-muted mb-3 font-13">
                  {{ $t("recurring_billing_desc") }}
                </p> -->

                <b-button @click="submitPayment('gold')" variant="light"
                  class="bg-gold text-dred px-5 fw-600 text-uppercase btn-lg" pill>{{ $t("web_lbl_nextsteps")
                  }}</b-button>
                <!-- <div class="my-3">
                    <a
                href="javascript:;"
                @click="goback"
                class="btn text-979 ff-open"
                >{{ $t("not_now") }}</a
              >
                </div> -->

              </div>
              <div style="width: 100%; height: 50px"></div>
            </div>

            <div v-if="verison == 'v2' && membership_level_id <= 9" class="swiper-slide membership-item">
              <div class="my-5 px-4">
                <div v-for="item in diamondCarouselItems" :key="item.index">
                  <div class="d-flex justify-content-start mb-4">
                    <div class="mr-3">
                      <b-img :src="item.icon" width="50" />
                    </div>
                    <div>
                      <h6 class="mb-1">{{ item.title }}</h6>
                      <p class="line_height_20">{{ item.desc }}</p>
                    </div>
                  </div>
                </div>

                <div class="text-center mb-5">
                  <div class="my-3">{{ $t("include_all_gold") }}:</div>
                  <b-img src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/payment/gold_upgrade_icons3.png"
                    width="300" />
                  <div class="mt-3">
                    {{ $t("genuine_matches") }}, {{ $t("match_and_chat") }}, {{ $t("match_boost") }},
                    {{ $t("dia_perfect_match") }}, {{ $t("profile_verify") }}
                    <!-- <span v-for="item in goldcarousels" :key="item.index">
                      {{ item.title }},
                    </span> -->
                  </div>
                </div>

                <!-- <div class="diamond dia_gradient_bg">
                  <div
                    class="
                      diamond_items
                      bg-light
                      w-75
                      mx-auto
                      rounded
                      p-3
                      border border-ls-c
                    "
                  >
                    <p class="mb-2 mt-0">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("receive_custom_matches") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("chat_with_matches") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("profile_improvement") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("dia_perfect_match") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("rematch_txt") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("activity_reports") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("city_travel") }}
                    </p>

                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("dia_personal_matchmaker") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("dia_match_boost") }}
                    </p>
                    <p class="mb-2">
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("dia_privacy_mode") }}
                    </p>
                    <p>
                      <i class="fas fa-check text-ls-dia mr-2"></i>
                      {{ $t("match_with_elites") }}
                    </p>
                  </div>
                </div> -->
              </div>
              <div v-if="fromAndroid" style="height: 30px"></div>
              <!-- <div v-else style="height: 15px"></div> -->
              <!-- style="margin-top: 80px" -->
              <div class="text-center">
                <!-- <p class="mb-5 fw-400">
                  {{ $t("schedule_diamond_call") }}
                </p> -->
                <b-button @click="diamondBook" variant="light" class="bg-dia text-white px-5 fw-600 mb-5 text-uppercase"
                  pill>{{ $t("free_consultation") }}</b-button>
              </div>

              <div style="width: 100%; height: 200px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <scheduleConsultation ref="scheduleConsultation" /> -->
  </div>
</template>
<script>
import helper from "../../utils/helper";
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}
// import scheduleConsultation from "../common/scheduleConsultation";
// import pageTop from "../common/pageTop";
export default {
  name: "membership-choose",
  components: {
    // scheduleConsultation,
    // pageTop,
  },
  data() {
    return {
      verison: 'v3',
      timer: null,
      time_count: null,
      timeLeft: 0,
      show_50_off: false,
      show_100_off: false,
      fromAndroid: false,
      client: null,
      membership_level_id: 0,
      chooseMembership: "diamond",
      query_membership_name: "",
      basicItems: null,
      goldItems: null,
      diamondItems: null,
      plans: null,
      currency: null,
      membershipSwiper: null,
      selectedMonth: 1,
      userInfo: this.$storage.get("userInfo"),
      userData: null,
      swiperOption: {
        //init:false,
        speed: 500,
        // loop: true,
        initialSlide: 0,
        direction: "horizontal",
        // autoplay: {
        //   delay: 7000,
        //   disableOnInteraction: false,
        // },
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        //   bulletActiveClass: "ls-silver-bullet-active",
        // },
      },
      langCode: "en_US",
      cdnBase:
        "https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/",
      membershipTags: {
        basic: {
          en_US: {
            active: `Basic_solo.png`,
            inactive: `Basic_grey_solo.png`,
          },
          zh_CN: {
            active: `Basic_solo_CN.png`,
            inactive: `Basic_grey_solo_CN.png`,
          },
          zh_HK: {
            active: `Basic_solo_ZH.png`,
            inactive: `Basic_Grey_solo_ZH.png`,
          },
          th: {
            active: `Basic_solo_TH.png`,
            inactive: `Basic_grey_solo_TH.png`,
          },
        },
        gold: {
          en_US: {
            active: `Gold_solo.png`,
            inactive: `Gold_grey_solo.png`,
          },
          zh_CN: {
            active: `Gold_solo_CN.png`,
            inactive: `Gold_grey_solo_CN.png`,
          },
          zh_HK: {
            active: `Gold_solo_ZH.png`,
            inactive: `Gold_grey_solo_ZH.png`,
          },
          th: {
            active: `Gold_solo_TH.png`,
            inactive: `Gold_grey_solo_TH.png`,
          },
        },
        diamond: {
          en_US: {
            active: `Diamond_solo.png`,
            inactive: `Diamond_grey_solo.png`,
          },
          zh_CN: {
            active: `Diamond_solo_CN.png`,
            inactive: `Diamond_grey_solo_CN.png`,
          },
          zh_HK: {
            active: `Diamond_solo_ZH.png`,
            inactive: `Diamond_Grey_solo_ZH.png`,
          },
          th: {
            active: `Diamond_solo_TH.png`,
            inactive: `Diamond_grey_solo_TH.png`,
          },
        },
      },
      goldcarousels: [],
      diamondCarouselItems: [],
      goldLogo: '',
      offerEndIn: this.$t('offer_end_in'),
      offerEndInText: ''
    };
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();
    console.log('.....sdfsdfsdfds....')
    // self.divideNumber(159.99, 2, 1);

    setTimeout(function () {
      $("body").removeClass("noy-scroll");
    }, 500);

    let top_nav = _.get(self.$route, "query.top_nav", 1);
    let from = _.get(self.$route, "query.from", "");
    if (from == "android") {
      self.fromAndroid = true;
    } else {
      self.fromAndroid = false;
    }
    setTimeout(function () {
      $("body").off("scroll")
    }, 500)
    self.goldLogo = helper.getMembershipPhotoById(3);
    // console.log('self.goldLogo=', self.goldLogo)

    // if (top_nav == 0) {
    //   self.$refs.pageTop.hide();
    // } else {
    //   self.$refs.pageTop.setPageTitle(self.$t("choose_membership"));
    // }

    self.langCode = self.$storage.get("cur-lang");
    self.query_membership_name = _.get(self.$route, "query.type", null);

    self.$layer.loading();
    self.goldcarousels = helper.goldCarouselItems(self);
    // self.goldcarousels = helper.renewPlusCarousel(self);
    // self.diamondCarouselItems = helper.renewDiamondCarousel(self);
    self.diamondCarouselItems = helper.diamondCarouselItems(self);
    clearInterval(self.timer);
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.$layer.closeAll("loading");
        self.client = _.get(rs, "client");
        self.membership_level_id = _.get(rs, "client.membership_level_id");
        self.membership_level_id = 2;
        let city_free_trial_offer = _.get(self.client, 'city_free_trial_offer', 0)
        if (city_free_trial_offer == 1) {
          self.show_100_off = true;
          self.show_50_off = false;
        } else {
          self.show_100_off = false;
          self.show_50_off = true;
        }
        // self.show_50_off = false;
        // self.show_100_off = true;
        if (self.membership_level_id < 2) {
          self.membership_level_id = 1; // test
          self.check50offTime();
        }

        self.setChooseMembership();

        // console.log("langCode=", self.langCode);
        // self.basicItems = helper.diamondCarouselItems()
        self.loadMembershipData();
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
        console.log(["me error", err]);
      });
  },
  methods: {
    goback() {
      //   this.$router.back();
      this.$router.push({
        name: "free-index"
      })
    },
    check50offTime() {
      let self = this;
      //   let now = new Date();
      //   let created_time = new Date(self.client.created_time);
      //   let created_time = new Date("2022-05-11 14:27:15");
      //   let diff = now.getTime() - created_time.getTime();
      let time24 = 24 * 3600 * 1000;
      //   console.log(diff, time24);
      //   if (diff < time24) {
      self.timeLeft = time24;
      //   self.show_50_off = true;
      self.runTimer();
      //   } else {
      //     self.show_50_off = false;
      //   }
    },
    runTimer() {
      let self = this;
      self.timer = setInterval(function () {
        if (!self.timeLeft || self.timeLeft <= -1) {
          clearInterval(self.timer);
          //   self.show_50_off = false;s
        } else {
          self.time_count = helper.setLeftSec(Math.floor(self.timeLeft / 1000));
          self.timeLeft -= 1000;
          self.offerEndInText = self.offerEndIn.replace('[time_count]', `<span class="fw-600"
                    >${self.time_count.h}:${self.time_count.m}:${self.time_count.s}</span>`)
        }
      }, 1000);
    },
    formatPrice(price_val) {
      // console.log('price_val=', price_val)
      return helper.currenyPriceFormat(
        price_val,
        _.get(this.currency, "show_decimals")
      );
    },
    setChooseMembership() {
      let self = this;
      // if (self.membership_level_id <= 1) {
      //   self.chooseMembership = "basic";
      // } else
      if (self.membership_level_id <= 2) {
        self.chooseMembership = "gold";
      } else if (self.membership_level_id >= 3) {
        self.chooseMembership = "diamond";
      }
    },
    getNameByIndex(index) {
      let self = this;

      if (self.membership_level_id <= 1) {
        return _.get(
          {
            // 0: "basic",
            0: "gold",
            1: "diamond",
          },
          index
        );
      } else if (self.membership_level_id == 2) {
        return _.get(
          {
            0: "gold",
            1: "diamond",
          },
          index
        );
      } else if (self.membership_level_id >= 3) {
        return _.get(
          {
            0: "diamond",
          },
          index
        );
      }
    },
    getIndexs() {
      let self = this;

      if (self.membership_level_id <= 1) {
        return {
          //   basic: 0,
          gold: 0,
          diamond: 1,
        };
      } else if (self.membership_level_id == 2) {
        return {
          gold: 0,
          diamond: 1,
        };
      } else if (self.membership_level_id >= 3) {
        return {
          diamond: 0,
        };
      }
    },
    diamondBook() {
      this.$refs.scheduleConsultation.showModal(2);
    },
    submitPayment(membership_name) {
      let self = this;

      let coupon_id = null;
      if (self.show_50_off) {
        coupon_id = 'free_50%_off'
      } else if (self.show_100_off) {
        coupon_id = 'free_100%_off'
      }

      self.$storage.setPaymentSelectedPlan(
        JSON.stringify({
          productType: "upgrade",
          plan_name: membership_name,
          plan_month: self.selectedMonth,
          product: self.plans[membership_name],
          currency: self.currency,
          client_id: _.get(self.userInfo, "client_id", 0),
          user_data: self.userData,
          coupon: coupon_id,
        })
      );

      self.$router.push({
        name: "payment-checkout",
        query: { lang: self.langCode, top_nav: 0 },
      });
      return false;
    },
    changeMembershipByName(membership_name) {
      let self = this;
      let map = {
        basic: 0,
        gold: 1,
        diamond: 2,
      };
      let index = _.get(map, membership_name, 0);
      self.changeMembership(membership_name, index);
    },
    changeMembership(membership_name, index) {
      let self = this;

      self.chooseMembership = membership_name;

      if (self.membershipSwiper && self.membershipSwiper.activeIndex != index) {
        self.membershipSwiper.slideTo(index, 100, false);
      }
    },
    startSwiper() {
      let self = this;

      let initialSlideVal = 0;

      let indexs = self.getIndexs();

      let membership_name = self.chooseMembership;
      if (
        self.query_membership_name &&
        self.query_membership_name != self.chooseMembership
      ) {
        membership_name = self.query_membership_name;
      }

      initialSlideVal = _.get(indexs, membership_name, 0);
      //   console.log(
      //     "indexs=",
      //     indexs,
      //     self.chooseMembership,
      //     membership_name,
      //     "initialSlideVal=",
      //     initialSlideVal
      //   );

      self.swiperOption.initialSlide = initialSlideVal;
      self.swiperOption.on = {
        slideChangeTransitionEnd: function () {
          //   console.log(this.activeIndex);
          let name = self.getNameByIndex(this.activeIndex);
          self.changeMembership(name, this.activeIndex);
          //   console.log("name=", name);
          //   if (this.activeIndex == 0) {
          //     self.changeMembership("basic", this.activeIndex);
          //   } else if (this.activeIndex == 1) {
          //     self.changeMembership("gold", this.activeIndex);
          //   } else if (this.activeIndex == 2) {
          //     self.changeMembership("diamond", this.activeIndex);
          //   }
        },
      };

      setTimeout(function () {
        self.membershipSwiper = new VueAwesomeSwiper.Swiper(
          "#membershipSwiper",
          self.swiperOption
        );
      }, 10);
    },

    parseMembershipLevelRow(row) {
      let self = this;
      console.log('parseMembershipLevelRow', row, self.currency)
      row["price_pre_1month"] = _.get(row, "price_row.1_month", 0);

      row["price_pre_3month"] = helper.divideNumber(_.get(row, "price_row.3_month", 0), 3, self.currency.show_decimals)
      row["price_pre_6month"] = helper.divideNumber(_.get(row, "price_row.6_month", 0), 6, self.currency.show_decimals)
      row["price_pre_12month"] = helper.divideNumber(_.get(row, "price_row.12_month", 0), 12, self.currency.show_decimals)

      row["price_50off_1month"] = helper.divideNumber(_.get(row, "price_row.1_month", 0), 2, self.currency.show_decimals)
      row["price_50off_3month"] = helper.divideNumber(_.get(row, "price_row.3_month", 0), 2, self.currency.show_decimals)
      row["price_50off_6month"] = helper.divideNumber(_.get(row, "price_row.6_month", 0), 2, self.currency.show_decimals)
      row["price_50off_12month"] = helper.divideNumber(_.get(row, "price_row.12_month", 0), 2, self.currency.show_decimals)



      row["save_3month"] = Math.round(
        ((row["price_pre_1month"] - row["price_pre_3month"]) * 100) /
        row["price_pre_1month"]
      );

      row["save_6month"] = Math.round(
        ((row["price_pre_1month"] - row["price_pre_6month"]) * 100) /
        row["price_pre_1month"]
      );

      row["save_12month"] = Math.round(
        ((row["price_pre_1month"] - row["price_pre_12month"]) * 100) /
        row["price_pre_1month"]
      );
      //   console.log("row", row);


      return row;
    },
    parseMembershipLevels: function (levels) {
      let self = this;
      let data = {
        basic: {},
        gold: {},
        diamond: {},
      };
      self.diamondSelects = [];
      self.hasNullPricesCount = 0;
      for (let v of levels) {
        let leveId = parseInt(v.membership_level_id);

        if (leveId == 2) {
          if (!_.get(v, "prices.0")) {
            self.hasNullPricesCount++;
            // console.log("has no prices", self.hasNullPricesCount);
          }
          data["basic"] = self.parseMembershipLevelRow(v);
        } else if (leveId == 3) {
          if (!_.get(v, "prices.0")) {
            self.hasNullPricesCount++;
            // console.log("has no prices", self.hasNullPricesCount);
          }
          data["gold"] = self.parseMembershipLevelRow(v);
        } else if (leveId >= 4) {
          //   if (!_.get(v, "prices.0")) {
          //     self.hasNullPricesCount++;
          //     // console.log("has no prices", self.hasNullPricesCount);
          //   }

          data[`diamond_${leveId}`] = self.parseMembershipLevelRow(v);
          //
        }
      }

      // console.log("nextBtnClass", self.nextBtnClass);
      // console.log(data);
      return data;
    },
    loadMembershipData() {
      let self = this;
      // console.log("loadInitData...");
      self.$layer.loading();
      self.$api
        .getMembershipLevels("", self.$store.state)
        .then((rs) => {
          //   console.log(rs);
          if (_.has(rs, "levels")) {
            // console.log("has levels");
            self.currency = _.get(rs, "currency");
            self.plans = self.parseMembershipLevels(_.get(rs, "levels"));
            // console.log(self.currency, self.plans);
            // self.startSwiper();
            if (
              self.query_membership_name &&
              self.query_membership_name != self.chooseMembership
            ) {
              setTimeout(function () {
                self.changeMembershipByName(self.query_membership_name);
              }, 300);
            }
          }
          self.$layer.closeAll("loading");
        })
        .catch((err) => {
          self.$layer.closeAll("loading");

          // console.log(["level error", err]);
        });
    },
  },
};
</script>
<style scoped>
.basic,
.gold,
.diamond {
  position: relative;
  height: 140px;
  margin-top: 50px;
}

.gold {
  height: 225px;
}

.diamond {
  height: 320px;
}

.basic_items,
.gold_items,
.diamond_items {
  position: relative;
  top: -20px;
  /* left: 50%;
  transform: translateX(-50%); */
  margin: 0 auto;
}

.basic_gradient_bg {
  background: linear-gradient(354deg, transparent 72px, rgb(230, 30, 90) 0);
}

.gold_gradient_bg {
  background: linear-gradient(352deg, transparent 71px, rgb(213, 179, 111) 0);
}

.dia_gradient_bg {
  /* background: linear-gradient(352deg, transparent 71px, rgb(98, 146, 198) 0); */
  background: url(https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/dia_gradient_bg.png) no-repeat;
  background-size: cover;
}
</style>