<template>
  <div>
    <div v-if="show" class="backdrop" :class="isBlackBg ? 'bg-faf' : 'bg-faf'">
      <div class="page6-2 py-3">
        <a href="javascript:;" @click.stop="closeModal" class="p-2">
          <!-- <i class="lsicon close_white"></i> -->
          <i class="fa-light fa-xmark fs-30 text-ee1"></i>
        </a>
      </div>
      <div class="body text-1d">
        <!-- <div class="position-absolute" style="top: 15px;
    left: 15px;
    font-size: 35px;">
        <a href="javascript:;" @click.stop="closeModal" class="p-2">
          <i class="lsicon close_white"></i>
        </a>
      </div> -->

        <div class="text-center">
          <h1 v-if="isLikeSent" class="text-ee1 fw-600 mb-4">LIKE SENT!</h1>
          <div
            class="mx-auto rounded-circle mb-4"
            style="width: 100px; height: 100px; overflow: hidden"
          >
            <b-img :src="match.primary_photo" class="w-100"></b-img>
          </div>
          <div
            v-if="isPaid && hasChatFromMatch == 0"
            class="rounded-10 mx-auto bg-faf p-3 mb-5"
          >
            <p class="mb-3" v-html="popupTitle"></p>

            <!-- <b-input-group class="my-3">
                                
                                <b-input-group-append>
                                
                                </b-input-group-append>
                            </b-input-group> -->
            <div class="position-relative my-3">
              <div class="w-100">
                <b-form-input
                  v-model="message"
                  @input="checkInput"
                  :placeholder="$t('type_message')"
                  id="greetInput"
                  class="v6-2 pl-3 btn-pill"
                ></b-form-input>
              </div>
            </div>

            <div class="d-flex justify-content-around w-50 mx-auto">
              <button
                v-for="item in emojis"
                :key="item"
                @click="emojiClick(item)"
                type="button"
                class="btn btn-outline-secondary emoji"
              >
                {{ item }}
              </button>
            </div>

            <div class="text-right">
              <b-button
                style=""
                pill
                variant="ee1"
                @click="sendStartMessage"
                class="o-btn lg"
                :class="message ? '' : 'disabled'"
                ><i
                  class="fa-light fa-paper-plane-top fs-24"
                  :class="message ? 'text-white' : 'text-faf'"
                  style="position: relative; top: 1px; left: 2px"
                ></i>
              </b-button>
            </div>
          </div>

          <div
            v-if="isPaid && hasChatFromMatch > 0"
            class="rounded-10 mx-auto bg-faf p-3 mb-5"
          >
            <div class="d-flex justify-content-center mb-4">
              <div>
                <b-img
                  width="50"
                  src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.0/new_message_icon.png"
                ></b-img>
              </div>
              <div
                class="d-flex align-items-center"
                v-html="match_sent_msg"
              ></div>
            </div>

            <b-button variant="ee1" class="px-5 l-space2 text-uppercase">{{
              $t("view_message")
            }}</b-button>
          </div>

          <div v-if="!isPaid" class="rounded-10 mx-auto bg-white p-3 mb-5">
            <p class="mb-3" v-html="upgrade_chat_match"></p>
            <b-button variant="ee1" pill class="px-5 l-space2 text-uppercase">{{
              $t("start_chat")
            }}</b-button>
          </div>

          <div v-if="!isChatAction">
            <b-button
              @click.stop="closeModal"
              pill
              variant="outline-1d"
              class="px-5 fw-500"
              >{{ $t("back_to_matches") }}</b-button
            >
          </div>
        </div>

        <!-- <div v-if="isLikeSentPaid" class="text-center">
                    
                    </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "like-chat-pupop",
  props: {
    isBlackBg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      isLikeSent: true,
      isPaid: true,
      hasChatFromMatch: 2,
      isChatAction: false,
      message: "",
      emojis: ["👋", "😉", "❤️", "😍"],
      popupTitle: "",
      match_sent_msg: "",
      upgrade_chat_match: "",
      match: null,
      matchTest: {
        first_name: "Jenny",
        age: 32,
        height: 174,
        job_title: "Marketing manager",
        ethnicity: "Asian",
        education_level: "Master's degree",
        annual_income: "HK$200k - 300k",
        relationship_status: "Never married",
        child_status: "Want child",
        religion: "Agnostic",
        smoking_status: "Non-smoker",
        drinking_status: "Non-drinker",
        university_name: "Sydney University",
        primary_photo:
          "https://cdn-cf2.lovestruck.com/upload-m/crop_1652631291.png",
        other_photos: [
          "https://cdn-cf2.lovestruck.com/upload-m/crop_1640571181.png",
          "https://cdn-cf2.lovestruck.com/upload-m/chat/2021/12/298df0bee5dd9b74fe2afa5e1d5ad014.png",
        ],
        self_intro:
          "A stable-minded girl and good listener, also be confident towards life. I love travelling, reading books and sharing my opinion about the books via online platform. Also, I am fond of attending workshops to learn flower arrangement and baking, etc.",
      },
    };
  },
  methods: {
    checkInput() {
      this.message = $.trim(this.message);
    },
    sendStartMessage() {
      let self = this;
      if (!self.message) {
        return false;
      }
      self.$layer.loading();
      //   console.log("messaeg", self.message);
      let pdata = new FormData();
      //changeDateVenueInvite
      pdata.append("message", self.message);
      pdata.append("to_client_id", _.get(self.match, "client_id", 0));
      pdata.append("is_date_chat", 1);
      pdata.append("use_tim", 1);
      let typeName = "";
      if (self.isLikeSent) {
        typeName = "like_sent";
      } else {
        typeName = "start_chat";
      }

      self.$api
        .postClientMessage(pdata, self.$store.state)
        .then((rs) => {
          self.$emit("afterAction", { type: typeName });
          self.$layer.closeAll("loading");
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          self.$layer.msg(_.get(error, "response.data.error.body"));
          //self.afterAction(intro_id, type);
          console.log(error);
        });
    },
    emojiClick(item) {
      this.message = `${this.message}${item}`;
    },
    showModal(data) {
      let self = this;
      self.show = true;
      self.match = _.get(data, "match");
      self.hasChatFromMatch = _.get(data, "match.msg_received_count");
      //   self.hasChatFromMatch = 0;
      self.isLikeSent = _.get(data, "isLikeSent");
      self.isPaid = _.get(data, "isPaid");
      self.isChatAction = _.get(data, "isChatAction");
      self.popupTitle = self
        .$t("start_date_with")
        .replace(
          "[match_first_name]",
          `<span class="fw-700">${self.match.first_name}</span>`
        );
      self.match_sent_msg = self
        .$t("match_sent_msg")
        .replace(
          "[match_first_name]",
          `<span class="fw-700">${self.match.first_name}</span>`
        );
      self.upgrade_chat_match = self
        .$t("upgrade_chat_match")
        .replace(
          "[match_first_name]",
          `<span class="fw-700">${self.match.first_name}</span>`
        );
      // console.log('show', data)
    },
    closeModal() {
      let self = this;
      // console.log('closeModal...')
      self.show = false;
      self.$emit("afterAction", { type: "close" });
      // self.isReport = false
      // self.showblockonly = false

      // return false;

      //   self.client = null;
      //   self.label_button = null;
      //   self.labelHtmlText = null;
    },
  },
};
</script>
<style scoped>
.backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0,0,0,.4); */
  /* background: #e61e5a; */
  z-index: 99;
}

.body {
  max-width: 600px;
  /* background: #fff; */
  border-radius: 8px;
  /* margin: 0 auto; */
  position: absolute;
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  width: 100%;
}
.emoji {
  font-size: 20px;
  min-width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 8px;
  margin-right: 10px;
  border-color: #dee2e6;
}
.emoji:last-child {
  margin-right: 0;
}
</style>