<template>
    <div class="bg-black min-h100 text-white text-center py-5">

        <div class="mx-auto" style="max-width: 480px !important">

            <div v-if="rname == 'verify-error'">
                <h3 class="mb-4">Fail!</h3>
                <p>Please try again later!</p>
                <div class="my-5 text-center">
                    <b-button @click="howitwork" variant="ls-gold-g2" pill size="md" class="px-5 border-0 fw-600">Go to
                        verify again</b-button>
                </div>
            </div>
            <div v-else>

                <div v-if="client && client.client_yoti && client.client_yoti.liveness_auth == 'PASS'">
                    <h3 class="mb-4">Congratulations, you're verified!</h3>
                    <p>
                        Thanks for helping keep Lovestruck safe for everyone.
                        You'll now receive more matches and a verified badge on your profile.
                    </p>
                </div>

                <div v-else>
                    <h3 class="mb-4">Verification processing</h3>
                    <p>
                        We're reviewing your information now.
                        This should only take a few minutes.
                    </p>
                </div>

            </div>
            <div class="text-center py-4">
                <b-button @click="goAccountPage" variant="ls-gold-g2" size="md" pill class="px-5 fw-700">{{
                    $t("go_my_account") }}</b-button>
            </div>

            <!-- <div class="p-4">
        Yoti verify result:
        <p v-for="(item, index) in result" :key="index">
        {{index}}:{{item}}
        </p>
    </div> -->
        </div>
    </div>
</template>
<script>
import { webSocket } from "../../utils/mixin";
export default {
    name: "yoti-result",
    data() {
        return {
            result: null,
            rname: '',
            client: null,
        }
    },
    mounted() {
        let self = this;
        ////https://developers.yoti.com/identity-verification/render-the-user-view#error-codes
        self.result = _.get(self.$route, "query", '');

        self.rname = self.$route.name;

        self.$api
            .getClientMe(self.$store.state)
            .then((rs) => {
                self.client = _.get(rs, 'client')

            })
        self.initSocket();

    },
    mixins: [webSocket],
    methods: {
        doYotiResMsg(jsonData) {
            let self = this;
            console.log('doYotiResMsg')
            if (!client.client_yoti) {
                client.client_yoti = {
                    liveness_auth: ''
                }
            }
            let toClientId = _.get(jsonData, 'to_client_id')
            if (self.client && toClientId == self.client.client_id) {
                let verify_status = _.get(jsonData, 'data.verify_status')
                if (verify_status == 'pass') {
                    client.client_yoti.liveness_auth = 'PASS'
                } else if (verify_status == 'fail') {
                    self.rname = 'verify-error'
                }

                return false;
            }
        },
        goAccountPage() {
            this.$router.push({ name: "profile-me" });
        },
        howitwork() {
            this.$router.push({ name: "verify-howitwork" });
        }
    }
}
</script>