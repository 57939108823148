<template>
  <div class="bg-faf min-h100">
    <div
      v-if="selectedPlan"
      class="page6-2 px-3 page-div text-center py-4 text-1d"
    >
      <div style="height: 10vh"></div>
      <h5 class="mb-2 fw-600 ff-prata fs-30">
        {{ $t("congratulations_txt") }}!
      </h5>
      <!-- <b-img
        center
        width="200"
        :src="`https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/payment/congrats_icon_${selectedPlan.plan_name}.png`"
      /> -->
      <p class="text-7e">{{ $t("account_upgraded_to") }}</p>
      <div style="height: 20vh"></div>
      <div>
        <b-img center width="200" :src="membershipPhoto" />
      </div>
      <div style="height: 20vh"></div>
      <!-- <div
        v-if="selectedPlan.product"
        class="text-uppercase"
        :style="`color:${selectedPlan.product.color_code}`"
      >
        {{ selectedPlan.product.description }}
      </div> -->

      <!-- <div class="text-center">
        <div v-if="selectedPlan" class="d-flex justify-content-center align-items-center">
        <b-img height="36" src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/LSM_icon_large.png"></b-img>
        <span class="text-ls-y3 fw-700 fs-20">{{selectedPlan.product.description}}</span>
      </div>
      </div> -->

      <p class="mb-3 text-7e">
        {{ $t("thx_purchase_desc") }}
      </p>
      <!-- m_complete_my_profile -->
      <div v-if="showBtns">
        <b-button
          v-if="step"
          @click="completeProfile"
          variant="ee1"
          pill
          class="px-5 l-space2 text-uppercase w-100"
          style="height: 53px"
          >{{ $t("go_my_account") }}</b-button
        >
        <b-button
          v-else
          @click="goAccountPage"
          variant="ee1"
          pill
          class="px-5 l-space2 text-uppercase w-100"
          style="height: 53px"
          >{{ $t("go_my_account") }}</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../utils/helper";
export default {
  name: "upgrade-congrats",
  data() {
    return {
      client: null,
      membershipPhoto: null,
      step: null,
      showBtns: true,
      selectedPlan: null,
      ref: null,
      upgrade_membership_level_id: 1,
    };
  },
  mounted() {
    let self = this;
    // console.log(self.$parent);
    self.$parent.closeLoading();
    self.ref = _.get(self.$route, "query.ref");
    self.$layer.loading();
    //TODO check no login
    if (!self.$storage.get("token")) {
      let tmpUser = self.$storage.get("tmp-user");
      self.showBtns = false;
      if (tmpUser && tmpUser.token) {
        self.$store.state.token = tmpUser.token;
      }
    }

    self.$storage.clearUserInfo();
    // self.$api
    //   .getClientMe(self.$store.state, `?ref=upgrade`)

    self.selectedPlan = self.$storage.getPaymentSelectedPlan();
    if (self.ref == "approved_agreement") {
      self.$api.postReadAgreement({}, self.$store.state);
    }
    // self.selectedPlan.plan_name = 'plus'
    // console.log('self.selectedPlan', self.selectedPlan)
    self.$api
      .getClientMe2(self.$store.state, `?ref=upgrade`)
      .then((rs) => {
        self.$layer.closeAll("loading");

        let membership_level_id = _.get(rs, "client.membership_level_id");
        self.upgrade_membership_level_id = membership_level_id;
        // if(self.selectedPlan){
        //     upgrade_membership_level_id = _.get(
        //   self.selectedPlan,
        //   "product.prices.0.membership_level_id",
        //   membership_level_id
        // );
        // }
        // self.upgrade_membership_level_id = 3;
        // self.client = _.get(rs, "client");
        self.membershipPhoto = helper.getMembershipPhotoById(
          self.upgrade_membership_level_id
        );

        self.step = _.get(rs, "client.uncompleted_field.profile_step", null);
        // console.log('self.step', self.step)
        // self.step = 5;
        // if(self.step){

        // }

        //uncompleted_field
        // console.log("self.membershipPhoto", self.membershipPhoto);
        self.$storage.remove("tmp-user");
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
        console.log(["me error", err]);
      });
  },
  methods: {
    goAccountPage() {
      // this.$router.push({ name: "match-new" });
      this.$router.push({ name: "profile-me" });
    },
    completeProfile() {
      let self = this;
      let response = {
        is_new_user: 0,
        first_load_page: `profile_step${self.step}`,
        membership_level_id: self.upgrade_membership_level_id,
      };
      let jump = helper.parseFirstLoadPage(response);

      //   console.log('jump', jump)
      //   return false;
      if (jump.type == "link") {
        // console.log(url)
        if (typeof location != "undefined") {
          location.href = jump.route_name;
        }
        return false;
      }

      self.$router.push({
        name: jump.route_name,
        query: jump.query
          ? { ...jump.query, ref: "upgrade" }
          : { ref: "upgrade" },
      });
    },
  },
};
</script>