<template>
  <div class="text-center">
    <div v-if="loadingMore">{{ $t("Loading") }}...</div>
    <!-- <div v-if="noMore">{{ $t("no_more_found") }}style="color:#777676;"</div> -->
  </div>
</template>
<script>
export default {
  name: "bottom-detector",
  props: {
    loadingMore: {
      type: Boolean,
      required: true,
    },
    distance: {
      type: Number,
      default: 200,
    },
    noMore: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.listenBottomOut, true);
    this.element = document.documentElement;
  },
  destroyed() {
    this.destroyedBottomOut();
  },
  data() {
    return {
      element: null,
    };
  },
  methods: {
    destroyedBottomOut() {
      //   console.log("destroyed Bottom Out");
      window.removeEventListener("scroll", this.listenBottomOut, false);
    },
    listenBottomOut() {
      if (this.noMore || this.loadingMore) {
        this.destroyedBottomOut();
        // console.log("noMore", this.noMore, "loadingMore", this.loadingMore);
        return false;
      }
      //   console.log("scrolling...");
      let scrollTop = this.element.scrollTop || document.body.scrollTop;
      let clientHeight = this.element.clientHeight;
      //   let scrollHeight = this.element.scrollHeight;
      let scrollHeight = document.body.scrollHeight;

      //   console.log(scrollTop, clientHeight, scrollHeight, this.distance);

      if (scrollTop + clientHeight >= scrollHeight - this.distance) {
        // console.log("arriveBottom");
        this.$emit("arriveBottom");
      }
    },
  },
};
</script>