<template>
  <div>
    <b-modal
      v-model="show"
      ref="feedback-modal"
      size="md"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <b-container fluid>
        <div>
          <b-img
            v-if="intro"
            width="100"
            rounded="circle"
            center
            thumbnail
            :src="intro.photo_url"
            fluid
            alt
          ></b-img>
        </div>
        <div v-if="type == 'view'">
          <div v-if="intro" class="text-center my-3">
            {{
              $t("feedback_from_someone").replace(
                "[match_first_name]",
                intro.first_name
              )
            }}
          </div>
          <div
            v-if="
              intro &&
              !intro.target_user_enjoy_date &&
              !intro.target_user_chemistry_rating
            "
          >
            <div class="text-center">{{ $t("no_feedback_given") }}</div>
          </div>
          <div v-else class="mx-auto" style="width: 200px">
            <p class="mb-3">
              <strong>{{ $t("enjoyed_txt") }}:</strong>
              <b-button
                class="rounded-circle source ml-3"
                v-if="intro"
                :variant="view_enjoy_date_class"
                size="sm"
                >{{ intro.target_user_enjoy_date }}</b-button
              >
            </p>
            <p class="mb-3">
              <strong>{{ $t("connection_txt") }}:</strong>
              <b-button
                class="rounded-circle source ml-3"
                v-if="intro"
                :variant="view_chemistry_rating_class"
                size="sm"
                >{{ intro.target_user_chemistry_rating }}</b-button
              >
            </p>
            <p class="mb-3">
              <strong>{{ $t("date_again") }}?</strong>
              <strong class="ml-3" :class="date_again_class">{{
                date_again_text
              }}</strong>
            </p>
          </div>

          <div class="text-center">
            <b-button @click="hideModal" class="mt-3">{{
              $t("close_txt")
            }}</b-button>
          </div>
        </div>

        <div v-if="type == 'send'">
          <div v-if="step != 4 && intro" class="text-center my-3">
            {{
              $t("feedback_for_someone").replace(
                "[match_first_name]",
                intro.first_name
              )
            }}
          </div>
          <div v-if="step == 1" class="text-center mb-3 q1">
            <p class="font-weight-bold">{{ $t("q_enjoy_date") }}</p>

            <div class="mt-3">
              <b-button
                class="rounded-circle source mr-2"
                v-for="se in sources"
                @click="sourceClick('enjoy_date', se)"
                :key="se"
                :variant="formData.enjoy_date == se ? 'ls-g' : ''"
                size="sm"
                >{{ se }}</b-button
              >
            </div>
          </div>
          <div v-if="step == 2" class="text-center mb-3 q2">
            <p class="font-weight-bold">{{ $t("q_connection") }}</p>

            <div class="mt-3">
              <b-button
                class="rounded-circle source mr-2"
                v-for="se in sources"
                @click="sourceClick('chemistry_rating', se)"
                :key="se"
                :variant="formData.chemistry_rating == se ? 'ls-g' : ''"
                size="sm"
                >{{ se }}</b-button
              >
            </div>
          </div>

          <div v-if="step == 3" class="text-center mb-3 q3">
            <p class="font-weight-bold">{{ $t("q_date_again") }}</p>
            <div class="mt-3">
              <b-button @click="dateAgain(1)" variant="ls-g" class="mr-3"
                >Yes</b-button
              >
              <b-button @click="dateAgain(2)">No</b-button>
            </div>
            <div v-if="loading" class="text-center mt-2">
              <b-spinner></b-spinner>
            </div>
          </div>

          <div v-if="step == 4" class="text-center my-3">
            <p v-if="intro">
              {{
                $t("thx_send_feedback").replace(
                  "[match_first_name]",
                  intro.first_name
                )
              }}
            </p>
            <b-button @click="hideModal" class="mt-3">{{
              $t("close_txt")
            }}</b-button>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "feedbackModal2",
  data() {
    return {
      show: false,
      type: "send",
      intro: null,
      formData: {
        enjoy_date: "",
        chemistry_rating: "",
        date_again: "",
      },
      loading: false,
      step: 1,
      sources: [1, 2, 3, 4, 5],
      view_enjoy_date_class: "",
      view_chemistry_rating_class: "",
      date_again_class: "",
      date_again_text: "",
    };
  },
  methods: {
    showModal(intro, type) {
      let self = this;
      self.show = true;
      self.type = type;
      self.intro = intro;
      self.step = 1;
      self.loading = false;
      self.formData = {
        enjoy_date: "",
        chemistry_rating: "",
        date_again: "",
      };
      if (type == "view") {
        self.setViewStyle();
      }
    },
    hideModal() {
      let self = this;
      // console.log("hideModal");
      self.$refs["feedback-modal"].hide();
      self.show = false;
      self.intro = null;
    },
    setViewStyle() {
      let self = this;

      self.view_enjoy_date_class = self.btnColor(
        self.intro.target_user_enjoy_date
      );
      self.view_chemistry_rating_class = self.btnColor(
        self.intro.target_user_chemistry_rating
      );

      if (self.intro.target_user_date_again == 1) {
        self.date_again_text = "YES";
        self.date_again_class = "text-diamond";
      } else {
        self.date_again_text = "NO";
        self.date_again_class = "text-secondary";
      }
    },
    btnColor(value) {
      if (value <= 2) {
        return "";
      } else if (value <= 4) {
        return "ls-g";
      } else {
        return "ls-p";
      }
    },
    sourceClick(type, value) {
      let self = this;
      if (type == "enjoy_date") {
        self.formData.enjoy_date = value;
        setTimeout(function () {
          self.step = 2;
        }, 500);
      } else if (type == "chemistry_rating") {
        self.formData.chemistry_rating = value;
        setTimeout(function () {
          self.step = 3;
        }, 500);
      }
    },
    dateAgain(value) {
      let self = this;
      self.formData.date_again = value;
      self.loading = true;

      // setTimeout(function() {
      //   self.step = 4;
      //   self.loading = false;
      // }, 500);
      self.postFormData();
    },
    postFormData() {
      let self = this;

      // console.log(self.formData);
      let fdata = new FormData();
      fdata.append("intro_id", _.get(self.intro, "client_intro_id"));
      fdata.append("enjoy_date", self.formData.enjoy_date);
      fdata.append("chemistry_rating", self.formData.chemistry_rating);
      fdata.append("date_again", self.formData.date_again);

      // self.$layer.loading();
      self.$api
        .postDateFeedback(fdata, self.$store.state)
        .then((rs) => {
          // self.$layer.closeAll("loading");
          self.loading = false;
          self.step = 4;

          self.$emit(
            "afterSendFeedback",
            _.get(self.intro, "client_intro_id"),
            self.formData
          );
        })
        .catch((error) => {
          // self.$layer.closeAll("loading");
          self.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.source {
  width: 36px !important;
  height: 36px;
}
</style>