<template>
  <div>
    <div class="text-center mt-3 mb-5">
      <b-img height="30" style="border: none !important" :src="plan_logo" />
    </div>
    <div v-if="plans">
      <div
        class="p-3 mb-4"
        :class="
          membershipType == 'diamond'
            ? 'diamond dia_gradient_bg'
            : 'gold gold_gradient_bg'
        "
      >
        <div
          v-if="upgradeCarousels"
          class="swiper-container"
          id="membershipUpgradeSwiper"
          style="max-width: 360px;margin-top: 50px;border: 1px solid #eee;border-radius: 4px;"

        >
          <div class="swiper-wrapper">
            <div
              v-for="item in upgradeCarousels"
              :key="item.index"
              class="
                swiper-slide
                p-3
                bg-white
                text-center
                mx-auto
                updateCarouselItem
              "
              :class="membershipType == 'diamond' ? 'diamond' : 'gold'"
            >
              <b-img
                v-if="item.icon"
                width="50"
                center
                class="mb-3"
                :src="item.icon"
              />
              <img
                v-if="item.icons"
                v-for="url in item.icons"
                :key="url"
                width="50"
                class="mb-3"
                :src="url"
              />
              <h5 class="mb-3">{{ item.title }}</h5>
              <p class="mb-3">{{ item.desc }}</p>
            </div>
          </div>
          <div class="swiper-pagination" style="bottom: 40px"></div>
        </div>
      </div>

      <div v-if="membershipType == 'diamond'" class="text-center">
        <p class="mb-3 fw-500">
          {{ $t("schedule_diamond_call") }}
        </p>
        <b-button
          @click="diamondBook"
          variant="light"
          class="bg-dia text-white px-5 fw-600 mb-5 text-uppercase"
          pill
          >{{ $t("free_consultation") }}</b-button
        >
      </div>

      <div v-if="membershipType == 'gold'" class="text-center pb-4">
        <p class="fw-600 font-1_1rem mt-4">
          {{ selectedMonth }} {{ $t("n_month") }}: {{ currency.symbol
          }}{{ formatPrice(plans.gold.prices[0][`${selectedMonth}_month`]) }}
        </p>
        <p class="mb-4">
          {{ currency.symbol
          }}{{ formatPrice(plans.gold[`price_pre_${selectedMonth}month`]) }}/{{
            $t("1_month")
          }}
        </p>
        <b-button
          @click="submitPayment('gold')"
          variant="light" 
          class="bg-gold text-dred px-5 fw-600 text-uppercase mb-3"
          pill
          >{{ $t("web_lbl_nextsteps") }}</b-button
        >

        <!-- <p class="text-muted fw-600 mb-3">
          {{ $t("recurring_billing") }}
        </p>
        <p class="text-muted mb-3">
          {{ $t("recurring_billing_desc") }}
        </p> -->
      </div>
    </div>
    <div v-else>
      <div class="text-center py-3">
        <div class="spinner-border text-dark" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </div>
    <scheduleConsultation ref="scheduleConsultation" />
  </div>
</template>
<script>
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}
import helper from "../../utils/helper";
import scheduleConsultation from "./scheduleConsultation";
export default {
  name: "membership-upgrade-com",
  components: {
    scheduleConsultation,
  },
  data() {
    return {
      client: null,
      plans: null,
      currency: null,
      plan_logo: "",
      membershipType: "gold",
      membership_level_id: 0,
      membershipLogos: {},
      membershipSwiper: null,
      selectedMonth: 3,
      userInfo: this.$storage.get("userInfo"),
      userData: null,
      upgradeCarousels: null,
      swiperOption: {
        //init:false,
        speed: 500,
        // loop: true,
        initialSlide: 0,
        direction: "horizontal",
        // autoplay: {
        //   delay: 7000,
        //   disableOnInteraction: false,
        // },
        // spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "ls-gold-bullet-active",
        },
      },
      langCode: "en_US",
      initialSlideVal: 1,
    };
  },
  methods: {
      resetStatus(){
          $('.updateCarouselItem').css({"visibility": 'hidden'})
      },
    initComData(options) {
      let self = this;
      self.initialSlideVal = _.get(options, "initialSlideVal", 1);
      self.membershipType = _.get(options, "membershipType");

      self.membershipLogos = helper.membershipPhoto();

      self.plan_logo = _.get(self.membershipLogos, self.membershipType);

      self.langCode = self.$storage.get("cur-lang");
      self.client = _.get(options, "client");
      self.membership_level_id = _.get(options, "client.membership_level_id");
      self.loadInitData();
    },
    formatPrice(price_val) {
      return helper.currenyPriceFormat(
        price_val,
        _.get(this.currency, "show_decimals")
      );
    },
    loadInitData() {
      let self = this;
      if (self.membershipType == "gold") {
        self.upgradeCarousels = helper.goldCarouselItems(self);
      } else if (self.membershipType == "diamond") {
        self.swiperOption.pagination.bulletActiveClass =
          "ls-diamond-bullet-active";

        self.upgradeCarousels = helper.diamondCarouselItems(self, true);
      }

      // console.log("langCode=", self.langCode);
      // self.basicItems = helper.diamondCarouselItems()
      self.loadMembershipData();
    },
    diamondBook() {
      this.$emit("diamondBook", {});
      this.$refs.scheduleConsultation.showModal(2);
    },
    submitPayment(membership_name) {
      let self = this;

      self.$storage.setPaymentSelectedPlan(
        JSON.stringify({
          productType: "upgrade",
          plan_name: membership_name,
          plan_month: self.selectedMonth,
          product: self.plans[membership_name],
          currency: self.currency,
          client_id: _.get(self.client, "client_id", 0),
          user_data: self.userData,
        })
      );

      self.$router.push({
        name: "payment-checkout",
        query: { lang: self.langCode, top_nav: 0 },
      });
      return false;
    },
    startSwiper() {
      let self = this;

      self.swiperOption.initialSlide = self.initialSlideVal - 1;
      //   self.swiperOption.on = {
      //     slideChangeTransitionEnd: function () {
      //       //   console.log(this.activeIndex);
      //       let name = self.getNameByIndex(this.activeIndex);
      //       self.changeMembership(name, this.activeIndex);

      //     },
      //   };

      setTimeout(function () {
        self.membershipSwiper = new VueAwesomeSwiper.Swiper(
          "#membershipUpgradeSwiper",
          self.swiperOption
        );
      }, 10);
      setTimeout(function(){
          $('.updateCarouselItem').css({"visibility": 'visible'})
          console.log('vvv')
      }, 300)
      
    },
    parseMembershipLevelRow(row) {
      row["price_pre_1month"] = Math.round(
        _.get(row, "prices.0.1_month", 0) / 1
      );

      row["price_pre_3month"] = Math.round(
        _.get(row, "prices.0.3_month", 0) / 3
      );

      row["price_pre_6month"] = Math.round(
        _.get(row, "prices.0.6_month", 0) / 6
      );

      row["price_pre_12month"] = Math.round(
        _.get(row, "prices.0.12_month", 0) / 12
      );

      row["save_3month"] = Math.round(
        ((row["price_pre_1month"] - row["price_pre_3month"]) * 100) /
          row["price_pre_1month"]
      );

      row["save_6month"] = Math.round(
        ((row["price_pre_1month"] - row["price_pre_6month"]) * 100) /
          row["price_pre_1month"]
      );

      row["save_12month"] = Math.round(
        ((row["price_pre_1month"] - row["price_pre_12month"]) * 100) /
          row["price_pre_1month"]
      );

      return row;
    },
    parseMembershipLevels: function (levels) {
      let self = this;
      let data = {
        basic: {},
        gold: {},
        diamond: {},
      };
      self.diamondSelects = [];
      self.hasNullPricesCount = 0;
      for (let v of levels) {
        let leveId = parseInt(v.membership_level_id);

        if (leveId == 2) {
          if (!_.get(v, "prices.0")) {
            self.hasNullPricesCount++;
            // console.log("has no prices", self.hasNullPricesCount);
          }
          data["basic"] = self.parseMembershipLevelRow(v);
        } else if (leveId == 3) {
          if (!_.get(v, "prices.0")) {
            self.hasNullPricesCount++;
            // console.log("has no prices", self.hasNullPricesCount);
          }
          data["gold"] = self.parseMembershipLevelRow(v);
        } else if (leveId >= 4) {
          //   if (!_.get(v, "prices.0")) {
          //     self.hasNullPricesCount++;
          //     // console.log("has no prices", self.hasNullPricesCount);
          //   }

          data[`diamond_${leveId}`] = self.parseMembershipLevelRow(v);
          //
        }
      }

      // console.log("nextBtnClass", self.nextBtnClass);
      //   console.log(data);
      return data;
    },
    loadMembershipData() {
      let self = this;
      // console.log("loadInitData...");
      self.$layer.loading();
      self.$api
        .getMembershipLevels("", self.$store.state)
        .then((rs) => {
          //   console.log(rs);
          if (_.has(rs, "levels")) {
            // console.log("has levels");
            self.currency = _.get(rs, "currency");
            self.plans = self.parseMembershipLevels(_.get(rs, "levels"));
            // console.log(self.currency, self.plans);

            // if (
            //   self.query_membership_name &&
            //   self.query_membership_name != self.chooseMembership
            // ) {
            setTimeout(function () {
              self.startSwiper();
              // self.changeMembershipByName(self.query_membership_name);
            }, 100);
            // }
          }
          self.$layer.closeAll("loading");
        })
        .catch((err) => {
          self.$layer.closeAll("loading");

          // console.log(["level error", err]);
        });
    },
  },
};
</script>
<style scoped>
.updateCarouselItem{visibility: hidden;}
</style>