<template>
  <div class="bg-white" id="lsUpgradePopup">
    <div
      v-if="options"
      class="text-white text-center py-3"
      :class="selectedOption == 'invite_elite' ? 'bg-ls-dgray' : 'bg-primary'"
    >
      <div v-html="options.alert"></div>
      <div v-if="timerCount" v-html="timerCount"></div>
    </div>

    <div v-if="options" class="my-5 text-center">
      <div v-if="options.icon" class="d-block mx-auto mb-3 plan_carousel_icon">
        <img :src="options.icon" class="w-100 border-0" />
      </div>
      <h5 class="text-dark px-2">{{ options.title }}</h5>
      <p class="d-block mt-3 font-weight-light px-3">{{ options.desc }}</p>
    </div>

    <div class="text-center">
      <a
        v-if="showSilverBtn"
        href="javascript:;"
        @click="goToSilver"
        class="btn btn-lg text-uppercase shadow-888 px-4 font-1rem bg-silver text-6d mt-3"
        >{{ $t("get_sliver") }}</a
      >
      <p class></p>
      <a
        href="javascript:;"
        v-if="showGoldBtn"
        @click="goToGold"
        class="btn btn-lg text-uppercase shadow-888 px-4 font-1rem bg-gold text-dred mt-3"
        >{{ $t("get_gold") }}</a
      >

      <a
        href="javascript:;"
        v-if="showDiamondBtn"
        @click="goToBooking"
        class="btn btn-lg btn-ls-d text-capitalize px-4 font-1rem mt-3"
        >{{ $t("get_diamond_txt") }}</a
      >

      <a
        href="javascript:;"
        v-if="showScheduleBtn"
        @click="goToSchedule"
        class="btn btn-lg btn-ls-d text-capitalize px-4 font-1rem mt-3"
        >Schedule Now</a
      >

      <p class></p>
      <a
        href="javascript:;"
        @click="notNow"
        class="btn btn-lg px-4 font-1rem mt-3 text-muted"
        >{{ notNowText }}</a
      >
    </div>
  </div>
</template>
<script>
import UA from "ua-device";
export default {
  name: "upgradePopup",
  data() {
    return {
      selectedOption: "",
      options: null,
      timerCount: "",
      timer: null,
      notNowText: "",
      btnAct: "",
      showSilverBtn: false,
      showGoldBtn: false,
      showScheduleBtn: false,
      showDiamondBtn: false,
    };
  },
  methods: {
    notNow() {
      let self = this;
      let userAgent = new UA(navigator.userAgent);
      // console.log(userAgent.device.type, self.$layer);

      if (userAgent.device.type == "mobile") {
        self.$router.go(-1);
      } else {
        let iframeBox = $("#memberIframeBox", parent.document);

        // iframeBox.children("iframe").attr("src", "");
        iframeBox.fadeOut();
      }
    },
    chatLink() {
      this.$storage.set("chat-ref", location.href);
      this.$router.push({
        name: "client-chat",
        query: { clientId: "employee" },
      });
      //   let self = this;
      //   let paths = location.pathname.replace("/", "").split("/");
      //   let urlpart = [paths[0], paths[1], "employee/chat"].join("/");
      //   let token = self.$storage.get("token");
      //   self.$storage.set("ls-ref", location.href);
      //   // console.log(`/${urlpart}?token=${token}`);
      //   window.top.location.href = `/${urlpart}?token=${token}`;
    },
    goToSchedule() {
      this.goToBooking();
    },
    goToBooking() {
      let self = this;
      //record api
      let pdata = new FormData();
      //membership_level_id
      let userInfo = self.$storage.get("userInfo");
      if (userInfo) {
        if (_.get(userInfo, "client_status_id") == 0) {
          pdata.append("chat_key", "activate_account");
        } else {
          pdata.append("chat_key", "diamond_upgrade");
        }

        self.$api
          .postStartAutoChat(pdata, self.$store.state)
          .then((rs) => {
            self.chatLink();
          })
          .catch((err) => {
            self.$layer.closeAll("loading");

            // console.log(['me error', err]);
          });
      }

      return;

      // let step = 1;
      // if (self.btnAct == "book_confirmed") {
      //   step = 3;
      // }
      // self.$router.push({
      //   name: "book-consultation",
      //   query: { step: step, fidx: self.options.index }
      // });
    },
    goToSilver() {
      // console.log("index=", this.options.index);
      this.$router.push({
        name: "payment-plan",
        params: { planName: "onlySilver" },
        query: { index: this.options.index, top_nav: 0 },
      });
    },
    goToGold() {
      // console.log("index=", this.options.index);
      this.$router.push({
        name: "payment-plan",
        params: { planName: "onlyGold" },
        query: { index: this.options.index, top_nav: 0 },
      });
    },
    texts() {
      let self = this;
      return {
        popular: {
          alert: self.$t("does_not_popular"),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/invite_popular.png",
          title: self.$t("ls_silver_title2"),
          desc: self.$t("ls_silver_desc2"),
          index: 0,
        },
        elite: {
          alert: self.$t("does_not_elite"),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/invite_elite.png",
          title: self.$t("ls_gold2_title1"),
          desc: self.$t("ls_gold2_desc1"),
          index: 0,
        },
        dateLimit: {
          alert: self.$t("date_limit_perday"),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/unlimited_invites.png",
          title: self.$t("ls_silver_title1"),
          desc: self.$t("ls_silver_desc1"),
          index: 1,
        },
        second: {
          alert: self.$t("does_not_second"),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/second_chances.png",
          title: self.$t("dia_second_chance"),
          desc: self.$t("dia_second_chance_desc"),
          index: 0,
        },
        priority_match: {
          alert: self
            .$t("membership_dont_include")
            .replace("[POP_NAME]", self.$t("ls_silver2_title3")),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/skip_the_line.png",
          title: self.$t("ls_silver2_title3"),
          desc: self.$t("ls_silver2_desc3"),
          index: 2,
        },
        match_boost: {
          alert: self
            .$t("membership_dont_include")
            .replace("[POP_NAME]", self.$t("ls_gold2_title3")),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/match_boost_1.png",
          title: self.$t("ls_gold2_title3"),
          desc: self.$t("ls_gold2_desc3"),
          index: 2,
        },
        privacy_mode: {
          alert: self
            .$t("membership_dont_include")
            .replace("[POP_NAME]", self.$t("ls_gold2_title2")),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/privacy_mode_1.png",
          title: self.$t("ls_gold2_title2"),
          desc: self.$t("ls_gold2_desc2"),
          index: 1,
        },
        advanced_match: {
          alert: self
            .$t("membership_dont_include")
            .replace("[POP_NAME]", self.$t("ls_gold2_title4")),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/advanced_filter.png",
          title: self.$t("ls_gold2_title4"),
          desc: self.$t("ls_gold2_desc4"),
          index: 3,
        },
        invite_elite: {
          alert: self
            .$t("membership_dont_include")
            .replace("[POP_NAME]", self.$t("strong_elite_member")),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/invite_elite.png",
          title: self.$t("dia_invite_elite"),
          desc: self.$t("dia_invite_elite_desc"),
          index: 4,
        },
        dia_match_boost: {
          alert: self
            .$t("membership_dont_include")
            .replace("[POP_NAME]", self.$t("dia_match_boost")),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/match_boost.png",
          title: self.$t("dia_match_boost"),
          desc: self.$t("dia_match_boot_desc"),
          index: 7,
        },
        dia_privacy_mode: {
          alert: self
            .$t("membership_dont_include")
            .replace("[POP_NAME]", self.$t("dia_privacy_mode")),
          icon:
            "https://cdn-cf2.lovestruck.com/upload-s/gen/img/icons/2020/privacy_mode.png",
          title: self.$t("dia_privacy_mode"),
          desc: self.$t("dia_privacy_mode_desc"),
          index: 6,
        },
        active_account: {
          alert: `<b>Your account is not active yet</b>`,
          icon: "",
          title: "",
          desc:
            "Please schedule a consultation with your matchmaker to verify and activate your account.",
          index: 6,
        },
      };
    },
    startTimeCount() {
      let self = this;
      if (self.selectedOption != "dateLimit") {
        return false;
      }
      if (self.timer) {
        clearInterval(self.timer);
      }
      let lastReqTimeSec = _.get(self.$route, ["query", "last_reqTime"], 0);
      // console.log("lastReqTimeSec", lastReqTimeSec);
      // let lastReqTimeSec = Math.floor(new Date(lastReqTimeSec).getTime() / 1000);

      let oneDaySec = 24 * 60 * 60;
      // console.log("last_reqTime", last_reqTime, lastReqTimeSec, oneDaySec);

      self.timer = setInterval(function () {
        let d = new Date();

        // console.log(Math.floor(d.getTime() / 1000));
        let left =
          oneDaySec - (Math.floor(d.getTime() / 1000) - lastReqTimeSec);
        // console.log("lastReqTimeSec", left);
        let lefthour = "";
        let leftmin = "";
        let leftsec = "";
        if (left > 0) {
          lefthour = Math.floor(left / 3600);
          leftmin = Math.floor((left - lefthour * 3600) / 60);
          leftsec = left - lefthour * 3600 - leftmin * 60;
        } else {
          lefthour = 23 - d.getHours();
          leftmin = 59 - d.getMinutes();
          leftsec = 60 - d.getSeconds();
          if (lefthour == 0 && leftsec == 0) {
            lefthour += 1;
          }
        }

        // console.log(left, lefthour, leftmin, leftsec);

        //console.log(hour, min, sec, 24 - hour, 60 - min, 60 - sec);

        lefthour = lefthour < 10 ? "0" + lefthour : lefthour;
        leftmin = leftmin < 10 ? "0" + leftmin : leftmin;
        leftsec = leftsec < 10 ? "0" + leftsec : leftsec;

        // console.log(lefthour, leftmin, leftsec);
        self.timerCount = `<strong>${lefthour} : ${leftmin} : ${leftsec}</strong>`;
      }, 1000);
    },
    init() {
      let self = this;

      self.notNowText = self.$t("not_now");
      if (self.selectedOption == "dateLimit") {
        self.notNowText = self.$t("no_thx_will_wait");
      }

      let texts = self.texts();
      self.options = _.get(texts, self.selectedOption, false);

      if (
        self.selectedOption == "dateLimit" ||
        self.selectedOption == "popular" ||
        // self.selectedOption == "second" ||
        self.selectedOption == "priority_match"
      ) {
        self.showSilverBtn = true;
      } else if (self.selectedOption == "active_account") {
        self.showScheduleBtn = true;
      } else if (
        self.selectedOption != "invite_elite" &&
        self.selectedOption != "second" &&
        self.selectedOption.indexOf("dia_") == -1
      ) {
        self.showGoldBtn = true;
      } else {
        self.showDiamondBtn = true;
      }

      self.startTimeCount();
    },
  },
  mounted() {
    let self = this;

    if (_.get(self.$parent, "isLoading") && !self.$parent.isLoading) {
      self.$parent.showLoading();
    }
    // console.log("self.$route.query-------", self.$route.query);
    let option = _.get(self.$route, ["query", "option"], false);
    self.btnAct = _.get(self.$route, ["query", "act"], false);
    if (option) {
      self.selectedOption = option;

      self.init();
    }

    if (_.get(self.$parent, "isLoading")) {
      self.$parent.closeLoading();
    } else {
      $("#viewContent").show();
    }
  },
};
</script>
<style scoped>
</style>