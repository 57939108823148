<template>
  <!-- :class="
      step == 'idphoto_dis' || (step == 'idphoto' && photoIdDisapprovedTime)
        ? 'bg-white'
        : 'bg-faf'
    " -->
  <div id="stepPage" class="height-in min-h100 text-1d bg-faf">
    <pageTop ref="pageTop" classNames="page6-2"></pageTop>
    <div class="page6-2">
      <div class="text-center">
        <!-- ff-urbanist -->
        <!-- <div class="d-flex justify-content-between pt-3">
            <div class="mr-3">
                <b-img
            v-if="step == 'first_name'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/name_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'gender'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/gender_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'dob'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/DOB_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'job_title'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/job_title_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'income'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/income_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'education'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/edu_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'relationship_status'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/relationship_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'child_plan'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/child_plans_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'height'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/height_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'email'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/email_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'residential'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/residential_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'age_range'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/Icons_0023_icon-age-range.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'school'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/uni_name_gold.png"
            center
            height="45"
          />

          <b-img
            v-if="step == 'ethnicity'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/ethnicity_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'religion'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/religion_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'smoking'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/smoking_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'drinking'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/drinking_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'food_habit'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/Icons_0020_icon-food-habit.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'intro'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/self_intro_gold.png"
            center
            height="45"
          />
          <b-img
            v-if="step == 'photo'"
            src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/onboarding/photo_gold.png"
            center
            height="45"
          />
            </div>
            <div class="w-100 d-flex align-items-center">
                    <div
            v-if="client"
            class="ls-progress-bar gold w-100"
          >
            <div
              class="ls-progress-completed gold"
              :style="`transition: 0.1s ease 0s; width:${progressValue}`"
            ></div>
          </div>
            </div>
          
          
        </div> -->
        <div v-if="type == 'reg'" class="px-3 pt-3">
          <div v-if="step != 'miss' && step != 'idphoto_dis' && step != 'idphoto' && step != 'know-better' && step != 'discover-love' && step != 'delve-intro'"
            class="w-100 d-flex align-items-center">
            <div class="ls-progress-bar bg-d7c v6-2 w-100">
              <div class="ls-progress-completed" :style="`transition: 0.1s ease 0s; width:${progressValue}`"></div>
            </div>
          </div>
        </div>


        <div v-if="step == 'miss'" class="pt-4">
          <h4 class="mb-4 fw-500 fs-27 ff-prata">
            {{ $t("nextstep_complete") }}
          </h4>
          <b-img class="mb-4" src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/miss_profile_image@3x.png"
            center width="180"></b-img>

          <p class="mb-3 fs-20 fw-500 text-7e">
            {{ $t("nextstep_complete_desc") }}
          </p>
          <p class="text-7e mb-0">{{ $t("nextstep_complete_desc2") }}</p>
          <div style="height: 10vh;"></div>
          <div>
            <b-button size="lg" pill @click="checkNextStep" variant="ee1" class="px-5 l-space2 text-uppercase w-100 fs-14"
              style="height: 53px;width:290px !important">{{ $t("m_continue_txt") }}
              <i class="fa-light fa-arrow-right" style="
                  position: absolute;
                  right: 8px;
                  top: 17px;
                  font-size: 16px;
                "></i></b-button>
          </div>
        </div>

        <div v-if="step == 'know-better'" class="pt-4">
          <div class="textBody">
              <h4 v-if="profile" class="my-5 fw-500 fs-30 ff-prata mx-auto" style="max-width: 250px;line-height: 48px;">
              Let's get to know <span class="text-ee1">you</span> better, {{ profile.first_name }}
            </h4>
            <div class="text-1d mx-auto fw-400" style="max-width: 240px;">First we’ll learn about you, and then we’d like to understand your preferences in a match.</div>
            <div style="height: 40vh;"></div>
            <div>
              <b-button size="lg" pill @click="checkNextStep" variant="ee1" class="px-2 l-space2 text-uppercase fs-14"
                style="height: 53px;width:244px !important;">ABOUT ME
                <i class="fa-light fa-arrow-right" style="
                    position: absolute;
                    right: 15px;
                    top: 17px;
                    font-size: 16px;
                  "></i></b-button>
            </div>
          </div>
          
          <div class="bgVideo">
            <video class="full-video" muted autoplay playsinline webkit-playsinline loop
              poster="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Background-4_PosterImage.png">
              <source src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Background-4.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          
        </div>

        <div v-if="step == 'delve-intro'" class="pt-4">
          <div class="textBody">
            <h4 v-if="profile" class="my-5 fw-500 fs-30 ff-prata mx-auto" style="max-width: 300px;line-height: 48px;">
            Let’s delve into your <span class="text-ee1">preferences</span>, {{ profile.first_name }}
          </h4>
          <div class="text-1d mx-auto fw-400" style="max-width: 260px;">
            This is the exciting part – sharing what you’re seeking. Our team of dating experts will carefully review your preferences to evaluate compatibility and ensure we find the best possible matches for you.
          </div>
          <div style="height: 40vh;"></div>
          <div>
            <b-button size="lg" pill @click="checkNextStep" variant="ee1" class="px-0 l-space2 text-uppercase fs-14"
              style="height: 53px;width:290px !important;">MY IDEAL PARTNER
              <i class="fa-light fa-arrow-right" style="
                  position: absolute;
                  right: 15px;
                  top: 17px;
                  font-size: 16px;
                "></i></b-button>
          </div>

          </div>
          <div class="bgVideo">
            <video class="full-video" muted autoplay playsinline webkit-playsinline loop
              poster="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Background-2_PosterImage.png">
              <source src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Background-2.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        
        </div>

        <div v-if="step == 'discover-love'" class="pt-4">
          <div class="textBody">

            <h4 class="my-5 fw-500 fs-30 ff-prata mx-auto" style="max-width: 300px;line-height: 48px;">
            Discover <span class="text-ee1">effortless</span> love with Lovestruck
          </h4>
          <div class="text-1d mx-auto fw-400" style="max-width: 240px;">
          <p class="mb-4">As a busy {{ profile.job_title }}, you don’t need to turn your dating life into another job. Leave the hard work to us, and our team will take care of everything for you. </p>
          <p>We handle everything – screening potential matches, handpicking great fits for you and setting up dates.</p>
          
          </div>
          <div style="height: 30vh;"></div>
          <div>
            <b-button size="lg" pill @click="checkNextStep" variant="ee1" class="px-2 l-space2 text-uppercase fs-14"
              style="height: 53px;width:290px !important;">OK, SOUNDS GOOD
              </b-button>
          </div>
          </div>
          <div class="bgVideo">
            <video class="full-video" muted autoplay playsinline webkit-playsinline loop
              poster="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Background-3_PosterImage.png">
              <source src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Background-3.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
        
        </div>

        <div class="pt-4 px-3 text-left">
          <!--1 relationship_type-->
          <div v-if="step == 'relationship_type'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_relationship_type')"></h4>
            <div class="mx-auto">
              <div v-if="attrs" class="mx-auto">
                <b-button v-for="item in attrs.relationshipType" :key="item.relationship_type_id" variant="white"
                  class="w-100 mb-3 btn-big text-left" :class="profile.relationship_type_id == item.relationship_type_id
                    ? 'selected fw-600'
                    : ''
                    " @click="
    selectAttrValue(
      'relationship_type_id',
      item.relationship_type_id,
      'relationship_type'
    )
    ">{{ item.description }}
                </b-button>
              </div>
            </div>
          </div>
          <!--1 name-->
          <div v-if="step == 'first_name'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_u_name')"></h4>
            <div class="mx-auto">
              <b-form-input id="first_name" v-model="profile.first_name" class="v6-2"
                @input="checkInput('first_name', 'first_name')" maxlength="32"
                :placeholder="$t('first_name')"></b-form-input>
            </div>
          </div>
          <!--2 gender @click="selectAttrValue('gender_id', item.gender_id, 2)"-->
          <div v-if="step == 'gender'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_u_gender')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.gender" :key="`gender-${item.gender_id}`" variant="white"
                class="w-100 mb-3 btn-big text-left genderbtn" :class="profile.gender_id == item.gender_id ? 'selected fw-600' : ''
                  " :data-id="item.gender_id">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--3 birthday-->
          <div v-if="step == 'dob'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('w_you_dob')"></h4>
            <div class="mx-auto">
              <div class="d-flex justify-content-between" id="input-dob">
                <div class="text-center py-3">
                  <b-spinner label="Loading..." variant="light"></b-spinner>
                </div>

                <!-- <div class="">
                  <p>{{ $t("date_day") }}</p>
                  <b-form-input
                    v-model="dob_day"
                    class="
                      bg-white
                      text-center text-dark
                      rounded
                      w-75
                      mx-auto
                      input-dob
                    "
                    maxlength="2"
                    placeholder="--"
                    readonly
                  ></b-form-input>
                </div>
                <div>
                  <p>{{ $t("date_month") }}</p>
                  <b-form-input
                    v-model="dob_month"
                    class="
                      bg-white
                      text-center text-dark
                      rounded
                      w-75
                      mx-auto
                      input-dob
                    "
                    maxlength="2"
                    placeholder="--"
                    readonly
                  ></b-form-input>
                </div>
                <div>
                  <p>{{ $t("date_year") }}</p>
                  <b-form-input
                    v-model="dob_year"
                    class="
                      bg-white
                      text-center text-dark
                      rounded
                      w-75
                      mx-auto
                      input-dob
                    "
                    maxlength="4"
                    placeholder="----"
                    readonly
                  ></b-form-input>
                </div> -->
              </div>
            </div>
            <!-- <p v-if="ageString" class="fw-600 mt-4">{{ ageString }}</p> -->
          </div>

          <!--age_range-->
          <div :style="`display:${step == 'age_range' ? '' : 'none'}`">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('match_age_range')"></h4>
            <div class="mx-auto">
              <div class="fs-20 fw-500 text-center text-ee1 mb-4">
                {{ age_ranges[0] }} - {{ age_ranges[1] }}
              </div>
              <vue-slider v-model="age_ranges" :min="18" :max="99" :min-range="5" :interval="1" :tooltip="'none'"
                :dotSize="30" :height="2" class="ee1" @change="ageRangeChange" :enable-cross="false" />
            </div>
          </div>

          <!--4 job title-->
          <div v-if="step == 'job_title'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('m_what_job_title')"></h4>
            <div class="mx-auto">
              <b-form-input id="job_title" v-model="profile.job_title" class="v6-2"
                @input="checkInput('job_title', 'job_title')" maxlength="32"
                :placeholder="$t('lang_jobtitle')"></b-form-input>
            </div>
          </div>

          <!--5 education-->
          <div v-if="step == 'education'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_highest_edu')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.educationLevel" :key="item.education_level_id" variant="white"
                class="w-100 mb-3 btn-big text-left" :class="profile.education_level_id == item.education_level_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'education_level_id',
      item.education_level_id,
      'education'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--5.2 school-->
          <div v-if="step == 'school' && profile && profile.education_level_id >= 1">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('w_school_name')"></h4>
            <div class="mx-auto">
              <b-form-input id="university_name" v-model="profile.university_name" type="text" class="v6-2"
                @input="checkInput('university_name', 'school')" maxlength="81"
                :placeholder="$t('enter_university_name')"></b-form-input>
            </div>
          </div>

          <!--6 income-->
          <div v-if="step == 'income'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_income_level')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.annualIncome" :key="item.annual_income_id" variant="white"
                class="mb-2 w-100 btn-big text-left" :class="profile.annual_income_id == item.annual_income_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'annual_income_id',
      item.annual_income_id,
      'income'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--7 child plan-->
          <div v-if="step == 'child_plan'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('w_child_plan')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.childPlans" :key="item.child_plans_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.child_plans_id == item.child_plans_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'child_plans_id',
      item.child_plans_id,
      'child_plan'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!-- match_attractive-->
          <div v-if="step == 'match_attractive'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('is_important_look')"></h4>
            <div class="d-flex justify-content-between mb-3 text-center">
              <b-button v-for="item in importants" :key="item" variant="white" class="mb-3 btn-big mr-2"
                style="width: 64px !important; height: 64px" :class="profile.looks_importance == item ? 'selected fw-600' : ''
                  " @click="
    selectAttrValue('looks_importance', item, 'match_attractive')
    ">{{ item }}
              </b-button>
            </div>
            <div class="d-flex justify-content-between">
              <div>{{ $t("not_important") }}</div>
              <div>{{ $t("very_important") }}</div>
            </div>
          </div>

          <!--match_income-->
          <div v-if="step == 'match_income'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('is_important_income')"></h4>
            <div class="d-flex justify-content-between mb-3 text-center">
              <b-button v-for="item in importants" :key="item" variant="white" class="mb-3 btn-big mr-2"
                style="width: 64px !important; height: 64px" :class="profile.income_importance == item ? 'selected fw-600' : ''
                  " @click="
    selectAttrValue('income_importance', item, 'match_income')
    ">{{ item }}
              </b-button>
            </div>
            <div class="d-flex justify-content-between">
              <div>{{ $t("not_important") }}</div>
              <div>{{ $t("very_important") }}</div>
            </div>
          </div>

          <!--8 relationship status-->
          <div v-if="step == 'relationship_status'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('w_your_status')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.relationshipStatus" :key="item.relationship_status_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.relationship_status_id == item.relationship_status_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'relationship_status_id',
      item.relationship_status_id,
      'relationship_status'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>
          <div v-if="step == 'child_status'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('w_your_child_status')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.childStatus" :key="item.child_status_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.child_status_id == item.child_status_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'child_status_id',
      item.child_status_id,
      'child_status'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--9 tall-->
          <div v-if="step == 'height'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('w_you_height')"></h4>
            <div class="mx-auto">
              <!-- <div class="d-flex justify-content-between" id="input-tall">
                <div class="text-center py-3">
                  <b-spinner label="Loading..." variant="light"></b-spinner>
                </div>
              </div>-->
              <b-input-group class="v6-2" append="cm" style="width: 200px">
                <b-form-input id="height" v-model="profile.height" type="number" class="v6-2" :min="100" :max="250"
                  @input="checkInput('height', 'height')" placeholder="Enter your height"></b-form-input>
              </b-input-group>
            </div>
          </div>

          <!--residential_status-->
          <!-- <div v-if="step == 'residential'">
            <h4 class="mb-5 fw-700">What's your residential status?</h4>
            <div v-if="attrs" style="max-width: 280px" class="mx-auto">
              <b-button
                v-for="item in attrs.residentialStatus"
                :key="item.residential_status_id"
                variant="white"
                class="w-100 mb-3 btn-big text-left"
                :class="
                  profile.residential_status_id == item.residential_status_id
                    ? 'selected fw-600'
                    : ''
                "
                @click="
                  selectAttrValue(
                    'residential_status_id',
                    item.residential_status_id,
                    'residential'
                  )
                "
                >{{ item.description }}
                <i
                  v-if="profile.residential_status_id == item.residential_status_id"
                  class="lsicon checked_red btn-tag"
                ></i
              ></b-button>
            </div>
          </div> -->

          <!--10 ethnicity-->
          <div v-if="step == 'ethnicity'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_u_ethnicity')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.ethnicity" :key="item.ethnicity_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.ethnicity_id == item.ethnicity_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'ethnicity_id',
      item.ethnicity_id,
      'ethnicity'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--11 religion_id-->
          <div v-if="step == 'religion'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_u_religious')"></h4>
            <div v-if="attrs" class="mx-auto text-center-">
              <b-button v-for="item in attrs.religion" :key="item.religion_id" variant="white"
                class="mb-2 mr-3 btn-big text-left" style="width: 45% !important" :class="profile.religion_id == item.religion_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue('religion_id', item.religion_id, 'religion')
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--free_time-->
          <div v-if="step == 'free_time'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('where_spend_time')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.freeTime" :key="item.free_time_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.free_time_id == item.free_time_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'free_time_id',
      item.free_time_id,
      'free_time'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--exercise-->
          <div v-if="step == 'exercise'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('how_often_exercise')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.exercise" :key="item.exercise_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.exercise_id == item.exercise_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue('exercise_id', item.exercise_id, 'exercise')
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--morning_person-->
          <div v-if="step == 'morning_person'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('are_moring_person')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.morningPerson" :key="item.morning_person_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.morning_person_id == item.morning_person_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue(
      'morning_person_id',
      item.morning_person_id,
      'morning_person'
    )
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--12 smoking_id-->
          <div v-if="step == 'smoking'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('do_you_smoke')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.smoking" :key="item.smoking_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.smoking_id == item.smoking_id ? 'selected fw-600' : ''
                  " @click="
    selectAttrValue('smoking_id', item.smoking_id, 'smoking')
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--13 drinking_id-->
          <div v-if="step == 'drinking'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('do_you_drink')"></h4>
            <div v-if="attrs" class="mx-auto">
              <b-button v-for="item in attrs.drinking" :key="item.drinking_id" variant="white"
                class="w-100 mb-2 btn-big text-left" :class="profile.drinking_id == item.drinking_id
                  ? 'selected fw-600'
                  : ''
                  " @click="
    selectAttrValue('drinking_id', item.drinking_id, 'drinking')
    ">{{ item.description }}
              </b-button>
            </div>
          </div>

          <!--food_habit_id-->
          <!-- <div v-if="step == 'food_habit'">
            <h4 class="mb-5 fw-700">{{$t('my_food_habit_is')}}</h4>
            <div v-if="attrs" style=": 280px" class="mx-auto">
              <b-button
                v-for="item in attrs.foodHabit"
                :key="item.food_habit_id"
                variant="white"
                class="w-100 mb-3 btn-big text-left"
                :class="
                  profile.food_habit_id == item.food_habit_id
                    ? 'selected fw-600'
                    : ''
                "
                @click="
                  selectAttrValue(
                    'food_habit_id',
                    item.food_habit_id,
                    'food_habit'
                  )
                "
                >{{ item.description }}
                <i
                  v-if="profile.food_habit_id == item.food_habit_id"
                  class="lsicon checked_red btn-tag"
                ></i
              ></b-button>
            </div>
          </div> -->

          <!--14 self intro-->
          <div v-if="step == 'intro'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('about_yourself')"></h4>

            <b-form-textarea id="selfintro" class="v6-2 text-1d fs-17" v-model="profile.self_intro"
              :placeholder="$t('introduce_yourself')" rows="4" max-rows="8"
              @input="checkInput('self_intro', 'intro')"></b-form-textarea>
          </div>

          <!--email-->
          <div v-if="step == 'email'">
            <h4 class="mb-4 fw-500 ff-prata fs-30" v-html="$t('what_u_email')"></h4>
            <div class="mx-auto">
              <b-form-input id="email" v-model="profile.email" type="email" class="v6-2 mb-3"
                @input="checkInput('email', 'email')" maxlength="64" :placeholder="$t('web_header_email')"></b-form-input>
              <p class="fs-12">{{ $t("recover_account_email") }}</p>
            </div>
          </div>

          <!--15 photo-->
          <div class="" :style="`display:${step == 'photo' ? '' : 'none'}`">
            <h4 v-if="selectedTotal == 0" class="mb-3 fw-500 ff-prata fs-30" v-html="$t('add_u_photos')"></h4>
            <h4 v-if="selectedTotal >= 2 && selectedTotal < 6" class="mb-3 fw-500 ff-prata fs-30">
              Add more photos
            </h4>

            <p v-if="selectedTotal < 2">{{ $t("add_photo_desc") }}</p>
            <p v-if="selectedTotal >= 2 && selectedTotal < 6" v-html="$t('more_photo_more_dates')
              .replace('<b>', '')
              .replace('</b>', '')
              "></p>
            <onePhotoUpload ref="onePhotoUpload" :red_ver="true" :photoNum="6" @photoStatus="photoEditorStatus" />
            <div style="clear: both"></div>
            <!-- <div class="mb-3" v-html="$t('good_profile_photo_examples')"></div> -->
            <!-- <div v-if="selectedTotal == 0">
              <div class="mb-3" v-html="$t('tip_desc')"></div>
              <div
                class="d-flex justify-content-between mx-auto"
                style="max-width: 500px; width: 100%"
              >
                <img
                  src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/profile_pic1.png"
                  style="width: 30%"
                />
                <img
                  src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/profile_pic2.png"
                  style="width: 30%"
                />
                <img
                  src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/profile_pic3.png"
                  style="width: 30%"
                />
                
              </div>
            </div>
            <div
              v-if="selectedTotal == 1"
              class="mb-3"
              v-html="$t('2_photos_left')"
            ></div>
            <div
              v-if="selectedTotal == 2"
              class="mb-3"
              v-html="$t('1_photo_left')"
            ></div>
            <div
              v-if="selectedTotal == 3"
              class="mb-3"
              v-html="$t('add_3_photo_desc')"
            ></div> -->
          </div>

          <!--16 id photo-->
          <div class="text-center" :style="`display:${step == 'idphoto' || step == 'idphoto_dis' ? '' : 'none'
            }`">
            <h4 v-if="photoIdDisapprovedTime" class="fw-500 ff-prata fs-30 mb-4" v-html="$t('id_photo_rejected')"></h4>
            <h4 v-else class="fw-500 ff-prata fs-30 mb-4" v-html="$t('verify_u_identification')"></h4>

            <p v-if="photoIdDisapprovedTime" class="text-7e fw-500">
              {{ $t("take_id_photo_again") }}
            </p>
            <p v-else class="fw-500">
              {{ $t("take_id_photo") }}
            </p>

            <p class="text-7e">
              {{ $t("take_id_photo_desc") }}
            </p>

            <IDPhotoUpload ref="IDPhotoUpload" photoType="id_photo" :photoNum="1"
              :disapprovedTime="photoIdDisapprovedTime" @photoStatus="photoEditorStatus2" class="my-5" />
            <p class="fw-500 my-3 text-7e" v-html="$t('id_photo_desc')"></p>
            <p v-if="!photoIdDisapprovedTime" class="text-7e">
              <i>{{ $t("id_photo_desc2") }} </i>
            </p>
          </div>
        </div>
        <div v-if="type == 'edit'">
          <div v-if="showNextBtn &&
            step != 'photo' &&
            step != 'idphoto' &&
            step != 'idphoto_dis' &&
            step != 'miss'
            " class="d-flex justify-content-between pt-5 pb-3">
            <div>
              <a href="javascript:;" @click="cancelEdit" class="text-7e" style="height: 50px;
    display: block;
    line-height: 50px;">{{ $t('cancel_txt') }}</a>
            </div>
            <div>
              <b-button pill variant="ee1" @click="submitProfile" :class="canSubmit ? '' : 'disabled'" class="o-btn lg"><i
                  class="fa-light fa-check"></i></b-button>
            </div>
          </div>

        </div>
        <div v-if="type == 'reg'">
          <div v-if="showNextBtn &&
            step != 'photo' &&
            step != 'idphoto' &&
            step != 'idphoto_dis' &&
            step != 'miss'
            " class="pt-5 pb-3 text-right">
            <b-button pill variant="ee1" @click="submitProfile" :class="canSubmit ? '' : 'disabled'" class="o-btn lg"><i
                class="fa-light fa-arrow-right"></i>
              <!-- <span v-if="step == 'email'">{{ $t("m_continue_txt") }}</span>
              <span v-else>{{ $t("lang_page_next") }}</span> -->
            </b-button>
          </div>
          <div v-if="step == 'photo'" class="pt-5 pb-3 text-right">
            <!-- :class="selectedTotal == 0 || selectedTotal >= 3 ? '' : 'disabled'" -->
            <b-button pill variant="ee1" @click="submitProfile" class="o-btn lg"><i class="fa-light fa-arrow-right"></i>
              <!-- <span v-if="selectedTotal < 2">{{ $t("upload_a_photo") }}</span>
              <span v-else>{{ $t("lang_page_next") }}</span> -->
            </b-button>
          </div>
          <!-- <div v-if="step == 'intro'" class="text-center mb-5">
                <a @click="skipToNext" href="javascript:;" class="text-white">Skip for now</a>
            </div> -->
          <div v-if="step == 'idphoto' || step == 'idphoto_dis'" class="py-5">
            <b-button pill variant="ee1" @click="submitProfile" class="shadow-sm px-5 l-space2 text-uppercase"
              style="height: 53px">
              <span v-if="!selectedTotal || selectedTotal == 0">{{
                $t("upload_photo_id")
              }}</span>
              <span v-else>{{ $t("lang_page_next") }}</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";

import onePhotoUpload from "../common/onePhotoUpload";
import IDPhotoUpload from "../common/onePhotoUpload";
import helper from "../../utils/helper";
import datePicker from "../../utils/ios_datepicker.js";
// import { accountStatus } from "../../utils/mixin";
import pageTop from "../common/pageTop";
export default {
  name: "reg-step3",
  components: {
    // v5Top,
    pageTop,
    onePhotoUpload,
    IDPhotoUpload,
    VueSlider,
  },
  data() {
    return {
      type: "reg",
      step: null,
      showPage: false,
      canSubmit: false,
      showSubBtn: true,
      client: null,
      attrs: null,
      age_ranges: [20, 25],
      profile: {
        // ethnicity_id: 0,
        gender_id: null,
        first_name: "",
        height: null,
        gender: "",
        dob: null,
        education_level_id: 0,
        annual_income_id: 0,
        child_status_id: 0,
        child_plans_id: 0,
        relationship_status_id: "",
        job_title: "",
        email: "",
        university_name: "",
        self_intro: "",
        ethnicity_id: 0,
        food_habit_id: 0,
        drinking_id: 0,
        smoking_id: 0,
        religion_id: 0,
        ethnicity_id: 0,
      },
      currYear: null,
      minYear: null,
      maxYear: null,
      calendar: null,
      cancelText: this.$t("cancel_txt"),
      confirmText: this.$t("Ok"),
      ageString: null,
      curLang: this.$store.getters.curLang,
      userInfo: this.$storage.get("userInfo"),
      dob_year: "",
      dob_month: "",
      dob_day: "",
      pageRef: "",
      selectedTotal: null,
      bgClass: "",
      showStepIcon: true,
      photoIdDisapprovedTime: null,
      tallPicker: null,
      uploadBtnClick: false,
      showNextBtn: false,
      importants: [1, 2, 3, 4, 5],
    };
  },
  computed: {
    progressValue() {
      let v = 0;
      switch (this.step) {
        case "relationship_type":
          v = 4;
          break;
        case "first_name":
          v = 4 * 2;
          break;
        case "gender":
          v = 4 * 3;
          break;
        case "dob":
          v = 4 * 4;
          break;
        case "age_range":
          v = 4 * 5;
          break;
        case "job_title":
          v = 4 * 6;
          break;
        case "education": //residential
        case "school": //residential
          v = 4 * 7;
          break;
        case "income":
          v = 4 * 8;
          break;
        case "child_plan":
          v = 4 * 9;
          break;
        case "match_attractive":
          v = 4 * 10;
          break;
        case "match_income":
          v = 4 * 11;
          break;
        case "relationship_status":
          v = 4 * 12;
          break;
        case "child_status":
          v = 4 * 13;
          break;
        case "height":
          v = 4 * 14;
          break;
        case "ethnicity":
          v = 4 * 15;
          break;
        case "religion":
          v = 4 * 16;
          break;
        case "free_time":
          v = 4 * 17;
          break;
        case "exercise":
          v = 4 * 18;
          break;
        case "morning_person":
          v = 4 * 19;
          break;
        case "smoking":
          v = 4 * 20;
          break;
        case "drinking":
          v = 4 * 21;
          break;
        case "intro":
          v = 4 * 22;
          break;
        case "email":
          v = 4 * 23;
          break;
        case "photo":
          v = 100;
          break;
      }
      v = v > 100 ? 100 : v;
      return `${v}%`;
    },
  },
  //   mixins: [accountStatus],
  mounted() {
    let self = this;
    // self.$parent.closeLoading();
    // $("body").css({ background: "#e61e5a !important" });

    // self.type = _.get(self.$route, "query.type", 'reg');
    self.type = "reg";
    let step = "first_name";
    if (self.$route.name == "profile-edit-name") {
      self.type = "edit";
      // self.type = 'edit';
      step = _.get(self.$route, "params.name", "first_name");
    } else {
      // self.type = 'edit';
      step = _.get(self.$route, "query.step", "first_name");
    }

    // console.log(step, self.$route);
    self.pageRef = _.get(self.$route, "query.ref", "");
    // self.actName = _.get(self.$route, "query.act", "reg");

    if (self.type == "reg") {
      self.$refs.pageTop.hide();
    } else {
      self.$refs.pageTop.setPageTitle(self.$t("progress_title"))
    }

    if (step) {
      self.step = step ? step : "first_name";
      self.setFocus();
    }

    if (self.step == "age_range") {
      self.canSubmit = true;
    }

    // console.log("self.step=", self.step);
    if (self.step == "idphoto_dis" || self.step == "email") {
    } else {
      $("body").addClass("bg-black");
    }

    self.currYear = new Date().getFullYear();
    self.minYear = self.currYear - 100;
    self.maxYear = self.currYear - 18;
    // console.log("fetchAttrs 11");
    self.fetchAttrs();
    // console.log("fetchAttrs 22", self.selectedTotal);
    self.$api
      .getClientMe(self.$store.state, "")
      .then((rs) => {
        // console.log("getClientMe 11");
        // console.log("rs");
        self.$layer.closeAll("loading");
        self.client = _.get(rs, "client");
        // self.checkAccountStatus(self.client);
        // console.log(
        //   "client_photos size",
        //   _.size(_.get(self.client, "client_photos"))
        // );
        self.selectedTotal = _.size(_.get(self.client, "client_photos", []));
        // self.first_name = _.get(rs, "client.first_name");
        // self.gender = _.get(rs, "client.gender", "m");
        // self.email = _.get(rs, "client.email", "");
        // self.photoCdnBase = _.get(rs, "client.photo_cdn_base");
        // console.log("getClientMe 22", self.selectedTotal);

        let dob = _.get(rs, "client.dob");
        //self.photoLen = self.client.photos ? self.client.photos.length : 0;
        // self.client.city_id = 7;
        // self.client.membership_level_id = 3;
        // self.client.photo_id_disapproved_time = "sss";
        // console.log(
        //   "photo_id_disapproved_time=",
        //   self.client.photo_id_disapproved_time
        // );
        // console.log("1111-000÷0", dob);
        if (dob && dob.split("-").length == 3) {
          let dd = dob.split("-");
          self.dob_year = dd[0];
          self.dob_month = dd[1];
          self.dob_day = dd[2];
          //   self.dob = dob;
        } else {
          //   self.dob_year = self.currYear - 30;
          //   self.dob_month = 1;
          //   self.dob_day = 1;
        }
        // console.log("2222-0000");
        // self.confrimContent = self
        //   .$t("matchmaker_you_soon")
        //   .replace(
        //     "[phone_number]",
        //     `(${self.client.country_phone_code}) ${self.client.phone_number}`
        //   );

        // self.setPickerData();
        // if (step == 0) {
        //   self.checkStep0();
        // }
        self.photoIdDisapprovedTime = _.get(
          rs,
          "client.photo_id_disapproved_time"
        );

        // self.photoIdDisapprovedTime = '2024';

        let relationship_type_ids = _.get(
          rs,
          "client.client_requirement.relationship_type_ids",
          0
        );

        self.profile = {
          //   ethnicity_id:
          //     _.get(rs, "client.ethnicity_id") > 0
          //       ? _.get(rs, "client.ethnicity_id")
          //       : 0,
          residential_status_id:
            _.get(rs, "client.residential_status_id") != 0
              ? _.get(rs, "client.residential_status_id")
              : 0,
          education_level_id:
            _.get(rs, "client.education_level_id") != 0
              ? _.get(rs, "client.education_level_id")
              : 0,
          annual_income_id:
            _.get(rs, "client.annual_income_id") > 0
              ? _.get(rs, "client.annual_income_id")
              : 0,
          child_status_id:
            _.get(rs, "client.child_status_id") > 0
              ? _.get(rs, "client.child_status_id")
              : 0,
          child_plans_id:
            _.get(rs, "client.child_plans_id") > 0
              ? _.get(rs, "client.child_plans_id")
              : 0,
          relationship_status_id:
            _.get(rs, "client.relationship_status_id") > 0
              ? _.get(rs, "client.relationship_status_id")
              : 0,
          job_title: _.get(rs, "client.job_title", ""),
          email: _.get(rs, "client.email", ""),
          first_name: _.get(rs, "client.first_name", ""),
          height: _.get(rs, "client.height"),
          dob: _.get(rs, "client.dob", ""),
          gender: _.get(rs, "client.gender", ""),
          self_intro: _.get(rs, "client.self_intro", ""),
          university_name: _.get(rs, "client.university_name", ""),
          food_habit_id:
            _.get(rs, "client.food_habit_id") > 0
              ? _.get(rs, "client.food_habit_id")
              : 0,
          drinking_id:
            _.get(rs, "client.drinking_id") > 0
              ? _.get(rs, "client.drinking_id")
              : 0,
          smoking_id:
            _.get(rs, "client.smoking_id") > 0
              ? _.get(rs, "client.smoking_id")
              : 0,
          religion_id:
            _.get(rs, "client.religion_id") > 0
              ? _.get(rs, "client.religion_id")
              : 0,
          ethnicity_id:
            _.get(rs, "client.ethnicity_id") > 0
              ? _.get(rs, "client.ethnicity_id")
              : 0,
          exercise_id:
            _.get(rs, "client.exercise_id") > 0
              ? _.get(rs, "client.exercise_id")
              : 0,
          free_time_id:
            _.get(rs, "client.free_time_id") > 0
              ? _.get(rs, "client.free_time_id")
              : 0,
          morning_person_id:
            _.get(rs, "client.morning_person_id") > 0
              ? _.get(rs, "client.morning_person_id")
              : 0,
          age_range_min: _.get(
            rs,
            "client.client_requirement.age_range_min",
            0
          ),
          age_range_max: _.get(
            rs,
            "client.client_requirement.age_range_max",
            0
          ),
          looks_importance: _.get(
            rs,
            "client.client_requirement.looks_importance",
            0
          ),
          income_importance: _.get(
            rs,
            "client.client_requirement.income_importance",
            0
          ),
          relationship_type_id:
            relationship_type_ids && relationship_type_ids != "0"
              ? relationship_type_ids
              : "",
        };
        self.age_ranges = _.get(rs, "client.age_ranges");
        //   ? _.get(rs, "client.age_ranges")
        //   : [30, 35];
        // self.profile.height = 0;

        if (self.profile.gender == "m") {
          self.profile.gender_id = 1;
        } else if (self.profile.gender == "f") {
          self.profile.gender_id = 2;
        }

        // // if (self.client.membership_level_id < 2 && self.step < 11) {
        // //   self.showStepIcon = true;
        // // } else {
        self.showStepIcon = false;
        // // }
        // console.log("self.profile=", self.profile);

        if (self.step == "dob") {
          setTimeout(function () {
            //   console.log("dddddd");
            self.initDatePicker();
          }, 300);
        }
        // console.log(self.step)
        self.selectedTotal = 0;
        if (self.step == "photo") {
          self.$refs.onePhotoUpload.loadPhotos(
            _.get(self.client, "client_photos"),
            _.get(self.client, "photo_cdn_base")
          );
          self.showPage = true;
          // console.log('self.showPage=', self.showPage)
        } else {
          let photo_len = _.get(self.client, "client_photos", []).length;
          if (photo_len >= 2) {
            self.selectedTotal = photo_len;
          }
        }

        if (self.step == "height") {
          setTimeout(function () {
            //   console.log("dddddd");
            self.initTallPicker();
          }, 300);
        }
        if (self.step == "gender") {
          self.genderbtnEvent();
        }
        if (self.step == "email") {
          $("body").removeClass("bg-black").addClass("bg-white");
          $("#stepPage").removeClass("bg-black").addClass("bg-white");
        }

        // if(self.step == 'idphoto'){
        //     if (self.photoIdDisapprovedTime) {
        //         $("body").removeClass("bg-black").addClass('bg-white');
        //         $("#stepPage").removeClass("bg-black").addClass('bg-white');
        //         // setTimeout(function(){
        //         //     $("body").removeClass("bg-black").addClass('bg-white');
        //         //     $("#stepPage").removeClass("bg-black").addClass('bg-white');
        //         //     console.log('photoIdDisapprov')
        //         // }, 400)
        //     }
        // }

        // console.log("hhhh");

        if (!self.step) {
          self.checkNextStep();
        }

        self.checkNextBtnStatus(self.step);
        // console.log("getClientMe 22");
        // if (self.step == 3) {
        //   console.log("dddddd-0000");
        //   setTimeout(function () {
        //     console.log("dddddd");
        //     self.initDatePicker();
        //   }, 800);
        // }
        // if (self.step == 11) {
        //   self.$refs.onePhotoUpload.loadPhotos(
        //     _.get(rs, "client.client_photos"),
        //     _.get(rs, "client.photo_cdn_base")
        //   );
        // }

        self.checkInput("", self.step);
        // console.log("getClientMe 33");
        // setTimeout(function () {
        //   $("body").css({ background: "#e61e5a !important" });
        // }, 400);
        console.log("self.step=", self.step);
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
      });
  },
  methods: {
    cancelEdit() {
      this.$router.back();
    },
    checkNextBtnStatus(step) {
      let self = this;
      if (
        _.includes(
          [
            "first_name",
            "dob",
            "age_range",
            "job_title",
            "school",
            "height",
            "intro",
            "email",
          ],
          step
        )
      ) {
        self.showNextBtn = true;
      } else {
        self.showNextBtn = false;
      }
    },
    skipToNext() {
      // let steps = [
      //     'first_name', 'gender', 'dob', 'photo',
      //     'job_title'
      // ];
      this.step = "email";
    },
    ageRangeChange() {
      this.canSubmit = true;
    },
    initTallPicker() {
      return false;
      //input-tall
      let self = this;
      self.tallPicker = new datePicker();

      if (!self.profile.height) {
        self.profile.height = 170;
      }

      self.tallPicker.init({
        trigger: "#input-tall",
        type: "onelist",
        minY: 120,
        maxY: 230,
        unitName: "CM",
        dvalue: self.profile.height > 0 ? self.profile.height : 170,
        langKey: "en",
        okText: "OK",
        cancelText: "Cancel",
        onSubmit: function () {
          /*确认时触发事件*/
          // self.profile.dob = self.tallPicker.date_value;
          //   console.log(
          //     self.tallPicker.value,
          //     self.tallPicker.date_value
          //     //   self.profile.dob
          //   );
          self.profile.height = self.tallPicker.value;
          self.canSubmit = true;
          //   console.log("profile", self.profile);
        },
        onClose: function () {
          /*取消时触发事件*/
        },
      });

      self.canSubmit = true;
    },
    initDatePicker() {
      let self = this;
      //   console.log("initDatePicker");
      self.calendar = new datePicker();

      let d_dob = self.profile.dob
        ? self.profile.dob
        : `${self.currYear - 30}-1-1`;

      // if(!self.profile.dob){
      //     self.profile.dob = d_dob
      // }

      self.calendar.init({
        trigger: "#input-dob",
        type: "date",
        minDate: `${self.minYear}-1-1`,
        maxDate: `${self.maxYear}-12-31`,
        dvalue: d_dob,
        langKey: self.curLang.indexOf("zh") > -1 ? "zh" : "en",
        okText: self.confirmText,
        cancelText: self.cancelText,
        onSubmit: function () {
          /*确认时触发事件*/
          self.profile.dob = self.calendar.date_value;
          //   console.log(
          //     self.calendar.value,
          //     self.calendar.date_value,
          //     self.profile.dob
          //   );

          let parseRes = helper.parseDate(self.calendar.date_value);
          //   self.ageString = self
          //     .$t("u_are_age")
          //     .replace("[age]", self.currYear - parseRes.yy);
          //   self.ageString = `Your are ${self.currYear - parseRes.yy} years old`;
          //   self.checkSubmitStatus();
          //   console.log("parseRes", parseRes);
          //   calendar.value = `${parseRes.dd} ${calendar.month_name} ${parseRes.yy}`;
          self.dob_year = parseRes.yy;
          self.dob_month = parseRes.mm;
          self.dob_day = parseRes.dd;

          self.canSubmit = true;
        },
        onClose: function () {
          /*取消时触发事件*/
        },
      });
    },
    checkInput(name, step) {
      let self = this;

      self.canSubmit = false;
      //   console.log("step=", step);

      if (step == "first_name") {
        if (self.profile.first_name) {
          self.canSubmit = true;
        }
      } else if (step == "gender") {
        if (self.profile.gender_id) {
          self.canSubmit = true;
          self.genderbtnEvent();
        }
      } else if (step == "dob") {
        if (self.profile.dob) {
          self.canSubmit = true;
        }
      } else if (step == "job_title") {
        if (self.profile.job_title) {
          self.canSubmit = true;
        }
      } else if (step == "income") {
        if (self.profile.annual_income_id) {
          self.canSubmit = true;
        }
      } else if (step == "education") {
        if (self.profile.education_level_id) {
          self.canSubmit = true;
        }
      } else if (step == "relationship_status") {
        if (self.profile.relationship_status_id) {
          self.canSubmit = true;
        }
      } else if (step == "child_status") {
        if (self.profile.child_status_id) {
          self.canSubmit = true;
        }
      } else if (step == "child_plan") {
        if (self.profile.child_plans_id) {
          self.canSubmit = true;
        }
      } else if (step == "height") {
        if (self.profile.height >= 120 && self.profile.height < 230) {
          self.canSubmit = true;
        }
      } else if (step == "email") {
        // console.log("check email", step, self.canSubmit);
        if (helper.checkEmail(self.profile.email)) {
          //   console.log("true");
          self.canSubmit = true;
        }
      } else if (step == "photo") {
        if (self.client.photos && self.client.photos.length >= 3) {
          self.canSubmit = true;
        }
      } else if (step == "school") {
        if (self.profile.university_name) {
          self.canSubmit = true;
        }
      } else if (step == "intro") {
        if (self.profile.self_intro) {
          self.canSubmit = true;
        }
      } else if (step == "food_habit") {
        if (self.profile.food_habit_id) {
          self.canSubmit = true;
        }
      } else if (step == "drinking") {
        if (self.profile.drinking_id) {
          self.canSubmit = true;
        }
      } else if (step == "smoking") {
        if (self.profile.smoking_id) {
          self.canSubmit = true;
        }
      } else if (step == "religion") {
        if (self.profile.religion_id) {
          self.canSubmit = true;
        }
      } else if (step == "ethnicity") {
        if (self.profile.ethnicity_id) {
          self.canSubmit = true;
        }
      } else if (step == "age_range") {
        self.canSubmit = true;
      } else if (step == "idphoto" || step == "idphoto_dis") {
        self.selectedTotal = 0;
        // if(!self.client.identity_card_proof){
        //     self.selectedTotal = 0
        // }else{
        //     self.$refs.IDPhotoUpload.loadPhotos(
        // [
        //     {
        //         client_photo_id: 0,
        //         photo_url: self.client.identity_card_proof,
        //         is_primary: 0
        //     }
        // ],
        // _.get(self.client, "photo_cdn_base")
        // );
        // }
      }
    },
    genderbtnEvent() {
      let self = this;
      //   console.log("genderbtnEvent init");
      if ($(".genderbtn").length == 0) {
        // console.log("no gender btn");
        return false;
      }
      $(".genderbtn").on("click", function () {
        // console.log("genderbtnEvent click");
        let btn = $(this);
        let val = parseInt(btn.attr("data-id"));
        // console.log("gender click", val);
        if (val != 1 && val != 2) {
          return false;
        }
        // console.log("genderbtnEvent", val);
        self.profile.gender_id = val;
        self.profile.gender = val == 1 ? "m" : "f";

        $(".genderbtn").removeClass("selected fw-600");
        $(".genderbtn i.checked_red").hide();
        btn.addClass("selected fw-600");
        btn.children("i.checked_red").show();

        // if ('gender' == self.step) {
        //   self.canSubmit = true;
        // } else {
        //   self.canSubmit = false;
        // }
        self.submitProfile();
      });
    },
    selectAttrValue(name, value, step) {
      let self = this;
      if (name == "gender_id") {
        self.profile.gender_id = value;
        self.profile.gender = value == 1 ? "m" : "f";
      } else {
        self.profile[name] = value;
      }

      // console.log("selectAttrValue", name, value, step, self.profile);
      //   if (step == self.step) {
      //     self.canSubmit = true;
      //   } else {
      //     self.canSubmit = false;
      //   }
      if (self.type == "reg" && !self.showNextBtn) {
        self.submitProfile();
      } else if (self.type == "edit") {
        self.submitProfile();
      }
    },
    setFocus() {
      let self = this;
      //   console.log("setFocus");
      setTimeout(function () {
        if (self.step == "first_name") {
          $("#first_name").focus();
          console.log("set 1 focus");
        } else if (self.step == "job_title") {
          $("#job_title").focus();
          console.log("set 4 focus");
        } else if (self.step == "height") {
          $("#height").focus();
          console.log("set 9 focus");
        } else if (self.step == "email") {
          $("#email").focus();
          console.log("set 5 focus");
        }
      }, 800);
    },
    gotoMatchpage() {
      let self = this;

      if (self.client.membership_level_id >= 2) {
        self.$router.push({ name: "matchmaking-new-matches" });
      } else if (
        self.client.city.has_diamond == 1 &&
        self.client.membership_level_id <= 1
      ) {
        //
        self.$router.push({ name: "op-match-list" });
        // self.$router.push({ name: "matchmaking-callback" });
      } else if (
        self.client.city.has_diamond == 0 &&
        self.client.membership_level_id <= 1
      ) {
        //
        self.$router.push({ name: "op-match-list" });
      } else if (self.client.dia_call_back == 1) {
        self.$router.push({ name: "op-match-list" });
        // self.$router.push({ name: "matchmaking-callback" });
      } else {
        self.$router.push({ name: "op-match-list" });
        // self.$router.push({ name: "matchmaking-index" });
      }
      self.$layer.closeAll("loading");
    },
    photoEditorStatus2(data) {
      let self = this;
      console.log("photoEditorStatus2", data);
      self.selectedTotal = _.get(data, "selectedTotal");
      self.canUploadPhoto = _.get(data, "canSubmit");
      //   self.checkSubmitStatus();
      if (self.canUploadPhoto) {
        self.canSubmit = true;
      } else {
        self.canSubmit = false;
      }

      if (_.get(data, "uploadCompleted")) {
        $("body").removeClass("bg-white").addClass("bg-black");
        $("#stepPage").removeClass("bg-white").addClass("bg-black");
        self.gotoMatchpage();
        // $("body").removeClass("bg-ls-red");
        // self.$router.push({ name: "elite-list" });
        // if (self.client.membership_level_id > 1) {
        //   //   self.$router.push({ name: "elite-list" });
        //   //   self.$router.push({ name: "client-chat" });
        //   self.$router.push({
        //     name: "match-new",
        //     query: { ref: self.pageRef },
        //   });
        // } else {
        //   self.$router.push({
        //     // name: "free-index",
        //     name: "free-loading",
        //     query: { ref: self.pageRef },
        //   });
        // }
        // self.$router.push({ name: "match-new", query: { ref: "reg" } });
      }
    },
    photoEditorStatus(data) {
      let self = this;

      self.selectedTotal = _.get(data, "selectedTotal");
      self.canUploadPhoto = _.get(data, "canSubmit");
      //   self.selectedTotal = 0;
      //   console.log("photoEditorStatus", self.selectedTotal);
      //   self.checkSubmitStatus();
      if (self.canUploadPhoto) {
        self.canSubmit = true;
      } else {
        self.canSubmit = false;
      }

      if (_.get(data, "photo_url")) {
        self.client.photos = _.get(data, "photo_url");
      }

      //   console.log("photoEditorStatus", self.selectedTotal, self.uploadBtnClick, data, self.step);

      if (self.uploadBtnClick && _.get(data, "selectedTotal") >= 2) {
        //   if (_.get(data, "uploadCompleted")) {
        // console.log("uploadCompleted");
        //     // self.$router.push({ name: "elite-list" });
        //     if (self.client.membership_level_id > 1) {
        //       self.$router.push({ name: "match-new", query: { ref: self.pageRef } });
        //     } else {
        //       self.$router.push({ name: "free-index", query: { ref: self.pageRef } });
        //     }
        //     // self.$router.push({ name: "match-new", query: { ref: "reg" } });
        // console.log('--checkNextStep')
        self.checkNextStep();
      }
    },
    checkNextStep() {
      let self = this;
      self.showSubBtn = true;

      $("body").removeClass("bg-white").addClass("bg-black");
      $("#stepPage").removeClass("bg-white").addClass("bg-black");
      if(self.step == 'miss'){
        self.step = 'know-better'
      }else if (!self.profile.education_level_id) {
        self.step = "education";
        self.showNextBtn = false;
      } else if (
        !self.profile.university_name &&
        self.profile.education_level_id >= 1
      ) {
        self.step = "school";
        self.showNextBtn = true;
      } else if (!self.profile.annual_income_id) {
        self.step = "income";
        self.showNextBtn = false;
      } else if (!self.profile.relationship_status_id) {
        self.step = "relationship_status";
        self.showNextBtn = false;
      } else if (!self.profile.height) {
        self.step = "height";
        //initTallPicker
        setTimeout(function () {
          //   console.log("dddddd");
          self.initTallPicker();
        }, 300);
        self.showNextBtn = true;
      } else if (!self.profile.ethnicity_id) {
        self.step = "ethnicity";
        self.showNextBtn = false;
      } else if (!self.profile.religion_id) {
        self.step = "religion";
        self.showNextBtn = false;
      } else if (!self.profile.exercise_id) {
        self.step = "exercise";
        self.showNextBtn = false;
      } else if (!self.profile.smoking_id) {
        self.step = "smoking";
        self.showNextBtn = false;
      } else if (!self.profile.drinking_id) {
        self.step = "drinking";
        self.showNextBtn = false;
      }else if(self.step == 'drinking'){
        self.step = 'delve-intro'
      }else if (!self.profile.relationship_type_id) {
        self.step = "relationship_type";
        self.showNextBtn = false;
      } else if (!self.profile.child_plans_id) {
        self.step = "child_plan";
        self.showNextBtn = false;
      }else if(self.step == 'child_plan'){
        self.step = 'discover-love'
      }else if (!self.profile.self_intro) {
        self.step = "intro";
        self.showNextBtn = true;
      }
      
      // else if (!self.profile.first_name) {
      //   self.step = "first_name";
      //   self.showNextBtn = true;
      // } else if (!self.profile.gender_id) {
      //   self.step = "gender";
      //   setTimeout(function () {
      //     self.genderbtnEvent();
      //   }, 500);
      //   self.showNextBtn = false;
      // } else if (!self.profile.dob) {
      //   self.step = "dob";
      //   // console.log("hhhh");
      //   setTimeout(function () {
      //     //   console.log("dddddd");
      //     self.initDatePicker();
      //   }, 300);
      //   self.showNextBtn = true;
      // } else if (!self.profile.age_range_min && !self.profile.age_range_max) {
      //   self.step = "age_range";
      //   self.showNextBtn = true;
      // } else if (!self.profile.job_title) {
      //   self.step = "job_title";
      //   self.showNextBtn = true;
      // } 
      // else if (!self.profile.looks_importance) {
      //   self.step = "match_attractive";
      //   self.showNextBtn = false;
      // } else if (!self.profile.income_importance) {
      //   self.step = "match_income";
      //   self.showNextBtn = false;
      // } 
      
      // else if (!self.profile.free_time_id) {
      //   self.step = "free_time";
      //   self.showNextBtn = false;
      // } 
      
      // else if (!self.profile.morning_person_id) {
      //   self.step = "morning_person";
      //   self.showNextBtn = false;
      // } 
        else if (self.selectedTotal < 2) {
        self.$refs.onePhotoUpload.loadPhotos(
          _.get(self.client, "client_photos"),
          _.get(self.client, "photo_cdn_base")
        );

        self.step = "photo";
        self.showSubBtn = false;
      } 
      // else if (!self.profile.email) {
      //   self.step = "email";
      //   self.showNextBtn = true;
      //   $("body").removeClass("bg-black").addClass("bg-white");
      //   $("#stepPage").removeClass("bg-black").addClass("bg-white");
      // } 
      
      else if (
        (!self.client.identity_card_proof ||
          self.client.photo_id_disapproved_time) &&
        !self.client.identity_card
      ) {
        self.step = "idphoto";
        self.selectedTotal = 0;

        if (self.client.photo_id_disapproved_time) {
          $("body").removeClass("bg-black").addClass("bg-white");
          $("#stepPage").removeClass("bg-black").addClass("bg-white");
        }

        if (self.$refs.onePhotoUpload) {
          //   console.log("onePhotoUpload.clearAndInit");
          self.$refs.onePhotoUpload.clearAndInit();
        } else if (self.$refs.IDPhotoUpload) {
          //   console.log("IDPhotoUpload.clearAndInit");
          self.$refs.IDPhotoUpload.clearAndInit();
        }

        if (!self.client.identity_card_proof) {
          self.selectedTotal = 0;
        } else {
          self.$refs.IDPhotoUpload.loadPhotos(
            [
              {
                client_photo_id: 0,
                photo_url: self.client.identity_card_proof,
                is_primary: 0,
              },
            ],
            _.get(self.client, "photo_cdn_base")
          );
        }
      } else {
        self.gotoMatchpage();
        return false;
      }
    },
    checkNextStepOld() {
      let self = this;
      //   console.log("ttt");
      // self.bgClass = "bg-ls-red";
      self.showSubBtn = true;
      //   $("body").addClass("bg-ls-red");
      let m_id = _.get(self.client, "membership_level_id", 0);
      let freeOP = false;
      if (_.get(self.client, "city.lsm_online_payment", 0) == 1 && m_id <= 1) {
        freeOP = true;
      }

      let paid = m_id >= 2 ? true : false;
      let city_id = _.get(self.client, "city.city_id", 1);
      //1
      if (!self.profile.first_name) {
        self.step = "first_name";
      }
      //2
      else if (!self.profile.gender_id) {
        self.step = "gender";
        setTimeout(function () {
          self.genderbtnEvent();
        }, 500);
      }
      //3
      else if (!self.profile.dob) {
        self.step = "dob";
        // console.log("hhhh");
        setTimeout(function () {
          //   console.log("dddddd");
          self.initDatePicker();
        }, 300);
      }

      //5
      else if (!self.profile.job_title) {
        self.step = "job_title";
      } else if (
        (city_id == 3 || city_id == 34) &&
        paid &&
        !self.profile.residential_status_id
      ) {
        self.step = "residential";
      }
      //6
      else if ((paid || freeOP) && !self.profile.annual_income_id) {
        self.step = "income";
      }
      //7
      else if ((paid || freeOP) && !self.profile.education_level_id) {
        self.step = "education";
      }
      //7.2
      else if (
        paid &&
        !self.profile.university_name &&
        self.profile.education_level_id >= 1
      ) {
        self.step = "school";
      }

      //   else if (!self.profile.residential_status_id && (self.client.city_id==3 || self.client.city_id==34)) {
      //     self.step = 'residential_status';
      //   }

      //    else if (!self.age_ranges) {
      //     self.age_ranges = [30, 35];
      //     self.step = "age_range";
      //     self.canSubmit = true;
      //   }
      //8
      else if ((paid || freeOP) && !self.profile.relationship_status_id) {
        self.step = "relationship_status";
      }
      //9
      else if ((paid || freeOP) && !self.profile.child_plans_id) {
        self.step = "child_plan";
      }
      //10
      else if (paid && !self.profile.height) {
        self.step = "height";
        //initTallPicker
        setTimeout(function () {
          //   console.log("dddddd");
          self.initTallPicker();
        }, 300);
      }
      //11
      else if (paid && !self.profile.ethnicity_id) {
        self.step = "ethnicity";
      }
      //12
      else if (paid && !self.profile.religion_id) {
        self.step = "religion";
      }
      //13
      else if (paid && !self.profile.smoking_id) {
        self.step = "smoking";
      }
      //14
      else if (paid && !self.profile.drinking_id) {
        self.step = "drinking";
      }
      //15
      else if (paid && !self.profile.self_intro) {
        self.step = "intro";
      }
      //16
      else if ((paid || freeOP) && !self.profile.email) {
        self.step = "email";
      } //4
      else if (paid && self.selectedTotal < 6) {
        self.$refs.onePhotoUpload.loadPhotos(
          _.get(self.client, "client_photos"),
          _.get(self.client, "photo_cdn_base")
        );

        self.step = "photo";
        self.showSubBtn = false;
      }
      //   else if (
      //     (self.pageRef == "upgrade" || self.client.membership_level_id >= 2) &&
      //     (!self.client.identity_card_proof ||
      //       self.client.photo_id_disapproved_time)
      //   ) {
      //     self.step = 12;
      //     self.selectedTotal = 0;

      //     if (self.client.photo_id_disapproved_time) {
      //       self.bgClass = "";
      //       $("body").removeClass("bg-ls-red");
      //     }

      //     if (self.$refs.onePhotoUpload) {
      //       //   console.log("onePhotoUpload.clearAndInit");
      //       self.$refs.onePhotoUpload.clearAndInit();
      //     } else if (self.$refs.IDPhotoUpload) {
      //       //   console.log("IDPhotoUpload.clearAndInit");
      //       self.$refs.IDPhotoUpload.clearAndInit();
      //     }
      //   }
      else {
        self.$router.push({ name: "matchmaking-index" });
        // $("body").removeClass("bg-ls-red");
        // if (self.client.membership_level_id > 1) {
        //   //
        //   self.$router.push({
        //     name: "match-new",
        //     query: { ref: self.pageRef },
        //   });
        // } else {
        //   self.$router.push({
        //     // name: "free-index",
        //     name: "free-loading",
        //     query: { ref: self.pageRef },
        //   });
        // }
        return false;
      }
      self.showPage = true;
      //   if (self.client.membership_level_id < 2 && self.step < 11) {
      //     self.showStepIcon = true;
      //   } else {
      //     self.showStepIcon = false;
      //   }
      //   console.log("self.step", self.step);
      self.checkInput("", self.step);
    },
    submitProfile() {
      let self = this;

      let fdata = new FormData();

      if (self.step == "relationship_type") {
        fdata.append("relationship_type_id", self.profile.relationship_type_id);
        //requirement
      } else if (self.step == "match_attractive") {
        fdata.append("looks_importance", self.profile.looks_importance);
        //requirement
      } else if (self.step == "match_income") {
        fdata.append("income_importance", self.profile.income_importance);
        //requirement
      } else if (self.step == "first_name") {
        fdata.append("first_name", self.profile.first_name);
      } else if (self.step == "gender") {
        fdata.append("gender", self.profile.gender_id == 1 ? "m" : "f");
      } else if (self.step == "dob") {
        fdata.append("dob", self.profile.dob);
      } else if (self.step == "job_title") {
        fdata.append("job_title", self.profile.job_title);
      } else if (self.step == "residential_status") {
        fdata.append(
          "residential_status_id",
          self.profile.residential_status_id
        );
      } else if (self.step == "income") {
        fdata.append("annual_income_id", self.profile.annual_income_id);
      } else if (self.step == "education") {
        fdata.append("education_level_id", self.profile.education_level_id);
      } else if (self.step == "relationship_status") {
        fdata.append(
          "relationship_status_id",
          self.profile.relationship_status_id
        );
      } else if (self.step == "child_status") {
        fdata.append("child_status_id", self.profile.child_status_id);
      } else if (self.step == "child_plan") {
        fdata.append("child_plans_id", self.profile.child_plans_id);
      } else if (self.step == "height") {
        // add check
        if (self.profile.height >= 250 || self.profile.height <= 120) {
          self.$layer.msg("Please enter a valid height: 120 ~ 250cm");
          self.canSubmit = false;
          return false;
        }

        fdata.append("height", self.profile.height);
      } else if (self.step == "email") {
        // add check
        if (!helper.checkEmail(self.profile.email)) {
          self.$layer.msg(self.$t("val_email_invalid"));
          self.canSubmit = false;
          return false;
        }

        fdata.append("email", self.profile.email);
      } else if (self.step == "photo") {
        if (!self.selectedTotal || self.selectedTotal < 2) {
          self.$refs.onePhotoUpload.toggleFile(self.selectedTotal);
        } else {
          self.$layer.loading();
          self.uploadBtnClick = true;
          self.$refs.onePhotoUpload.uploadImage();
        }

        // self.$router.push({ name: "match-new", query: { ref: "reg" } });
        return false;
      } else if (self.step == "idphoto" || self.step == "idphoto_dis") {
        if (!self.selectedTotal || self.selectedTotal == 0) {
          self.$refs.IDPhotoUpload.toggleIDFile();
        } else {
          self.$layer.loading();
          self.$refs.IDPhotoUpload.uploadOne();
        }

        // self.$router.push({ name: "match-new", query: { ref: "reg" } });
        return false;
      } else if (self.step == "school") {
        fdata.append("university_name", self.profile.university_name);
      } else if (self.step == "age_range") {
        self.profile.age_range_min = self.age_ranges[0];
        self.profile.age_range_max = self.age_ranges[1];
        fdata.append("age_range_min", self.age_ranges[0]);
        fdata.append("age_range_max", self.age_ranges[1]);
      } else if (self.step == "intro") {
        fdata.append("self_intro", self.profile.self_intro);
      } else if (self.step == "food_habit") {
        fdata.append("food_habit_id", self.profile.food_habit_id);
      } else if (self.step == "drinking") {
        fdata.append("drinking_id", self.profile.drinking_id);
      } else if (self.step == "smoking") {
        fdata.append("smoking_id", self.profile.smoking_id);
      } else if (self.step == "religion") {
        fdata.append("religion_id", self.profile.religion_id);
      } else if (self.step == "ethnicity") {
        fdata.append("ethnicity_id", self.profile.ethnicity_id);
      } else if (self.step == "free_time") {
        fdata.append("free_time_id", self.profile.free_time_id);
      } else if (self.step == "exercise") {
        fdata.append("exercise_id", self.profile.exercise_id);
      } else if (self.step == "morning_person") {
        fdata.append("morning_person_id", self.profile.morning_person_id);
      } else {
      }
      self.postData(fdata);
    },
    postData(fdata) {
      let self = this;

      //   self.step = parseInt(self.step) + 1;
      //   if (self.step == 3) {
      //     setTimeout(function () {
      //       self.initDatePicker();
      //     }, 800);
      //   }
      //   self.canSubmit = false;
      //   self.setFocus();

      //   console.log(self.profile, self.step);
      //   return false;

      self.$layer.loading();

      self.$api
        .postClientUpdate(fdata, self.$store.state)
        .then((rs) => {
          // console.log(rs);
          self.$layer.closeAll("loading");

          //   self.step = parseInt(self.step) + 1;

          //   if (self.step == 3) {
          //     setTimeout(function () {
          //       self.initDatePicker();
          //     }, 800);
          //   }

          // if(self.step == 3){
          //     self.age_ranges = [_.get(rs, 'ageRanges.age_min', 30), _.get(rs, 'ageRanges.age_max', 35)];
          // }

          //   if (self.step == 'age_range' && self.client.membership_level_id < 2) {
          //       $("body").removeClass("bg-ls-red");
          //     // self.$router.push({ name: "free-index" });
          //     self.$router.push({ name: "free-loading" });
          //     return false;
          //   }
          //   if(self.step == 10){
          //       self.step = 'age_range';
          //       self.canSubmit = true
          //   }else{

          //   }

          if (self.type == "edit") {
            $("body").removeClass("bg-black");
            self.$router.back();
          } else {
            self.checkNextStep();
            self.canSubmit = false;
            self.setFocus();
          }
        })
        .catch((err) => {
          //   console.log(["save error", err]);
          self.$layer.closeAll("loading");
          self.$layer.msg(
            _.get(
              err,
              "response.data.error.body",
              _.get(err, "message", "Failed")
            )
          );
          //   self.$layer.msg(err.response.data.error.body);
        });
    },
    fillAttrs() {
      //   console.log("fill-attrs");

      let self = this;

      let currency_id = _.get(self.userInfo, "default_currency_id", 1);

      let incomes = [];
      //   console.log(
      //     "self.attrs.annualIncome",
      //     self.userInfo,
      //     currency_id,
      //     self.attrs.annualIncome
      //   );
      for (let v of self.attrs.annualIncome) {
        if (v.currency_id == currency_id) {
          incomes.push(v);
        }
      }
      //   console.log("self.attrs.annualIncome", self.attrs.annualIncome, incomes);
      if (incomes.length == 0) {
        currency_id = 1;
        for (let v of self.attrs.annualIncome) {
          if (v.currency_id == currency_id) {
            incomes.push(v);
          }
        }
      }
      //console.log([currency_id, incomes]);
      self.attrs.annualIncome = incomes;

      //   console.log("self.attrs", self.attrs);
    },
    fetchAttrs() {
      let self = this;

      self.$api
        .getAttributes(self.$store.state)
        .then((rs) => {
          //   console.log("getAttributes success");
          self.attrs = rs;
          self.fillAttrs();
        })
        .catch((err) => {
          console.log(["error", rs]);
        });
    },
  },
};
</script>
<style scoped>
.textBody{
  position: relative;
    z-index: 3;
}
.bgVideo{
  position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
</style>