import { render, staticRenderFns } from "./upgradePopup.vue?vue&type=template&id=a8f3cef4&scoped=true&"
import script from "./upgradePopup.vue?vue&type=script&lang=js&"
export * from "./upgradePopup.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "a8f3cef4",
  "1b224940"
  
)

export default component.exports