<template>
    <div class="bg-black min-h100 text-white text-center">

        <div class="mx-auto" style="max-width: 480px !important">
            <iframe v-if="yotiUrl" :src="yotiUrl" style="height:100vh; width:100%; border:none;" allow="camera"></iframe>
            <div v-else class="py-5">
       <h3 class="mb-4">How it works</h3> 
       <p class="mb-4">
        Our facial recognition technology compares the face in your video selfie with an ID document of your choice (e.g. ID card, Passport, Driver's License). We delete your facial recognition information after verification – usually within 24 hours.
       </p>

       <div class="my-5">
            <b-button
            @click="verifyMe"
            variant="ls-gold-g2"
            pill
            size="lg"
            class="px-5 border-0 fw-600"
            >Verify me</b-button
          >
        </div>
        <div>
            <b-button pill variant="light" class="px-5">Maybe later</b-button>
        </div>
    </div>
    </div>
    </div>
</template>
<script>
export default {
    name: "verify-howitwork",
    data(){
        return {
            yotiUrl: null,
        }
    },
    mounted(){

    },
    methods:{
        verifyMe(){
            let self = this;
            self.$api.getYotiCreate(self.$store.state)
            .then(rs=>{
                self.yotiUrl = _.get(rs, 'verify_url')
            })
        }
    }
}
</script>