<template>
  <div class="min-h100 bg-faf text-1d">
    <pageTop
      ref="pageTop"
      classNames="page6-2"
    ></pageTop>

    <div v-if="profile" class="page6-2 pb-5">
      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("about_me_txt") }}
      </div>
      <div class="p-3 bg-faf border-d7c">
        <textarea
          class="form-control mt-2 rounded v6-2 fs-16"
          rows="6"
          placeholder="Introduce yourself"
          v-model="profile.self_intro"
          @change="textFieldUpdate('self_intro')"
          >{{ profile.self_intro }}</textarea
        >
      </div>

      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("basic_info_txt") }}
      </div>

      <ul class="list-group list-group-flush profileEdit">
        <li class="list-group-item bg-faf border-d7c">
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">{{ $t("first_name") }}</div>
            <div style="width: 150px" class="text-7e">
              {{ profile.first_name }}
            </div>
            <div style="width: 20px">&nbsp;</div>
          </div>
        </li>
        <li class="list-group-item bg-faf border-d7c">
          <div class="d-flex justify-content-between">
            <div class="text-capitalize" style="width: 140px">
              {{ $t("lang_gender") }}
            </div>
            <div style="width: 150px" class="text-7e">
              <span v-if="profile.gender == 'm'">{{ $t("Male") }}</span>
              <span v-if="profile.gender == 'f'">{{ $t("Female") }}</span>
            </div>
            <div style="width: 20px">&nbsp;</div>
          </div>
        </li>
        <li class="list-group-item bg-faf border-d7c">
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">
              {{ $t("web_lbl_account_dob") }}
            </div>
            <div style="width: 150px" class="text-7e">
              {{ profile.dob }}
            </div>
            <div style="width: 20px">&nbsp;</div>
          </div>
        </li>

        <li v-if="profile.horoscope" class="list-group-item bg-faf border-d7c">
          <div class="d-flex justify-content-between">
            <div class="text-capitalize" style="width: 140px">
              {{ $t("Horoscope") }}
            </div>
            <div style="width: 150px" class="text-7e">
              {{ profile.horoscope }}
            </div>
            <div style="width: 20px">&nbsp;</div>
          </div>
        </li>

        <li
          @click="gotoedit('height')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="text-capitalize" style="width: 140px">
              {{ $t("height_txt") }}
            </div>
            <div
              class="fw-400"
              style="width: 150px"
              :class="profile.height ? '' : 'text-ee1'"
            >
              <span v-if="profile.height">{{ profile.height }} cm</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>
        <li
          @click="gotoedit('ethnicity')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <label class="text-capitalize" style="width: 140px">{{
              $t("ethnicity_txt")
            }}</label>
            <div
              style="width: 150px"
              :class="profile.ethnicity_id > 0 ? '' : 'text-ee1'"
            >
              <span v-if="profile.ethnicity_id">{{ profile.ethnicity }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>
      </ul>

      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("relationship_children") }}
      </div>

      <ul class="list-group list-group-flush profileEdit">
        <li
          @click="gotoedit('relationship_status')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">
              {{ $t("relationship_status") }}
            </div>
            <div
              :class="profile.relationship_status_id > 0 ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.relationship_status_id">{{
                profile.relationship_status
              }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>
        <li
          @click="gotoedit('child_plan')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">{{ $t("child_plans") }}</div>
            <div
              :class="profile.child_plans_id > 0 ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.child_plans_id">{{
                profile.child_plans
              }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>
        <!-- <li class="list-group-item">
          <div class="row">
            <label class="col text-capitalize text-secondary">{{ $t("child_plans") }}</label>
            <div
              class="
                col
                font-weight-normal
                text-capitalize
                d-flex
                ls-justify-content-end
              "
              :class="profile.child_plans_id > 0 ? '' : 'text-diamond'"
            >
              
              <smart-select
                ref="child_plans_id"
                @beforeShow="showOption('child_plans_id')"
                :selected="profile.child_plans_id"
                filed="child_plans_id"
                @updateValue="updateSelectedValue"
                idName="child_plans_id"
                descName="description"
                titleKey="child_plans"
                :emptyString="`${$t('add_txt')} ${$t('your_txt')} ${$t(
                  'child_plans'
                )}`"
                :options="attrs.childPlans"
              />
            </div>
          </div>
        </li> -->
      </ul>
      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("work_education") }}
      </div>

      <ul class="list-group list-group-flush profileEdit">
        <li
          @click="gotoedit('job_title')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">{{ $t("lang_jobtitle") }}</div>
            <div
              :class="profile.job_title ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.job_title">{{ profile.job_title }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>
        <li
          @click="gotoedit('income')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">
              {{ $t("annual_salary_txt") }}
            </div>
            <div
              class=""
              :class="profile.annual_income_id > 0 ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.annual_income_id">{{
                profile.annual_income
              }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>

        <li
          @click="gotoedit('education')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">
              {{ $t("highest_education_txt") }}
            </div>
            <div
              :class="profile.education_level_id ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.education_level_id">{{
                profile.education_level
              }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>
        <li
          v-if="profile.education_level_id >= 2"
          @click="gotoedit('school')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">
              {{ $t("college_university_txt") }}
            </div>
            <div
              :class="profile.university_name ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.university_name">{{
                profile.university_name
              }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>
      </ul>

      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("others_txt") }}
      </div>

      <ul class="list-group list-group-flush profileEdit">
        <li
          @click="gotoedit('religion')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">{{ $t("religion_txt") }}</div>
            <div
              style="width: 150px"
              :class="profile.religion_id > 0 ? '' : 'text-ee1'"
            >
              <span v-if="profile.religion_id">{{ profile.religion }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>

        <li
          @click="gotoedit('smoking')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div class="" style="width: 140px">{{ $t("smoking_txt") }}</div>
            <div
              :class="profile.smoking_id > 0 ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.smoking_id">{{
                profile.smoking_status
              }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>

        <li
          @click="gotoedit('drinking')"
          class="list-group-item bg-faf border-d7c cursor"
        >
          <div class="d-flex justify-content-between">
            <div style="width: 140px">{{ $t("drinking_txt") }}</div>
            <div
              :class="profile.drinking_id > 0 ? '' : 'text-ee1'"
              style="width: 150px"
            >
              <span v-if="profile.drinking_id">{{
                profile.drinking_status
              }}</span>
              <span v-else>{{ $t("add_txt") }}</span>
            </div>
            <div>
              <i class="fa-regular fa-chevron-right text-ee1 fs-22"></i>
              <!-- <i class="lsicon arrow_right_grey"></i> -->
            </div>
          </div>
        </li>

        <!-- <li class="list-group-item">
          <div class="row">
            <label class="col text-capitalize text-secondary">{{ $t("food_habit") }}</label>
            <div
              class="
                col
                font-weight-normal
                text-capitalize
                d-flex
                ls-justify-content-end
              "
              :class="profile.food_habit_id > 0 ? '' : 'text-diamond'"
            >
             
              <smart-select
                ref="food_habit_id"
                @beforeShow="showOption('food_habit_id')"
                :selected="profile.food_habit_id"
                filed="food_habit_id"
                @updateValue="updateSelectedValue"
                idName="food_habit_id"
                titleKey="food_habit"
                :emptyString="`${$t('add_txt')} ${$t('your_txt')} ${$t(
                  'habits_text'
                )}`"
                :options="attrs.foodHabit"
              />
            </div>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
// import commonHead from "../common/head";
import pageTop from "../common/pageTop";
import SmartSelect from "../common/smartSelect";
export default {
  name: "edit2",
  components: {
    // commonHead,
    pageTop,
    SmartSelect,
  },
  data() {
    return {
      profile: null,
      update: {},
      client: {},
      photos: [],
      attrs: {},
      annualIncomes: null,
      unused: true,
      iswebused: false,
      isZH: this.$store.getters.curLang.indexOf("zh_") > -1,
      //publicPath:process.env.BASE_URL,
      curCity: this.$store.getters.curCity,
      userInfo: this.$storage.get("userInfo"),
    };
  },
  mounted() {
    // console.log(this.$store.getters.curLang);
    let self = this;
    self.$parent.closeLoading();
    //
    self.$refs.pageTop.setPageTitle(self.$t("progress_title"));

    if (_.get(self.$route, ["query", "nav"], 1) == 1) {
      self.iswebused = true;
    }
    self.$layer.loading();
    self.$api
      .getClientMe(self.$store.state)

      .then((rs) => {
        self.$layer.closeAll("loading");
        // console.log(rs);
        if (rs.hasOwnProperty("client")) {
          self.photos = rs.client.photos;
          self.profile = rs.client;
          //   self.profile.job_title = ''
        }
        //console.log('self.$store.state', self.$store.state)
        // return self.$api.getAttributes(self.$store.state);
      })
      //   .then((rs) => {
      //     // console.log(rs);
      //     if (rs.hasOwnProperty("gender")) {
      //       self.attrs = rs;
      //     }
      //     self.init();
      //     // self.$parent.closeLoading();
      //     self.$layer.closeAll("loading");
      //   })
      .catch((err) => {
        self.$layer.closeAll("loading");
        // self.$parent.closeLoading();
        console.log(["me error", err]);
      });
  },
  methods: {
    gotoedit(stepName) {
      //name: "reg-step"
      this.$router.push({
        name: "profile-edit-name",
        params: { name: stepName },
      });
    },
    heightNumFocus() {
      $("#heightNum").focus();
    },
    showOption(field) {
      let self = this;
      self.$refs[field].showBox();
    },
    saveUpdate(field, value) {
      let self = this;

      let fdata = new FormData();

      if (field && value) {
        fdata.append(field, value);
      } else {
        for (let i in self.update) {
          if (self.update[i]) {
            fdata.append(i, self.update[i]);
          }
        }
      }

      self.$layer.loading();

      self.$api
        .postClientUpdate(fdata, self.$store.state)
        .then((rs) => {
          // console.log(rs);

          self.$layer.closeAll("loading");

          //   self.$layer.msg(rs.message);
        })
        .catch((err) => {
          console.log(["save error", err]);
          self.$layer.closeAll("loading");

          self.$layer.msg(_.get(err, "response.data.error.body"), "error");
        });
    },
    textFieldUpdate(fieldName) {
      let self = this;
      let value = _.get(self.profile, fieldName);
      if (value) {
        self.update[fieldName] = value;

        self.saveUpdate(fieldName, value);
      }
      // console.log(self.update);
    },
    updateSelectedValue(fieldName, value, act) {
      // console.log(
      //   "updateValue",
      //   fieldName,
      //   value,
      //   act,
      //   _.isEmpty(value),
      //   _.isNumber(value),
      //   _.isString(value)
      // );
      if (!_.isNumber(value) && _.isEmpty(value)) {
        return false;
      }
      // return false;
      let self = this;
      // console.log("updateValue", fieldName, value, act);
      self.profile[fieldName] = value;
      self.update[fieldName] = value;
      self.saveUpdate(fieldName, value);
      // console.log(self.update);
    },
    init() {
      let self = this;
      let currency_id = 1;

      if (_.get(self.userInfo, "default_currency_id", false)) {
        currency_id = _.get(self.userInfo, "default_currency_id", 1);
      } else if (
        _.get(self.userInfo, ["data", "client", "default_currency_id"], false)
      ) {
        currency_id = _.get(
          self.userInfo,
          ["data", "client", "default_currency_id"],
          1
        );
      }

      let incomes = [];
      for (let v of self.attrs.annualIncome) {
        if (v.currency_id == currency_id) {
          incomes.push(v);
        }
      }

      if (incomes.length == 0) {
        currency_id = 1;
        for (let v of self.attrs.annualIncome) {
          if (v.currency_id == currency_id) {
            incomes.push(v);
          }
        }
      }
      // console.log(self.profile);
      //console.log([currency_id, incomes]);
      self.annualIncomes = incomes;

      // console.log("self.annualIncomes", self.annualIncomes);

      // setTimeout(function() {
      //   self.initEvents();
      // }, 200);
    },
  },
};
</script>
<style scoped>
.list-group-flush.profileEdit .list-group-item {
  border: none;
  border-bottom: 2px solid #f8f9fa;
}
/* ::-webkit-input-placeholder,
.text-diamond input::-webkit-input-placeholder,
.text-diamond textarea::-webkit-input-placeholder,
.text-diamond input:-moz-placeholder,
.text-diamond textarea:-moz-placeholder,
.text-diamond input::-moz-placeholder,
.text-diamond textarea::-moz-placeholder,
.text-diamond input:-ms-input-placeholder,
.text-diamond textarea:-ms-input-placeholder {
  color: #52c5af !important;
} */
</style>