import axios from 'axios'
import _ from 'lodash';
import storage from '../utils/storage'


//axios.defaults.baseURL = "https://api.lovestruck.com";
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/json';
// axios.defaults.transformResponse = function(data){
//     return JSON.parse(data);
//     //console.log('transformResponse', data)
// }

// axios.interceptors.request.use(function (config) {

//     return config;
//   }, function (error) {

//     return Promise.reject(error);
//   });


// axios.interceptors.response.use(function (response) {

//     return response;
// }, function (error) {

//     return Promise.reject(error);
// });


const TIME_OUT_MS = 60 * 1000 // 

function handleUrl(url) {
    if (url.indexOf('http') > -1) {
        return url;
    }
    //   let host = storage.get('api_host')
    //   if (!host || host.indexOf('undefined') > -1) {
    //     host = 'https://api.lovestruck.com'
    //   }
    let host = 'https://api.lovestruck.com'
    // console.log(`${host}${url}`)
    return `${host}${url}`
}
function getToken(state) {
    let local_token = storage.get('token')
    let store_token = _.get(state, 'token', '')
    return local_token ? local_token : store_token;
}

function getIso2(state) {
    return _.get(state, ['curSite', 'iso2'], 'HK');
}

function getCurLang(state) {
    let local_lang = storage.get('cur-lang')
    let store_lang = _.get(state, 'curLang', 'en_US')
    return local_lang ? local_lang : store_lang
}

/*
 * @param data 
 * @return
 */
function handleParams(data) {
    return data
}

const service = axios.create({
    timeout: 15000 // 请求超时时间
})


// 响应拦截器
service.interceptors.response.use(
    response => {
        // dataAxios 是 axios 返回数据中的 data
        //console.log('response', response)
        let apiHost = _.get(response.headers, 'x-recommand-api-host')
        // console.log('response', response, apiHost)
        if (apiHost) {
            storage.set('api_host', `https://${apiHost}`)
        }

        return response;
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400: error.message = '请求错误'; break
                case 401: error.message = '未授权，请登录';
                    storage.clearLogin()
                    if (typeof location == 'undefined') {
                        console.log('未授权，请登录')
                    } else {
                        let paths = location.pathname.replace("/", "").split("/");

                        location.href = `${location.origin}/apps/${paths[1]}`
                    }

                    break
                case 403: error.message = '拒绝访问'; break
                case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
                case 408: error.message = '请求超时'; break
                case 500: error.message = '服务器内部错误'; break
                case 501: error.message = '服务未实现'; break
                case 502: error.message = '网关错误'; break
                case 503: error.message = '服务不可用'; break
                case 504: error.message = '网关超时'; break
                case 505: error.message = 'HTTP版本不受支持'; break
                default: break
            }
        }
        // errorLog(error)
        return Promise.reject(error)
    }
)

export default {
    post(url, data, params, state) {
        return service({
            method: 'post',
            url: handleUrl(url),
            data: handleParams(data),
            // timeout: TIME_OUT_MS,
            headers: {
                'Accept-Language': _.get(params, 'lang', getCurLang(state)),
                'Authorization': _.get(params, 'token', getToken(state)),
                'Accept-Iso2': _.get(params, 'iso2', getIso2(state)),
                'App-Name': 'ls6-web'
            }
        });
    },

    get(url, params, state) {
        return service({
            method: 'get',
            url: handleUrl(url),
            // timeout: TIME_OUT_MS,
            headers: {
                'Accept-Language': _.get(params, 'lang', getCurLang(state)),
                'Authorization': _.get(params, 'token', getToken(state)),
                'Accept-Iso2': _.get(params, 'iso2', getIso2(state)),
                'App-Name': 'ls6-web'
            }
        });
    },

}