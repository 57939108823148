<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <div
        class=""
        style="
          position: absolute;
          width: 100px;
          height: 100px;
          top: -40px;
          left: 50%;
          margin-left: -50px;
          overflow: hidden;
          border-radius: 50px;
          border: 4px solid #fff;
        "
      >
        <b-img v-if="target" :src="target.photo_url" class="w-100" />
      </div>
      <p
        v-if="target"
        class="text-center font-1_2rem mb-2"
        style="margin-top: 60px"
        v-html="inviteTitle"
      ></p>
      <p class="font-1_2rem text-diamond font-weight-bold mb-3">
        {{ displayDateTimeString }}
      </p>
      <div v-if="venue">
        <h6 class="font-1_2rem font-weight-bold mb-1">{{ venue["name"] }}</h6>
        <p v-if="venue['cuisine_type']">
          {{ venue["cuisine_type"]["description"] }}
        </p>

        <b-media tag="li" class="my-2">
          <template v-slot:aside>
            <b-img
              v-if="venue['primary_photo']"
              :src="venue['primary_photo']['photo_url']"
              style="width: 100px; max-height: 120px"
            ></b-img>
          </template>
          <!-- <p v-if="venue['price_range']" class="text-truncate text-secondary">
            <i class="lsicon price"></i>
            {{ venue["price_range"]["description"] }} /{{ $t("person") }}
          </p> -->
          <p
            v-if="venue['venue_price_range_id'] > 0"
            class="text-truncate text-secondary"
            v-html="renderPriceRange(venue['venue_price_range_id'])"
          ></p>
          <p
            v-if="
              statusName != 'match_confirmed' &&
              statusName != 'match_invite_you'
            "
            class="text-truncate text-secondary"
          >
            <i class="lsicon date_credit"></i>
            {{ dateCreditCostStr }}
          </p>
          <!-- <p
            v-if="venue['date_credit_offer'] > 0"
            class="text-truncate text-secondary"
          >
            <i class="lsicon offer"></i>
            {{ venue["date_credit_offer"] }}
          </p> -->
        </b-media>
        <address class="text-secondary">
          <i class="lsicon location"></i>
          {{ venue["address"] }}
        </address>
      </div>
      <div class="d-flex justify-content-around pb-3">
        <div>
          <b-button
            @click="changeVenueDate"
            variant="outline-secondary"
            class="px-4"
            >{{ $t("link_change") }}</b-button
          >
        </div>

        <div
          v-if="
            statusName != 'match_confirmed' && statusName != 'match_invite_you'
          "
        >
          <b-button @click="sendVenueDate" variant="ls-g" class="px-4">{{
            $t("Send")
          }}</b-button>
        </div>

        <div
          v-if="
            statusName == 'match_confirmed' || statusName == 'match_invite_you'
          "
        >
          <b-button
            @click="
              confirmedVenueDate(introId, venue ? venue.date_credit_cost : 1)
            "
            variant="ls-p"
            class="px-4"
            >{{ $t("Confirm") }}</b-button
          >
          <p class="text-truncate text-secondary">
            <i class="lsicon date_credit"></i>
            {{ dateCreditCostStr }}
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "selectVenueDate",
  data() {
    return {
      show: false,
      introId: null,
      targetClientId: null,
      statusName: "",
      selectDateTime: "2020-07-17 19:30",
      displayDateTimeString: "",
      inviteTitle: "",
      dateCreditCostStr: "",
      target: {
        first_name: "Will1",
        photo_url:
          "https://xavatar.imedao.com/community/20199/1570584141885-1570584143982.jpg",
      },
      venue: {
        venue_id: 11,
        name: "Lovestruck Cafe",
        cuisine_type: "Cafe",
        venue_price_range_id: 2,
        date_credit_cost: 2,
        // date_credit_offer: 2,
        address: "Level 15, Silver Fortune Plaza, 1 Wellington Street, Central",
        primary_photo: {
          photo_url:
            "https://xavatar.imedao.com/community/20184/1525776927644-1525776927880.jpeg",
        },
        price_range: {
          description: "$100-200",
        },
      },
    };
  },
  methods: {
    renderPriceRange(rangeId) {
      let html =
        '<i class="lsicon money_gery"></i><i class="lsicon money_gery"></i><i class="lsicon money_gery"></i><i class="lsicon money_gery"></i><i class="lsicon money_gery"></i>';
      for (let i = 0; i < rangeId; i++) {
        html = html.replace("money_gery", "monery_gold");
      }
      return html;
    },
    confirmedVenueDate(introId, dateCreditCost) {
      let self = this;
      // let index = _.findIndex(self.dataList, { client_intro_id: introId });
      // let intro = _.get(self.dataList, index);
      // if (!intro) {
      //   return false;
      // }
      self.$emit("confirmInvite", {
        introId: introId,
        dateCreditCost: dateCreditCost,
        targetClientId: self.targetClientId,
        target: self.target,
        dateTimeString: self.displayDateTimeString,
        venueName: _.get(self.venue, "name"),
      });
    },
    closeModel(emit) {
      let self = this;

      self.venue = null;
      self.selectDateTime = "";

      self.show = false;
      if (emit) {
        // console.log("self.target=", self.target);
        self.$emit("changeVenueDate", {
          introId: self.introId,
          targetClientId: self.targetClientId,
          targetClientName: _.get(self.target, "first_name", ""),
        });
      }
      self.target = null;
      self.introId = null;
    },
    showModal(data) {
      //   console.log("data=", data);
      let self = this;
      self.show = true;
      self.introId = _.get(data, "introId");
      self.targetClientId = _.get(data, "targetClientId");
      self.statusName = _.get(data, "statusName");
      self.venue = _.get(data, "venue");
      self.selectDateTime = _.get(data, "dateTime");
      self.target = _.get(data, "target");

      self.initData();
    },
    initData() {
      let self = this;

      if (self.selectDateTime) {
        let selectDateTime = self.$moment(self.selectDateTime);
        self.displayDateTimeString = `${selectDateTime.format(
          "dddd"
        )} ${selectDateTime.format("DD")} ${selectDateTime.format(
          "MMMM"
        )}, ${selectDateTime.format("HH:mm")}`;
      }

      self.inviteTitle = self
        .$t("match_be_invited_at")
        .replace("[match_first_name]", _.get(self.target, "first_name"));

      if (
        self.statusName == "match_confirmed" ||
        self.statusName == "match_invite_you"
      ) {
        self.inviteTitle = self
          .$t("want_date_you")
          .replace("[match_first_name]", _.get(self.target, "first_name"));
      }

      let dcc =
        _.get(self.venue, "date_credit_cost") > 0
          ? _.get(self.venue, "date_credit_cost")
          : 1;

      if (dcc == 1) {
        self.dateCreditCostStr = `${dcc} ${self.$t("a_date_credit")}`;
      } else {
        self.dateCreditCostStr = `${dcc} ${self.$t("Date_credits")}`;
      }
    },
    changeVenueDate() {
      let self = this;
      self.closeModel(1);
    },
    sendVenueDate() {
      let self = this;
      self.$emit("sendInvite", {
        introId: self.introId,
        targetClientId: self.targetClientId,
        venueId: _.get(self.venue, "venue_id"),
        dateTime: self.selectDateTime,
        dateCreditCost:
          _.get(self.venue, "date_credit_cost") > 0
            ? _.get(self.venue, "date_credit_cost")
            : 0,
      });
    },
  },
};
</script>