import { createApp } from './app'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/css/global.css'

import 'vue-layer/lib/vue-layer.css'
import layer from "vue-layer";
import jQuery from 'jquery/dist/jquery.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import apiRequest from './api/apiRequest.js';
import { API_URL } from './core/const';

// Framework7
// Import Framework7 Bundle
// import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import Framework7-Vue with all components
//import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';


// Init plugin and register all components
//Framework7.use(Framework7Vue);



//import Framework7 from 'framework7';

// Import additional components
// import Framework7Vue from 'framework7-vue';

// Install F7 Components using .use() method on class:
// // Framework7.use(Framework7Vue);
// // import { f7App, f7Navbar, f7Statusbar, f7View, f7Block, f7BlockTitle, f7List, f7ListItem, f7SkeletonBlock, f7Button, f7Page, f7Messages, f7Message, f7Popup, f7NavRight, f7Link } from 'framework7-vue';

// Vue.component('f7-app', f7App);
// Vue.component('f7-navbar', f7Navbar);
// Vue.component('f7-statusbar', f7Statusbar);
// Vue.component('f7-block', f7Block);
// Vue.component('f7-block-title', f7BlockTitle);
// Vue.component('f7-list', f7List);
// Vue.component('f7-list-item', f7ListItem);
// Vue.component('f7-skeleton-block', f7SkeletonBlock);
// Vue.component('f7-view', f7View);
// Vue.component('f7-button', f7Button);
// Vue.component('f7-page', f7Page);
// Vue.component('f7-messages', f7Messages);
// Vue.component('f7-message', f7Message);
// Vue.component('f7-popup', f7Popup);
// Vue.component('f7-nav-right', f7NavRight);
// Vue.component('f7-link', f7Link);

import './assets/css/crm_page.css'
import 'swiper/dist/css/swiper.css'


//Vue.use()
Vue.prototype.$api = apiRequest;
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = API_URL.LIVE;

if (typeof window != 'undefined') {
    Vue.prototype.$layer = layer(Vue);

    window.$ = jQuery;
}

//console.log($);

// a global mixin that calls `asyncData` when a route component's params change
Vue.mixin({
    beforeRouteUpdate(to, from, next) {
        // console.log('--entry-client:beforeRouteUpdate')
        const { asyncData } = this.$options
        if (asyncData) {
            asyncData({
                store: this.$store,
                route: to
            }).then(next).catch(next)
        } else {
            next()
        }
    }
})
//console.log('--------entry-client')

const TARGET_NODE = process.env.WEBPACK_TARGET === 'node'
const { app, router, store, i18n } = createApp()
routerReady(app, router, store, i18n);


function routerReady(app, router, store, i18n) {
    // prime the store with server-initialized state.
    // the state is determined during SSR and inlined in the page markup.
    if (window.__INITIAL_STATE__) {
        store.replaceState(window.__INITIAL_STATE__)
    }

    // console.log('--entry-client:',store.getters.curLang)
    // wait until router has resolved all async before hooks
    // and async components...
    router.onReady(() => {
        // Add router hook for handling asyncData.
        // Doing it after initial route is resolved so that we don't double-fetch
        // the data that we already have. Using router.beforeResolve() so that all
        // async components are resolved.

        router.beforeResolve((to, from, next) => {

            const matched = router.getMatchedComponents(to)
            const prevMatched = router.getMatchedComponents(from)
            let diffed = false
            const activated = matched.filter((c, i) => {
                return diffed || (diffed = (prevMatched[i] !== c))
            })


            const asyncDataHooks = activated.map(c => c.asyncData).filter(_ => _)

            //   console.log('--entry-client:beforeResolve', {
            //     asyncDataHooks,
            //     activated,
            //     matched,
            //     diffed,
            //   })
            if (!asyncDataHooks.length) {
                return next()
            }

            if (!asyncDataHooks.length) {
                return next()
            }

            Promise.all(asyncDataHooks.map(hook => hook({ store, route: to })))
                .then(() => {
                    next()
                })
                .catch(next)
        })

        // actually mount to DOM
        app.$mount('#app')
    })
}
