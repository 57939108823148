<template>
  <div class="bg-faf min-h100 page6-2 px-3">
   
    <div class="d-flex justify-content-between py-3">
      <div><a href="javascript:;" @click="cancelEdit" class="text-ee1">{{ $t('cancel_txt') }}</a></div>
      <div class="text-1d fw-600">{{ $t('edit_photos') }}</div>
      <div>
        <a href="javascript:;" @click="uploadImgsHandler" class="text-ee1 fw-600">{{ $t('save_txt') }}</a>
      </div>
    </div>
    <div v-if="showLoading" class="text-center py-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
    <div class="text-center pb-5" :style="divStyle">
        <!-- <h4 class="mb-3 text-ls-y3">{{$t('edit_photos')}}</h4> -->
      <p v-if="showSubTitle" class="font-weight-normal text-7e my-2" v-html="$t('more_photo_more_dates')"></p>
      <div class="text-center">
        
        <onePhotoUpload
              ref="onePhotoUpload"
              :red_ver="true"
              :photoNum="6"
              mode="edit"
              @photoStatus="photoEditorStatus"
            />

        <div class="my-4 text-7e px-3" v-html="$t('team_choose_primary_photo')"></div>

      </div>

      <!-- <div style="height: 80px"></div> -->
    </div>
  </div>
</template>
<script>
// import photoEditor from "../common/photoEditorCom";
import onePhotoUpload from "../common/onePhotoUpload";
// import v5Top from "../common/v5topCom.vue";
import pageTop from "../common/pageTop";
export default {
  name: "photo-edit",
  components: {
    // v5Top,
    pageTop,
    onePhotoUpload,
    // photoEditor,
  },
  data() {
    return {
      client: null,
      canUploadPhoto: false,
      photoLen: 0,
      selectedTotal: 0,
      photoCdnBase: "",
      showSubTitle: true,
      divStyle: {
        display: 'none'
      },
      showLoading: true
    };
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();
    // self.$layer.loading();
    // self.$refs.pageTop.setPageTitle("Settings");
    setTimeout(function () {
      $("body").removeClass("noy-scroll");
    }, 500);
    // self.$refs.pageTop.setPageTitle(self.$t('edit_photos'));
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        // console.log("rs");
        self.$layer.closeAll("loading");
        self.client = _.get(rs, "client");
        // self.first_name = _.get(rs, "client.first_name");
        // self.gender = _.get(rs, "client.gender", "m");
        self.photoCdnBase = _.get(rs, "client.photo_cdn_base");
        // let dob = _.get(rs, "client.dob");

        // self.photoLen = self.client.photos.length;
        // console.log("self.photoLen=", self.photoLen);
        self.selectedTotal = _.size(_.get(self.client, "client_photos", []));

        self.$refs.onePhotoUpload.loadPhotos(
            _.get(self.client, "client_photos"),
            _.get(self.client, "photo_cdn_base")
          );

          setTimeout(()=>{
            self.showLoading = false;
            self.divStyle = {
              display: 'block'
            }
          }, 1000)
        // self.$refs.photoEditor.loadPhotoList(
        //   self.client.client_photos,
        //   self.photoCdnBase
        // );

        // console.log(
        //   "self.profile ",
        //   self.client.client_photos,
        //   self.photoCdnBase
        // );
      })
      .catch((err) => {
        self.$layer.closeAll("loading");
      });
  },
  methods: {
    cancelEdit(){
      this.$router.back();
    },
    backEventHandler(){
        // this.uploadImgsHandler()
    },
    photoEditorStatus(data) {
      let self = this;
      console.log('photoEditorStatus', data)
      // let uploadNum = _.get(data, "uploadNum");
      self.selectedTotal = _.get(data, "selectedTotal");
        if(self.selectedTotal >= 6){
            self.showSubTitle = false;
        }

      self.canUploadPhoto = _.get(data, "canSubmit");

      if (_.get(data, "photo_url")) {
        self.client.photos = _.get(data, "photo_url");
      }

      // if (_.get(data, "uploadSuccess")) {
      //   self.photoLen++;
      // }
      if (_.get(data, "uploadCompleted")) {
        self.$router.push({ name: "profile-me" });
      }
    },

    uploadImgsHandler() {
      let self = this;
      if (self.selectedTotal == 0) {
        self.$refs.onePhotoUpload.toggleFile(self.selectedTotal);
        // self.$refs.photoEditor.toggleFile(
        //   self.photoLen > 0 ? self.photoLen - 1 : 0
        // );

        return false;
      }
      //   console.log("self.canUploadPhoto=", self.canUploadPhoto);
      if (!self.canUploadPhoto) {
        self.$layer.msg(self.$t("add_some_photo"));
        return false;
      }
      self.$refs.onePhotoUpload.uploadImage();
      // self.$refs.photoEditor.uploadImgs();
    },
  },
};
</script>