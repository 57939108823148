<template>
  <div>
    <span
      class="weui-switch"
      :class="[
        canClick ? '' : 'cant-click',
        switchStatus ? 'weui-switch-on' : '',
      ]"
      :value="value"
      @click.stop="toggle"
      style="position: relative"
    >
      <div
        v-if="switchStatus && direction.length > 0"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          padding: 0 5px;
          line-height: 20px;
          color: #fff;
          user-select: none;
        "
      >
        {{ direction[0] }}
      </div>
      <div
        v-if="!switchStatus && direction.length > 0"
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          padding: 0 5px;
          right: 2px;
          line-height: 22px;
          color: #7a7a7a;
          text-align: right;
          user-select: none;
        "
      >
        {{ direction[1] }}
      </div>
      <i v-if="icon" :class="icon" style="position: absolute;
    right: 5px;
    z-index: 10;
    top: 3px;
    font-size: 13px;"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "switchComponent",
  props: {
    readonly:{
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean | Number,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    canClick: {
      type: Boolean | Number,
      default: true,
    },
  },
  data() {
    return {
      isChecked: this.value,
    };
  },
  computed: {
    direction() {
      if (this.text) {
        return this.text.split("|");
      } else {
        return [];
      }
    },
    switchStatus() {
      return this.isChecked;
    },
  },

  // watch: {
  //   value: {
  //     // console.log("vavavavav", val);
  //     handler(newValue, oldValue) {
  //       // console.log(newValue, oldValue);
  //       // if (newValue != oldValue) {
  //       //   this.isChecked = newValue;
  //       // }
  //       this.isChecked = newValue;
  //     },
  //     deep: true
  //   },
  //   isChecked(val) {
  //     // console.log("change", val);

  //     this.$emit("change", val, this.name);
  //   }
  // },
  methods: {
    turnOn() {
      this.isChecked = true;
    },
    turnOff() {
      this.isChecked = false;
    },
    toggle() {
      if(this.readonly){
        
      }else{
        if (!this.canClick) {
        this.isChecked = false;
        this.$emit("change", {});
        return false;
      } else {
        this.isChecked = !this.isChecked;
      }
      // console.log("toggle");

      // console.log("toggle", this.isChecked);
      
      }

      this.$emit("change", this.isChecked, this.name, "toggle");
      
    },
  },
};
</script>

<style>
.weui-switch {
  display: block;
  position: relative;
  width: 52px;
  height: 24px;
  border: 1px solid #dfdfdf;
  outline: 0;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #ccc;
  transition: background-color 0.1s, border 0.1s;
  cursor: pointer;
}
.weui-switch:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 22px;
  border-radius: 15px;
  background-color: #ccc;
  transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
}
.weui-switch:after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
}
.weui-switch.cant-click:before {
  background-color: #a2a2a2;
}
.weui-switch-on {
  border-color: #6f6f6f;
  background-color: #000;
}
.weui-switch-on:before {
  border-color: #729dc7;
  /* border-color: #1aad19; */
  /* background-color: #53c6b0; */
  /* background-color: #729dc7; */
  background-color: #000;
}
.weui-switch-on:after {
  transform: translateX(28px);
}
.normal .weui-switch-on {
  background-color: #53c6b0;
}
.normal .weui-switch-on:before {
  background-color: #53c6b0;
}
.gold .weui-switch-on {
  background-color: #d5b36f;
}
.gold .weui-switch-on:before {
  background-color: #d5b36f;
}

.red .weui-switch-on {
  background-color: #e61e5a;
}
.red .weui-switch-on:before {
  background-color: #e61e5a;
}
.ee1 .weui-switch.cant-click:before {
  background-color: #d7c2bf;
}
.ee1 .weui-switch-on {
  background-color: #ee153d;
}
.ee1 .weui-switch-on:before {
  background-color: #ee153d;
}
</style>
