<template>
  <div>
    <b-modal
      v-model="show"
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
    >
      <!-- no-close-on-backdrop country code, phone number, name, gender, age, job title. -->
      <div class="text-center fw-700 mb-4">
        {{ $t("complete_basic_profile") }}
      </div>
      <b-form>
        <countrySmsCodeList
          @selectCountry="selectCountry"
          ref="countrySmsCodeList"
        ></countrySmsCodeList>

        <b-form-group
          :label="$t('phone_number')"
          label-for="phone_number"
          description=""
          class="blabel"
        >
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-secondary"
                @click="showCountrySmsCode"
                v-html="selectCountryHtml"
                class="border-b-i"
              ></b-button>
            </b-input-group-prepend>

            <b-form-input
              type="text"
              v-model="form.phone_number"
              required
              class="form-input"
              id="phone_number"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          :label="$t('your_name')"
          label-for="first_name"
          class="blabel"
        >
          <b-form-input
            id="first_name"
            v-model="form.first_name"
            placeholder=""
            required
            class="form-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="$t('u_gender')"
          class="blabelradio"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            v-model="form.gender"
            :aria-describedby="ariaDescribedby"
            name="radio-sub-component"
          >
            <b-form-radio value="m">{{ $t("Male") }}</b-form-radio>
            <b-form-radio value="f">{{ $t("Female") }}</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group class="blabel" :label="$t('u_age')" label-for="age">
          <b-form-input
            id="age"
            type="number"
            min="18"
            max="100"
            v-model="form.age"
            placeholder="18-99"
            required
            class="form-input"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="$t('u_job_title')"
          label-for="job_title"
          class="blabel"
        >
          <b-form-input
            id="job_title"
            v-model="form.job_title"
            placeholder=""
            required
            class="form-input"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <div class="text-center">
        <b-button variant="ls-red2" @click="submit" class="fw-600">{{
          $t("m_continue_txt")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import countrySmsCodeList from "./countrySmsCodeList";
import helper from "../../utils/helper";
export default {
  name: "register-form",
  components: {
    countrySmsCodeList,
  },
  data() {
    return {
      show: false,
      form: {
        first_name: "",
        country_phone_code: "",
        phone_number: "",
        age: null,
        gender: null,
        job_title: "",
        device_id: "",
        add_new_user: 0,
      },
      userData: null,
      selectCountryHtml: null,
      curSite: this.$store.getters.curSite,
    };
  },
  mounted() {},
  methods: {
    submit() {
      let self = this;

      if (
        !self.form.country_phone_code ||
        !helper.checkMobile2(
          self.form.country_phone_code,
          self.form.phone_number
        )
      ) {
        //console.log('mobile error');
        self.$layer.msg(self.$t("val_mobile_invaild"));

        return false;
      }

      if (!self.form.first_name) {
        self.$layer.msg(self.$t("val_username_empty"));
        return false;
      }

      if (!self.form.gender) {
        //
        self.$layer.msg(self.$t("validate_genderuser_empty"));
        return false;
      }
      if (!self.form.age || self.form.age < 18 || self.form.age > 99) {
        //
        self.$layer.msg(self.$t("pls_valid_age"));
        return false;
      }

      if (!self.form.job_title) {
        self.$layer.msg(self.$t("web_job_empty"));
        return false;
      }

      self.checkPhoneAccount(1);
    },
    inputMobile() {},
    showCountrySmsCode() {
      // console.log('showCountrySmsCode');
      this.$refs.countrySmsCodeList.showList();
    },
    selectCountry(data) {
      //console.log([
      //	'parent',data
      // ]);
      //   console.log("ddd", data);
      this.form.country_phone_code = data.dial_code;
      this.selectCountryHtml = `<img src="${data.flag_url}" class="border-0"/> +${data.dial_code}`;
      //   this.selectCountryHtml = `${data.emoji} +${data.dial_code}`;
    },
    phoneInputEvent() {
      let self = this;
      console.log("phoneInputEvent...");
      $("#phone_number").on("blur", function () {
        self.checkPhoneAccount(0);
      });
    },
    checkPhoneAccount(addNewUser = 0) {
      let self = this;

      if (
        !self.form.country_phone_code ||
        !helper.checkMobile2(
          self.form.country_phone_code,
          self.form.phone_number
        )
      ) {
        //console.log('mobile error');
        if (addNewUser) {
          self.$layer.msg(self.$t("val_mobile_invaild"));
        }

        return false;
      }
      self.form.add_new_user = addNewUser;
      let pData = new FormData();
      pData.append("add_new_user", self.form.add_new_user);
      pData.append("icode", self.form.country_phone_code);
      pData.append("mobile", self.form.phone_number);
      pData.append("first_name", self.form.first_name);
      pData.append("gender", self.form.gender);
      pData.append("age", self.form.age);
      pData.append("job_title", self.form.job_title);

      self.$api.postMobileCheck(pData, self.$store.state).then((rs) => {
        if (_.has(rs, "token")) {
          self.userData = rs;
          self.$emit("userData", self.userData);
        }
      });
    },

    showModal() {
      let self = this;
      self.show = true;
      self.form.country_phone_code = _.get(self.curSite, "dial_code", "852");
      self.selectCountryHtml = `${_.get(self.curSite, "emoji")} +${
        self.form.country_phone_code
      }`;

      setTimeout(function () {
        $("#phone_number").focus();
        self.phoneInputEvent();
      }, 500);
    },
    hideModal() {
      let self = this;
      self.show = false;
      $("#phone_number").off("blur");
    },
  },
};
</script>
<style scoped>
</style>