<template>
  <div class="bg-faf min-h100 text-1d">
    <div
      class="position-relative text-center page6-2 py-4 px-3"
    >
      <h3 class="mb-3 ff-prata">{{ $t("schedule_phone_consultation") }}</h3>
      <div class="mb-4">
        <i
          class="fa-solid fa-circle-phone text-ee1"
          style="font-size: 70px"
        ></i>
        <!-- <img
          src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2023/circle-phone-gold.svg"
          width="70"
          class="mb-3"
        /> -->
      </div>
      <p class="mb-3 px-3 text-left">
        {{ $t("diamond_phone_call") }}
      </p>

      <div class="mt-4 mb-4">
        <!--<p class="font-weight-bold text-left">{{ selectMonth }}</p>-->

        <div
          v-if="days"
          class="my-3 position-relative mx-auto"
          style="width: 100%"
        >
          <div v-for="d in days" :key="d.full" class="d-inline-block mr-2 mb-2">
            <button
              :class="bookDate == d.full ? 'btn-ee1' : 'btn-white'"
              @click="selectDay(d.full)"
              class="btn px-2"
              style="width: 75px !important"
            >
              {{ d.sm }} {{ d.d }}<br />
              {{ d.w }}
            </button>
          </div>
        </div>
      </div>
      <p class="mb-3 text-left">
        Select a one hour call window for us to call you
      </p>
      <div v-if="times" class="my-3">
        <div>
          <button
            v-for="t in times"
            :key="t"
            :class="bookTime == t ? 'btn-ee1' : 'btn-white'"
            @click="selectTime(t)"
            class="btn px-2 mr-1 mb-1"
          >
            {{ t }}
          </button>
        </div>
      </div>

      <div class="">
        <b-button
          type="button"
          variant="ee1"
          pill
          @click="postBook"
          class="my-2 l-space2 text-uppercase w-100"
          style="height: 53px"
        >
          Book Consultation
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bookConsultation",
  data() {
    return {
      step: 1,
      days: null,
      selectMonth: "",
      bookDate: "",
      bookTime: "",
      //   daySwiper: null,
      //   swiperOption: {
      //     slidesPerView: 5,
      //     spaceBetween: 3,
      //     freeMode: true,
      //   },
      times: [
        "10:00",
        //        "10:30",
        "11:00",
        //         "11:30",
        "12:00",
        //         "12:30",
        "13:00",
        //         "13:30",
        "14:00",
        //         "14:30",
        "15:00",
        //         "15:30",
        "16:00",
        //         "16:30",
        "17:00",
        //         "17:30",
        "18:00",
        //         "18:30",
        "19:00",
        //        "19:30",
        "20:00",
      ],
      bookMeetDesc: "",
      bookResContent: "",
      curStore: null,
      lang: this.$store.getters.curLang,
      curCity: this.$store.getters.curCity,
      client: null,
      booking: null,
      showClose: false,
      back: 2,
      ref: "other",
    };
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();

    self.$layer.loading();

    // console.log("step =", self.step);
    self.$api
      .getClientMe(self.$store.state)
      .then((rs) => {
        self.client = _.get(rs, "client");
        self.booking = _.get(rs, "booking");
        self.curStore = _.get(rs, "store");

        // if (self.client.membership_level_id <= 1) {
        //   self.bookMeetDesc = self.$t("book_meet_desc1");
        // } else if (self.client.membership_level_id <= 3) {
        //   self.bookMeetDesc = self.$t("book_meet_desc");
        // } else {
        //   self.bookMeetDesc = self.$t("book_meet_desc2");
        // }

        self.initBooking();
        self.createDays();

        self.$layer.closeAll("loading");
      })
      .catch((err) => {
        self.$layer.closeAll("loading");

        // console.log(['me error', err]);
      });

    //self.getCurStore();
  },
  methods: {
    setResContent() {
      let self = this;
      let tpl = self.$t("book_consultation_res");

      tpl = tpl
        .replace("[book_time]", self.booking.date_format)
        .replace("[store_address]", self.curStore.address)
        .replace("[store_phone]", self.curStore.phone_number)
        .replace(/\n/g, "<br/>");
      // .replace("[store_address]", self.store.address)
      // .replace("[store_phone]", self.store.phone_number)
      // .replace(/\n/g, "<br/>");
      self.bookResContent = tpl;
      self.showClose = true;
      // console.log(tpl, self.bookResContent);
    },
    initBooking() {
      let self = this;
      let consultation_time = _.get(self.booking, "consultation_time");
      // console.log("initBooking");
      if (consultation_time) {
        let d = self.$moment(consultation_time);
        self.bookDate = d.format("YYYY-MM-DD");
        self.bookTime = d.format("HH:mm");
      }
    },
    getCurStore() {
      let self = this;
      self.$api.getCurStore("", self.$store.state).then((rs) => {
        self.curStore = _.get(rs, "store");
      });
    },
    goToNext() {
      let self = this;

      if (self.step == 1) {
        self.step = 2;
        self.createDays();
      } else if (self.step == 2) {
        //todo post data to server
        self.postBook();
      }
    },
    postBook() {
      let self = this;

      if (!self.bookDate || !self.bookTime) {
        self.$layer.msg(self.$t("invalid_date"));
        return false;
      }

      let dateString = `${self.bookDate} ${self.bookTime}`;
      let md = self.$moment(dateString);
      if (!md.isValid()) {
        self.$layer.msg(self.$t("invalid_date"));
        return false;
      }
      //consultation_time
      let fdata = new FormData();
      fdata.append("consultation_time", md.format("YYYY-MM-DD HH:mm:ss"));
      self.$layer.loading();
      self.$api
        .postBookConsultation(fdata, self.$store.state)
        .then((rs) => {
          //   console.log("postBookConsultation", rs);
          self.$layer.closeAll("loading");
          self.$router.push({
            name: "profile-me",
          });
          //   self.bookResContent = _.get(rs, "book_consultation_res").replace(
          //     /\n/g,
          //     "<br/><br/>"
          //   );
          //   self.step = 3;
          //   self.showClose = true;
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          console.log("err", err);
        });
    },
    selectTime(time) {
      let self = this;
      self.bookTime = time;
    },
    selectDay(date) {
      let self = this;
      self.bookDate = date;
      let i = _.findIndex(self.days, { full: date });
      self.selectMonth = _.get(self.days, `${i}.m`);
    },

    createDays() {
      let self = this;
      // console.log("createDays", self.lang);
      self.$moment.locale(self.lang);
      // self.$moment.locale("th");
      // self.$moment.locale("zh_CN");
      // self.$moment.locale("zh_HK");
      let now = self.$moment();
      let days = [
        // {
        //   m: now.format("MMMM"),
        //   w: now.format("ddd"),
        //   d: now.format("DD"),
        //   full: now.format("YYYY-MM-DD")
        // }
      ];
      // console.log(now.format("YYYY-MM-DD"));

      for (let d = 1; d <= 12; d++) {
        let newd = now.add(1, "d");
        days.push({
          sm: newd.format("MMM"),
          m: newd.format("MMMM"),
          w: newd.format("ddd"),
          d: newd.format("DD"),
          full: newd.format("YYYY-MM-DD"),
        });
      }

      // console.log("days", days);
      self.days = days;
      self.selectMonth = _.get(self.days, `0.m`);
      self.bookDate = _.get(self.days, `0.full`);
      //   self.initDaySwiper();
    },
  },
};
</script>