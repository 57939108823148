import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { sync } from 'vuex-router-sync'
import { createStore } from './store/index'
import BootstrapVue from "bootstrap-vue";
import VueChatScroll from "vue-chat-scroll";
// import layer from "vue-layer";
import moment from "moment/moment";
import axios from 'axios'
import { createI18n } from './vueI18n'

import filters from './utils/filters'
import storage from './utils/storage'
import interceptorsSetup from './utils/interceptors'
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVue)
Vue.use(VueChatScroll)


// and running it somewhere here
interceptorsSetup();
Vue.prototype.$moment = moment;
Vue.prototype.$http = axios.create({
    baseURL: "https://api.lovestruck.com",
});
Vue.prototype.$storage = storage

// document's click handler
let handleOutsideClick

Vue.directive('closable', {
    bind(el, binding, vnode) {
        handleOutsideClick = (e) => {
            e.stopPropagation()
            const { handler, exclude } = binding.value
            let clickedOnExcludedEl = false
            exclude.forEach(refName => {
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName]
                    clickedOnExcludedEl = excludedEl.contains(e.target)
                }
            })
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]()
            }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
    },
    unbind() {
        document.removeEventListener('click', handleOutsideClick)
        document.removeEventListener('touchstart', handleOutsideClick)
    }
});

export function createApp() {

    // console.log('--app.createRouter')




    let store = createStore()

    let i18n = createI18n()

    let router = createRouter(store, i18n)

    sync(store, router, i18n)
    // console.log('--app.createApp')



    let app = new Vue({
        filters,
        router,
        store,
        i18n,
        render: h => h(App),
        methods: {

        }
    })
    //app.i18n = i18n;

    return { app, router, store, i18n }
}
