<template>
  <div class="bg-faf min-h100">
    <div class="page6-2 py-5 px-3 text-center text-1d">
      <h5 class="fw-600 fs-30 mb-5 ff-prata">{{ $t("payment_declined") }}</h5>

      <div class="my-4 text-ee1">
        <!-- <b-img
          src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/payment/declined_icon.png"
          width="100"
          center
        /> -->
        <i
          class="fa-regular fa-triangle-exclamation"
          style="font-size: 53px"
        ></i>
      </div>

      <p class="text-7e" v-html="$t('payment_declined_desc')"></p>
      <div style="height: 30vh"></div>
      <div class="mt-5">
        <b-button
          @click="backCheckOut('card')"
          variant="ee1"
          class="mb-4 px-5 text-uppercase l-space2 w-100"
          pill
          style="height: 53px"
        >
          {{ $t("try_another_card") }}
        </b-button>

        <b-button
          @click="backCheckOut('bank')"
          variant="outline-ee1"
          class="fw-600 px-5 text-uppercase l-space2 w-100"
          pill
          style="height: 53px"
        >
          {{ $t("try_bank_transfer") }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "payment-declined",
  data() {
    return {
      langCode: "en_US",
    };
  },
  mounted() {
    let self = this;
    self.langCode = this.$storage.get("cur-lang");
    self.$parent.closeLoading();
  },
  methods: {
    backCheckOut(vtype) {
      let self = this;
      //payment-checkout
      self.$router.push({
        name: "payment-checkout",
        query: { lang: self.langCode, top_nav: 0, type: vtype },
      });
    },
  },
};
</script>