import apiHttp from './apiHttp.js'
import storage from '../utils/storage'


function getCurLang(state) {
    //console.log('-----getCurLang', state)
    return _.get(state, 'curLang', 'en_US');
}

function getCurSite(state) {
    return _.get(state, 'curSite', false);
    //return storage.get('cur-site')
}

export default {
    test(url, params) {
        apiHttp.test(url, params);
    },
    postScheduleCallback(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/scheduleCallback', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postScheduleCallback error', err
                    ])
                    reject(err)
                })
        });
    },
    postMatchOperates(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/matchOperates', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postPaymentPageLog error', err
                    ])
                    reject(err)
                })
        });
    },
    getLikesV6(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/likesV6?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getLikesV6 error', err
                    ])
                    reject(err)
                })
        });
    },
    getMatchesV6(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/matchesV6?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMatchesV6 error', err
                    ])
                    reject(err)
                })
        });
    },
    getOPMatchList(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/lsv6/previewMatches?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getOPMatchList error', err
                    ])
                    reject(err)
                })
        });
    },
    getMatchmakingMatchList(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/lsv6/matchList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMatchmakingMatchList error', err
                    ])
                    reject(err)
                })
        });
    },
    getMatchmakingNewMatches(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/lsv6/newMatches?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMatchmakingNewMatches error', err
                    ])
                    reject(err)
                })
        });
    },
    postPaymentPageLog(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/visitPaymentPageLog', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postPaymentPageLog error', err
                    ])
                    reject(err)
                })
        });
    },
    getApplyCreateTIMGroup(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/applyCreateTIMGroup?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getApplyCreateTIMGroup error', err
                    ])
                    reject(err)
                })
        });
    },
    getRenewalPaymentData(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/lsv6/renewalPaymentData?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getRenewalPaymentData error', err
                    ])
                    reject(err)
                })
        });
    },
    getRenewalPaymentDataV5(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/membership/renewalPaymentDataV5?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getRenewalPaymentDataV5 error', err
                    ])
                    reject(err)
                })
        });
    },
    getFreeUserPaymentDataV5(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/membership/freeUserPaymentDataV5?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getFreeUserPaymentDataV5 error', err
                    ])
                    reject(err)
                })
        });
    },
    getCountryMenus(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/content/countryMenu?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getCountryMenus error', err
                    ])
                    reject(err)
                })
        });
    },
    getLsStartup(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/index/lsStartup?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getLsStartup error', err
                    ])
                    reject(err)
                })
        });
    },
    getLovestruckCreditPrices(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/lovestruckPrices?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getLovestruckCreditPrices error', err
                    ])
                    reject(err)
                })
        });
    },
    getNewMatchPreviewList(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/matchPreviewList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getNewMatchPreviewList error', err
                    ])
                    reject(err)
                })
        });
    },
    getNewMatchList(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/newMatchList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getNewMatchList error', err
                    ])
                    reject(err)
                })
        });
    },
    getSendFirstMatchmakerMsg(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/sendFirstMatchmakerMsg?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getSendFirstMatchmakerMsg error', err
                    ])
                    reject(err)
                })
        });
    },
    getGenerateInitialIntros(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/generateIntros?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getGenerateInitialIntros error', err
                    ])
                    reject(err)
                })
        });
    },
    getIntroMatchList(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/introMatchList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getIntroMatchList error', err
                    ])
                    reject(err)
                })
        });
    },
    getSuccessStories(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/lsv6/successStories?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getSuccessStories error', err
                    ])
                    reject(err)
                })
        });
    },
    getPageData(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/lsv6/pageData?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getPageData error', err
                    ])
                    reject(err)
                })
        });
    },
    getLanguageOrder(paramString, state) {
        //{{api_host}}/api/v2/index/languageOrder?iso2=th
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/index/languageOrder?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getLanguageOrder error', err
                    ])
                    reject(err)
                })
        });
    },
    postRequirementSkip(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/requirementSkip', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postRequirementSkip error', err
                    ])
                    reject(err)
                })
        });
    },
    postReportUser(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/reportUser', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postReportUser error', err
                    ])
                    reject(err)
                })
        });
    },
    postBlockUser(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/blockUser', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postBlockUser error', err
                    ])
                    reject(err)
                })
        });
    },
    getRequirementMatchList(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/requirementMatchList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getRequirementMatchList error', err
                    ])
                    reject(err)
                })
        });
    },
    postLeadUpdate(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/lead/update', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postLeadUpdate error', err
                    ])
                    reject(err)
                })
        });
    },
    postAddContact(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/addContact', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postAddContact error', err
                    ])
                    reject(err)
                })
        });
    },
    postRemoveContact(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/removeContact', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postRemoveContact error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientContacts(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/contacts?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientContacts error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientMatchContacts(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/matchChatList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientMatchContacts error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientConversations(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/Conversation?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientConversations error', err
                    ])
                    reject(err)
                })
        });
    },
    postUserCheck(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/userCheck', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postUserCheck error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientAutoChat(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/autoChat?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientAutoChat error', err
                    ])
                    reject(err)
                })
        });
    },
    getNewUserAutoChat(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/newUserAutoChat?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getNewUserAutoChat error', err
                    ])
                    reject(err)
                })
        });
    },
    getAvailabilityList(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/venue/availabilityList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getAvailabilityList error', err
                    ])
                    reject(err)
                })
        });
    },
    postStartAutoChat(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/startAutoChat', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postStartAutoChat error', err
                    ])
                    reject(err)
                })
        });
    },
    postBuyTicket(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/event/buyTicket', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postBuyTicket error', err
                    ])
                    reject(err)
                })
        });
    },
    getReservationCreditPrices(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/reservationCredit/prices?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getReservationCreditPrices error', err
                    ])
                    reject(err)
                })
        });
    },
    getEventTicketPrices(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/event/prices?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getEventTicketPrices error', err
                    ])
                    reject(err)
                })
        });
    },
    getRoomAccessToken(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/video/roomAccessToken?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getRoomAccessToken error', err
                    ])
                    reject(err)
                })
        });
    },
    postSaveCreditCard(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/creditCard', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postSaveCreditCard error', err
                    ])
                    reject(err)
                })
        });
    },
    getCreditCard(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/client/creditCard?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getCreditCard error', err
                    ])
                    reject(err)
                })
        });
    },
    getMatchmakers(paramString, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .get(`/api/v2/index/matchmakers?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMatchmakers error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientOrderInfo(paramString, state) {
        return new Promise((resolve, reject) => {
            //client_order_id=11177
            apiHttp
                .get(`/api/v2/order/clientOrderInfo?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientOrderInfo error', err
                    ])
                    reject(err)
                })
        });
    },
    postCancelBookConsultation(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/cancelBookConsultation', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postCancelBookConsultation error', err
                    ])
                    reject(err)
                })
        });
    },
    getCurStore(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/curStore`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getCurStore error', err
                    ])
                    reject(err)
                })
        });
    },
    postAddIntro(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/intros/addIntro', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postAddIntro error', err
                    ])
                    reject(err)
                })
        });
    },
    postBookConsultation(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/bookConsultation', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postBookConsultation error', err
                    ])
                    reject(err)
                })
        });
    },
    getEliteList(paramString, params, state) {
        return new Promise((resolve, reject) => {
            //page=1&page_size=20?
            apiHttp
                .get(`/api/v2/client/elites?${paramString}`, params, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getEliteList error', err
                    ])
                    reject(err)
                })
        });
    },
    postCancelDateEvent(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/event/cancelJoin', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postCancelDateEvent error', err
                    ])
                    reject(err)
                })
        });
    },
    postJoinDateEvent(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/event/joinEvent', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postJoinDateEvent error', err
                    ])
                    reject(err)
                })
        });
    },
    getDateEventList(paramString, params, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/event/dateEvents?${paramString}`, params, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getDateEventList error', err
                    ])
                    reject(err)
                })
        });
    },
    getCurrentInviteEvent(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/currentInviteEvent${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getCurrentInviteEvent error', err
                    ])
                    reject(err)
                })
        });
    },
    getEventInfo(paramString, params, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/event/info${paramString}`, params, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getEventInfo error', err
                    ])
                    reject(err)
                })
        });
    },
    getEventList(paramString, params, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/event/list${paramString}`, params, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getEventList error', err
                    ])
                    reject(err)
                })
        });
    },
    getIsAuth(paramString, params, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/authorise/isAuth${paramString}`, params, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getIsAuth error', err
                    ])
                    reject(err)
                })
        });
    },
    postClientMessage(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/sendMessage', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postClientMessage error', err
                    ])
                    reject(err)
                })
        });
    },
    getMatchClients(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/matchList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMatchClients error', err
                    ])
                    reject(err)
                })
        });
    },
    postMobileCheck(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post(`/api/v2/authorise/check`, data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postMobileCheck error', err
                    ])
                    reject(err)
                })
        });
    },
    getMobileCheck(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/authorise/check${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMobileCheck error', err
                    ])
                    reject(err)
                })
        });
    },
    getDateTimeList(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/venue/availabilityTimeRange${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getDateTimeList error', err
                    ])
                    reject(err)
                })
        });
    },
    postDateFeedback(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/intros/saveFeedback', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postDateFeedback error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientCount(state) {
        return new Promise((resolve, reject) => {
            apiHttp
                // .get(`/api/v2/client/myCount`, false, state)
                .get(`/api/v2/lsv6/myCount`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientCount error', err
                    ])
                    reject(err)
                })
        });
    },
    getVenueList(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/venue/list?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getVenueList error', err
                    ])
                    reject(err)
                })
        });
    },
    postDateAction(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/intros/actionDate', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postDateAction error', err
                    ])
                    reject(err)
                })
        });
    },
    getMatchList(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/intros/matchList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMatchList error', err
                    ])
                    reject(err)
                })
        });
    },
    postClientRequirement(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/requirement', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postClientRequirement error', err
                    ])
                    reject(err)
                })
        });
    },
    postClientOperate(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/operates', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postClientOperate error', err
                    ])
                    reject(err)
                })
        });
    },
    postDateConcierge(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/dateConcierge', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postDateConcierge error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientRequirement(state) {
        //console.log('state token',_.get(state,'token','no token'))
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/requirement`, false, state)
                .then(rs => {
                    //console.log('requirement', rs)
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientRequirement error', err
                    ])
                    reject(err)
                })
        });
    },
    getSimpleClient(id, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/simpleClient?id=${id}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getSimpleClient error', err
                    ])
                    reject(err)
                })
        });
    },
    getMatchStatus(id, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/matchStatus?target_client_id=${id}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getMatchStatus error', err
                    ])
                    reject(err)
                })
        });
    },
    getSimpleClient(id, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/simpleClient?id=${id}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getSimpleClient error', err
                    ])
                    reject(err)
                })
        });
    },
    getChatTarget(id, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/chatTarget?target_client_id=${id}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getChatTarget error', err
                    ])
                    reject(err)
                })
        });
    },
    getClient(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/client/client?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClient error', err
                    ])
                    reject(err)
                })
        });
    },
    getIpLocal(state) {

        return new Promise((resolve, reject) => {
            apiHttp
                .get('https://service2.lovestruck.com/ip', false, state)
                .then(rs => {

                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getIpLocal error', err
                    ])
                    reject(err)
                })
        });

    },
    getPhraseInfo(paramString, state) {
        ///v2/phrase/info
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/phrase/info?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getPhraseInfo error', err
                    ])
                    reject(err)
                })
        });
    },
    getPhrase(state) {
        let cur_site = getCurSite(state);
        //let lang = getCurLang();

        let chunk_cache_key = `phrase-${cur_site.url_long}`;
        //let chunk_expiry_cache_key = `${chunk_cache_key}_expiry`

        return new Promise((resolve, reject) => {

            // let chunk_expiry = localStorage.getItem(chunk_expiry_cache_key);
            // let chunks = JSON.parse(localStorage.getItem(chunk_cache_key));
            // let now = (new Date()).getTime()
            let chunks = storage.get(chunk_cache_key);
            //console.log(now)
            if (!chunks) {

                apiHttp
                    .get('/api/v2/phrase/forAppWeb/', false, state)
                    .then(rs => {
                        if (rs.data.hasOwnProperty('en_us')) {
                            storage.setItem(chunk_cache_key, JSON.stringify(rs.data), 7200);
                        }
                        resolve(rs.data)
                    })
                    .catch(err => {
                        console.error([
                            'getPhrase error', err
                        ])
                        reject(err)
                    })
            } else {
                resolve(chunks);
            }
        });

    },
    postLoginByToken(iToken, state) {
        // let fdata = new FormData();
        // fdata.append('token', iToken)
        let params = {
            token: iToken
        }
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/authorise/loginFromToken', {}, params, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postLoginByToken error', err
                    ])
                    reject(err)
                })
        });
    },
    postLogin(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/authorise/login/', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postLogin error', err
                    ])
                    reject(err)
                })


        });
    },
    postVerifyPhone(data, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .post('/api/gen/authorise/verifyPhone/', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postVerifyPhone error', err
                    ])
                    reject(err)
                })


        });
    },
    postRequestWithPhone(data, state) {
        return new Promise((resolve, reject) => {

            apiHttp
                .post('/api/gen/authorise/requestWithPhone/', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postRequestWithPhone error', err
                    ])
                    reject(err)
                })


        });
    },
    getCountryCode2(state) {
        let local_lang = storage.get('cur-lang');
        let store_lang = _.get(state, 'curLang', 'en_US')
        let lang = local_lang ? local_lang : store_lang;
        let cache_key = `country_list.${lang}1.4`;

        return new Promise((resolve, reject) => {

            //let countryList = JSON.parse(localStorage.getItem(cache_key));
            let countryList = storage.get(cache_key)

            if (countryList) {
                resolve(countryList);
            } else {
                apiHttp
                    .get(`/api/v2/index/CountryCodes`, false, state)
                    .then(rs => {
                        if (rs.data.hasOwnProperty('list')) {
                            storage.set(cache_key, JSON.stringify(rs.data.list), 3600)
                            //localStorage.setItem(cache_key, JSON.stringify(rs.data));
                        }
                        resolve(rs.data.list)
                    })
                    .catch(err => {
                        console.error([
                            'getCountryCode error', err
                        ])
                        reject(err)
                    })
            }


        });


    },
    getCountryCode(state) {
        //let lang = getCurLang();
        let cache_key = 'country_list';

        return new Promise((resolve, reject) => {

            //let countryList = JSON.parse(localStorage.getItem(cache_key));
            let countryList = storage.get(cache_key)

            if (countryList) {
                resolve(countryList);
            } else {
                apiHttp
                    .get(`/api/gen/country_code/?format=json`, false, state)
                    .then(rs => {
                        if (rs.data.hasOwnProperty('primary')) {
                            storage.set(cache_key, JSON.stringify(rs.data))
                            //localStorage.setItem(cache_key, JSON.stringify(rs.data));
                        }
                        resolve(rs.data)
                    })
                    .catch(err => {
                        console.error([
                            'getCountryCode error', err
                        ])
                        reject(err)
                    })
            }


        });


    },
    getPageContent(pageId, state) {
        let lang = getCurLang(state);
        let site = getCurSite(state);

        let cache_key = `page-${pageId}-${lang}`;

        return new Promise((resolve, reject) => {
            //let data = JSON.parse(localStorage.getItem(cache_key))
            let data = storage.get(cache_key)
            if (data) {
                resolve(data);
            } else {
                apiHttp
                    .get(`/api/${site.url_long}/content/${pageId}/`, false, state)
                    .then(rs => {
                        if (rs.data.hasOwnProperty('result')) {
                            storage.set(cache_key, JSON.stringify(rs.data.result.page_content), 7200)
                            resolve(rs.data.result.page_content)
                        }

                    })
                    .catch(err => {
                        console.error([
                            'getPageContent error', err
                        ])
                        reject(err)
                    })
            }
        });

    },
    getFreeUpgradePrice(paramString, state) {

        return new Promise((resolve, reject) => {
            let data = false;
            if (data) {
                resolve(data);
            } else {
                apiHttp
                    .get(`/api/v2/lsv6/freeUpgradePrice${paramString}`, false, state)
                    .then(rs => {
                        if (_.get(rs, 'data.currency', false)) {
                            resolve(_.get(rs, 'data'))
                        } else {
                            reject(rs)
                        }
                    })
                    .catch(err => {
                        console.error([
                            'getFreeUpgradePrice error', err
                        ])
                        reject(err)
                    })
            }

        });
    },
    getMembershipLevels(paramString, state) {
        // let lang = getCurLang(state);
        // let clientId = _.get(user, 'id', _.get(user, 'client_id', 0))
        // let cache_key = `crm-membership-level-${lang}-${clientId}`;
        // storage.remove(cache_key);

        return new Promise((resolve, reject) => {

            // let data = JSON.parse(localStorage.getItem(cache_key));
            // let data = storage.get(cache_key)
            let data = false;
            if (data) {
                resolve(data);
            } else {
                apiHttp
                    .get(`/api/v2/lsv6/membershipPrice${paramString}`, false, state)
                    .then(rs => {
                        if (_.get(rs, ['data', 'levels'], false)) {
                            resolve(_.get(rs, 'data'))
                        } else {
                            reject(rs)
                        }
                        // if (rs.data.hasOwnProperty('levels')) {
                        // 	storage.set(cache_key, JSON.stringify(rs.data), 120)

                        // }
                        // resolve(rs.data)
                    })
                    .catch(err => {
                        console.error([
                            'getMembershipLevels error', err
                        ])
                        reject(err)
                    })
            }

        });
    },
    getYotiCreate(state, params = '') {

        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/yoti/create?${params}`, false, state)
                .then(rs => {

                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getYotiCreate error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientMe(state, params = '') {

        return new Promise((resolve, reject) => {
            apiHttp
                .get('/api/v2/lsv6/me', params, state)
                .then(rs => {
                    storage.updateUserInfo(_.get(rs, 'data.client'))

                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientMe error', err
                    ])
                    reject(err)
                })
        });
    },
    getClientMe2(state, paramString) {

        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/lsv6/me${paramString}`, false, state)
                .then(rs => {
                    storage.updateUserInfo(_.get(rs, 'data.client'))

                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getClientMe2 error', err
                    ])
                    reject(err)
                })
        });
    },
    postReadConversation(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/readConversation', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {

                    console.error([
                        'postReadConversation error', err
                    ])
                    reject(err)
                })
        });

    },
    postDateQs(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/intros/saveDateQs', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {

                    console.error([
                        'postDateQs error', err
                    ])
                    reject(err)
                })
        });

    },
    postIntroUpdate(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/intros/update', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {

                    console.error([
                        'postIntroUpdate error', err
                    ])
                    reject(err)
                })
        });

    },
    getIntroOptions(state) {
        let lang = getCurLang(state);
        let cache_key = `crm-date-option-${lang}`;

        return new Promise((resolve, reject) => {
            // let data = JSON.parse(localStorage.getItem(cache_key))
            let data = storage.get(cache_key)

            if (data) {
                resolve(data)
            } else {
                apiHttp
                    .get('/api/v2/intros/dateOptions', false, state)
                    .then(rs => {
                        if (rs.data.hasOwnProperty('dateQs')) {
                            storage.set(cache_key, JSON.stringify(rs.data))
                            //localStorage.setItem(cache_key, JSON.stringify(rs.data));
                        }
                        resolve(rs.data)
                    })
                    .catch(err => {
                        console.error([
                            'getIntroOptions error', err
                        ])

                        reject(err)
                    })
            }


        });
    },
    getDateLists(paramString, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/intros/dateList?${paramString}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {

                    console.error([
                        'getIntroLists error', err
                    ])
                    reject(err)
                })
        });

    },
    getIntroInfo(introId, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/intros/introInfo?intro_id=${introId}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {

                    console.error([
                        'getIntroInfo error', err
                    ])
                    reject(err)
                })
        });

    },
    postClientUpdate(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/profile', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {

                    console.error([
                        'postClientUpdate error', err
                    ])
                    reject(err)
                })

        });

    },
    postRemovePhoto(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/rmPhoto', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {

                    console.error([
                        'postRemovePhoto error', err
                    ])
                    reject(err)
                })

        });

    },
    postSaveBankReceipt(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/saveBankReceipt', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'saveBankReceipt error', err
                    ])
                    reject(err)
                })
        });

    },
    postReadAgreement(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/readAgreement', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postReadAgreement error', err
                    ])
                    reject(err)
                })
        });

    },
    postUploadPhoto(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/client/photo', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postUploadPhoto error', err
                    ])
                    reject(err)
                })
        });

    },

    getClientPhotos(state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get('/api/v2/client/photo', false, state)
                .then(rs => {
                    if (rs.data.hasOwnProperty('photos')) {

                        resolve(rs.data.photos)
                    }
                })
                .catch(err => {
                    console.error([
                        'getClientPhotos error', err
                    ])
                    reject(err)
                })

        });

    },
    getAccountExist(params, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .get(`/api/v2/authorise/accountExist?${params}`, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'getAccountExist error', err
                    ])
                    reject(err)
                })

        });

    },
    postRegister(data, state) {
        return new Promise((resolve, reject) => {
            apiHttp
                .post('/api/v2/authorise/Register', data, false, state)
                .then(rs => {
                    resolve(rs.data)
                })
                .catch(err => {
                    console.error([
                        'postRegister error', err
                    ])
                    reject(err)
                })
        });

    },
    getAttributes(state) {

        let lang = getCurLang(state);
        let cache_key = `attributes-${lang}`;
        //console.log('getAttributes',cache_key)
        return new Promise((resolve, reject) => {

            // let data = JSON.parse(localStorage.getItem(cache_key));
            let data = storage.get(cache_key)
            // console.log(data);
            if (data) {
                //console.log('resolve');
                resolve(data);
            } else {
                apiHttp
                    // .get('https://app.lovestruck.com/api/attributes', false, state)
                    .get('/api/v2/attribute/all', false, state)
                    .then(rs => {

                        if (rs.data.hasOwnProperty('gender')) {
                            storage.set(cache_key, JSON.stringify(rs.data), 600)
                            // localStorage.setItem(cache_key, JSON.stringify(rs.data));
                            resolve(rs.data);
                        }
                    })
                    .catch(err => {
                        console.error([
                            'getAttributes error', err
                        ])
                        reject(err)
                    })
            }
        });
    }
}
