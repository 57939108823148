<template>
  <div>
    <b-modal
      v-model="show"
      title
      hide-header
      hide-footer
      hide-header-close
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="position-absolute">
        <a href="javascript:;" @click="closeModal" class="p-2">
          <i class="lsicon arrow_left_red"></i>
        </a>
      </div>
      <div v-if="client" class="text-center my-3">
        <div class="circle-image mx-auto mb-4">
          <b-img :src="client.photo_url" />
        </div>
        <div class="text-center fw-700">
          {{ client.first_name }}, {{ client.age }}
        </div>
        <hr />
        <div class="text-center my-4">
          <div class="fw-600 text-5d fs-15 mb-3">
            {{ waiting_for_message }}
          </div>
          <div class="fs-15 mb-4 text-77">
            {{ waiting_for_message_desc }}
          </div>
          <div v-html="labelHtmlText"></div>
        </div>

        <hr />
        <div class="text-center">
          <div class="fw-600 text-5d fs-15 mb-3">{{ $t("dont_to_wait") }}</div>
          <div class="fs-14 mb-3 text-77">
            {{ $t("get_gold_to_chat") }}
          </div>
          <a
            href="javascript:;"
            @click="getGold"
            class="btn text-uppercase px-4 mt-2 bg-gold text-dred fw-600"
            >{{ $t("get_gold") }}</a
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "chat-upgrade-pupop",
  data() {
    return {
      show: false,
      client: null,
      label_button: null,
      labelHtmlText: "",
      waiting_for_message: "",
      waiting_for_message_desc: "",
    };
  },
  mounted() {
    let self = this;
  },
  methods: {
    getGold() {
      this.$emit("getGold", {});
      //   this.$router.push({
      //     name: "membership-upgrade",
      //     query: { type: "gold", page: 1 },
      //   });
    },
    parseLableArray() {
      let self = this;
      let label_array = _.get(self.label_button, "label_array");
      //   console.log("self.label_buttons", self.label_buttons);
      if (label_array) {
        let htmlText = "";
        for (let lab of label_array) {
          htmlText += `<span class="${
            lab.bold == "1" ? "fw-700" : ""
          }" style="color:${lab.color}">${lab.text}</span>`;
        }
        htmlText = htmlText.replace("[icon_chat_expired]", ``);
        //<i class="lsicon chat_expired"></i>
        return htmlText;
      }

      return htmlText;
    },
    closeModal() {
      let self = this;
      self.show = false;

      self.client = null;
      self.label_button = null;
      self.labelHtmlText = null;
    },
    showModal(client, label_button) {
      let self = this;

      self.client = client;
      self.label_button = label_button;
      self.labelHtmlText = self.parseLableArray();
      self.show = true;
      self.waiting_for_message = self
        .$t("waiting_for_message")
        .replace("[match_first_name]", self.client.first_name);
      self.waiting_for_message_desc = self
        .$t("waiting_for_message_desc")
        .replace("[match_first_name]", self.client.first_name);
    },
    test() {
      let self = this;
      self.show = true;
      self.client = {
        age: 33,
        client_id: 6841,
        dob: "1988-05-20",
        first_name: "Pauline",
        gender: "f",
        is_verified: 1,
        job_title: "收銀員",
        last_name: "Fok",
        membership_level_id: 2,
        msg_sent_count: 1,
        photo_url:
          "https://cdn-cf2.lovestruck.com/upload-s/crop_1638846362.jpeg",
        privacy_mode: 0,
        rating_level_id: 1,
        rating_level_name: "普通",
      };
      self.label_button = {
        label_array: [
          {
            bold: "1",
            color: "#13BD50",
            text: "聊天已开启",
          },
        ],
      };

      self.labelHtmlText = self.parseLableArray();
    },
  },
};
</script>