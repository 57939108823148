<template>
  <div class="min-h100 bg-faf">
    <topName ref="topNav" :showDateCredit="false" :showEventTicket="false" :showMobileTop="false" />
    <div class="page_container hideMobileTop">
      <div class="page6-2 px-0 page-div text-center">
        <div v-if="client" class="py-5 pt-3- bg_image text-faf" :style="`
            min-height: 44vh;
            background-position: bottom;
            background-image: url(https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/${bgName}.png);`">
          <!-- <b-img
            v-if="membershipPhoto"
            :src="membershipPhoto"
            height="30"
            center
            class="mt-2"
          /> -->

          <!-- <p
            v-if="client.membership_level_id > 1 && client.cur_membership_level"
            class="mt-3 text-muted text-capitalize"
          >
            {{ $t("membership_text") }}:
            <b>{{ client.cur_membership_level.description }}</b>
          </p> -->

          <div class="d-flex justify-content-around pt-3">
            <div style="width: 68px">
              <div style="width: 100%; height: 30px"></div>
              <a @click="editProfile" href="javascript:;">
                <!-- <b-img
                  width="50"
                  src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/edit_profile_button.png"
                /> -->
                <div class="o-div-54-white mx-auto mb-2">
                  <i class="fa-light fa-pencil text-d7c"></i>
                </div>
                <p class="text-faf text-capitalize fs-13">
                  {{ $t("progress_title") }}
                </p>
              </a>
            </div>
            <div>
              <a @click="photoEdit" href="javascript:;" class="me-photo-div mb-3 mx-auto"
                :style="`background-image: url(${client.primary_photo});`">
                <!-- <b-img class="w-100" :src="client.primary_photo" center /> -->
              </a>
              <p class="fw-700 mb-2">
                {{ client.first_name
                }}<span v-if="client.age">, {{ client.age }}</span>
                <!-- <i
                  class="lsicon lsicon-18"
                  :class="
                    client.is_verified == 1 ? 'verified_blue2' : 'unverified'
                  "
                ></i> -->
              </p>
              <p class="fs-13 mb-0">
                {{ $t("member_id") }}: {{ client.client_id }}
              </p>
              <!-- <p>
                <span v-if="client.is_verified == 0" class="text-muted">{{
                  $t("unverified_user_txt")
                }}</span>
                <span v-if="client.is_verified == 1" class="text-success">{{
                  $t("verified_title")
                }}</span>
              </p> -->
              <!-- <div v-if="levelTextImg" class="mt-3">
                <b-img center width="90" :src="levelTextImg"></b-img>
              </div> -->
              <div v-if="client.membership_level_id > 1 && client.membership_level" class="text-uppercase mt-3 fw-700">
                - {{ client.membership_level.description }} -
              </div>
              <!-- <div v-else class="mt-3 fw-700">FREE</div> -->
            </div>
            <div style="width: 68px">
              <div style="width: 100%; height: 30px"></div>
              <a @click="settings" href="javascript:;">
                <div class="o-div-54-white mx-auto mb-2">
                  <i class="fa-light fa-gear text-d7c"></i>
                </div>
                <!-- <b-img
                  width="50"
                  src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/lsm-app/settings_button.png"
                /> -->
                <p class="text-faf text-capitalize fs-13">
                  {{ $t("settings_txt") }}
                </p>
              </a>
            </div>
          </div>
        </div>
        <!-- <div v-if="client && client.membership_level_id<=1" class="my-3 text-center"> -->
        <!-- <b-button
            @click="goPaymentPage"
            variant="ls-green2"
            pill
            class="px-4 fw-500 text-uppercase btn-lg"
            >{{ $t("frontpage_section6_button") }}</b-button
          > -->
        <!-- </div> -->

        <div v-if="client" class="py-4 w-100">
          <!-- <div v-if="client.membership_level_id > 1 && booking">
            <b-img
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/phone_icon.png"
              center
              width="50"
              class="mb-3"
            />
            <p class="fw-700 text-muted mb-3">{{ $t("our_team_call_you") }}</p>
            <p class="text-ls-dia fw-700">{{ booking.date_format_long }}</p>

            <div class="mt-4">
              <b-button
                @click="membershipClick('diamond')"
                variant="outline-secondary"
                class="text-uppercase fw-700"
                >{{ $t("Reschedule") }}</b-button
              >
            </div>
          </div> -->

          <div v-if="client.show_dia_call_back_section == 1" class="px-3">
            <!-- <b-img
              src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls_com_black/call_icon_white.png"
              center
              width="50"
              class="mb-3"
            /> -->
            <div class="mb-3">
              <i class="fa-light fa-phone-volume fs-40 text-ee1"></i>
            </div>
            <p class="text-1d fw-600 mb-3" v-html="willCallYouOn"></p>

            <p v-if="booking && !booking.consultation_time_expired" class="text-ee1 fw-600 mb-3">
              {{ booking.date_format_long }}
            </p>
            <div class="mt-4"><i class="fa-light fa-circle-question text-ee1"></i></div>
            <p class="text-7e" v-html="any_q_contact"></p>
          </div>

          <div v-if="client.membership_level_id > 1 &&
            client.membership_level_id <= 5 &&
            carouselLen > 0
            ">
            <div class="mt-3 w-100 mx-auto bg-white p-3" style="max-width: 320px">
              <div class="swiper-container" id="membershipSwiper">
                <div class="swiper-wrapper mb-3-">
                  <div class="swiper-slide text-center" v-for="(item, key) in carousels" :key="`swiper-${key}`">
                    <div>
                      <!-- :src="https://cdn-cf2.lovestruck.com/upload/gen/img/v12/Logo-v12@2x.png" -->
                      <!-- <b-img height="40" class="mb-2" :src="mLevelLogos[currentBtn.type]"></b-img> -->
                      <b-img height="20" class="mb-0" src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/logos/Lovestruck-Ruby-Wordmark-RGB.png"></b-img>
                      <!-- <p class="mb-0 text-ee1 fw-700 fs-14 l-space1">
                        -
                        {{ currentBtn.type == "plus" ? "PLUS" : "MATCHMAKING" }}
                        -
                      </p> -->
                    </div>
                    <hr class="my-3" />
                    <div class="d-block mx-auto mb-3">
                      <i :class="item.icon" class="fs-40 text-ee1"></i>
                    </div>
                    <p class="fw-600 text-7e px-2 mb-1" v-html="item.title"></p>
                    <p class="d-block font-weight-light fs-13 px-2 text-7e" v-html="item.desc"></p>
                  </div>
                </div>
                <!-- <div class="swiper-pagination" style="bottom: -2px"></div> -->
                <!-- <div v-if="navigation" class="swiper-button-prev"></div>
        <div v-if="navigation" class="swiper-button-next"></div> -->
              </div>

              <div class="text-center">
                <b-button pill @click="membershipClick(currentBtn.type)"
                  :variant="currentBtn.type == 'plus' ? 'ee1' : '1d'" class="mt-3 text-uppercase l-space2"
                  style="height: 53px; min-width: 260px">{{ $t('button_membership_upgrade') }}</b-button>
              </div>
            </div>
            <div class="swiper-pagination ee1 mt-3" style="position: unset"></div>
          </div>

          <!-- <div
            v-if="client.membership_level_id <= 1 && !booking"
>>>>>>> uat
            class="text-center py-5"
          >
            <b-button
              @click="getStared"
              variant="ls-green2"
              pill
              class="text-uppercase fw-600 px-4"
              >{{ $t("frontpage_section6_button") }}</b-button
            >

          </div> -->
        </div>
      </div>
    </div>
    <!-- <scheduleConsultation
      ref="scheduleConsultation"
      @closePopup="closeScheduleModel"
    /> -->
    <membershipUpgradePopup ref="membershipUpgradePopup" />
  </div>
</template>
<script>
import topName from "../common/topNav";
import helper from "../../utils/helper";
import membershipUpgradePopup from "../payment/membershipUpgradePopup.vue";
// import scheduleConsultation from "../common/scheduleConsultation";
// import planCarousel from "../payment/planCarousel.vue";
// import { accountStatus } from "../../utils/mixin";
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}
export default {
  name: "profile-me",
  components: {
    topName,
    membershipUpgradePopup,
    // scheduleConsultation,
    // planCarousel,
  },
  data() {
    return {
      client: null,
      booking: null,
      store: null,
      membershipPhoto: null,
      membershipSwiper: null,
      carouselLen: 0,
      carousels: [],
      currentBtn: null,
      any_q_contact: "",
      willCallYouOn: "",
      levelTextImg: "",
      swiperOption: {
        //init:false,
        speed: 500,
        loop: true,
        initialSlide: 0,
        direction: "horizontal",
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "ls-ee1-bullet-active",
        },
      },
      renewDiscout: "",
      bgName: "bg_starter",
      mLevelLogos: null
    };
  },
  //   mixins: [accountStatus],
  mounted() {
    let self = this;
    // console.log(self.$parent);
    self.$parent.closeLoading();
    // self.$parent.loadBranchBar();
    self.mLevelLogos = helper.membershipPhoto()
    self.getMe();
  },
  methods: {
    renewBtnClick() {
      //name: "renew-payment",
      this.$router.push({
        name: "renew-payment",
      });
    },
    gotPayment(type, pageV) {
      this.$refs.membershipUpgradePopup.showModal({
        initialSlideVal: pageV,
        membershipType: type,
        client: this.client,
      });
    },
    closeScheduleModel() {
      this.getMe();
    },
    getStared() {
      this.$router.push({
        name: "free-payment",
        // name: "membership-choose",
      });
    },
    goPaymentPage() {
      //   this.currentIntro = item;
      this.$router.push({
        name: "membership-choose",
      });
    },
    getMe() {
      let self = this;
      self.$layer.loading();

      self.$api
        .getClientMe(self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          //   let membership_level_id = _.get(rs, "client.membership_level_id");
          //   membership_level_id = 2;
          //   if (membership_level_id < 1) {
          //     self.$router.push({ name: "profile-setting" });
          //     return false;
          //   }
          self.client = _.get(rs, "client");
          self.store = _.get(rs, "store");
          //   self.checkAccountStatus(self.client);
            // self.client.membership_level_id = 2;
          //   self.client.show_dia_call_back_section = 1;
          self.booking = _.get(rs, "booking");

          if (self.client.membership_level_id == 3) {
            self.bgName = "bg_plus";
          } else if (self.client.membership_level_id >= 4) {
            self.bgName = "bg_matchmaking";
          } else {
            self.bgName = "bg_starter";
          }
          //   self.membershipPhoto =
          //     helper.getMembershipPhotoById(membership_level_id);

          self.renewDiscout = self
            .$t("renew_discout")
            .replace("[discount]", self.client.membership_discount);
          // console.log("self.membershipPhoto", self.membershipPhoto);
          if (!self.booking && self.client.membership_has_expired == 0) {
          }

          let any_q_contact = self.$t("any_q_contact");

          self.any_q_contact = `${any_q_contact}<br/><span class="text-7e fw-600">${self.store.phone_number}</span>`;
          // .replace(
          //   "[phone_number]",
          //   `<br/><span class="text-white fw-600">${self.store.phone_number}</span>`
          // );
          self.willCallYouOn = self
            .$t("will_call_you_on")
            .replace("[phone_mobile]", `<br/>+${self.client.country_phone_code} ${self.client.phone_number}`) //
            .replace("<b>", '<b class="text-7e">');
          //   console.log(helper.membershipTextPhoto(), helper.getMembershipNameById(membership_level_id))
          //   self.levelTextImg = _.get(
          //     helper.membershipTextPhoto(),
          //     helper.getMembershipNameById(membership_level_id)
          //   );
          //   self.membershipPhoto =
          //     helper.getMembershipPhotoById(membership_level_id);
          // console.log("self.membershipPhoto", self.membershipPhoto);
          //   self.client.dia_call_back = 0
          if (self.client.show_dia_call_back_section == 0) {
            self.setCarousel();
          }
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          console.log(["me error", err]);
        });
    },
    photoEdit() {
      //name: 'photo-edit'
      this.$router.push({ name: "photo-edit" });
    },
    editProfile() {
      this.$router.push({ name: "profile-edit" });
    },
    matchFilter() {
      this.$router.push({ name: "profile-requirement" });
    },
    settings() {
      this.$router.push({ name: "profile-setting" });
    },
    membershipClick(type) {
      let self = this;
      //   console.log("type=", type, self.membershipSwiper);
      //   self.gotPayment("gold", 1);
      //     return false;
      let pageV = _.get(self.membershipSwiper, "activeIndex", 1);
      if (type == "plus") {
        self.$router.push({
          name: "contextual-upgrades",
          query: {
            type: "plus",
          },
        });
        // self.gotPayment("gold", pageV == 11 ? 1 : pageV);
        // self.$router.push({
        //   name: "membership-upgrade",
        //   query: { type: "gold", page: pageV == 11 ? 1 : pageV },
        // });
      } else if (type == "matchmaking") {
        self.$router.push({
          name: "contextual-upgrades",
          query: {
            type: "matchmaking",
          },
        });
      }

      //   else if(type == 'black'){
      //         //name: "matchmaking-index"
      //         self.$router.push({ name: "matchmaking-index" })
      //   }

      //   else {
      //     let membership_level_id = _.get(self.client, "membership_level_id");
      //     // membership_level_id = 5;
      //     if (membership_level_id <= 2) {
      //       pageV = pageV - 4;
      //     } else if (membership_level_id > 4) {
      //       console.log(self.carousels[pageV - 1]);
      //       pageV = _.get(self.carousels, `${pageV - 1}.index`, 1);
      //     }
      //     self.gotPayment("diamond", pageV);
      //     // self.$router.push({
      //     //   name: "membership-upgrade",
      //     //   query: { type: "diamond", page: pageV },
      //     // });
      //     // self.$refs.scheduleConsultation.showModal(2);
      //   }
    },
    carouselSlideEnd(activeIndex) {
      let self = this;
      //   console.log("carouselSlideEnd", activeIndex, self.carouselLen);
      let c_index = activeIndex - 1;
      if (activeIndex > self.carouselLen) {
        c_index = 0;
      }
      //   let current = self.carousels[c_index];
      self.currentBtn = _.get(self.carousels, `${c_index}.btn`);
      //   console.log(current.btn.text);
    },
    setCarousel() {
      let self = this;

      //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
      let membership_level_id = _.get(self.client, "membership_level_id", 0);
      if (membership_level_id <= 1 || membership_level_id >= 6) {
        return false;
      }
      //   let is_verified = _.get(self.client, "is_verified", 0);
      //   let membershipName = helper.getMembershipNameById(membership_level_id);
      //   console.log("membershipName=", membershipName);

      //   self.$refs.planCarousel.changePlanName(membershipName);
      //   membership_level_id = 5;
      self.carousels = [];
      // let regular = helper.lsmRegularItemsMe(self, false);
      // let premium = helper.lsmPremiumItemsMe(self, false);

      // // let lsm = membership_level_matchmaking_id==0 ? helper.lsmCarouselItems(self, false) : [];
      // if(membership_level_id <= 2){
      //     self.carousels = [...regular, ...premium];
      // }else if(membership_level_id == 3){
      //     self.carousels = premium;
      // }
      //   self.getGoldCarousels(membership_level_id);
      self.carousels = helper.mePageCarousels(self, membership_level_id);
      //   if (membership_level_id == 3) {

      //   } else {
      //     self.carousels = helper.meStarterCarousel(self);
      //   }
      // console.log('self.carousels', self.carousels)

      self.carouselLen = self.carousels.length;
      if (self.carouselLen == 0) {
        return false;
      }

      self.currentBtn = _.get(self.carousels, "0.btn");

      if (VueAwesomeSwiper) {
        // if (self.navigation) {
        //   self.swiperOption.navigation = {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev",
        //   };
        // }
        if (self.carouselLen == 1) {
          self.swiperOption.autoplay = false;
          self.swiperOption.navigation = false;
        }

        // let index = _.get(self.$route, "query.index", 0);
        // let page = _.get(self.$route, "query.page", 1);

        self.swiperOption.initialSlide = 0;

        (self.swiperOption.on = {
          slideChangeTransitionStart: function () {
            // console.log(this.activeIndex);
            self.carouselSlideEnd(this.activeIndex);
          },
        }),
          setTimeout(function () {
            self.membershipSwiper = new VueAwesomeSwiper.Swiper(
              "#membershipSwiper",
              self.swiperOption
            );
          }, 10);
      }
    },
  },
};
</script>
<style scoped>
.carousel-div {
  background: url(https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/curved_bg_shape.png) no-repeat;
  background-size: cover;
  background-position: top;
  padding-top: 100px;
  padding-bottom: 40px;
  margin-top: -60px;
  min-height: 200px;
}
</style>