import Vue from 'vue';

import moment from 'moment'
import Storage from './storage'


//e.g Aug 21, 2019
const formatDates = Vue.filter('formatDates', function (value) {
    if (value) {
        return moment(String(value)).format('MMM DD, YYYY')
    }
});

//e.g 12/02/2019
const formatDate = Vue.filter("formatDate", function (value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});

//e.g 12:50
const formatTime = Vue.filter("formatTime", function (value) {
    if (value) {
        return moment(String(value)).format("hh:mm");
    }
});
const timestampFormat = Vue.filter("timestampFormat", function (value) {
    if (value && !isNaN(value.toString())) {
        let date = new Date(value * 1000);
        return moment(date).format("DD/MM/YYYY");
    }
    // else {
    //     return `[${new Date(value).toLocaleString()}]`;
    // }
    return '';
})
const timestamp = Vue.filter("timestamp", function (value) {
    if (value && !isNaN(value.toString())) {
        let date = new Date(value * 1000);
        return `[${date.toLocaleString()}]`;
    } else {
        return `[${new Date(value).toLocaleString()}]`;
    }
})
const messageTimeFormat = Vue.filter('messageTimeFormat', function (value) {
    var result = '';
    if (value) {
        var date_now = new Date();
        let date = new Date(value * 1000);
        let diff_s = Math.abs((date_now.getTime() - date.getTime()) / 1000);
        let one_day_s = 24 * 3600;
        let diff_d = Math.ceil(diff_s / one_day_s);
        let curLang = Storage.get('cur-lang')
        let Yesterday = 'Yesterday';
        let momentLocale = 'en'
        if (curLang == 'zh_CN') {
            momentLocale = 'zh-cn'
            Yesterday = '昨天';
        } else if (curLang == 'zh_HK') {
            momentLocale = 'zh-hk'
            Yesterday = '昨天';
        } else if (curLang == 'th') {
            momentLocale = 'th'
            Yesterday = 'เมื่อวานนี้';
        }
        moment.locale(momentLocale)
        let reDate = moment(date)
        if (diff_s < one_day_s) {
            result = reDate.format("hh:mm");;
        } else if (diff_s >= one_day_s && diff_d >= 1 && diff_d < 2) {
            result = Yesterday
        } else if (diff_d >= 2 && diff_d <= 6) {
            result = reDate.format('dddd')
            // result = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()]
        } else if (diff_d >= 7) {
            // if (date_now.getFullYear() == date.getFullYear()) {
            //     result = reDate.format(momentLocale == 'zh-cn' ? 'MM-DD' : 'DD-MM')
            //     // result = moment(date).format("MM/DD");;
            // } else {
            result = reDate.format(momentLocale == 'zh-cn' ? 'YYYY年MM月DD日' : 'DD/MM/YYYY')
            // result = moment(date).format("YYYY/MM/DD");
            // }
        }
    }

    return result;
})
//e.g 1 sec ago, 1 hr ago ...
const timeAgo = Vue.filter("timeAgo", function (value) {
    // console.log('timeAgo=', value)
    var result = '';
    if (value) {
        var date_now = new Date();
        let date = new Date(value * 1000);
        var date_past = date.toLocaleString();
        // var delta = Math.abs((new Date(date_past)).getTime() - date_now.getTime()) / 1000;
        var delta = Math.abs(date - date_now) / 1000;
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        var seconds = delta % 60;
        // console.log('date_past=', date_past, (new Date(date_past)).getTime(), 'delta=', delta, 'days=', days, 'hours=', hours, 'minutes=', minutes)
        if (days == 0 && hours && minutes) {
            result = `${hours}h, ${minutes}m ago`;
        }
        if (days == 0 && hours == 0 && minutes) {
            result = `${minutes} min ago`;
        }
        if (days == 0 && hours == 0 && minutes && seconds >= 1) {
            result = `${minutes} min, ${Math.round(seconds)} sec ago`;
        }
        if (days == 0 && hours == 0 && minutes == 0 && seconds >= 1) {
            result = `${Math.round(seconds)} sec ago`;
        }
        if (days == 0 && hours == 0 && minutes == 0 && (seconds >= 0 && seconds < 1)) {
            result = `just now`;
        }
        if (days > 0) {
            result = `${days} days ago`
        }
        // console.log('timeAgo=', result)
        return result;
    }
})
const timeActivity = Vue.filter("timeActivity", function (value) {
    var result = '';
    if (value) {
        var date_current = new Date();
        let date_past = new Date(value);
        //Current Date
        let date_current_month = date_current.getMonth();
        let date_current_date = date_current.getDate();
        let date_current_year = date_current.getFullYear();
        //Past Date
        let date_past_month = date_past.getMonth();
        let date_past_date = date_past.getDate();
        let date_past_year = date_past.getFullYear();

        if (date_current_year == date_past_year && date_current_month == date_past_month && date_current_date == date_past_date) {
            result = 'Today';
        }
        if (date_current_year == date_past_year && date_current_month == date_past_month && date_current_date - 1 == date_past_date) {
            result = 'Yesterday';
        }
        if (date_current_year == date_past_year && date_current_month == date_past_month && (date_current_date - date_past_date > 1 && date_current_date - date_past_date < 8)) {
            result = 'This week';
        }
        if (date_current_year == date_past_year && date_current_month == date_past_month && (date_current_date - date_past_date > 7 && date_current_date - date_past_date < 15)) {
            result = 'Last week';
        }
        if (date_current_year == date_past_year && date_current_month - 1 == date_past_month && (date_current_date - date_past_date > 14)) {
            result = 'This month';
        }
        if (date_current_year == date_past_year && date_current_month - 1 == date_past_month) {
            result = 'Last month';
        }
        if (date_current_year == date_past_year && (date_current_month > date_past_month && date_current_month - date_past_month > 1)) {
            result = 'This year';
        }
        if (date_current_year - 1 == date_past_year) {
            result = 'Last year';
        }
        if (date_current_year - date_past_year > 1 && date_current_year - date_past_year < 3) {
            result = 'Year ago';
        }

        return result;
    }
    else {
        result = "NOT YET ACTIVE"
        return result;
    }
})

const isEmpty = Vue.filter("isEmpty", function (value) {
    if (!value) {
        return '';
    } else {
        return value;
    }
})

const unknown = Vue.filter("unknown", function (value) {
    if (!value) {
        return 'Unknown';
    } else {
        return value
    }

})

const deviceFilter = Vue.filter("deviceFilter", function (device_id) {
    var deviceList = JSON.parse(localStorage.getItem('startupData')) ? JSON.parse(localStorage.getItem('startupData'))['devices'] : [];
    if (!device_id) {
        return '';
    }
    return deviceList.filter((item) => {
        return item.device_id == device_id && item.language_id == 1
    }).map((item) => {
        return item.description
    }
    ).toString();

})
const formatPrice = Vue.filter("formatPrice", function (value) {
    if (!value) {
        return value;
    }
    let parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts.length == 2) {
        return parts.join(".");
    } else {
        return `${parts[0]}.00`
    }

})

const formatFloat2 = Vue.filter("formatFloat2", function (value) {
    if (!value) {
        return value;
    }
    return parseFloat(value).toFixed(2);
})

const timeLeft = Vue.filter("timeLeft", function (leftSec) {
    if (isNaN(leftSec) && leftSec <= 0) {
        return '';
    }
    let hours = Math.floor(leftSec / 3600);
    let minutes = Math.floor((leftSec - hours * 3600) / 60);
    let sec = leftSec - hours * 3600 - minutes * 60;

    let str = `${hours >= 10 ? hours : "0" + hours}:${minutes >= 10 ? minutes : "0" + minutes
        }:${sec >= 10 ? sec : "0" + sec}`;

    return str;
})

// const htmlFormat = Vue.filter('htmlFormat', function (msg) {
//     msg = msg.replace(/\r\n/g, "<br/>").replace(/\n/g, "<br/>");

//     if (msg.indexOf('[icon_lovestruck]') > -1) {
//         msg = msg.replace('[icon_lovestruck]', `<img height="28" src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/icon_lovestruck_red.png"/>`)
//     }

//     return msg;
// })

export default {
    formatDates,
    formatDate,
    formatTime,
    timestamp,
    timeAgo,
    timeActivity,
    isEmpty,
    unknown,
    deviceFilter,
    formatPrice,
    formatFloat2,
    timeLeft,
    messageTimeFormat,
    timestampFormat,
    // htmlFormat
}