<template>
  <div class="min-h100 bg-faf">
    <pageTop ref="pageTop" :routerBack="false" @navBack="backEventHandler" classNames="page6-2"></pageTop>
    <div class="page6-2 pb-5">
      <!-- <div
        class="pt-2 rounded mt-2"
      >
            
            <div class="d-flex justify-content-between fw-700 bg-d7c text-1d p-3">
                <h5 class="fw-600">
                    {{ $t("main_filters") }}
                </h5>
                
            </div>
            
        </div> -->
      <div class="text-capitalize p-3 fw-700 bg-d7c text-1d">
        {{ $t("main_filters") }}
      </div>
      <div class=" mx-auto py-2 rounded">
        <div class="collapse show" id="baseCollapse">
          <ul class="list-group list-group-flush">
            <li class="list-group-item bg-faf text-1d border-d7c">
              <div v-if="requirement" @click="showOption('gender_id')" class="form-group mb-0">

                <div class="d-flex justify-content-between">
                  <label class="
                    fw-500
                    filter_title
                  ">{{ $t("lang_motivation") }}</label>
                  <div style="width:70px">
                    <smart-select ref="gender_id" :selected="requirement.gender_id" filed="gender_id"
                      @beforeShow="showOption('gender_id')" @updateValue="updateValue" idName="gender_id"
                      descName="description" titleKey="lang_motivation" :options="attrs.gender" :textRight="1" />
                  </div>
                </div>

              </div>
            </li>

            <li class="list-group-item bg-faf text-1d border-d7c">
              <div class="form-group mb-0" style="width:80%">
                <div class="d-flex justify-content-between">
                  <label class="
                    fw-500
                    filter_title
                  ">{{ $t("set_search_age") }}</label>
                  <div>{{ ages[0] }}-{{ ages[1] }}</div>
                </div>

                <vue-slider v-model="ages" :min="18" :max="99" :min-range="5" :interval="1" :tooltip="'none'"
                  :dotSize="24" :height="4" class="ee1-full pl-4" :enable-cross="false" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="mx-auto bg-d7c text-1d p-3">
        <!-- <div class="bg-light"> -->
        <div class="d-flex justify-content-between">
          <h5 class="fw-600">
            {{ $t("perfect_match_filter") }}
          </h5>
          <div class="text-white">
            {{ $t('Activated') }} <b class="text-white">{{ ferfectMustHave }}/{{ ferfectMaxMustHave }}</b>
          </div>
        </div>

      </div>
      <p class="fw-300 text-7e" data-id="5890" v-html="$t('maximum_match_filter_desc')"></p>
      <!-- </div> -->

      <div class="mx-auto py-2">
        <div v-if="client && client.membership_level_id < 3" class="collapse show bg-faf clearfloat" id="silverCollapse">
          <ul v-if="requirement" class="list-group list-group-flush">
            <!-- <li @click="getMember" class="list-group-item px-2">
              <div class="d-flex mb-0">
                <div
                  class="text-nowrap mr-2 filter_title text-capitalize text-secondary"
                >
                  {{ $t("verified_member_only") }}
                </div>
              </div>
              <switchc
                class="filter_must_have"
                ref="must_be_verified"
                @change="getMember"
                name="must_be_verified"
                :value="false"
                :text="switchText"
                :canClick="false"
              ></switchc>
            </li> -->
            <li @click="getMember" class="list-group-item bg-faf text-1d border-d7c">
              <div class="mb-0 filter_body" style="width:85%">
                <div class="filter_title fw-500">
                  <span class="">{{
                    $t("height_range_txt")
                  }}</span>
                  <span class="float-right text-7e">———</span>
                </div>
                <vue-slider v-model="heights" :min="120" :max="240" :min-range="10" :interval="1" :tooltip="'none'"
                  :dotSize="24" :height="4" :enable-cross="false" class="d7c-full pl-4 mt-3" />
              </div>
              <div class="filter_must_have ee1" style="top:24x;">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>

            </li>
            <li @click="getMember" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("min_edu_level_txt") }}
                </div>
                <div class="filter_body text-right">———</div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>

            </li>
            <li @click="getMember" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("min_income_txt") }}
                </div>
                <div class="filter_body text-right">———</div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>

            </li>
            <li v-if="requirement" @click="getMember" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="mr-2 filter_title fw-500">
                  {{ $t("relationship_status") }}
                </div>
                <div class="filter_body text-right">———</div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>
            </li>
            <!-- <li
              v-if="requirement"
              @click="getMember"
              class="list-group-item px-2 cursor"
            >
              <div class="d-flex mb-0">
                <div
                  class="
                    mr-2
                    filter_title
                    text-capitalize text-secondary
                  "
                >
                  {{ $t("child_status") }}
                </div>
              </div>
              

              <div class="filter_must_have gold">

                <b-form-checkbox
                  :disabled="true"
                  size="lg"
                  switch
                ></b-form-checkbox>
              </div>

              
            </li>-->

            <li v-if="requirement" @click="getMember" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("child_plans") }}
                </div>
                <div class="filter_body text-right">———</div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>
            </li>

            <li v-if="requirement" @click="getMember" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("religion_txt") }}
                </div>
                <div class="filter_body text-right">———</div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>

            </li>

            <li v-if="requirement" @click="getMember" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("web_lbl_ethnicity") }}
                </div>
                <div class="filter_body text-right">———</div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>

            </li>

            <!--  -->
            <li @click.stop="getMember" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("nationality_txt") }}
                </div>
                <div class="filter_body text-right">———</div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox :disabled="true" size="lg" switch></b-form-checkbox>
              </div>

            </li>
          </ul>
        </div>
        <div v-if="client && client.membership_level_id >= 3" class="collapse show bg-faf clearfloat" id="silverCollapse">
          <ul class="list-group list-group-flush">


            <li v-if="requirement" class="list-group-item bg-faf text-1d border-d7c">
              <div class="mb-0 filter_body" style="width:80%">
                <div class="fw-500 filter_title">
                  <span class="">{{
                    $t("height_range_txt")
                  }}</span>
                  <span class="float-right text-7e">{{ heights[0] }}-{{ heights[1] }} cm</span>
                </div>
                <!-- <div class="text-truncate filter_body">sfdsdf,sdfdsfds,ddffdfdfdfd,sdfdsfdsfds,dfdfdfd</div> -->
                <vue-slider v-model="heights" :min="120" :max="240" :interval="1" :tooltip="'none'" :dotSize="24"
                  @change="heihtChange" class="ee1-full pl-4 mt-3" :height="4" :min-range="10" :enable-cross="false" />
              </div>

              <div class="filter_must_have ee1" style="top:24px;">
                <b-form-checkbox v-model="requirement.height_importance_id" @input="changeSwitch2('height_importance_id')"
                  size="lg" switch></b-form-checkbox>
              </div>
            </li>


            <li v-if="requirement" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0" @click="showOption('min_education_level_id')">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("min_edu_level_txt") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select ref="min_education_level_id" :selected="requirement.min_education_level_id"
                    filed="min_education_level_id" @beforeShow="showOption('min_education_level_id')"
                    @updateValue="updateValue" idName="education_level_id" descName="description"
                    titleKey="min_edu_level_txt" :options="attrs.educationLevel" />
                </div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.min_education_level_importance_id"
                  @input="changeSwitch2('min_education_level_importance_id')" size="lg" switch></b-form-checkbox>
              </div>

            </li>
            <li v-if="requirement" class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0" @click="showOption('annual_income_min_id')">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("min_income_txt") }}
                </div>
                <div v-if="annualIncomes" class="filter_body text-truncate">
                  <smart-select ref="annual_income_min_id" :selected="requirement.annual_income_min_id"
                    filed="annual_income_min_id" @beforeShow="showOption('annual_income_min_id')"
                    @updateValue="updateValue" idName="annual_income_id" descName="description" titleKey="min_income_txt"
                    :options="annualIncomes" />
                </div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.annual_income_importance_id"
                  @input="changeSwitch2('annual_income_importance_id')" size="lg" switch></b-form-checkbox>
              </div>

            </li>
            <li v-if="requirement" @click="showOption('relationship_status_ids')"
              class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="mr-2 filter_title fw-500">
                  {{ $t("relationship_status") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select ref="relationship_status_ids" :selected="requirement.relationship_status_ids"
                    filed="relationship_status_ids" multiple="1" @beforeShow="showOption('relationship_status_ids')"
                    @updateValue="updateValue" idName="relationship_status_id" descName="description"
                    titleKey="relationship_status" :options="attrs.relationshipStatus" />
                </div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.relationship_status_importance_id"
                  @input="changeSwitch2('relationship_status_importance_id')" size="lg" switch></b-form-checkbox>
              </div>

            </li>
            <!-- <li
              v-if="requirement"
              @click="showOption('child_status_ids')"
              class="list-group-item cursor bg-black text-white border-ls-6"
            >
              <div class="d-flex mb-0">
                <div
                  class="
                    mr-2
                    filter_title
                    text-capitalize text-white
                  "
                >
                  {{ $t("child_status") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select
                    ref="child_status_ids"
                    :selected="requirement.child_status_ids"
                    filed="child_status_ids"
                    multiple="1"
                    @beforeShow="showOption('child_status_ids')"
                    @updateValue="updateValue"
                    idName="child_status_id"
                    descName="description"
                    titleKey="child_status"
                    :options="attrs.childStatus"
                  />
                </div>
              </div>
              <div class="filter_must_have gold">
                <b-form-checkbox
                  v-model="requirement.child_status_importance_id"
                  @input="changeSwitch2('child_status_importance_id')"
                  size="lg"
                  switch
                ></b-form-checkbox>
              </div>
              
<<<<<<< HEAD
            </li> -->

            <!-- :canClick="requirement.child_status_ids" -->
            <li v-if="requirement" @click="showOption('child_plans_ids')"
              class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("child_plans") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select ref="child_plans_ids" :selected="requirement.child_plans_ids" filed="child_plans_ids"
                    multiple="1" @beforeShow="showOption('child_plans_ids')" @updateValue="updateValue"
                    idName="child_plans_id" descName="description" titleKey="child_plans" :options="attrs.childPlans" />
                </div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.child_plans_importance_id"
                  @input="changeSwitch2('child_plans_importance_id')" size="lg" switch></b-form-checkbox>
              </div>

            </li>

            <li v-if="requirement" @click="showOption('ethnicity_ids')"
              class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("web_lbl_ethnicity") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select ref="ethnicity_ids" :selected="requirement.ethnicity_ids" filed="ethnicity_ids"
                    multiple="1" @beforeShow="showOption('ethnicity_ids')" @updateValue="updateValue"
                    idName="ethnicity_id" descName="description" titleKey="web_lbl_ethnicity"
                    :options="attrs.ethnicity" />
                </div>
              </div>
              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.ethnicity_importance_id"
                  @input="changeSwitch2('ethnicity_importance_id')" size="lg" switch></b-form-checkbox>
              </div>
            </li>
            <li v-if="requirement" @click="showOption('religion_ids')"
              class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("religion_txt") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select ref="religion_ids" :selected="requirement.religion_ids" filed="religion_ids" multiple="1"
                    @beforeShow="showOption('religion_ids')" @updateValue="updateValue" idName="religion_id"
                    descName="description" titleKey="religion_txt" :options="attrs.religion" />
                </div>
              </div>

              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.religion_importance_id"
                  @input="changeSwitch2('religion_importance_id')" size="lg" switch></b-form-checkbox>
              </div>
            </li>

            <li v-if="requirement" @click="showOption('smoking_ids')"
              class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("smoking_txt") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select ref="smoking_ids" :selected="requirement.smoking_ids" filed="smoking_ids" multiple="1"
                    @beforeShow="showOption('smoking_ids')" @updateValue="updateValue" idName="smoking_id"
                    descName="description" titleKey="smoking_txt" :options="attrs.smoking" />
                </div>
              </div>

              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.smoking_importance_id"
                  @input="changeSwitch2('smoking_importance_id')" size="lg" switch></b-form-checkbox>
              </div>
            </li>

            <li v-if="requirement" @click="showOption('drinking_ids')"
              class="list-group-item cursor bg-faf text-1d border-d7c">
              <div class="d-flex mb-0">
                <div class="
                    mr-2
                    filter_title fw-500
                  ">
                  {{ $t("drinking_txt") }}
                </div>
                <div class="filter_body text-truncate">
                  <smart-select ref="drinking_ids" :selected="requirement.drinking_ids" filed="drinking_ids" multiple="1"
                    @beforeShow="showOption('drinking_ids')" @updateValue="updateValue" idName="drinking_id"
                    descName="description" titleKey="drinking_txt" :options="attrs.drinking" />
                </div>
              </div>

              <div class="filter_must_have ee1">
                <b-form-checkbox v-model="requirement.drinking_importance_id"
                  @input="changeSwitch2('drinking_importance_id')" size="lg" switch></b-form-checkbox>
              </div>
            </li>
          </ul>
        </div>
      </div>


      <!-- <div
        class="px-0 mx-auto mt-3 pb-5 text-center"
      >
        <b-button @click="save" variant="ls-gold-g2" pill class="fw-600 px-5">
          {{ $t("save_txt") }}
        </b-button>
      </div> -->
    </div>
    <alertModal ref="alertModal" @okEvent="alertOkClick" @cancelEvent="alertCancelClick" />
    <lsIframe ref="lsIframe" :iframeUrl="memberUrl"></lsIframe>
    <scheduleConsultation ref="scheduleConsultation" />
    <diamondPopup ref="diamondPopup" @scheduleNow="scheduleNowHander" />
    <!-- <platinumPopup ref="platinumPopup" @upgradeClick="getPlatinumPayment" /> -->
  </div>
</template>
<script>
// import commonHead from "../common/head";
import SmartSelect from "../common/smartSelect";

import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";

import switchc from "../common/switchc";
import lsIframe from "../common/iframe";
import diamondPopup from "../common/diamondPopup";
// import UA from "ua-device";
import scheduleConsultation from "../common/scheduleConsultation";
// import platinumPopup from "../common/platinumPopup.vue";
import pageTop from "../common/pageTop";
import alertModal from "../common/alertModal";
export default {
  name: "requirement",
  components: {
    // commonHead,
    SmartSelect,
    pageTop,
    VueSlider,
    switchc,
    lsIframe,
    scheduleConsultation,
    diamondPopup,
    // platinumPopup,
    alertModal
  },
  data() {
    return {
      oldrequires: null,
      requirement: null,
      requirementTest: {
        client_requirement_id: 79212,
        client_id: 1084,
        gender: "f",
        gender_id: 2,
        must_be_verified: 1,
        age_range_importance_id: 2,
        age_range_min: 18,
        age_range_max: 28,
        height_importance_id: 2,
        height_min: 140,
        height_max: 170,
        relationship_status_importance_id: 1,
        relationship_status_ids: "1,2",
        child_status_importance_id: 1,
        child_status_ids: "1,2,3,4",
        child_plans_importance_id: 1,
        child_plans_ids: "1",
        ethnicity_importance_id: 1,
        ethnicity_ids: "3,2,4,5",
        nationality_importance_id: 1,
        nationality_ids:
          "75,1,76,77,2,78,79,80,3,81,82,4,5,83,84,85,6,86,87,88,7,89,90,91,92,93,94,95,96,8,97,21,98,99,9,100,101,102,103,104,10,106,105,107,11,12,13,108,109,14,15,16,17,18,19,110,111,20,112,113,114,115,116,117,22,118,119,120,121,122,23,24,123,124,125,25,126,127,26,128,129,130,132,131,133,134,135,136,27,138,137,28,29,30,31,32,33,34,139,35,36,140,141,37,38,142,143,144,145,39,40,146,147,148,41,149,150,151,152,153,42,154,155,156,157,158,159,160,43,161,44,162,163,45,164,165,166,167,46,168,169,47,48,170,213,49,171,172,173,174,175,50,51,52,53,176,54,55,177,178,179,180,181,182,56,57,183,58,184,185,59,60,186,187,188,189,190,61,62,63,64,191,192,193,65,66,194,67,68,195,196,69,197,198,199,200,201,202,70,203,71,204,72,205,206,207,208,73,209,74,210,211,212",
        annual_income_importance_id: 1,
        relationship_type_ids: null,
        relationship_type_importance_id: 1,
        annual_income_min_id: 3,
        annual_income_max_id: 8,
        min_education_level_importance_id: 1,
        min_education_level_id: -1,
        spoken_language_importance_id: 1,
        spoken_language_ids: "33",
        job_industry_importance_id: 1,
        job_industry_ids: null,
        job_title_importance_id: 1,
        job_title: null,
        religion_importance_id: 1,
        religion_ids: "1,2,3,4,5,14,6,7,8,9,10,11,13",
        smoking_importance_id: 1,
        smoking_ids: "1,2",
        drinking_importance_id: 1,
        drinking_ids: "1,2,3",
        food_habit_importance_id: 1,
        food_habit_ids: "1,2,3",
        trait_ids: null,
        hobbies_importance_id: 1,
        hobbies: null,
        other_requirements: null,
      },
      // switchText: "on|off",
      switchText: "",
      ages: [18, 33],
      heights: [140, 200],
      attrs: {},
      client: {},
      showDiamond: false,
      mustHaveMaps: {
        height_importance_id: ["height_min", "height_max"],
        ethnicity_importance_id: ["ethnicity_ids"],
        relationship_status_importance_id: ["relationship_status_ids"],
        child_status_importance_id: ["child_status_ids"],
        child_plans_importance_id: ["child_plans_ids"],
        religion_importance_id: ["religion_ids"],
        smoking_importance_id: ["smoking_ids"],
        drinking_importance_id: ["drinking_ids"],
        food_habit_importance_id: ["food_habit_ids"],
        min_education_level_importance_id: ["min_education_level_id"],
        nationality_importance_id: ["nationality_ids"],
        annual_income_importance_id: ["annual_income_min_id"],
      },
      basicMustHaveFields: [
        "height_importance_id",
        "ethnicity_importance_id",
        "relationship_status_importance_id",
        "child_status_importance_id",
        "child_plans_importance_id",
        "religion_importance_id",
        // "smoking_importance_id",
        // "drinking_importance_id",
        "food_habit_importance_id",
      ],
      basicMaxMustHave: 3,
      basicMustHave: 0,
      basicMustHaveChecked: [],

      advancedMustHaveFields: [
        "min_education_level_importance_id",
        "nationality_importance_id",
        "annual_income_importance_id",
      ],
      advancedMaxMustHave: 3,
      advancedMustHave: 0,
      advancedMustHaveChecked: [],

      ferfectMaxMustHave: 3,
      ferfectMustHave: 0,
      ferfectMustHaveChecked: [],
      ferfectMustHaveFields: [
        "height_importance_id",
        "ethnicity_importance_id",
        "relationship_status_importance_id",
        // "child_status_importance_id",
        "child_plans_importance_id",
        "religion_importance_id",
        "min_education_level_importance_id",
        // "nationality_importance_id",
        "annual_income_importance_id",
        "smoking_importance_id",
        "drinking_importance_id",
      ],
      //userInfo: this.$storage.get('userInfo')
      annualIncomes: [],
      advancedFiltersDesc: "",
      basicFiltersDesc: "",
      ferfectFiltersDesc: this.$t("select_up_perfect_match"),
      ferfectFiltersDesc2: this.$t("select_up_perfect_match2"),
      memberUrl: "",
      loadeddata: false,
      curCity: this.$store.getters.curCity,
    };
  },
  // computed: {
  //     requirement(){
  //         return this.requires;
  //     }
  // },
  methods: {
    backEventHandler() {
      this.save()
    },
    // heightChange(number, index) {
    //   console.log("heightChange", number, index);
    // },
    // heightDragEnd(number, index) {
    //   console.log("heightDragEnd", number, index);
    // },
    //   checkSelectedNum(field){
    //       this.showOption(fiel)
    //   },
    alertOkClick() {
      this.$refs.alertModal.hideModal();
    },
    alertCancelClick() {
      this.$refs.alertModal.hideModal();
    },
    alertLimit() {
      let self = this

      let htmlText = `<p class="mb-3">${self.$t('limit_reached_desc1')}</p>
        <p>${self.$t('limit_reached_desc2')}</p>`

      self.$refs.alertModal.showModal({
        title: self.$t("limit_reached"),
        message: htmlText,
        okText: self.$t("Ok").toUpperCase(),
        showCancel: false,
        metaData: {
          type: "limit-reached",
        },
      });
    },
    changeSwitch2(field) {
      let self = this;

      //   console.log(
      //     "changeSwitch2",
      //     field,
      //     self.requirement[field],
      //     _.indexOf(self.ferfectMustHaveChecked, field),
      //     self.ferfectMustHave
      //   );
      if (self.requirement[field]) {
        if (self.ferfectMustHave >= 3) {
          //   console.log(`set ${field}= false`);
          setTimeout(function () {
            self.requirement[field] = false;
            //TODO delete all values
            self.deleteIdsValues(field)
          }, 500);
          self.alertLimit()
          //   self.$layer.msg(self.ferfectFiltersDesc2);
          return false;
        } else {
          if (_.indexOf(self.ferfectMustHaveChecked, field) == -1) {
            self.ferfectMustHaveChecked.push(field);
          }
          if (field != "height_importance_id") {
            // console.log("checkIdsValues..");
            self.checkIdsValues(field);
          }
        }
      } else {
        // console.log(`pull ${field}`);
        _.pull(self.ferfectMustHaveChecked, field);
        //TODO delete all values
        self.deleteIdsValues(field)
      }

      self.ferfectMustHave = self.ferfectMustHaveChecked.length;

      //   console.log(self.ferfectMustHaveChecked);
    },
    getPlatinumPayment() {
      this.$router.push({
        name: "membership-choose",
        // name: "payment-plan",
        // params: { planName: "platinum" },
        // query: { top_nav: 0, page: 1 },
      });
    },
    getGoldPayment(pageInex) {
      this.$router.push({
        name: "membership-upgrade",
        query: { type: "gold", page: pageInex },
        // name: "payment-plan",
        // params: { planName: "gold" },
        // query: { top_nav: 0, page: pageInex },
      });
    },
    getVerifiedMember() {
      this.$refs.diamondPopup.showModal(5);
    },
    getVerifiedPopup() {
      this.$refs.scheduleConsultation.showVerifyAccountModal(1);
    },
    navBackHander() {
      this.save();
    },
    scheduleNowHander() {
      this.$refs.diamondPopup.closeModel();
      this.$refs.scheduleConsultation.showModal(2);
    },
    getMember() {
      let self = this;
      //   self.$refs.diamondPopup.showModal(1);

      self.$router.push({
        // name: "membership-upgrade",
        name: "contextual-upgrades",
        // query: { type: "premium", page: 1 },
      });

      //   let membership_level_id = _.get(self.client, "membership_level_id", 0);
      //   if (membership_level_id <= 2) {
      //     self.$refs.platinumPopup.showModal(1);
      //     return false;
      //   }

      //   self.$refs.scheduleConsultation.showModal(2);


      // }
    },
    openUpgradePop(optionName) {
      //
      let self = this;

      self.$refs.scheduleConsultation.showModal(2);

      // let userAgent = new UA(navigator.userAgent);

      // if (userAgent.device.type == "mobile") {
      //   self.$router.push({
      //     name: "payment-upgrade-pop",
      //     query: { option: optionName },
      //   });
      // } else {
      //   self.memberUrl =
      //     process.env.BASE_URL +
      //     self.curCity +
      //     "/payment/upgradePopup" +
      //     "?option=" +
      //     optionName +
      //     "&t=";
      //   new Date().getTime();

      //   //console.log(self.memberUrl);

      //   self.$refs.lsIframe.show();
      // }
    },
    getIdsField(importance_name) {
      let ids_field = importance_name.replace("importance_", "");
      //   if(ids_field == 'min_education_level_ids' || ids_field == 'min_education_level_ids')
      if (
        _.indexOf(["min_education_level_id", "annual_income_id"], ids_field) ==
        -1
      ) {
        ids_field += "s";
      }
      if (ids_field == "annual_income_id") {
        ids_field = "annual_income_min_id";
      }

      return ids_field;
    },
    deleteIdsValues(importance_name) {
      let self = this;
      let ids_field = self.getIdsField(importance_name)
      console.log('deleteIdsValues', importance_name, ids_field, self.requirement[ids_field])
      setTimeout(function () {
        self.requirement[ids_field] = ''
      }, 400)
      if (importance_name == 'height_importance_id') {

      } else {
        self.$refs[ids_field].clearSelected()
      }

      console.log('after clear', self.requirement[ids_field])
    },
    checkIdsValues(importance_name) {
      let self = this;

      if (self.ferfectMustHave >= 3) {
        self.alertLimit()
        // self.$layer.msg(self.advancedFiltersDesc);
        return false;
      }
      let ids_field = self.getIdsField(importance_name)

      //   console.log("checkIdsValues=", ids_field, self.requirement[ids_field]);
      //   if (!self.requirement[ids_field]) {
      //     console.log("show option");
      self.showOption(ids_field);
      //   }
    },
    changeSwitch(val, name, ref) {
      return false;//no use
      let self = this;
      console.log("changeSwitch", val, name, ref);
      //true must_be_verified toggle
      //console.log(val, name, self.basicMustHaveFields);
      // console.log(_.indexOf(self.basicMustHaveFields, name), name)
      if (_.indexOf(self.ferfectMustHaveFields, name) > -1) {
        // console.log('basic', name)
        let checkCount = self.ferfectMustHaveChecked.length;
        // console.log("ferfectMustHaveChecked", self.ferfectMustHaveChecked);
        if (checkCount >= self.ferfectMaxMustHave) {
          if (_.indexOf(self.ferfectMustHaveChecked, name) > -1) {
            if (!val) {
              _.pull(self.ferfectMustHaveChecked, name);
              self.requirement[name] = false;
              self.ferfectMustHave = self.ferfectMustHaveChecked.length;
              //clear ids
              self.clearIds(name);
              return false;
            }
          }
          self.requirement[name] = false;
          self.$layer.msg(self.ferfectFiltersDesc);
          // self.basicMustHave = self.basicMustHaveChecked.length;
          return false;
        } else {
          if (_.indexOf(self.ferfectMustHaveChecked, name) > -1) {
            if (!val) {
              _.pull(self.ferfectMustHaveChecked, name);
              self.requirement[name] = false;
              //   console.log("self.requirement[name]", self.requirement[name]);
              //clear ids
              self.clearIds(name);
              if (ref == "option" && _.get(self.$refs, name)) {
                self.$refs[name].turnOff();
              }
            }
          } else {
            if (val) {
              self.ferfectMustHaveChecked.push(name);
              self.requirement[name] = true;
              if (ref == "option" && _.get(self.$refs, name)) {
                self.$refs[name].turnOn();
              }
              //   console.log("add", self.basicMustHaveChecked, name, true);
              self.checkIdsValues(name);
            } else {
              self.requirement[name] = false;
              self.clearIds(name);
              if (ref == "option" && _.get(self.$refs, name)) {
                self.$refs[name].turnOff();
              }
            }
          }
        }
        self.ferfectMustHave = self.ferfectMustHaveChecked.length;
      } else if (_.indexOf(self.basicMustHaveFields, name) > -1) {
        // console.log('basic', name)
        let checkCount = self.basicMustHaveChecked.length;

        if (checkCount >= self.basicMaxMustHave) {
          if (_.indexOf(self.basicMustHaveChecked, name) > -1) {
            if (!val) {
              _.pull(self.basicMustHaveChecked, name);
              self.requirement[name] = false;
              self.basicMustHave = self.basicMustHaveChecked.length;
              //clear ids
              self.clearIds(name);
              return false;
            }
          }
          self.requirement[name] = false;
          self.$layer.msg(self.basicFiltersDesc);
          // self.basicMustHave = self.basicMustHaveChecked.length;
          return false;
        } else {
          if (_.indexOf(self.basicMustHaveChecked, name) > -1) {
            if (!val) {
              _.pull(self.basicMustHaveChecked, name);
              self.requirement[name] = false;
              //clear ids
              self.clearIds(name);
              if (ref == "option" && _.get(self.$refs, name)) {
                self.$refs[name].turnOff();
              }
            }
          } else {
            if (val) {
              self.basicMustHaveChecked.push(name);
              self.requirement[name] = true;
              if (ref == "option" && _.get(self.$refs, name)) {
                self.$refs[name].turnOn();
              }
              // console.log('add', self.basicMustHaveChecked, name, true)
              self.checkIdsValues(name);
            }
          }
        }
        self.basicMustHave = self.basicMustHaveChecked.length;
      } else if (_.indexOf(self.advancedMustHaveFields, name) > -1) {
        let checkCount = self.advancedMustHaveChecked.length;

        if (checkCount >= self.advancedMaxMustHave) {
          if (_.indexOf(self.advancedMustHaveChecked, name) > -1) {
            if (!val) {
              _.pull(self.advancedMustHaveChecked, name);
              self.requirement[name] = false;
              self.advancedMustHave = self.advancedMustHaveChecked.length;
              //clear ids
              self.clearIds(name);
              return false;
            }
          }
          self.requirement[name] = false;
          self.$layer.msg(self.advancedFiltersDesc);
          // self.advancedMustHave = self.advancedMustHaveChecked.length;
          return false;
        } else {
          if (_.indexOf(self.advancedMustHaveChecked, name) > -1) {
            if (!val) {
              _.pull(self.advancedMustHaveChecked, name);
              self.requirement[name] = false;
              //clear ids
              self.clearIds(name);
            }
          } else {
            if (val) {
              self.advancedMustHaveChecked.push(name);
              self.requirement[name] = true;
            }
          }
        }
        self.advancedMustHave = self.advancedMustHaveChecked.length;
      } else {
        self.requirement[name] = val;
        // if (val) {
        //   self.$refs[name].turnOn();
        // } else {
        //   self.$refs[name].turnOff();
        // }
        console.log("name", name, val, self.requirement);
      }
      //   self.requirement[name] = val ;//? 1 : 0;
      //   console.log('done..')
    },
    heihtChange(value) {
      let self = this;
      if (self.ferfectMustHave >= 3) {
        return false;
      } else {
        //console.log('height', value, self.requirement.height_importance_id)
        if (value[1] > 120 && !self.requirement.height_importance_id) {
          self.requirement.height_importance_id = true;
          // self.changeSwitch(true, "height_importance_id", "option");
          // self.changeSwitch2("height_importance_id");
        }
      }
    },
    clearIds(name) {
      let self = this;
      let field = _.get(self.mustHaveMaps, name);
      console.log(field, "clear");
      for (let k of field) {
        self.requirement[k] = "";

        if (k == "height_min") {
          self.heights = [140, 200];
        } else if (_.get(self.$refs, k)) {
          self.$refs[k].clearSelected();
        }
      }
    },
    save() {
      let self = this;
      self.$layer.loading();
      self.requirement.age_range_min = self.ages[0];
      self.requirement.age_range_max = self.ages[1];

      self.requirement.height_min = self.heights[0];
      self.requirement.height_max = self.heights[1];
      //copy obj
      let requirement = JSON.parse(JSON.stringify(self.requirement));

      for (let v in requirement) {
        if (v == "height_importance_id") {
          if (
            requirement[v] &&
            self.requirement.height_min > 0 &&
            self.requirement.height_max > 0
          ) {
            requirement[v] = 1;
          } else {
            requirement[v] = 0;
          }
        } else if (v == "min_education_level_importance_id") {
          if (requirement[v] && self.requirement.min_education_level_id > -1) {
            requirement[v] = 1;
          } else {
            requirement[v] = 0;
          }
        } else if (v == "annual_income_importance_id") {
          if (requirement[v] && self.requirement.annual_income_min_id > 0) {
            requirement[v] = 1;
          } else {
            requirement[v] = 0;
          }
        } else if (v.indexOf("_importance_id") > -1) {
          //   if(requirement[v])
          //       //child_status_importance_id: 1,
          //       //child_status_ids: "1,2,3,4",
          let key = v.replace("_importance_id", "_ids");

          //     //   console.log(
          //     //     "key=",
          //     //     v,
          //     //     key,
          //     //     _.has(requirement, key),
          //     //     _.get(requirement, key),
          //     //     _.size(_.get(requirement, key))
          //     //   );

          if (
            requirement[v] &&
            _.has(requirement, key) &&
            _.size(_.get(requirement, key)) > 0
          ) {
            requirement[v] = 1;
            self.requirement[v] = true;
          } else {
            requirement[v] = 0;
            self.requirement[v] = false;
          }
          //       //   requirement[v] = requirement[v] ? 1 : 0;
        }
      }
      //   return;
      if (requirement.must_be_verified) {
        requirement.must_be_verified = 1;
      } else {
        requirement.must_be_verified = 0;
      }

      let fdata = new FormData();
      let test = {};
      let upadted = false;
      for (let vv in self.oldrequires) {
        if (requirement[vv] != self.oldrequires[vv]) {
          fdata.append(vv, requirement[vv]);
          test[vv] = requirement[vv];
          upadted = true;
        }
      }
      if (upadted) {
        self.$storage.set("match_filter_updated", 1, 3600);
      }

      //   console.log("save requirement", test);
      //     return;
      self.$api
        .postClientRequirement(fdata, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          if (_.get(rs, "success")) {
            // self.$layer.msg("Success!");
            //go back
            // self.$router.go(-1);
            self.$router.push({
              name: "matchmaking-new-matches",
              // query: { lang: self.langCode, top_nav: 0 },
            });
          }
        })
        .catch((err) => {
          console.log(["error", err]);
          self.$layer.closeAll("loading");
          let message = _.get(err, ["response", "data", "error", "body"], "");
          if (message) {
            self.$layer.msg(message);
          }
        });
    },
    langChanged(lang) {
      console.log("......langChanged......", lang);
      let self = this;
      self.fetchAttrs();
    },
    updateValue(filed, value, act) {
      //   console.log("updateValue", filed, value, act);
      let self = this;
      if (filed == 'gender_id') {
        self.requirement.gender_id = value;
        self.requirement.gender = value == 1 ? 'm' : 'f';
        return false;
      } else {
        if (self.ferfectMustHave >= 3) {
          self.alertLimit()
          // self.$layer.msg(self.advancedFiltersDesc);
          return false;
        }
        if (_.has(self.requirement, filed)) {
          self.requirement[filed] = value;
        }
        // console.log(self.requirement);
        if (act == "init") {
          return false;
        }

        // if(!self.loadeddata){
        //     return false;
        // }

        let importanceId = self.getImportanceId(filed);

        //   if (filed.indexOf("_ids") > -1) {
        //     importanceId = filed.replace("_ids", "_importance_id");
        //   } else if (filed.indexOf("_id") > -1) {
        //     importanceId = filed.replace("_id", "_importance_id");
        //   }

        //   if (filed == "annual_income_min_id") {
        //     importanceId = "annual_income_importance_id";
        //   }
        self.requirement[importanceId] = value ? true : false;
      }

      //   self.changeSwitch(value ? true : false, importanceId, "option");
    },
    changeGender(gender) {
      // this.requirement.gender_id = gender_id;
      this.requirement.gender = gender;
    },
    getImportanceId(filed) {
      // let self = this;
      let importanceId = "";

      if (filed.indexOf("_ids") > -1) {
        importanceId = filed.replace("_ids", "_importance_id");
      } else if (filed.indexOf("_id") > -1) {
        importanceId = filed.replace("_id", "_importance_id");
      }

      if (filed == "annual_income_min_id") {
        importanceId = "annual_income_importance_id";
      }
      return importanceId
    },
    showOption(field) {
      let self = this;
      if (field == 'gender_id') {
        self.$refs[field].showBox();
      } else {
        let importanceId = self.getImportanceId(field);
        //   return false;
        //   console.log(field, importanceId, self.requirement[importanceId], self.requirement[field])
        if (self.requirement[importanceId] && self.requirement[field]) {
          return false;
        }
        console.log(
          "showOption",
          field,
          self.ferfectMustHave,
          self.ferfectMustHave >= 3
        );
        if (self.ferfectMustHave >= 3) {
          self.alertLimit()
          // self.$layer.msg(self.advancedFiltersDesc);
          return false;
        } else if (_.get(self.$refs, field)) {
          self.$refs[field].showBox();
        }
      }


      return false;
    },
    getAnnualIncomeByCurrencyId(currency_id) {
      let self = this;
      for (let incomek in self.attrs.annualIncome) {
        if (
          self.attrs.annualIncome[incomek]["currency_id"] ==
          self.client.default_currency_id
        ) {
          self.annualIncomes.push(self.attrs.annualIncome[incomek]);
        }
      }
    },
    fetchAttrs() {
      let self = this;
      self.$layer.loading();

      console.log("fetchAttrs  begin....");
      Promise.all([
        self.$api.getClientMe(self.$store.state),
        self.$api.getAttributes(self.$store.state),
        self.$api.getClientRequirement(self.$store.state),
      ])
        .then((rs) => {
          self.$layer.closeAll("loading");

          self.client = rs[0]["client"];
          //test
          // self.client.cur_membership_level_active  = 1;
          // self.client.membership_level_matchmaking_id = 4;
          // self.client.membership_level_id = 2;
          //   self.client.is_verified = 0;

          self.attrs = rs[1];
          //   console.log(self.client.default_currency_id, self.attrs)

          self.getAnnualIncomeByCurrencyId(self.client.default_currency_id)
          if (!self.annualIncomes || self.annualIncomes.length == 0) {
            console.log('not foun')
            self.getAnnualIncomeByCurrencyId(1)
          }


          self.requirement = rs[2]["requirement"];
          self.requirement.gender_id = self.requirement.gender == 'f' ? 2 : 1;

          self.requirement.must_be_verified =
            self.requirement.must_be_verified == 1 ? true : false;

          self.oldrequires = JSON.parse(JSON.stringify(rs[2]["requirement"]));
          //   self.requirement.height_importance_id = 2;
          for (let v in self.requirement) {
            if (v.indexOf("_importance_id") > -1) {
              self.requirement[v] = self.requirement[v] >= 1 ? true : false;

              if (
                _.indexOf(self.ferfectMustHaveFields, v) > -1 &&
                self.ferfectMustHaveChecked.length <= self.ferfectMustHave &&
                self.requirement[v]
              ) {
                // console.log(
                //   v,
                //   _.indexOf(self.ferfectMustHaveFields, v),
                //   self.ferfectMustHaveChecked.length,
                //   self.ferfectMustHave,
                //   self.requirement[v]
                // );
                self.ferfectMustHave += 1;
                self.ferfectMustHaveChecked.push(v);
              }
            }

            // if (
            //   _.indexOf(self.basicMustHaveFields, v) > -1 &&
            //   self.basicMustHaveChecked.length < self.basicMaxMustHave &&
            //   self.requirement[v]
            // ) {
            //   self.basicMustHave += 1;
            //   self.basicMustHaveChecked.push(v);
            // }

            // if (
            //   _.indexOf(self.advancedMustHaveFields, v) > -1 &&
            //   self.advancedMustHaveChecked.length < self.advancedMaxMustHave &&
            //   self.requirement[v]
            // ) {
            //   self.advancedMustHave += 1;
            //   self.advancedMustHaveChecked.push(v);
            // }
          }
          //   console.log(
          //     "fetch all",
          //     self.ferfectMustHave,
          //     self.ferfectMustHaveChecked
          //   );
          // self.requirement['height_importance_id'] = true;
          if (self.requirement.age_range_min > 0 && self.requirement.age_range_max > 0) {
            self.ages = [
              self.requirement.age_range_min,
              self.requirement.age_range_max,
            ];
          }

          if (self.requirement.height_min > 0 && self.requirement.height_max > 0) {
            self.heights = [
              self.requirement.height_min,
              self.requirement.height_max,
            ];
          }

          //console.log('fetchAttrs  end....')
          self.loadeddata = true;
        })
        .catch((err) => {
          console.log(["error", err]);
          //   self.$parent.closeLoading();
          self.$layer.closeAll("loading");
        });
    },
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();
    $("body").addClass("bg-light");
    // self.alertLimit()
    //     console.log(self.$router, self.$route)
    self.fetchAttrs();
    setTimeout(function () {
      $("body").removeClass("noy-scroll");
    }, 500);
    self.$refs.pageTop.setPageTitle(self.$t('match_filter'));
    self.advancedFiltersDesc = self
      .$t("advanced_filter_desn")
      .replace("[limit_number]", self.basicMaxMustHave);
    self.basicFiltersDesc = self
      .$t("basic_filter_desn")
      .replace("[limit_number]", self.advancedMaxMustHave);
  },
};
</script>
<style scoped></style>