export default {
    getUsedTrans(trans, state) {
        let usedTrans = {}
        // let langs = _.get(state.curSite, 'langs', ['en_US'])
        let langs = ['en_us', 'zh_hk', 'zh_cn', 'th'];
        // console.log('langs=', langs)
        for (let k of this.used_keys) {
            // console.log('used key=', k)

            for (let lang of langs) {
                let lang_code = lang//.lang_code.toLowerCase()
                // console.log(lang_code, _.get(trans, `${lang_code}.${k}`, _.get(trans, `en_US.${k}`, k)))
                if (!_.has(usedTrans, lang_code)) {
                    usedTrans[lang_code] = {}
                }

                usedTrans[lang_code][k] = _.get(trans, `${lang_code}.${k}`, _.get(trans, `en_US.${k}`, k))
            }

        }
        usedTrans['is_filtered2'] = 1;
        return usedTrans
    },
    used_keys: [
        'Dates', 'error_title', 'no_date_found', 'search_txt', 'Photo', 'm_report_member', 'block_user', 'Loading', 'no_more_found',
        'receive_better_matches', 'get_personal_matchmaker', 'button_sendfriend_send', 'button_membership_upgrade',
        'Ok', 'block_user', 'm_report_member', 'report_member_desc', 'send_date_request', 'cancel_txt', 'Send', 'send_date_request_desc',
        'send_greeting', 'api_error_type', 'api_photo_large', 'update_photo_txt', 'canot_del_main_photo', 'upload_u_photo',
        'select_one', 'select_one_more', 'start_date_with', 'page_title', 'how_works', 'meet_our_makers',
        'frontpage_section6_header2', 'Payment method', 'credit_card', 'Bank Transfer', 'original_price', 'payment_3col_save',
        'total_txt', 'lang_price', 'number_txt', 'secure_checkout', 'transfer_step_1', 'Bank Name', 'Account Name',
        'Account number', 'amount_txt', 'transfer_step_2', 'upload_receipt', 'paypal_checkout', 'payment_3col_card_txt',//, 'm_buy_now'
        'payment_3col_expiry_txt', 'payment_3col_security_txt', 'val_email_invalid', 'payment_3col_month', 'n_month',
        'payment_desc', '1_month', 'membership_for_month', 'renew_same_price', 'month_short', 'm_continue_txt', 'payment_3col_card_num',
        'cvv_txt', 'web_header_email', 'mm_yy', 'payment_declined', 'payment_declined_desc', 'try_another_card', 'try_bank_transfer',
        'upload_payment_receipt', 'payment_pending_review', 'payment_pending_review_desc1', 'payment_pending_review_desc2', 'payment_pending_review_desc3',
        'close_txt', 'payment_confirmed', 'congratulations_txt', 'thx_purchase_desc', 'm_complete_my_profile', 'go_my_account',
        'account_upgraded_to', 'progress_title', 'member_id', 'settings_txt', 'Upgrade', 'about_me_txt', 'basic_info_txt',
        'first_name', 'lang_gender', 'Male', 'Female', 'web_lbl_account_dob', 'height_txt', 'ethnicity_txt',
        'add_txt', 'web_lbl_ethnicity', 'relationship_children', 'relationship_status', 'add_txt', 'child_status', 'work_education',
        'lang_jobtitle', 'annual_salary_txt', 'highest_education_txt', 'college_university_txt', 'others_txt', 'religion_txt',
        'smoking_txt', 'habits_text', 'drinking_txt', 'edit_photos', 'more_photo_more_dates', 'team_choose_primary_photo', 'done_txt',
        'add_some_photo', 'main_filters', 'lang_motivation', 'set_search_age', 'perfect_match_filter', 'Activated', 'maximum_match_filter_desc',
        'height_range_txt', 'min_edu_level_txt', 'min_income_txt',
        'child_plans', 'religion_txt', 'nationality_txt', 'diamond_preference', 'upgrade_for_access', 'save_txt', 'select_up_perfect_match',
        'select_up_perfect_match2', 'advanced_filter_desn', 'basic_filter_desn', 'account_settings', 'Language',
        'mobile_number', 'login_email_address', 'account_status', 'unpause_account', 'pause_account', 'membership_text',
        'membership_level_text', 'full_name', 'paid_amount', 'ls_gold_title5', 'ls_gold2_desc3', 'dia_privacy_mode', 'dia_privacy_mode_desc', 'notify_txt',
        'push_notify', 'email_notify', 'legal_text', 'privacy_policy_text', 'terms_service_text', 'web_drop_logout', 'delete_account',
        'pause_account_title', 'pause_account_desc', 'pause_my_account', 'pause_u_account', 'active_u_account', 'active_u_account_desc', 'unpause_my_account',
        'confirm_del_account_title', 'confirm_del_account_desc', 'del_my_account', 'del_account_desc', 'email_notify_desn',
        'permission_limit_view', 'not_now', 'require_photo_desc', 'get_plus', 'id_photo_desc2', 'id_photo_desc',

        'upload_a_photo', 'my_number_is', 'sms_send_desc', 'web_lbl_nextsteps', 'phone_number',
        'val_mobile_invaild', 'my_code_is', 'link_change', 'sms_arrive_within60', 'try_again', 'send_new_code', 'error_sms_code',
        'web_home_get_verify', 'web_home_get_verify_again', 'match_filter', 'lang_page_next',
        'match_filters_updated', 'match_filters_updated_desc1', 'match_filters_updated_desc2', 'match_with_elites', 'match_with_elites_desc',
        'original_price', 'renew_same_price', 'membership_for_month', 'offer_end_in', 'dia_perfect_match', 'dia_perfect_match_desc',
        'dia_personal_matchmaker', 'dia_personal_matchmaker_desc', 'rematch_txt', 'rematch_desc2', 'date_concierge', 'date_concierge_desc',
        'dia_profile_view', 'dia_profile_view_desc', 'genuine_matches', 'genuine_matches_desc', 'match_and_chat', 'match_and_chat_desc',
        'match_boost', 'match_boost_desc', 'profile_verify', 'profile_verify_desc', 'get_starter', 'get_gold', 'get_diamond_txt',
        'profile_review_setup', 'profile_review_setup_desc', 'match_verified_user', 'match_verified_user_desc',
        'id_photo_rejected', 'take_id_photo_again', 'take_id_photo_desc',
        'phone_consultation', 'diamond_phone_call', 'use_phone_sign',
        'consultation_confirmed', 'booked_consultation_for', 'team_contact_phone', 'appointment_success_msg',

        'what_u_name', 'what_u_gender', 'w_you_dob', 'm_what_job_title', 'what_highest_edu', 'w_school_name', 'what_income_level',
        'w_your_status', 'w_child_plan', 'w_you_height', 'what_u_ethnicity', 'what_u_religious', 'do_you_smoke', 'do_you_drink',
        'about_yourself', 'add_u_photos', 'add_photo_desc', 'verify_u_identification', 'take_id_photo', 'my_basics',
        'check_later_time', 'we_finding_matches',
        'lsm_page_header', 'will_call_you', 'will_call_you_desc', 'our_team_call_you', 'usually_48_hours', 'any_q_contact', 'will_call_you_on',
        'team_excit_meet_you', 'what_client_say', 'account_is_paused', 'unpause_for_activate_matches', 'success_story',
        'what_client_say', 'frontpage_section6_header2', 'team_excit_meet_you', 'verify_pending_title', 'verify_pending_desc',
        'verifying_your_ID', 'verifying_your_ID_desc', 'recurring_billing', 'discount_txt', 'normal_price', 'previously_price',
        'renew_with_discount', 'today_only', 'renew_with_months', 'n_month_price', 'w_your_child_status',
        'verified_matches', 'verified_matches_desc', 'start_shats', 'start_shats_desc', 'priority_matching',
        'priority_matching_desc', 'all_starter_features', 'all_starter_features_desc', 'all_gold_features', 'all_gold_features_desc',
        'call_back_title', 'call_back_desc', 'ok_call_me', 'you_privacy_mode', 'start_membership', 'here_match_for_you',
        'match_sent_msg', 'view_message', 'upgrade_chat_match', 'start_chat', 'back_to_matches', 'introduce_yourself', 'sign_in',
        'sign_term_privacy_cookie', 'cookie_privacy_text', 'type_message', 'what_phone_number', 'send_code_desc',
        'enter_code', 'enter_the_code', 'take_a_photo', 'y_member_expired', 'what_u_email', 'enter_u_mobile',
        'del_account_warning', 'what_relationship_type', 'is_important_look', 'not_important', 'very_important', 'is_important_income',
        'where_spend_time', 'how_often_exercise', 'are_moring_person', 'preparing_matches', 'collecting_match',
        'creating_account', 'recover_account_email', 'nextstep_complete', 'nextstep_complete_desc', 'nextstep_complete_desc2',
        'enter_university_name', 'off_privacy_mode', 'off_privacy_mode_desc1', 'off_privacy_mode_desc2',
        'search_matches', 'renew_chat_with', 'match_ended_service', 'renew_now', 'Horoscope', 'limit_reached',
        'limit_reached_desc1', 'limit_reached_desc2', 'on_privacy_mode', 'sms_code_invalid', 'upload_photo_id',
        'get_matchmaking', 'match_age_range', 'renew_with_month', 'pls_try_again', 'match_no_available', 'what_u_age',
        'got_it', 'scammers_fake_profiles', 'about_us', 'meet_team_desc', 'welcome_ls_text', 'hi_someone',
        'we_appreciate_desc', 'ready_date_journey', 'call_to_start_membership', 'dont_worry_for_time', 'thx_choose_ls',
        'we_forward_speak', 'call_for_plus_package', 'profile_perfecter', 'profile_perfecter_desc', 'dating_passport',
        'dating_passport_desc', 'book_a_call', 'book_a_call_desc', 'membership_ends_in_days', 'renew_risk_more_later',
        'schedule_phone_consultation', 'invalid_date',
        '1m_free_trial', 'get_1stm_free', 'then_txt', 'get_50_off', 'first_month', 'event_normally', 'free_consultation', 'pre_month',
        'payment_desc_promo', 'discount_50','subscribe_now'
        //'add_profile_photo', 'add_profile_photo_desc1', 'add_profile_photo_desc2','add_great_photo', 
        //'web_lbl_managephotos', 'photo_h1', 
        //'', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',

    ],



}