<template>
  <div>
    <div
      v-if="showCallbackPopup"
      style="
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 12;
        background: rgba(0, 0, 0, 0.6);
      "
    >
      <div
        class="page6-2 center_center text-1d"
        style="width: 90%"
      >
        <div class="px-4 pt-4 bg-white rounded-10 text-center">
          <h3 class="mb-3 fw-600 fs-18">{{ title }}</h3>

          <p class="text-center mb-5 text-7e">{{ callBackDesc }}</p>

          <div class="mb-3">
            <b-button
              @click="scheduleCallback"
              variant="ee1"
              pill
              class="l-space2 text-uppercase w-75"
              style="height: 53px"
              >{{ $t("ok_call_me") }}</b-button
            >
          </div>
          <div class="mb-3">
            <p @click="hideModal" class="cursor text-7e py-2 fw-500 mb-0">
              {{ $t("cancel_txt") }}
            </p>
          </div>

          <div class="">
            <b-img
              center
              width="170"
              src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/matchmaker-call@3x.png"
            ></b-img>
          </div>
        </div>

        <!-- <div
          v-if="type == 'matchmaking'"
          class="d-flex justify-content-between"
        >
          <div>
            <b-button
              @click="scheduleCallback"
              variant="ls-gold-g2"
              class="fw-600 px-4"
              >{{ $t("ok_call_me") }}</b-button
            >
          </div>
          <div>
            <p @click="hideModal" class="cursor text-77 py-2 mb-0">
              {{ $t("cancel_txt") }}
            </p>
            
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dia-callback-popup",
  props: {
    // type: {
    //   type: String,
    //   default: "matchmaking",
    // },
  },
  data() {
    return {
      title: "",
      showCallbackPopup: false,
      callBackDesc: "",
      type: "",
      btnVariant: "ls-dia-g2",
    };
  },
  mounted() {
    let self = this;
  },
  methods: {
    showModal(type) {
      let self = this;
      self.type = type;
      self.showCallbackPopup = true;

      //   console.log("showModal", self.type);

      switch (self.type) {
        case "starter":
          self.title = self.$t("book_a_call");
          self.callBackDesc = self.$t("book_a_call_desc");
          self.btnVariant = "ls-starter";
          break;
        case "gold":
          self.title = self.$t("book_a_call");
          self.callBackDesc = self.$t("book_a_call_desc");

          self.btnVariant = "ls-gold6";

          break;
        case "plus":
          self.title = self.$t("call_back_title");
          self.callBackDesc = self.$t("call_for_plus_package");
          self.btnVariant = "ls-plus-g2";
          break;
        case "diamond":
        case "matchmaking":
          self.title = self.$t("call_back_title");
          self.callBackDesc = self
            .$t("call_back_desc")
            .replace("[type]", self.type);
          //   self.btnVariant = "ls-dia-g2";
          self.btnVariant = "ls-dia-g3";
          break;
        default:
          self.title = self.$t("call_back_title");
          self.callBackDesc = self
            .$t("call_back_desc")
            .replace("[type]", self.type);
          break;
      }
    },
    hideModal() {
      this.showCallbackPopup = false;
    },

    scheduleCallback() {
      let self = this;

      let fdata = new FormData();

      if (self.type == "diamond") {
        self.type = "matchmaking";
      }

      fdata.append("package_name", self.type);

      self.$layer.loading();

      self.$api
        .postScheduleCallback(fdata, self.$store.state)
        .then((rs) => {
          // console.log(rs);
          self.$layer.closeAll("loading");

          //name: "matchmaking-callback"
          self.$emit("afterPost", {});
        })
        .catch((err) => {
          console.log(["save error", err]);
          self.$layer.closeAll("loading");

          self.$layer.msg(err.response.data.error.body);
        });
    },
  },
};
</script>