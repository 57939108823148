// this is aliased in webpack config based on server/client build
import { createAPI } from 'create-api'
import axios from "axios";
//import storage from '../utils/storage'

axios.defaults.baseURL = "https://api.lovestruck.com";


const logRequests = !!process.env.DEBUG_API

const api = createAPI()

// warm the front page cache every 15 min
// make sure to do this only once across all requests
// if (api.onServer) {
//   warmCache()
// }
//
// function warmCache () {
//   fetchItems((api.cachedIds.top || []).slice(0, 30))
//   setTimeout(warmCache, 1000 * 60 * 15)
// }

let hashCode = function (str) {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function fetch(options, state) {

    let { url } = options
    let hash = hashCode(`${url}-${options.addonHash}`)

    let useCache = _.get(options, 'cache', true)

    logRequests && console.log(`fetching ${hash}...`)
    // console.log('----fetch:', url)
    const cache = api.cachedItems

    if (useCache === true && cache && cache.has(hash)) {
        logRequests && console.log(`cache hit for ${hash}.`)
        //console.log(`cache hit for ${hash}.`)
        return Promise.resolve(cache.get(hash))
    } else {
        return axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': _.get(options, 'lang', _.get(state, 'curLang', 'en_US')),
                'Authorization': _.get(options, 'token', _.get(state, 'token', '')),
                'Accept-Iso2': _.get(options, 'iso2', _.get(state, ['curSite', 'iso2'], 'HK'))
            }
        })
            .then(rs => {
                cache && cache.set(hash, rs.data)
                return rs.data
            })

        // return axios.get(url).then(rs => {
        //     //console.log(hash,rs.data)
        //   cache && cache.set(hash, rs.data)
        //   return rs.data
        // })
    }
}

export default {
    fetchItem(options) {
        return fetch(options)
    },
    getSites(state) {
        return fetch({
            url: '/api/v2/index/sites',
            addonHash: _.get(state, 'curLang', 'en_US')
        }, state).then(rs => {
            //console.log('get sites 0000:', rs.result.total);
            let sites = rs.sites;
            let cities = {};
            for (let [key, site] of sites.entries()) {
                if (site.url_long_alias.length > 0) {
                    for (let city of site.url_long_alias) {
                        if (city == 'gen') {
                            cities[site.url_long] = {
                                'url_long': site.city_url ? site.city_url : site.url_long,//site.city_url
                                'index': key
                            };
                            if (site.city_url && site.city_url.indexOf('-') > -1) {
                                // console.log('ddd', site.city_url)
                                cities[`${site.city_url}`] = {
                                    'url_long': site.city_url,//
                                    'index': key
                                };
                            }
                            continue;
                        }
                        cities[city] = {
                            'url_long': site.city_url ? site.city_url : site.url_long,//site.city_url
                            'index': key
                        };

                        if (site.city_url && site.city_url.indexOf('-') > -1) {
                            // console.log('ddd', site.city_url)
                            cities[`${site.city_url}`] = {
                                'url_long': site.city_url,//
                                'index': key
                            };
                        }
                    }
                } else {
                    if (site.url_long == 'gen') {
                        // cities[site.url_long] = {
                        //   'url_long': site.url_long,
                        //   'index': key
                        // };
                        continue;
                    }

                    cities[site.url_long] = {
                        'url_long': site.city_url ? site.city_url : site.url_long,//
                        'index': key
                    };
                    // console.log('kkkk', site.city_url, site.city_url.indexOf('-'))
                    if (site.city_url && site.city_url.indexOf('-') > -1) {
                        // console.log('ddd', site.city_url)
                        cities[`${site.city_url}`] = {
                            'url_long': site.city_url,//
                            'index': key
                        };
                    }
                }
            }

            // console.log('citylist', cities)

            return { citylist: cities, sites: sites }
        })
    },
    getIpCity(state) {
        return fetch({
            url: 'https://service2.lovestruck.com/ip',
            cache: false
        }, state)
    },
    getPhrase(state) {
        return fetch({
            url: '/api/v2/phrase/forAppWeb?iso2=' + _.get(state.curSite, 'iso2', 'HK'),
        }, state)
    },
    getAttributes(state) {
        return fetch({
            // url: 'https://app.lovestruck.com/api/attributes',
            url: '/api/v2/attribute/all',
            addonHash: _.get(state, 'curLang', 'en_US')
        }, state)
    }
};
