import Vue from "vue";
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

export function createI18n() {
	
	//console.log('init-vue-i18n',store.state.curCity)
	const messages = {};
	return new VueI18n({
			locale: 'en_US',
			messages
		})
}