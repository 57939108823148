<template>
  <div class="height-in bg-faf min-h100 pb-5">
    <!-- <a
      href="javascript:;"
      @click="goToPhonePage"
      class="p-2"
      style="position: absolute; width: 40px; height: 40px; left: 0; top: 0"
    >
      <i class="lsicon arrow_left_y"></i>
    </a> -->
    <div class="page6-2 px-3">
      <!-- <div class="pt-2">
        <div class="ls-progress-bar bg-d7c v6-2">
          <div class="ls-progress-completed" style="transition: 0.1s ease 0s; width: 10%"></div>
        </div>
      </div> -->
      <div class="fs-30 ff-prata fw-500 line-h-35 py-4 text-center">
        {{ $t("enter_the_code") }}
      </div>
      <div style="height: 5vh;"></div>
      <!-- <div class="text-7e">
        {{ $t("send_code_desc") }}<br />
        {{ enterSmsCodeDesc }}
        <a @click="goToPhonePage" href="javascript:;" class="text-7e ml-4 fw-500">{{ $t("link_change") }}</a>
      </div> -->

      <!-- <div>
        <div class="form-group mt-4 text-center">
          <input type="number" class="form-control v6-2 fs-30 l-space2 fw-500" @input="checkVcodeInput" v-model="smsCode"
            autocomplete="off" maxlength="4" :placeholder="$t('enter_code')" />
        </div>
      </div>
      <div class="text-right">
        <b-button pill variant="ee1" @click="login" :class="canSubmit ? '' : 'disabled'" class="o-btn lg"><i
            class="fa-light fa-arrow-right"></i></b-button>
      </div> -->
    </div>

    <div class="register_box position-relative mx-auto" id="reg_form" style="max-width: 320px">

      <div>
        <div class="form-group mt-4 text-center">
          <input type="number" class="form-control d-inline-block rounded text-center v6-2 code1"
            @input="checkCodeInput(1)" v-model="code1" autocomplete="off" maxlength="1"
            oninput="this.value = this.value.replace(/[^0-9]{1}/g, '');" />

          <input type="number" class="form-control d-inline-block rounded text-center v6-2 mx-3 code2" autocomplete="off"
            @input="checkCodeInput(2)" v-model="code2" maxlength="1"
            oninput="this.value = this.value.replace(/[^0-9]{1}/g, '');" />

          <input type="number" class="form-control d-inline-block rounded text-center v6-2 code3" autocomplete="off"
            @input="checkCodeInput(3)" v-model="code3" maxlength="1"
            oninput="this.value = this.value.replace(/[^0-9]{1}/g, '');" />

          <input type="number" class="form-control d-inline-block rounded text-center v6-2 ml-3 code4" autocomplete="off"
            @input="checkCodeInput(4)" v-model="code4" maxlength="1"
            oninput="this.value = this.value.replace(/[^0-9]{1}/g, '');" />


        </div>

        <div class="text-center">
          <p class="text-7e">{{ $t("send_code_desc") }}</p>
        </div>

        <p v-if="signData" class="text-center my-3 text-7e">
          {{ enterSmsCodeDesc }}
          <a class="text-1d ml-3 fw-700" @click="goToPhonePage" href="javascript:;">{{ $t("link_change") }}</a>
        </p>


      </div>
    </div>

    <alertLayerCom ref="alertLayerCom" @greenClick="hideAlert" @blueClick="sendNewSms" />
  </div>
</template>
<script>
import helper from "../../utils/helper";
import alertLayerCom from "../common/alertLayerCom";
export default {
  name: "smsVerify",
  components: {
    alertLayerCom,
  },
  data() {
    return {
      signData: null,
      smsCode: "",
      canSubmit: false,
      deviceId: 3, // 3 (desktop web), 4 (mobile web)
      ls_sms_sec: 60,
      ls_timer: null,
      sms_btn_text: "Send code",
      resend_btn_txt: "Resend",
      can_send_sms: false,
      code1: null,
      code2: null,
      code3: null,
      code4: null,
      curSite: this.$store.getters.curSite,
      langCode: "",
      enterSmsCodeDesc: null,
      //   smsArriveWithin60: this.$t('sms_arrive_within60'),
      //   smsTimerCountText: '',
      smsTimer: null,
      timerSec: 60,
      realMobile: "",
    };
  },
  mounted() {
    let self = this;
    self.$parent.closeLoading();
    self.langCode = self.$storage.get("cur-lang");
    document.title = self.$t("page_title");
    // console.log("curSite", self.curSite.url_long);
    // self.showAlert("response.data.output.error.body");

    let signData = self.$storage.get("signIn");

    if (!signData) {
      let mb = _.get(self.$route.query, "mb", "").split("-");
      if (_.get(mb, "0") && _.get(mb, "1")) {
        signData = {
          i: _.get(mb, "0"),
          m: _.get(mb, "1"),
        };
      }
    }

    if (!signData) {
      self.$layer.msg("Miss phone number");
      setTimeout(function () {
        // self.$router.back();
        self.goToPhonePage();
      }, 1500);
      return false;
    }

    // signData = {
    //   i: "86",
    //   m: "17520475173",
    // };
    self.sms_btn_text = self.$t("web_home_get_verify");
    self.resend_btn_txt = self.$t("web_home_get_verify_again");

    if (signData) {
      self.signData = signData;
      //   self.enterSmsCodeDesc = self
      //     .$t("enter_sms_code_desc")
      //     .replace("[phone_number]", `+${self.signData.i} ${self.signData.m}`);
      self.enterSmsCodeDesc = `+${self.signData.i} ${self.signData.m}`;
      //   self.ls_timer = setInterval(() => {
      //     self.dTime();
      //   }, 1000);
      // self.timerCount();
      //   console.log("sign data");
      self.realMobile = _.get(self.signData, "m", "").replace(/[^\d]/g, "");
      console.log("self.realMobile", self.realMobile);
    } else {
      //   console.log("none sign data");
    }

    setTimeout(function () {
      //   $("#codeInput").focus();
      $(`input.code1`).focus();
    }, 200);
  },
  methods: {
    timerCount() {
      let self = this;

      self.smsTimerCountText = self.smsArriveWithin60.replace(
        "60",
        self.timerSec
      );

      self.smsTimer = setInterval(function () {
        if (self.timerSec <= 0) {
          clearInterval(self.smsTimer);
          self.timerSec = 60;
        } else {
          self.timerSec--;
          self.smsTimerCountText = self.smsArriveWithin60.replace(
            "60",
            self.timerSec
          );
        }
      }, 1000);
    },
    checkVcodeInput() {
      if (this.smsCode.length == 4) {
        this.canSubmit = true;
        // this.login();
      } else {
        this.canSubmit = false;
      }
    },
    hideAlert() {
      let self = this;
      self.code1 = "";
      self.code2 = "";
      self.code3 = "";
      self.code4 = "";
      self.smsCode = "";

      $(`input.code1`).focus();

      self.$refs.alertLayerCom.hideAlert();
    },
    sendNewSms() {
      let self = this;
      self.can_send_sms = true;

      self.sendSms();
    },
    showAlert(msg) {
      let self = this;
      self.$refs.alertLayerCom.showAlert({
        title: self.$t("sms_code_invalid"),
        content: self.$t("pls_try_again"),
        greenBtnText: self.$t("try_again"),
        blueBtnText: self.$t("send_new_code"),
        useTimer: true,
      });
    },
    checkCodeInput(n) {
      let self = this;
      //   console.log("iput", n);
      let p = /^\d$/;
      if (!p.test(self[`code${n}`])) {
        return;
      }
      if (n < 4) {
        $(`input.code${n + 1}`).focus();
      }

      if (n == 4 && self.code4 >= 0 && self.code4 <= 9) {
        $(`input.code4`).blur();
      }

      //   console.log("code", self.code1, self.code2, self.code3, self.code4);

      if (self.code1 && self.code2 && self.code3 && self.code4) {
        self.smsCode = `${self.code1}${self.code2}${self.code3}${self.code4}`;
        self.login();
        // self.canSubmit = true;
      } else {
        self.canSubmit = false;
      }
    },
    goToPhonePage() {
      //sign-in
      this.$router.push({ name: "home-lsm" });
    },
    login() {
      let self = this;
      let fdata = new FormData();

      fdata.append("mobile_international_code", _.get(self.signData, "i"));
      fdata.append("mobile_phone_number", self.realMobile);
      fdata.append("verify_code", self.smsCode);
      fdata.append("city_path", self.curSite.url_long);

      let lid = self.$layer.loading();
      self.$api
        .postVerifyPhone(fdata, self.$store.state)
        .then((response) => {
          if (response.authoriseToken) {
            let pdata = new FormData();
            pdata.append("authoriseToken", response.authoriseToken);
            pdata.append("device_id", self.deviceId);

            return self.$api.postLogin(pdata, self.$store.state);
          } else {
            self.$layer.close(lid);
            console.log("something error");
          }
        })
        .then((response) => {
          console.log(["login crm", response]);

          if (_.has(response, "client_id")) {
            helper.setLoginData(response);

            self.$store.commit(
              "SET_TOKEN",
              _.get(response, "access_token", "")
            );
            self.$store.commit("SET_CUR_LANG", {
              curlang: _.get(response, "lang_code", "en_US"),
            });

            let jump = helper.parseFirstLoadPage(response);

            if (jump.type == "link") {
              // console.log(url)
              if (typeof location != "undefined") {
                location.href = jump.route_name;
              }
              return false;
            }

            self.$router.push({
              name: jump.route_name,
              params: jump.params ? jump.params : {},
              query: jump.query ? jump.query : {},
            });

            // }
            self.$layer.close(lid);

            // if (response.is_new_user == 1) {
            //   // go to register steps
            //   //   self.$router.push({ name: "register-profile2" });
            //   self.$router.push({ name: "reg-step", query: { step: 0 } });
            //   self.$layer.close(lid);
            // } else {
            //   //payment-reservation
            //   let login_jump = self.$storage.getLogiJump();
            //   if (!login_jump) {
            //     // self.$router.push({ name: "match-list" });
            //     // self.$router.push({ name: "elite-list" });
            //     self.$router.push({ name: "client-chat" });
            //   } else {
            //     let jump_url = `${location.origin}/app/${_.get(
            //       response,
            //       "city_url",
            //       "hongkong"
            //     )}${login_jump}?lang=${_.get(response, "lang_code", "")}`;
            //     // console.log(jump_url);
            //     self.$storage.clearLogiJump();

            //     location.href = jump_url;
            //   }

            //   self.$layer.close(lid);
            // }
          } else {
            self.$layer.close(lid);
            // self.$layer.msg("Login failed, please try again.");
            self.showAlert(self.$t("error_sms_code"));
          }
        })
        .catch((error) => {
          console.log(["veriry sms error", error]);
          self.$layer.close(lid);
          let message = "Somethig was wroing...";

          if (_.has(error, "response.data.error")) {
            message = _.get(error, "response.data.error.body");
          }
          self.showAlert(message);
          //   self.$layer.msg(message);
        });
    },
    sendSms() {
      var self = this;
      if (!self.can_send_sms) {
        console.log("can not send");
        return false;
      }
      self.hideAlert();

      if (!_.get(self.signData, "i") || !_.get(self.signData, "m")) {
        //console.log('mobile error');
        self.$layer.msg(self.$t("val_mobile_invaild"));

        return false;
      }

      let fdata = new FormData();
      fdata.append("mobile_international_code", _.get(self.signData, "i"));
      fdata.append("mobile_phone_number", self.realMobile);

      let lid = self.$layer.loading();

      self.$api
        .postRequestWithPhone(fdata, self.$store.state)
        .then((response) => {
          //console.log(response);
          self.$layer.closeAll("loading");

          if (response.hasOwnProperty("success") && !response.success) {
            let message = JSON.parse(response.output);
            // console.log("message", message);
            self.$layer.msg(_.get(message, "error.body"));
            // self.showAlert(_.get(message, "error.body"));
            return false;
          } else {
            // self.step = 2;
            // $("#icode").focus();
            self.ls_timer = setInterval(() => {
              self.dTime();
            }, 1000);
            // self.goToSmsVerify();
          }
        })
        .catch((error) => {
          self.$layer.closeAll("loading");
          // console.log(["sms error", error]);
          if (_.has(error, "response.data")) {
            self.$layer.msg(_.get(error, "response.data.output.error.body"));
            // self.showAlert(_.get(error, "response.data.output.error.body"));
          } else if (
            _.has(error, "message") &&
            _.get(error, "message").indexOf("timeout") > -1
          ) {
            // self.step = 2;
            // $("#icode").focus();
            self.ls_timer = setInterval(() => {
              self.dTime();
            }, 1000);
          }
        });
    },
    dTime() {
      let self = this;
      if (self.ls_sms_sec == 0) {
        self.can_send_sms = true;
        self.ls_sms_sec = 60;

        //send_btn.removeClass('disabled');
        self.sms_btn_text = self.resend_btn_txt;
        clearInterval(self.ls_timer);
        return false;
      } else {
        self.can_send_sms = false;
        self.sms_btn_text = `${self.resend_btn_txt}(${self.ls_sms_sec}s)`;
        self.ls_sms_sec--;
      }
    },
    checkInput() {
      if (this.signData && this.smsCode.length == 4) {
        this.canSubmit = true;
        this.login();
      } else {
        this.canSubmit = false;
      }
    },
  },

};
</script>
<style>
.code1,
.code2,
.code3,
.code4 {
  max-width: 50px;
  height: 50px;
  font-size: 30px !important;
}
</style>