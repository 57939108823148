<template>
  <div :class="classNames" :style="styleString">
    <div v-if="showHead" class="pageTopDiv py-2 bg-faf">
      <a
        v-if="showBack"
        href="javascript:;"
        @click="goback"
        class="px-2 topBackBtn"
      >
        <!-- <i class="lsicon arrow_left_y2"></i> -->
        <i class="fa-regular fa-chevron-left text-ee1 fs-24"></i>
      </a>

      <div
        v-if="page_title"
        class="text-capitalize text-center fw-600 fs-18 w-100 text-1d"
      >
        {{ page_title }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-top",
  props: {
    showBack: {
      type: Boolean,
      default: true,
    },
    routerBack: {
      type: Boolean,
      default: true,
    },
    classNames: {
      type: String,
      default: "",
    },
    styleString: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showHead: true,
      page_title: null,
    };
  },
  mounted() {},
  methods: {
    hide() {
      this.showHead = false;
    },
    setPageTitle(title) {
      this.page_title = title;
    },
    goback() {
      this.$emit("navBack");
      if (this.routerBack) {
        this.$router.back();
      }
    },
  },
};
</script>
<style>
.pageTopDiv {
  position: relative;
}
.topBackBtn {
  position: absolute;
  left: 10px;
  top: 10px;
}
</style>