<template>
  <div class="height-in min-h100 text-faf bg-20">
    <div class="page6-2">
      <div
        v-if="step == 1"
        class="bg_image text-center py-3"
        style="
          background-image: url('https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Comp_1_PosterImage.png');
        "
      >
        <div class="text-left ml-3 mb-2">
          <a href="javascript:;">
            <i class="fa-regular fa-chevron-left fs-24 text-faf"></i>
          </a>
        </div>
        <h4 class="mb-4 fw-500 ff-prata fs-24 mb-4">
          Our members are looking<br />for long-term relationships
        </h4>
        <p class="mb-5">
          And are verified through a 3-step<br />verification process.
        </p>

        <div class="mb-4">
          <div class="mb-2">
            <i class="fa-light fa-circle-check text-ee1 fs-22"></i>
          </div>
          Mobile phone verification
        </div>
        <div class="mb-4">
          <div class="mb-2">
            <i class="fa-light fa-circle-check text-ee1 fs-22"></i>
          </div>
          Photo ID verification
        </div>
        <div class="mb-5">
          <div class="mb-2">
            <i class="fa-light fa-circle-check text-ee1 fs-22"></i>
          </div>
          Profile photo verification
        </div>

        <p class="mb-4">You’ll be safe from scammers<br />and fake profiles</p>

        <div class="">
          <b-button
            pill
            variant="ee1"
            @click="goitClick(2)"
            class="px-5 l-space2 text-uppercase"
            style="height: 53px; min-width: 240px"
          >
            {{ $t("got_it") }}
          </b-button>
        </div>
      </div>

      <div
        v-if="step == 2"
        class="bg_image text-center py-3"
        style="
          background-image: url('https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Comp_2_PosterImage.png');
        "
      >
        <div class="text-left ml-3 mb-2">
          <a href="javascript:;">
            <i class="fa-regular fa-chevron-left fs-24 text-faf"></i>
          </a>
        </div>
        <h4 class="mb-4 fw-500 ff-prata fs-30 mb-4">
          <span class="text-ee1">No more</span><br />endless swiping
        </h4>
        <p class="mb-5">
          <b-img
            width="220"
            src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/welcome_2@3x.png"
          ></b-img>
        </p>

        <div class="fw-400 mb-3">
          We’ll send you carefully<br />screened matches based on<br />your
          specific requirements.
        </div>

        <div class="fw-400 mb-4">
          Start chatting with matches<br />that are of interest.
        </div>

        <div class="">
          <b-button
            pill
            variant="ee1"
            @click="goitClick(3)"
            class="px-5 l-space2 text-uppercase"
            style="height: 53px; min-width: 240px"
          >
            {{ $t("got_it") }}
          </b-button>
        </div>
      </div>

      <div
        v-if="step == 3"
        class="bg_image text-center py-3"
        style="
          background-image: url('https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/Comp_2_PosterImage.png');
        "
      >
        <div class="text-left ml-3 mb-2">
          <a href="javascript:;">
            <i class="fa-regular fa-chevron-left fs-24 text-faf"></i>
          </a>
        </div>
        <h4 class="mb-4 fw-500 ff-prata fs-24 mb-4">
          Arrange dates yourself or<br />let our team help you
        </h4>
        <p class="mb-5">
          <b-img
            width="300"
            src="https://cdn-cf2.lovestruck.com/upload-m/gen/img/ls-app-6.2/date_concierge_en_US.png"
          ></b-img>
        </p>

        <div class="fw-400 mb-3">Our team can assist you with:</div>

        <div class="mb-4 text-left mx-auto" style="max-width: 160px">
          <div class="mb-3">
            <i class="fa-light fa-circle-check text-ee1 fs-22"></i> Date
            requests
          </div>
          <div class="mb-3">
            <i class="fa-light fa-circle-check text-ee1 fs-22"></i> Date
            scheduling
          </div>
          <div>
            <i class="fa-light fa-circle-check text-ee1 fs-22"></i> Reservations
          </div>
        </div>

        <div class="">
          <b-button
            pill
            variant="ee1"
            @click="goitClick(3)"
            class="px-5 l-space2 text-uppercase"
            style="height: 53px; min-width: 240px"
          >
            {{ $t("got_it") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "welcome",
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    goitClick(v) {
      this.step = v;
    },
    getStarted() {
      this.$router.push({
        name: "date-event-list",
        query: { ref: "register" },
      });
    },
  },
  mounted() {
    let self = this;
  },
};
</script>
<style scoped>
.welcome_text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>