import _ from 'lodash'

export default {

  message(state) {
    return state.message
  },

  attrs(state) {
    return state.attrs
  },
  curLang(state) {
    return state.curLang;
  },
  curCity(state) {
    return state.curCity;
  },
  curSite(state) {
    return state.curSite;
  },
  sites(state) {
    return state.sites;
  },
  cities(state) {
    return state.cities;
  },
  ipCity(state) {
    return state.ipCity
  },
  translation(state) {
    return state.translation;
  },
  token(state) {
    return state.token
  },
  user(state) {
    return state.user
  },
  userInfo(state) {
    return state.userInfo
  },
  matchmakers(state) {


    let lang = state.curLang;
    if (!lang) {

      return {}
    }

    try {

      let content = _.get(state.translation, lang.toLowerCase(), '')

      if (typeof content === 'string' || !content) {
        return {}
      }

      let message = content

      let rs = {}
      for (let i = 1; i <= 4; i++) {
        rs[i] = {
          title: _.get(message, `matchmaker_${i}_title`),
          position: _.get(message, `matchmaker_${i}_position`),
          desc1: _.get(message, `matchmaker_${i}_desc1`),
          desc2: _.get(message, `matchmaker_${i}_desc2`),
          desc3: _.get(message, `matchmaker_${i}_desc3`),
          src: `https://cdn-cf2.lovestruck.com/upload-s/gen/img/matchmakers/${_.get(message, `matchmaker_${i}_src`)}`,
        }
      }

      return rs
    } catch (err) {
      console.log('matchmakers-error', lang, err)
      return {}
    }
  },

  successStories(state) {
    let lang = state.curLang;
    //console.log('successStories',lang)
    if (!lang) {

      return {}
    }
    try {
      let content = _.get(state.translation, lang.toLowerCase(), '')

      if (typeof content === 'string' || !content) {
        return {}
      }

      let message = content

      let frameNames = [
        'goddard',
        'jeff',
        'karen',
        'bill_cena',
        'wing_paul',
        'billy_nancy',
        'nanjia_sammi',
        'calvin_yvonne',
        'rita_cheong',
        'hazel',
      ];

      let rs = {}
      for (let key in frameNames) {
        let frameName = frameNames[key]
        // console.log(`success_stories.__${frameName}.desc`)
        // console.log('--1', frameName, message[`success_stories.__${frameName}.desc`], typeof message)

        rs[key] = {
          title: _.get(message, `success_stories___${frameName}_title`, ''),
          desc: _.get(message, `success_stories___${frameName}_desc`, ''),
          image: `https://cdn-cf2.lovestruck.com/upload-s/gen/img/success_story/${frameName}.jpg`,
        }
      }

      //console.log('successStories',rs)

      return rs
    } catch (err) {
      console.log('successStories-error', lang, err)
      return {}
    }
  },
  loadingPageLangIcons(state) {

    let lang = state.curLang;
    // console.log('loadingPageLangIcons', lang)
    if (!lang) {

      return {}
    }
    try {
      let content = _.get(state.translation, lang.toLowerCase(), '')

      if (typeof content === 'string' || !content) {
        return {}
      }

      return {
        'banner': _.get(content, 'banner_filename', ''),
        'hicon1': _.get(content, 'loadingpage_icon1', ''),
        'hicon2': _.get(content, 'loadingpage_icon2', ''),
        'hicon3': _.get(content, 'loadingpage_icon3', ''),
        'hicon4': _.get(content, 'loadingpage_icon4', ''),
      }
    } catch (err) {
      console.log('loadingPageLangIcons-error', lang, err)
      return {}
    }



  }

}
