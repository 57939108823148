<template>
    
</template>
<script>
import { webSocket } from "../../utils/mixin";
export default {
    name: "test-server-notify",
    mixins: [webSocket],
    mounted(){
        let self = this
        self.initSocket();
        
    }
}
</script>
