<template>
    <div v-if="show">
        <div class="viewBg">
            <div class="px-3 text-right" style="position: absolute;
    right: 0;
    top: 0;z-index: 11111;">
                <i @click="closeModel" class="lsicon close_grey2 lsicon-30"></i>
            </div>
        <div class="mx-auto w-100" style="max-width:500px;">
            <div class="swiper-container" id="photoSwiper" style="height: 100vh;">
            <div class="swiper-wrapper mb-5">
              <div
                class="swiper-slide text-center photoBg"
                v-for="(item, key) in carousels"
                :key="`diaswiper-${key}`"
                :style="`background-image: url(${item})`"
              >
                <!-- <b-img center :src="item" style="max-width:480px;"/> -->
                
              </div>
              </div>
              <div class="swiper-pagination light"></div>
              </div>
        </div>
        </div>
    </div>
</template>
<script>
if (typeof window != "undefined") {
  var VueAwesomeSwiper = require("vue-awesome-swiper/dist/ssr");
}
export default {
    name: "photo-view",
    props: {
        carousels: {
            type: Array,
            default: []
        }
    },
    data(){
        return {
            show: false,
            photoSwiper: null,
            swiperOption: {
                //init:false,
                speed: 500,
                // loop: true,
                initialSlide: 0,
                direction: "horizontal",
                // autoplay: {
                // delay: 7000,
                // disableOnInteraction: false,
                // },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    bulletActiveClass: "ls-gold-bullet-active",
                },
            },
            //   showSchedule: false,
            initialSlide: 0,
        }
    },
    mounted() {
        console.log(this.carousels)
    },
    methods: {
        startSwiper() {
      let self = this;
      if (VueAwesomeSwiper && self.carousels.length > 1) {
        //!self.lsSwiper &&
        // if (self.lsSwiper) {
        //   self.lsSwiper.destroy();
        // }
        if (self.photoSwiper) {
          if (self.photoSwiper[0]) {
            self.photoSwiper[0].observer.destroy();
          } else {
            self.photoSwiper.destroy();
          }
        }

        // if (self.navigation) {
        //   self.swiperOption.navigation = {
        //     nextEl: ".swiper-button-next",
        //     prevEl: ".swiper-button-prev"
        //   };
        // }
        // if (self.carouselLen == 1) {
        //   self.swiperOption.autoplay = false;
        //   self.swiperOption.navigation = false;
        // }

        // let page = _.get(self.$route, "query.page", 1);

        self.swiperOption.initialSlide = self.initialSlide;

        setTimeout(function () {
          self.photoSwiper = new VueAwesomeSwiper.Swiper(
            "#photoSwiper",
            self.swiperOption
          );
        }, 10);
      }
    },
        closeModel() {
            let self = this;
            self.show = false;

            self.initialSlide = 0;
        },
        showModal(initialSlide = 0) {
            let self = this;
            self.show = true;
            self.initialSlide = initialSlide;

            self.startSwiper();
        },
    }
}
</script>

<style scoped>
.viewBg{
    position: fixed;
    top:0;
    bottom:0;
    right:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 9999;
}
.photoBg{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
</style>