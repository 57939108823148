<template></template>
<script>
export default {
  name: "Unauthorized",
  mounted: function () {
    let self = this;
    // console.log("curCity=", self.$store.getters.curCity);
    this.$bvModal

      .msgBoxConfirm("User is not authorized! Please try again.", {
        title: "Unauthorized!",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "OK",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: false,
      })
      .then((value) => {
        //  window.location.href = window.location.origin;
        // let publicPath = process.env.BASE_URL;
        let curCity = self.$store.getters.curCity;

        // console.log("curCity=", curCity);

        if (typeof location == "undefined") {
          self.$router.push({ name: "city-home", params: { city: curCity } });
        } else {
          if (curCity) {
            location.href = `${location.origin}/${curCity}`;
          } else {
            location.href = `${location.origin}/hongkong`;
          }
        }
      })
      .catch((err) => {
        // An error occurred
      });
  },
};
</script>