<template>
  <div>
    <div id="pos-area">
      <p id="demo">Location info：<br /></p>
    </div>

    <div id="btn-area">
      <button @click="getCurLocation">GPS Location</button>
      <button @click="getIpLocation">IP Location</button>
      <!-- <button @click="showWatchPosition">开始监听位置</button>
        <button @click="showClearWatch">停止监听位置</button> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "test-gps",
  data() {
    return {
      geolocation: null,
      options: { timeout: 9000 },
      positionNum: 0,
    };
  },
  mounted() {
    let self = this;
    // self.geolocation = new qq.maps.Geolocation();

    document.getElementById("pos-area").style.height =
      document.body.clientHeight - 210 + "px";
  },
  methods: {
    getIpLocation() {
      let self = this;
      // self.geolocation.getIpLocation(self.showPosition, self.showErr)
    },
    getCurLocation() {
      let self = this;
      // self.geolocation.getLocation(self.showPosition, self.showErr, self.options);
    },
    showPosition(position) {
      let self = this;
      // self.positionNum ++;
      // document.getElementById("demo").innerHTML += "NO." + self.positionNum;
      // document.getElementById("demo").appendChild(document.createElement('pre')).innerHTML = JSON.stringify(position, null, 4);
      // document.getElementById("pos-area").scrollTop = document.getElementById("pos-area").scrollHeight;
    },
    showWatchPosition(position) {
      let self = this;
      self.positionNum++;
      // document.getElementById("demo").innerHTML += "NO." + self.positionNum;
      // document.getElementById("demo").appendChild(document.createElement('pre')).innerHTML = JSON.stringify(position, null, 4);
      // document.getElementById("pos-area").scrollTop = document.getElementById("pos-area").scrollHeight;
    },
    showErr() {
      let self = this;
      self.positionNum++;
      // document.getElementById("demo").innerHTML += "NO." + self.positionNum;
      // document.getElementById("demo").appendChild(document.createElement('p')).innerHTML = "Get location failed!";
      // document.getElementById("pos-area").scrollTop = document.getElementById("pos-area").scrollHeight;
    },
    showClearWatch() {
      let self = this;
      // self.geolocation.clearWatch();
      // document.getElementById("demo").innerHTML += "stop listen<br /><br />";
      // document.getElementById("pos-area").scrollTop = document.getElementById("pos-area").scrollHeight;
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  border: 0;
}
body {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
}
#pos-area {
  background-color: #009ddc;
  margin-bottom: 10px;
  width: 100%;
  overflow: scroll;
  text-align: left;
  color: white;
}
#demo {
  padding: 8px;
  font-size: small;
}
#btn-area {
  height: 100px;
}
button {
  margin-bottom: 10px;
  padding: 12px 8px;
  width: 42%;
  border-radius: 8px;
  background-color: #009ddc;
  color: white;
}
</style>