import { render, staticRenderFns } from "./upgradeBook.vue?vue&type=template&id=05235a7e&"
import script from "./upgradeBook.vue?vue&type=script&lang=js&"
export * from "./upgradeBook.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "1ea48585"
  
)

export default component.exports