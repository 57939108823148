<template>
  <div v-if="type == 1 && dataList">
    <div v-for="event in dataList" :key="event.event_id">
      <b-card @click="eventInfo(event)" no-body class="cursor mb-3 shadow-sm">
        <div class="px-3 pt-3 mb-2">
          <div v-if="event.nearly_sold_out" class="nearly_sold_out">
            {{ $t("nearly_sold_out") }}
          </div>

          <p class="font-weight-bold font-1_2rem mb-2">
            <i class="lsicon calendar_black lsicon-25" style="top: 5px"></i>
            {{ event.event_time_day2 }} {{ event.event_time_time }}
          </p>
          <!-- <p class="font-weight-bold font-1_2rem mb-2">
            {{ event.day }} {{ event.full_month }}
          </p> -->
          <p class="font-weight-bold mb-2">{{ event.event_name }}</p>
          <!-- <p class="mb-3">
            <i class="lsicon duration_black" style="top: 3px"></i>
            {{ $t("duration_txt") }}: {{ event.duration }} hours (<span
              class="text-diamond"
              >{{ event.event_time_time }}</span
            >)
          </p> -->

          <address class="mb-2">
            <p class="font-weight-bold">
              <i class="lsicon location"></i>
              {{ event.venue_name }}
            </p>

            <p class="text-secondary font_ls_tiny85">
              <span class="empty_span"></span>
              {{ event.venue_address }}
            </p>
          </address>

          <!-- <p v-if="!event.early_bird_past" class="text-truncate text-secondary">
            <i class="lsicon event_ticket"></i>
            <strong class="ml-2 mr-1">{{
              event.date_credits_early_bird
            }}</strong>

            <span class="small font-weight-normal text-capitalize"
              >{{
                event.date_credits_early_bird > 1
                  ? $t("event_tickets")
                  : $t("Event ticket")
              }}
              - until {{ event.early_bird_date }}(early bird)</span
            >
          </p> -->
          <p class="text-truncate text-secondary mb-2">
            <i class="lsicon event_ticket"></i>
            <!-- <span v-if="!event.early_bird_past" class="empty_span"></span> -->
            <strong class="ml-2 mr-1">{{ event.date_credits }}</strong>

            <span class="small font-weight-normal text-capitalize"
              >{{
                event.date_credits > 1
                  ? $t("event_tickets")
                  : $t("Event ticket")
              }}
              <!-- - from {{ event.regular_date }}(regular) -->
            </span>
          </p>

          <p class="text-truncate text-secondary mb-2">
            <i class="lsicon peoples"></i>
            <span class="ml-2">
              {{ $t("ages_txt") }}: {{ event.age_range_lower_female }} -
              {{ event.age_range_upper_male }}
            </span>
          </p>

          <p class="text-truncate text-secondary mb-2">
            <i class="lsicon offer mr-2"></i>
            {{ event.price_includes }}
          </p>

          <!-- <div v-if="event.potential_date_matches_count > 0" class="join_users my-3">
            <div class="listbox">
              <ul>
                <li v-for="joiner in event.potential_date_matches" :key="joiner.client_id">
                  <img :src="joiner.photo_url" />
                </li>
              </ul>
            </div>
            <p class="mt-1">
              <strong>{{event.potential_date_matches_count}}</strong>
              {{$t('potential_dates')}}
            </p>
          </div> -->
          <div v-if="event.isJoined == 0" class="text-center mt-3 pb-3">
            <b-button
              @click.stop="joinEvent(event.event_id)"
              variant="ls-red2"
              class="px-4"
              >{{ $t("join_event_txt") }}</b-button
            >
          </div>
        </div>

        <div
          v-if="event.isJoined == 1"
          @click.stop="showInfoPopup(event)"
          class="
            py-3
            text-center
            bg-confirmed
            text-white
            rounded-bottom
            font-weight-normal
          "
        >
          {{ $t("you_joining") }}
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "eventlist",
  props: {
    type: {
      type: Number,
    },
    dataList: {
      type: Array | null,
      default: null,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    showInfoPopup(event) {
      this.$emit("eventInfo", {
        eventId: event.event_id,
        type: 1,
      });
    },
    eventInfo(event) {
      //   if (!event.isJoined) {
      //     return false;
      //   }
      this.$emit("eventInfo", {
        eventId: event.event_id,
        type: 0,
      });
    },
    joinEvent(eventId) {
      this.$emit("joinEvent", {
        eventId,
      });
    },
  },
};
</script>