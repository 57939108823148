import tkeys from '../utils/translation_hepler'
export default {

    SET_ACTIVE_TYPE: (state, { message }) => {
        state.message = message
    },

    SET_ATTRS: (state, attrs) => {
        state.attrs = attrs
    },
    SET_SITES: (state, { gsites, gcities }) => {
        //console.log('SET_SITES')
        state.sites = gsites;
        state.cities = gcities;
    },
    SET_CUR_LANG: (state, { curlang }) => {
        state.curLang = curlang;
    },
    SET_CUR_SITE: (state, { cursite }) => {
        state.curSite = cursite;
    },
    SET_CUR_CITY: (state, { curcity }) => {
        state.curCity = curcity;
    },
    SET_TRANSLATION: (state, { translation, cache_key }) => {
        // console.log('real_singles--', translation['th']['real_singles'])
        // console.log('web_footer_link_about--', translation['th']['web_footer_link_about'])
        //todo need filter no used translation data

        // state.translation = translation
        // console.log('tkeys=', tkeys)
        let usedTrans = tkeys.getUsedTrans(translation, state)
        usedTrans.en_us.iso2 = translation.en_us.iso2
        usedTrans.cache_key = cache_key
        state.translation = usedTrans;
        // console.log('----- SET_TRANSLATION end')
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_USER: (state, user) => {
        state.user = user;
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo;
    },
    SET_IP_CITY: (state, { ipCity }) => {
        console.log('set ipcity', ipCity)
        // console.log('state',state)
        // console.log('state.cities',state.cities)
        let cities = state.cities;
        let ip_city = '';
        if (ipCity.data) {
            if (ipCity.data.city) {
                ip_city = ipCity.data.city.names['en'].toLowerCase().replace(' ', '');
            }

            if (!cities[ip_city] && ipCity.data.country) {
                ip_city = ipCity.data.country.names['en'].toLowerCase().replace(' ', '');
            }

            if (cities[ip_city]) {
                state.ipCity = ip_city;
                console.log('getipcity', ip_city)
            }
        }
    },

    reset(state) {
        // acquire initial state
        const s = {
            message: null,
            copyWriting: null,
            sites: null,
            cities: null,
            curCity: '',
            curLang: 'en_US',
            ipCity: '',
            curSite: {},
            translation: {}
        };
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    }
}
