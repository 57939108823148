<template>
  <div>
    <div v-if="client" class="lsrow bg-white pb-3 rounded">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <!-- <div class="position-relative"> -->
        <div v-if="client.primary_photo" class="position-relative">
          <img :src="client.primary_photo" class="w-100 border-0" />

          <div class="user-info p-3">
            <div
              v-if="client.is_verified"
              class="userVerified"
              style="right: 10px"
            >
              <i class="lsicon verified lsicon-40" style="top: -2px"></i>
            </div>
            <div class="name_str font-weight-normal">
              {{ client.first_name }}, {{ client.age }}
              <!-- <span class="ml-1">
                <i class="fa fa-check-circle text-ls-y"></i>
              </span> -->
            </div>
            <div class>
              <p class="font-weight-light small text-truncate">
                <i class="fa fa-suitcase text-gold"></i>
                {{ client.job_title }}
              </p>
            </div>
          </div>
        </div>

        <div class="botton_row" v-if="client && intro && label_buttons.buttons">
          <div class="text-center">
            <p v-if="label_buttons.date_title" class="font-weight-bold mt-3">
              {{ label_buttons.date_title }}
            </p>
            <p
              v-if="label_buttons.label"
              class="mt-3 font-weight-bold"
              :style="`color:${label_buttons.label_color}`"
              v-html="labelText"
            ></p>
          </div>

          <div class="text-center mt-2">
            <!-- <button
              v-if="label_buttons.buttons.chat_extend"
              @click="chatExtend"
              class="shadow o-btn small"
            >
              <i class="lsicon extend_chat_red lsicon-36"></i>
            </button> -->

            <button
              v-if="label_buttons.buttons.go_chat"
              @click="goToChat"
              class="shadow o-btn lg"
            >
              <i class="lsicon chat_full_red lsicon-50"></i>
            </button>

            <!-- <button
              v-if="label_buttons.buttons.lovestruck"
              @click="lovestruckMatch"
              class="shadow o-btn small"
            >
              <i class="lsicon super_like_red lsicon-36"></i>
            </button> -->

            <button
              v-if="label_buttons.buttons.arrange_date"
              @click="arrangeDate()"
              class="shadow o-btn"
            >
              <i class="lsicon arrange_date_red lsicon-40"></i>
            </button>
            <button
              v-if="label_buttons.buttons.chat_again"
              @click="chatAgain"
              class="shadow o-btn"
            >
              <i class="lsicon chat_again_red lsicon-40"></i>
            </button>

            <!-- <button
              v-if="label_buttons.buttons.next_match"
              @click="goToNextMatch"
              class="mr-3 shadow o-btn"
            >
              <i class="lsicon next_full_red lsicon-40"></i>
            </button> -->
          </div>

          <!-- <div
            v-if="client && intro && label_buttons.buttons"
            class="mt-5 text-center"
          >
            
            <b-button
              @click="skipDate"
              v-if="label_buttons.buttons.skip"
              variant="light"
              class="mr-3"
              style="width: 38px !important; border-radius: 19px"
              >X</b-button
            >

            <b-button
              v-if="label_buttons.buttons.change"
              @click="changeDate()"
              variant="ls-g"
              class="mr-3"
              >{{ $t("change_date_txt") }}</b-button
            >
            <b-button
              v-if="label_buttons.buttons.confirm"
              @click="confirmDate"
              variant="ls-p"
              class=""
              >{{ $t("confirm_date_txt") }}</b-button
            >
          </div> -->

          <!-- <div
            v-if="client && !intro && label_buttons.buttons"
            class="mt-5 text-center"
          >
            
            <b-button
              @click="skipDate"
              v-if="label_buttons.buttons.skip"
              variant="light"
              class="shadow mr-4"
              style="width: 38px !important; border-radius: 19px"
              >X</b-button
            >
            <b-button
              v-if="label_buttons.buttons.start_date"
              @click="startDate"
              variant="ls-y"
              class="shadow"
              ><i class="fab fa-weixin mr-2 position-relative"></i
              >{{ $t("Start date") }}</b-button
            >
            <b-button
              v-if="label_buttons.buttons.arrange_date"
              @click="arrangeDate()"
              variant="ls-y"
              class="shadow"
              ><i class="fa fa-calendar-alt text-white mr-2"></i
              >{{ $t("Arrange Date") }}</b-button
            >
          </div> -->
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="p-3">
          <p v-if="client.height" class="mb-1">
            <i class="lsicon height"></i>
            {{ client.height }}
          </p>
          <p v-if="client.ethnicity" class="text-truncate mb-1">
            <i class="lsicon ethnicity"></i>
            {{ client.ethnicity }}
          </p>
          <p v-if="client.nationality" class="text-truncate mb-1">
            <i class="lsicon nationality"></i>
            {{ client.nationality }}
          </p>
          <p v-if="client.education_level" class="text-truncate mb-1">
            <i class="lsicon education"></i>
            {{ client.education_level }}
          </p>
          <p v-if="client.annual_income" class="text-truncate mb-1">
            <i class="lsicon work"></i>
            {{ client.annual_income }}
          </p>
          <p v-if="client.relationship_status" class="text-truncate mb-1">
            <i class="lsicon relationship"></i>
            {{ client.relationship_status }}
          </p>
          <p v-if="client.child_status" class="text-truncate mb-1">
            <i class="lsicon child_status"></i>
            {{ client.child_status }}
          </p>
          <p v-if="client.child_plans" class="text-truncate mb-1">
            <i class="lsicon child_plans"></i>
            {{ client.child_plans }}
          </p>
          <p v-if="client.religion" class="text-truncate mb-1">
            <i class="lsicon religion"></i>
            {{ client.religion }}
          </p>
          <p v-if="client.drinking_status" class="text-truncate mb-1">
            <i class="lsicon drinking"></i>
            {{ client.drinking_status }}
          </p>
          <p v-if="client.smoking_status" class="text-truncate">
            <i class="lsicon smoking"></i>
            {{ client.smoking_status }}
          </p>
          <!-- <div class="lsrow mt-2">
          <div class="col-12">
            <p class="text-truncate">
              <i class="lsicon job"></i>
              {{ client.job_title }}
            </p>
          </div>
        </div> -->
          <!-- <div class="lsrow mt-2">
          <div class="col-lg-6 col-md-6 col-sm-12">
            
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            
          </div>
        </div> -->
          <!-- <div class="row mt-2">
                            <div class="col-12"><span><i class="lsicon work"></i>  {{client.annual_income}} </span></div>
            </div>-->
          <!-- <div class="lsrow mt-2">
          <div class="col-12">
            <p class="text-truncate">
              <i class="lsicon relationship"></i>
              {{ client.relationship_status }}
            </p>
          </div> -->
          <!-- <div class="col-6 text-truncate"><span><i class="lsicon religion"></i>  {{client.religion}} </span></div> -->
          <!-- </div> -->
          <!-- <div class="lsrow mt-2">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <p class="text-truncate">
              <i class="lsicon child_status"></i>
              {{ client.child_status }}
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <p class="text-truncate">
              <i class="lsicon child_plans"></i>
              {{ client.child_plans }}
            </p>
          </div>
        </div> -->
          <!-- <div class="lsrow mt-2">
          <div class="col-12">
            <p class="text-truncate">
              <i class="lsicon religion"></i>
              {{ client.religion }}
            </p>
          </div>
        </div> -->
          <!-- <div class="lsrow mt-2">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <p class="text-truncate">
              <i class="lsicon drinking"></i>
              {{ client.drinking_status }}
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <p class="text-truncate">
              <i class="lsicon smoking"></i>
              {{ client.smoking_status }}
            </p>
          </div>
        </div> -->
          <hr />
          <h5 class="text-secondary text-capitalize">
            {{ $t("about_myself") }}
          </h5>
          <div class="font-weight-light text-break">
            {{ client.self_intro }}
          </div>
        </div>

        <ul v-if="client.other_photos" class="profile_photo_list">
          <li v-for="(ophoto, index) in client.other_photos" :key="index">
            <img :src="ophoto" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "profile-card",
  data() {
    return {
      client: null,
      intro: null,
      label_buttons: null,
      show_button: false,
      chatCloseLeftSec: 0,
      firstLabelText: "",
      labelText: "",
      dateTitle: null,
      labelColor: "",
      countdownLeftTimer: null,
    };
  },
  mounted() {},
  methods: {
    chatAgain() {
      this.$emit("chatAgain");
    },
    chatExtend() {
      this.$emit("chatExtend");
    },
    lovestruckMatch() {
      this.$emit("lovestruckMatch");
    },
    goToChat() {
      this.$emit("goToChat");
    },
    goToNextMatch() {
      this.$emit("goToNextMatch");
    },
    skipDate() {
      this.$emit("skipDate");
    },
    confirmDate() {
      this.$emit("confirmDate");
    },
    startDate() {
      this.$emit("startDate");
    },
    changeDate() {
      this.$emit("changeDate");
    },
    arrangeDate() {
      this.$emit("arrangeDate");
    },
    showView(match, show_button) {
      let self = this;
      clearInterval(self.countdownLeftTimer);
      self.client = _.get(match, "target_client");
      self.intro = _.get(match, "intro");
      self.label_buttons = _.get(match, "label_button");
      self.show_button = show_button;
      //   console.log("show", client);
      //   this.initView();
      //   console.log("show2", client.first_name);

      self.firstLabelText = _.get(self.label_buttons, "label");
      self.dateTitle = _.get(self.label_buttons, "date_title");
      if (self.firstLabelText.indexOf("[left_time]") == -1) {
        self.labelText = _.get(self.label_buttons, "label")
          .replace(/\r\n/g, "<hr/>")
          .replace(/\n/g, "<hr/>");
      }

      self.secLeft = parseInt(_.get(self.label_buttons, "time_left", 0));
      self.labelColor = _.get(self.label_buttons, "label_color");
      if (self.secLeft > 0) {
        self.countdownLeftTime();
      }

      setTimeout(function () {
        self.$layer.closeAll("loading");
      }, 1000);
    },

    countdownLeftTime() {
      let self = this;
      self.countdownLeftTimer = setInterval(function () {
        self.secLeft--;
        if (self.secLeft < 0) {
          clearInterval(self.countdownLeftTimer);
        } else {
          let str = self.setLeftSec2(self.secLeft);
          self.labelText = self.firstLabelText.replace(
            "[left_time]",
            str
            // `<span class="text-danger ml-1">${str}</span>`
          );
          //   self.labelText = self.labelText.replace(
          //     /\(.*\)/,
          //     `(<span class="text-danger">${str}</span>)`
          //   );
        }
      }, 1000);
    },
    setLeftSec2(leftSec) {
      let self = this;
      //   console.log("self.secLeft=", self.secLeft);
      if (isNaN(leftSec) && leftSec <= 0) {
        return "";
      }
      let hours = Math.floor(leftSec / 3600);
      let minutes = Math.floor((leftSec - hours * 3600) / 60);
      let sec = leftSec - hours * 3600 - minutes * 60;

      let str = `${hours >= 10 ? hours : "0" + hours}:${
        minutes >= 10 ? minutes : "0" + minutes
      }:${sec >= 10 ? sec : "0" + sec}`;

      return str;
      //   console.log("left sec: ", str);
    },
  },
};
</script>
<style scoped>
.user-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
}
.profile_photo_list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}
.profile_photo_list li {
  width: 320px;
  display: inline-block;
}
.profile_photo_list li img {
  width: 100%;
}
@media (max-width: 575.98px) {
  .profile_photo_list li {
    width: 100%;
  }
  .botton_row {
    position: fixed;
    width: 100%;
    bottom: 80px;
    z-index: 3;
  }
}
</style>