<template>
  <div class="text-center">
    <!-- <h3>Choose a photo</h3> -->
    <!-- <p>{{ $t("upload_clearly_face_photo") }}</p> -->

    <div
      v-if="photoNum == 1 && photoType == 'match_photo'"
      class="photo_picker"
      :class="red_ver ? 'red' : ''"
    >
      <!-- <i class="img_counter">2</i> -->
      <div class="li_cont filediv">
        <div class="add_img">
          <div class="add_img_icon small">
            <span class="icon_div">+</span>

            <img
              v-if="photoType == 'id_photo'"
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/icon_upload_id_photo.svg"
              width="55"
            />
            <img
              v-else
              src="https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/icon_picture_upload_grey.png"
              width="55"
            />
            <span class="icon_title">{{ $t("update_photo_txt") }}</span>
          </div>

          <input
            type="file"
            accept="image/*"
            class="select_img_input"
            @change="handleInputChange"
          />
        </div>
      </div>
      <div class="li_cont imgdiv" style="display: none">
        <img class="myimg" src />
        <a href="javascript:;" @click="delImg" class="close_img_btn">X</a>
      </div>
    </div>

    <div
      v-if="photoNum == 1 && photoType == 'id_photo'"
      class="id_photo_picker mx-auto"
      :class="red_ver ? 'red' : ''"
      style="max-width: 200px"
    >
      <div class="position-relative">
        <img
          v-if="imageSelectedFiles[0]"
          :src="imageSelectedFiles[0]['src']"
          class="w-100"
        />

        <img
          v-else
          :src="`https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.2/${
            disapprovedTime
              ? 'verify_id_empty_rejected@3x.png'
              : 'verify_id_empty@3x.png'
          }`"
          class="w-100"
        />
        <!-- <img
          v-else
          :src="`https://cdn-cf2.lovestruck.com/upload-s/gen/img/ls-app-6.1/photo_ID_${idPhotoNamePart}.png`"
          class="w-100"
        /> -->

        <input
          type="file"
          accept="image/*"
          class="select_img_input id_photo_input"
          style="position: absolute; left: 0"
          @change="handleInputChange"
        />
      </div>
    </div>

    <div
      v-if="photoNum == 1 && photoType == 'receipt_photo'"
      class="receipt_photo_picker mx-auto mt-5"
      :class="red_ver ? 'red' : ''"
      style="width: 300px"
    >
      <div class="position-relative">
        <img
          v-if="imageSelectedFiles[0]"
          :src="imageSelectedFiles[0]['src']"
          class="w-100"
        />

        <!-- <img
          v-else
          :src="`https://cdn-cf2.lovestruck.com/upload-s/gen/img/red_design_2021/ID_card_placeholder${
            disapprovedTime ? '_b' : ''
          }.png`"
          class="w-100"
        /> -->

        <div
          v-else
          class="w-100"
          style="
            height: 300px;
            background: #fff;
            border-radius: 10px;
            font-size: 150px;
            display: inline-block;
            line-height: 300px;
            color: #ee153d;
          "
        >
          +
        </div>

        <input
          type="file"
          accept="image/*"
          class="select_img_input receipt_photo_input"
          style="position: absolute; left: 0"
          @change="handleInputChange"
        />
      </div>
    </div>

    <div v-if="styleType == 'v3' && photoNum > 1" class="my-4">
      <div class="d-flex justify-content-center mb-3">
        <div
          class="photoEditItem"
          :class="photoStatus.selectedTotal < 2 ? 's120' : ''"
        >
          <div v-if="imageSelectedFiles[0]" class="">
            <div
              class="bg_image imageView"
              :style="`background-image:url(${imageSelectedFiles[0]['src']});`"
            ></div>
            <div class="primaryTag"><i class="fa-solid fa-star text-f4b"></i></div>
          </div>
          <div v-else class="">
            <div class="empty_solt">
              +
              <input
                type="file"
                accept="image/*"
                class="select_img_input"
                style="position: absolute; left: 0"
                @change="handleInputChange"
              />
            </div>
          </div>
        </div>
        <div
          class="photoEditItem"
          :class="photoStatus.selectedTotal < 2 ? 's120' : ''"
        >
          <div v-if="imageSelectedFiles[1]" class="">
            
            <div
              class="bg_image imageView"
              :style="`background-image:url(${imageSelectedFiles[1]['src']});`"
            ></div>
            <div v-if="mode=='edit'">
              <a href="javascript:;" @click="removePhoto(1)" class="photo_remove v3"><i class="fa-light fa-xmark"></i></a>
            </div>
          </div>
          <div v-else class="">
            <div class="empty_solt">
              +
              <input
                type="file"
                accept="image/*"
                class="select_img_input"
                style="position: absolute; left: 0"
                @change="handleInputChange"
              />
            </div>
          </div>
        </div>
        <div class="photoEditItem" v-if="photoStatus.selectedTotal >= 2">
          <div v-if="imageSelectedFiles[2]" class="">
            
            <div
              class="bg_image imageView"
              :style="`background-image:url(${imageSelectedFiles[2]['src']});`"
            ></div>
            <div v-if="mode=='edit'">
              <a href="javascript:;" @click="removePhoto(2)" class="photo_remove v3"><i class="fa-light fa-xmark"></i></a>
            </div>
          </div>
          <div v-else class="">
            <div class="empty_solt">
              +<input
                type="file"
                accept="image/*"
                class="select_img_input"
                style="position: absolute; left: 0"
                @change="handleInputChange"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="photoStatus.selectedTotal >= 2"
        class="d-flex justify-content-center"
      >
        <div class="photoEditItem">
          <div v-if="imageSelectedFiles[3]" class="">
            <div
              class="bg_image imageView"
              :style="`background-image:url(${imageSelectedFiles[3]['src']});`"
            ></div>
            <div v-if="mode=='edit'">
              <a href="javascript:;" @click="removePhoto(3)" class="photo_remove v3"><i class="fa-light fa-xmark"></i></a>
            </div>
          </div>
          <div v-else class="">
            <div class="empty_solt">
              +<input
                type="file"
                accept="image/*"
                class="select_img_input"
                style="position: absolute; left: 0"
                @change="handleInputChange"
              />
            </div>
          </div>
        </div>
        <div class="photoEditItem">
          <div v-if="imageSelectedFiles[4]" class="">
            <div
              class="bg_image imageView"
              :style="`background-image:url(${imageSelectedFiles[4]['src']});`"
            ></div>
            <div v-if="mode=='edit'">
              <a href="javascript:;" @click="removePhoto(4)" class="photo_remove v3"><i class="fa-light fa-xmark"></i></a>
            </div>
          </div>
          <div v-else class="">
            <div class="empty_solt">
              +<input
                type="file"
                accept="image/*"
                class="select_img_input"
                style="position: absolute; left: 0"
                @change="handleInputChange"
              />
            </div>
          </div>
        </div>
        <div class="photoEditItem">
          <div v-if="imageSelectedFiles[5]" class="">
            <div
              class="bg_image imageView"
              :style="`background-image:url(${imageSelectedFiles[5]['src']});`"
            ></div>
            <div v-if="mode=='edit'">
              <a href="javascript:;" @click="removePhoto(5)" class="photo_remove v3"><i class="fa-light fa-xmark"></i></a>
            </div>
          </div>
          <div v-else class="">
            <div class="empty_solt">
              +<input
                type="file"
                accept="image/*"
                class="select_img_input"
                style="position: absolute; left: 0"
                @change="handleInputChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="styleType == 'v2' && photoNum > 1" class="my-4">
      <div class="mb-4">
        <div class="photo_view mx-auto">
          <div class="photo_img_div">
            <img
              v-if="imageSelectedFiles[0]"
              :src="imageSelectedFiles[0]['src']"
              class="photo_img"
            />
          </div>
          <div v-if="!imageSelectedFiles[0]" class="photo_choose">
            <span>+</span>
            <input
              type="file"
              accept="image/*"
              class="select_img_input"
              @change="handleInputChange"
            />
          </div>
          <div
            v-if="imageSelectedFiles[0]"
            @click="removePhoto(0)"
            class="photo_remove"
          >
            X
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="photo_view mr-4">
          <div class="photo_img_div">
            <img
              v-if="imageSelectedFiles[1]"
              :src="imageSelectedFiles[1]['src']"
              class="photo_img"
            />
          </div>
          <div v-if="!imageSelectedFiles[1]" class="photo_choose">
            <span>+</span>
            <input
              type="file"
              accept="image/*"
              class="select_img_input"
              @change="handleInputChange"
            />
          </div>
          <div
            v-if="imageSelectedFiles[1]"
            @click="removePhoto(1)"
            class="photo_remove"
          >
            X
          </div>
        </div>
        <div class="photo_view">
          <div class="photo_img_div">
            <img
              v-if="imageSelectedFiles[2]"
              :src="imageSelectedFiles[2]['src']"
              class="photo_img"
            />
          </div>
          <div v-if="!imageSelectedFiles[2]" class="photo_choose">
            <span>+</span>
            <input
              type="file"
              accept="image/*"
              class="select_img_input"
              @change="handleInputChange"
            />
          </div>
          <div
            v-if="imageSelectedFiles[2]"
            @click="removePhoto(2)"
            class="photo_remove"
          >
            X
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-html="$t('good_profile_photo_examples')"></div>
    <div class="text-center" style="max-width: 320px; margin: 0 auto">
      <img :src="exampleImage" alt="" class="w-100" />
    </div> -->
    <imageCopper @change="copperChange" ref="imageCopper" />
  </div>
</template>
<script>
if (typeof window != "undefined") {
  var ImageCompressor = require("image-compressor.js");
}
import imageCopper from "../common/imageCopper.vue";

export default {
  name: "one-photo-upload",
  components: {
    imageCopper,
  },
  props: {
    city_id: {
      type: Number,
      default: 1,
    },
    red_ver: {
      type: Boolean,
      default: false,
    },
    photoType: {
      type: String,
      default: "match_photo",
    },
    mode: {
      type: String,
      default: "add",
    },
    photoNum: {
      type: Number,
      default: 1,
    },
    disapprovedTime: {
      type: String,
    },
  },
  data() {
    return {
      styleType: "v3",
      currentFile: null,
      imgMaxSize: 1024 * 1024 * 20, // 10MB
      formData: {},
      canSubmit: false,
      photoStatus: {
        canSubmit: false,
        uploadSuccess: false,
        uploadCompleted: false,
        photo_url: [],
        selectedTotal: 0,
      },
      exampleImage: "",
      image1:
        "https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/profile_photo_examples1.png",
      image2:
        "https://cdn-cf2.lovestruck.com/upload-s/gen/img/2021/profile_photo_examples2.png",
      imageSelectedFiles: [],
      uploadedNum: 0,
      curSite: this.$store.getters.curSite,
      idPhotoNamePart: "hk",
    };
  },
  mounted() {
    let self = this;
    // console.log(self.curSite)
    if (self.curSite.iso2 == "TH") {
      self.idPhotoNamePart = "bkk";
    } else if (self.curSite.iso2 == "GB") {
      self.idPhotoNamePart = "uk";
    }

    if (_.indexOf([7, 22, 23, 24, 27, 28], self.city_id) > -1) {
      self.exampleImage = self.image2;
    } else {
      self.exampleImage = self.image1;
    }
  },
  methods: {
    copperChange(data) {
      //data.imageData;
      let self = this;
      self.showImage(data.imageData, data.file);
    },
    setSelectedTotal() {
      let self = this;
      self.selectedTotal = self.imageSelectedFiles.length;
      self.photoStatus.selectedTotal = self.imageSelectedFiles.length;
    },
    loadPhotos(photoList, cdnHost) {
      let self = this;

      if (photoList.length > 0) {
        self.photoStatus.photo_url = photoList;
        for (let photo of photoList) {
          self.imageSelectedFiles.push({
            id: photo.client_photo_id,
            src: `${cdnHost}${photo.photo_url}`,
            is_primary: photo.is_primary,
          });
        }
        // console.log("photoList=", self.imageSelectedFiles);

        self.setSelectedTotal();
        self.checkCanSubmit();
        console.log(
          "loadPhotos",
          self.imageSelectedFiles,
          self.photoStatus.selectedTotal
        );
      }
    },
    removePhoto(index) {
      let self = this;
      //   let wait = [];

      let id = _.get(self.imageSelectedFiles, `${index}.id`, 0);
      if (id > 0) {
        self.delImgFromServer(id, index);
      } else {
        self.removeSelectedImages(index);
      }
    },
    removeSelectedImages(index) {
      let self = this;
      let wait = [];
      for (let i in self.imageSelectedFiles) {
        if (i != index) {
          wait.push(self.imageSelectedFiles[i]);
        }
      }

      self.imageSelectedFiles = wait.length > 0 ? wait : [];
      self.setSelectedTotal();
      self.checkCanSubmit();
      //   self.$emit("photoStatus", self.photoStatus);
    },
    delImgFromServer(id, index) {
      let self = this;

      let fdata = new FormData();

      fdata.append("photo_id", id);
      self.$layer.loading();

      self.$api
        .postRemovePhoto(fdata, self.$store.state)
        .then((rs) => {
          self.$layer.closeAll("loading");
          //   console.log(rs);
          if (_.get(rs, "success")) {
            self.removeSelectedImages(index);
          }
        })
        .catch((err) => {
          self.$layer.closeAll("loading");
          self.$layer.msg(err.response.data.error.body);
        });
    },
    clearAndInit() {
      let self = this;
      //   console.log("...clearAndInit");
      self.canSubmit = false;
      self.formData = {};
      self.imageSelectedFiles = [];
      self.photoStatus = {
        canSubmit: false,
        uploadSuccess: false,
        uploadCompleted: false,
        photo_url: [],
      };
      $(".filediv").show();
      $(".imgdiv").hide();
    },
    toggleReceiptFile() {
      //   this.photoNum = 1;
      //   this.photoType = "id_photo";
      $("input.receipt_photo_input").first().click();
      //   console.log("toggleIDFile", this.photoNum, this.photoType);
    },
    toggleIDFile() {
      //   this.photoNum = 1;
      //   this.photoType = "id_photo";
      $("input.id_photo_input").first().click();
      //   console.log("toggleIDFile", this.photoNum, this.photoType);
    },
    toggleFile(index = 0) {
      //   let realIndex = index > 1 ? 0 : index;
      //   console.log(
      //     "toggleFile",
      //     index,
      //     realIndex,
      //     $("input.select_img_input").eq(realIndex)
      //   );

      $("input.select_img_input").eq(0).click();
    },
    handleInputChange() {
      let e = window.event;
      let self = this;

      let file = e.target.files[0];
      self.currentFile = file;
      //check type
      if (["jpeg", "png", "gif", "jpg"].indexOf(file.type.split("/")[1]) < 0) {
        self.$layer.msg(self.$t("api_error_type"));
        return;
      }

      //check size
      if (file.size > self.imgMaxSize) {
        self.$layer.msg(self.$t("api_photo_large"));
        return;
      }

      //   let index = $(e.target).closest("li").index(".img_list li");

      this.compressAndShow(file);
    },
    compressAndShow(file) {
      let self = this;

      const imageCompressor = new ImageCompressor.default(null, {
        quality: 0.9,
        convertSize: Infinity,
        maxWidth: 1000,
      });

      imageCompressor
        .compress(file)
        .then((file) => {
          // 创建 blob 字段 用于图片预览
          let blob = "";
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            blob = URL.createObjectURL(file);
          }
          // 缩略图
          let thumb = "";
          if (blob && file.type.substr(0, 6) === "image/") {
            thumb = blob;
          }
          if (self.photoType == "receipt_photo") {
            self.showImage(thumb ? thumb : blob, file);
          } else {
            self.$refs.imageCopper.showModal(
              file,
              self.photoType == "id_photo" ? 3 / 2 : 1 / 1
            );
          }

          //   if (self.photoNum == 1) {
          //     self.formData = self.blobToFormData(file);
          //     self.photoStatus.canSubmit = true;
          //   }

          //   self.$emit("photoStatus", self.photoStatus);
          //   self.autoUploadFile();
          //   self.upload();
        })
        .catch((err) => {
          console.log(["compressor error", err]);
        });
    },
    blobToFormData(file) {
      //let bolb = new Blob(blobAddr,{type:type})
      //const fileOfBlob = new File([blob], name);
      let formData = new FormData();

      formData.append("type", file.type);
      formData.append("size", file.size);
      formData.append("name", file.name);
      formData.append("photo", file);
      //formData.append('is_primary', is_primary);
      formData.append("show_on_profile", 1);

      //console.log(formData);
      return formData;
    },
    uploadImage() {
      let self = this;
      if (self.photoNum > 1) {
        self.uploads();
      } else {
        self.uploadOne();
      }
    },
    uploads() {
      let self = this;
      //   console.log(self.imageSelectedFiles);
      for (let source of self.imageSelectedFiles) {
        if (_.get(source, "id") > 0) {
          self.afterUpload();
          //   console.log("has id", self.uploadedNum);
          continue;
        }

        if (self.uploadedNum >= self.photoNum) {
          break;
        }
        let pdata = self.blobToFormData(source.file);

        self.upload(pdata);
      }
    },
    uploadOne() {
      let self = this;
      self.$layer.loading();
      self.formData = self.blobToFormData(
        _.get(self.imageSelectedFiles, "0.file")
      );
      //   self.formData.append("is_primary", 1);
      if (self.photoType == "id_photo") {
        self.formData.append("id_photo", 1);
      }

      self.upload(self.formData);
    },
    upload(formData) {
      let self = this;
      //   self.$layer.loading();
      //   self.formData.append("is_primary", 1);
      //   if (self.photoType == "id_photo") {
      //     self.formData.append("id_photo", 1);
      //   }
      //   console.log("upload to server");
      self.$api
        .postUploadPhoto(formData, self.$store.state)
        .then((rs) => {
          self.photoStatus.photo_url.push(_.get(rs, "photo.photo_url"));
          self.photoStatus.uploadSuccess = true;

          self.afterUpload();
        })
        .catch((err) => {
          // console.log(["upload error", err]);
          self.$layer.closeAll("loading");
          let message = _.get(
            err,
            "response.data.error.body",
            _.get(err, "message")
          );
          if (
            message.indexOf("timeout") > -1 ||
            message.indexOf("Network Error") > -1
          ) {
            // self.uploadNum++;
            self.afterUpload();
          } else {
            self.$layer.msg(message);
          }
        });
    },
    getSelectedFiles() {
      return this.imageSelectedFiles;
    },
    afterUpload(img, callback) {
      let self = this;
      self.$layer.closeAll("loading");
      self.uploadedNum++;
      console.log("afterUpload", self.uploadedNum, self.selectedTotal);
      if (self.uploadedNum >= self.selectedTotal) {
        self.photoStatus.uploadCompleted = true;
      }

      self.$emit("photoStatus", self.photoStatus);
    },
    showImage(blob, file) {
      let self = this;

      //   console.log("showImage", blob, file, self.photoNum, self.photoType);

      if (self.photoNum == 1 || self.photoType == "id_photo") {
        self.imageSelectedFiles = [
          {
            src: blob,
            file: file,
            "data-name": file.name,
            "data-type": file.type,
          },
        ];

        // console.log("imageSelectedFiles", self.imageSelectedFiles);

        //     self.imageSelectedFiles.push({
        //       src: blob,
        //       file: file,
        //       "data-name": file.name,
        //       "data-type": file.type,
        //     });
        //     console.log("imageSelectedFiles", self.imageSelectedFiles);
        //   } else {
        // $("img.myimg").attr({
        //   src: blob,
        //   "data-name": file.name,
        //   "data-type": file.type,
        // });

        // $(".li_cont").first().hide();
        // $(".li_cont").last().show();
      } else {
        self.imageSelectedFiles.push({
          src: blob,
          file: file,
          "data-name": file.name,
          "data-type": file.type,
        });
      }
      this.setSelectedTotal();
      this.checkCanSubmit();
      console.log(
        "showImage===",
        self.imageSelectedFiles,
        self.photoStatus.selectedTotal
      );
      //this.resetAddimgBtn();
    },
    checkCanSubmit() {
      let self = this;
      //   let img = $("img.myimg");

      self.canSubmit = false;

      //   if (img.attr("src")) {
      if (self.imageSelectedFiles.length >= self.photoNum) {
        self.canSubmit = true;
      }

      self.photoStatus.canSubmit = self.canSubmit;
      //   self.$emit("photoStatus", self.photoStatus);

      self.$emit("photoStatus", self.photoStatus);
    },
    delImg() {
      let self = this;
      let img = $("img.myimg");

      img.attr({ src: "" });

      $(".li_cont").first().show();
      $(".li_cont").last().hide();

      self.formData = null;

      self.checkCanSubmit();
    },
  },
};
</script>
<style scoped>
.photoEditItem {
  position: relative;
  max-width: 150px;
  width: 110px;
  height: 110px;
  font-size: 60px;
  /* overflow: hidden; */
  border-radius: 10px;
  padding: 5px;
}
.photoEditItem.s120 {
  width: 130px;
  height: 130px;
  font-size: 70px;
  line-height: 120px;
}
.empty_solt {
  color: #d7c2bf;
  border: 2px dotted #d7c2bf;
  border-radius: 10px;
  background: #fff;
  width: 100px;
  height: 100px;
}
.imageView {
  border-radius: 10px;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.photoEditItem.s120 .empty_solt,
.photoEditItem.s120 .imageView {
  width: 120px;
  height: 120px;
}
.photo_view {
  width: 150px;
  height: 150px;
  background: #ef628c;
  border-radius: 10px;
  position: relative;
}
.photo_img_div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}
.photo_choose,
.photo_remove {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 36px;
  font-weight: 700;
  color: #e61e5a;
  background: #fff;
  border-radius: 20px;
  position: absolute;
  bottom: -8px;
  right: -8px;
  cursor: pointer;
}
.photo_remove {
  font-size: 24px;
}
.photo_remove.v3{
  width: 20px;
    height: 20px;
    font-size: 16px;
    line-height: 20px;
    color: #1d1d1d;
    bottom: 7px;
    right: 7px;
    opacity: 0.8;
}
.primaryTag{
  position: absolute;
    top: 10px;
    left: 10px;
    font-size: 16px;
}
.photo_choose input {
  position: absolute;
  left: 0;
  top: 0;
}

img.photo_img {
  width: 100%;
}

.photo_picker {
  width: 200px;
  position: relative;
  height: 300px;
  margin: 30px auto;
}
.photo_picker.red {
  height: 200px;
}
.li_cont {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
}
.add_img {
  width: 100%;
  height: 100%;
  /* background: #d4af72; */
  color: #fff;
  overflow: hidden;
  border: 2px dotted #111;
  border-radius: 10px;
}
.photo_picker.red .add_img {
  border-color: #fff;
}
.add_img_icon {
  display: block;
  text-align: center;
  width: 100px;
  height: 80px;
  position: absolute;
  bottom: 115px;
  /* margin-bottom: -40px; */
  left: 50%;
  margin-left: -50px;
  color: #111;
  cursor: pointer;
}
.photo_picker.red .add_img_icon {
  color: #fff;
}
.add_img_icon .icon_div {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 80px;
  font-weight: bold;
}
.add_img_icon .icon_div.small {
  font-size: 40px;
}
.add_img_icon .icon_title {
  display: block;
  margin-top: 10px;
}
.close_img_btn {
  position: absolute;
  font-size: 12px;
  bottom: -2px;
  right: -2px;
  width: 20px;
  height: 20px;
  display: block;
  background: #fff;
  color: #111;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
  border-radius: 10px;
}
.select_img_input {
  display: inline-block;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.li_cont img.myimg {
  width: 100%;
}
</style>