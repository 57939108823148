<template>
  <div>
    <div
      class="modal fade"
      id="myModal"
      tabindex="-1"
      aria-labelledby="myModalLabel"
      :aria-hidden="ariaHidden"
      :class="show ? 'show' : ''"
      style="background: rgba(0, 0, 0, 0.4); overflow-y: scroll"
      :style="modalStyle"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="position-absolute" style="z-index: 1800">
            <a href="javascript:;" @click="closeModal" class="p-2">
            <!-- <i class="fa fa-close text-999 fw-300 fs-30 mt-2"></i> -->
              <i class="lsicon close_grey2" style="top: 8px"></i>
            </a>
          </div>
          <div class="modal-body">
            <upgradeCom ref="upgradeCom" @diamondBook="diamondBookClick" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import upgradeCom from "../common/membershipUpgradeCom";
export default {
  name: "membership-upgrade-popup",
  components: {
    upgradeCom,
  },
  data() {
    return {
      show: false,
      client: null,
      modalStyle: "",
      myModal: null,
      ariaHidden: true,
    };
  },
  mounted() {
    // this.myModal = new bootstrap.Modal(document.getElementById("myModal"), {
    //   keyboard: false,
    // });
    // console.log("this.myModal", this.myModal);
  },
  methods: {
    diamondBookClick() {
      this.closeModal();
    },
    closeModal() {
      let self = this;
      self.show = false;
      self.client = null;
      self.modalStyle = "";
      self.ariaHidden = true;
      self.$refs.upgradeCom.resetStatus();
      console.log("closeModal");
    },
    showModal(options) {
      let self = this;
      self.client = _.get(options, "client", null);
      self.$refs.upgradeCom.initComData(options);
      self.show = true;
      self.modalStyle = "display:block;";
      self.ariaHidden = false;

      //   self.myModal.show();
    },
  },
};
</script>