<template>
    <div>
        <!-- <canvas id="canvas"></canvas>
    <img id="source" src="" style="display: none;" crossorigin="anonymous"> -->
    <img id="preview" src="" crossorigin="anonymous">
    </div>
</template>
<script>
export default {
    name: 'test-canvas',
    data(){
        return {
            imagePath: 'https://cdn-cf2.lovestruck.com/upload/chat/2023/01/7d561c8d476ad0139b85f9a0b359dd0c.jpg',
            option: {
                x: 500,
                y: 500,
                w: 1200,
                h: 1800
            }
        }
    },
    mounted(){
        let self = this;
        // var canvas = document.getElementById('canvas');
        // var source = document.getElementById('source');
        var preview = document.getElementById('preview');
        
        var source = new Image();
        source.setAttribute('crossorigin', 'anonymous')
        source.onload = function () {
            // canvas.width = Math.floor(option.w);
            // canvas.height = Math.floor(option.h);
            // var ctx = canvas.getContext("2d");    // 获取2D渲染界面
            // ctx.drawImage(source, -Math.floor(option.x), -Math.floor(option.y)); // 绘制图像偏移以将其正确放置在裁剪区域上
            // let imageData = canvas.toDataURL('image/jpeg', 0.95);

            // preview.src = self.cropImage(this, self.option)
            preview.src = self.resizeImage(self.cropImage(this, self.option));
        }

        source.src = self.imagePath;
    },
    methods:{
        cropImage: function (image, croppingCoords) {

            var cc = croppingCoords;

            var workCan = document.createElement("canvas"); 

            workCan.width = Math.floor(cc.w);  

            workCan.height = Math.floor(cc.h);

            var ctx = workCan.getContext("2d"); 

            ctx.drawImage(image, -Math.floor(cc.x), -Math.floor(cc.y));
            return workCan;
            let imgbase64 = workCan.toDataURL(); 
            // let start = imgbase64.indexOf('base64,') + 7;
            // imgbase64 = imgbase64.substring(start, imgbase64.length);
            return imgbase64;
        },
        resizeImage(imgBase64){
            let can = document.createElement("canvas"); 
            can.width = 1000;  
            can.height = 1500;

            var ctx = can.getContext("2d"); 

            ctx.drawImage(imgBase64, 0, 0, 1200, 1800, 0, 0, 1000, 1500)

            let imgbase64 = can.toDataURL(); 

            return imgbase64;
        }
    }
}
</script>